import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { forEach as _forEach } from 'lodash';
import { Button } from 'react-bootstrap';
import { getDeviceType } from '../cc-editor/components/actions/deviceType';
import JSZip from 'jszip';
import { DELETER_FULFILLED } from 'actions/DeleterActions';

const initialState = {
  editionRV: null,
  pendingR: true,
  previewingR: false,
  printingR: false,
  accessibleR: false,
  exportingR: false,
  printx: false,
  savingR: false,
  unsavedR: false,
  linkDataR: '',
  campaignOptionsR: null,
  forwardingR: false,
  changesR: {},
  showErrorMessage: false,
  waitForSaving: false,
  placeholderPendingR: false,
  placeholderErrorR: false,
  timelineR: null,
  pendingTimelineR: false,
  timelineStartR: 0,
  updateTimelineR: true,
  showLoadMoreEntriesR: false,
  navEntries: undefined,
  isSubmission: false,
  submissionsC: { list: [], pending: false },
};

function handleButtonClick(downloadLink) {
  fetch(downloadLink)
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const filename = getFilenameFromUrl(downloadLink);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      link.click();
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}

function getFilenameFromUrl(url) {
  const path = url.split('/'); // Die URL anhand des Schrägstrichs (/) aufteilen
  return path.pop(); // Den letzten Teil der aufgeteilten URL zurückgeben, was normalerweise der Dateiname ist
}

function downloadZip(fileLinks) {
  const zip = new JSZip();
  const promises = fileLinks.map(fileLink => {
    return fetch(fileLink)
      .then(response => response.blob())
      .then(blob => {
        zip.file(getFilenameFromUrl(fileLink), blob);
      })
      .catch(error => {
        console.error('Error adding files to ZIP:', error);
      });
  });

  // Wait for all files to download before generating the ZIP archive
  Promise.all(promises)
    .then(() => {
      return zip.generateAsync({ type: 'blob' });
    })
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'Media.zip');
      link.click();
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error('Error creating ZIP:', error);
    });
}

export default function editor(state = initialState, action) {
  switch (action.type) {
    case 'GET_EDITION_PENDING': {
      return { ...state, pendingR: true };
    }
    case 'GET_EDITION_FULFILLED': {
      return { ...state, editionRV: action.payload, pendingR: false };
    }
    case 'GET_SUBMISSIONS_PENDING': {
      return { ...state, submissionsC: { pending: true } };
    }
    case 'GET_SUBMISSIONS_FULFILLED': {
      return { ...state, submissionsC: { list: action.payload, pending: false } };
    }
    case DELETER_FULFILLED: {
      const {
        payload: { deleterName },
      } = action;

      if (deleterName === 'submission') {
        const submissionsArray = state.submissionsC.list.list; // Zugriff auf list.list
        const deletedId = action.payload.response.data;

        // Neue Liste der Submissions ohne das gelöschte Element
        const newSubmissionsArray = submissionsArray.slice().filter(submission => submission.id !== deletedId);

        // Erstelle einen neuen State mit der aktualisierten submissionsC-Liste
        const newState = { ...state };
        newState.submissionsC = {
          ...state.submissionsC,
          list: {
            ...state.submissionsC.list,
            list: newSubmissionsArray, // Setze das gefilterte Array in list.list
          },
        };

        return newState; // Rückgabe des neuen States
      }

      break;
    }
    case 'GET_PDF_EDITOR_FULFILLED': {
      // handle success
      const { exportingR } = state;
      const exportImage = exportingR;
      const exportVideo = exportingR;
      const shareFB = url => {
        if (navigator.share) {
          navigator
            .share({
              title: 'CreaCheck',
              url,
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + url,
            'pop',
            'width=600, height=400, scrollbars=no'
          );
        }
      };

      const options = {
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>{action.payload?.messages.your_export}</h1>
              <p>{action.payload?.messages.export_download_info}</p>
              <div className="edition-download">
                {typeof action.payload?.data === 'string' && (
                  <>
                    <Button
                      bsStyle="success"
                      onClick={() => {
                        handleButtonClick(action.payload?.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download}
                    </Button>
                    {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && (exportImage === true || exportVideo === true) && (
                      <Button onClick={() => shareFB(action.payload?.data)}>
                        {action.payload?.messages.export_share}
                      </Button>
                    )}
                  </>
                )}
                {typeof action.payload?.data === 'object' && action.payload.data.length > 0 && (
                  <>
                    <Button
                      bsStyle="success"
                      onClick={() => {
                        downloadZip(action.payload.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download + ' - ZIP'}
                    </Button>
                    {action.payload.data.map((file, i) => {
                      let count = i + 1;
                      return (
                        <>
                          <Button
                            onClick={() => {
                              handleButtonClick(file);
                            }}
                          >
                            {action.payload?.messages.export_download +
                              ' - ' + action.payload?.messages.export_file +
                              ' ' + count}
                          </Button>
                          {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && exportImage === true && (
                            <Button onClick={() => shareFB(file)}>{action.payload?.messages.export_share}</Button>
                          )}
                        </>
                      );
                    })}
                    <Button
                      bsStyle="warning"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      {action.payload?.messages.editor.abort}
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };
      confirmAlert(options);

      return { ...state, previewingR: false, printingR: false, accessibleR: false, exportingR: false };
    }
    case 'SET_VARIABLES_IN_EDITOR': {
      if (action.payload === 'printing') {
        return { ...state, printingR: true };
      }
      if (action.payload === 'accessible') {
        return { ...state, accessibleR: true };
      }
      if (action.payload === 'exporting') {
        return { ...state, exportingR: true };
      }
      if (action.payload === 'previewing') {
        return { ...state, previewingR: true, printx: true };
      }
      if (action.payload === 'savingtrue') {
        return { ...state, savingR: true, showErrorMessage: false };
      }
      if (action.payload === 'unsavedtrue') {
        return { ...state, unsavedR: true };
      }
      if (action.payload === 'forwardingfalse') {
        return { ...state, forwardingR: false };
      }
      if (action.payload === 'linkdata') {
        return { ...state, linkDataR: '' };
      }
      if (action.payload === 'waitForSavingTrue') {
        return { ...state, waitForSaving: true };
      }
      if (action.payload === 'waitForSavingFalse') {
        return { ...state, waitForSaving: false };
      }
      if (action.payload === 'resetTimeline') {
        return {
          ...state,
          timelineR: null,
          pendingTimelineR: false,
          timelineStartR: 0,
          updateTimelineR: true,
          showLoadMoreEntriesR: false,
        };
      }
      return { ...state, unsavedR: action.payload };
    }
    case 'SAVE_CONTENT_FULFILLED': {
      return {
        ...state,
        savingR: false,
        editionRV: action.payload,
        changesR: {},
        waitForSaving: false,
        updateTimelineR: true,
      };
    }
    case 'SAVE_CONTENT_REJECTED': {
      return { ...state, savingR: false, unsavedR: true, showErrorMessage: true };
    }
    case 'SAVE_TO_FULFILLED': {
      return { ...state, savingR: false, linkDataR: action.payload.id };
    }
    case 'INIT_TEMPLATE_FULFILLED': {
      return { ...state, savingR: false, forwardingR: true };
    }
    case 'GET_CAMPAIGNS_FULFILLED': {
      const campaignOptionsCreate = [];
      _forEach(action.payload.list, campaign => {
        campaignOptionsCreate.push({ label: campaign.title, value: campaign.id });
      });
      return { ...state, campaignOptionsR: campaignOptionsCreate };
    }
    case 'SET_CHANGESR': {
      return { ...state, changesR: action.payload };
    }
    case 'SET_DATABASE_PENDING': {
      return { ...state, placeholderPendingR: true, placeholderErrorR: false };
    }
    case 'SET_DATABASE_FULFILLED': {
      return { ...state, editionRV: action.payload, placeholderPendingR: false };
    }
    case 'SET_DATABASE_REJECTED': {
      return { ...state, placeholderPendingR: false, placeholderErrorR: true };
    }
    case 'GET_TIMELINE_PENDING': {
      return { ...state, pendingTimelineR: true };
    }
    case 'GET_TIMELINE_FULFILLED': {
      const { timelineR, updateTimelineR } = state;
      let timelineRConcat;
      let timelineStart = 0;
      const limit = 10;
      let showLoadMoreEntries;

      if (timelineR !== null && timelineR.length >= limit && updateTimelineR === false) {
        timelineRConcat = timelineR;
        if (action.payload.length < limit) {
          showLoadMoreEntries = false;
        }
        if (action.payload && action.payload.length !== 0) {
          timelineRConcat = timelineR.concat(action.payload);
          timelineStart = timelineRConcat.length;
        }
      } else {
        timelineRConcat = action.payload;
        timelineStart = action.payload.length;
        if (timelineStart >= limit) {
          showLoadMoreEntries = true;
        }
      }
      return {
        ...state,
        timelineR: timelineRConcat,
        pendingTimelineR: false,
        timelineStartR: timelineStart,
        updateTimelineR: false,
        showLoadMoreEntriesR: showLoadMoreEntries,
      };
    }
    case 'SET_NAV_ENTRIES': {
      return { ...state, navEntries: action.payload };
    }
  }
  return state;
}

/*
 case 'E_F_UPLOAD_PENDING': {
      return { ...state, pending: true };
    }
    case 'E_F_UPLOAD_FULFILLED': {
      return { ...state, mediaFolders, mediaImages, pending: false };
    }

case 'GET_RSS_FULFILLED': {
  const { xxx } = payload;
  return { ...state, yyy };
}
  */
