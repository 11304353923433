import React from 'react';
import { connect } from 'react-redux';
import _, { find as _find, cloneDeep as _cloneDeep } from 'lodash';
import ReactDOM from "react-dom";
import {Button, Col, Row} from "react-bootstrap";
import FormAutocomplete from "../../../components/forms/FormAutocomplete";
import FormInput from "../../../components/forms/FormInput";
import FormFile from "../../../components/forms/FormFile";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import MediaLibraryCreacheck from "components/layout/mediaLibrary/MediaLibraryCreacheck";
import Tabs from "components/layout/Tabs";
import {createObjectURL} from "helpers/upload";
import EditorApplyStyles from "cc-editor/components/EditorApplyStyles";
import ImageEditorRc from "cc-editor/build/react-image-editor";
import Loader from "components/layout/Loader";
import Slider from "rc-slider/lib/Slider";
import ReactTooltip from 'react-tooltip';
import {confirmAlert} from "react-confirm-alert";
import EditorSettingsContext from "cc-editor/EditorSettingsContext";


const ContextModal = ({children}) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('context-modal')
  );
};

class Creatomate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: [],
      videoEdit: [],
      videoFile: undefined,
      image: [],
      imageEdit: [],
      imageFile: undefined,
      showMediaEdit: false,
      showMedia: false,

      showImageTooSmallError: false,
      showImageEditor: false,
      imageKey: '',
      imageThumb: '',
      editorImageSrc: null,
      imageSrc: null,
      imgWidth: 0,
      imgHeight: 0,



    }
    this.cropperRef = React.createRef();
  }


  setMedia = (initialUpdate, mediaType) => {
    const { id, attributes, updateParam, imageUpload } = this.props;

    if (mediaType === 'video') {
      const { videoEdit, videoFile } = this.state;
      let param = 'video-url' + '#aaa#' + videoEdit[1] + '#aaa#' + videoEdit[2] + '#aaa#' + videoEdit[3] + '#aaa#' + videoEdit[4];
      const overflow = false;
      const default_image = false;
      const errors = [{overflow, default_image}];
      const type = 'creatomate';

      updateParam(id, attributes['data-name'], param, errors, '', '', null, initialUpdate, type);

      if (videoEdit[0]==='own' && videoFile) {
        imageUpload(
          id,
          videoFile,
          'video',
          attributes['data-name'],
          null,
          0,
          0,
          null,
          null,
          null,
          null,
          null,
          'video-url',
          null,
          null,
          null,
          videoEdit[3],
          videoEdit[4],
        );
      }

      this.setState({video: videoEdit, image: [], showMediaEdit: false, videoFile: undefined}, () => {});

    } else {
        const { imageEdit } = this.state;
        // param = type + url + duration
        let param = 'image-url' + '#aaa#' + imageEdit[1] + '#aaa#' + imageEdit[2] + '#aaa#' + imageEdit[3] + '#aaa#' + imageEdit[4];
        const overflow = false;
        const default_image = false;
        const errors = [{overflow, default_image}];
        const type = 'creatomate';

        updateParam(id, attributes['data-name'], param, errors, '', '', null, initialUpdate, type);

        this.setState({image: imageEdit, video: [], showMediaEdit: false, imageFile: undefined}, () => {});
    }


  }

  getVideo = () => {
    const { name: Tag, attributes, params } = this.props;
    let video = []
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      video = param.value.split('#aaa#');
    }

    this.setState({video: video, videoEdit: video, image: [], imageEdit: []}, () => {  });
  }

  getImage = () => {
    const { name: Tag, attributes, params } = this.props;
    let image = []
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      image = param.value.split('#aaa#');
    }

    this.setState({image: image, imageEdit: image, video: [], videoEdit: []}, () => {  });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { attributes, params } = this.props;

    const param = _find(params, ['name', attributes['data-name']]);
    const prevPropsParam = _find(prevProps.params, ['name', attributes['data-name']]);
      if (param !== prevPropsParam) {
        if (param.value.includes('video') || param.value.includes('link')) {
          this.getVideo();
        } else if (param.value.includes('image')) {
          this.getImage();
        }
      }

  }

  componentDidMount = () => {

    const { attributes, params, id, updateParam } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (param?.value.includes('video') || param?.value.includes('link')) {
      this.getVideo();
    } else if (param?.value.includes('image')) {
      this.getImage();
    }
    if (param === undefined && typeof attributes['data-init'] !== undefined && attributes['data-init']) {
      this.setInitUrl(attributes['data-init'], 'video') // data-init is used for videos, like video-init - we need it for backward compatibility of templates that have already been created
    }
    if (param === undefined && typeof attributes['data-video-init'] !== undefined && attributes['data-video-init']) {
      this.setInitUrl(attributes['data-video-init'], 'video')
    }
    if (param === undefined && typeof attributes['data-image-init'] !== undefined && attributes['data-image-init']) {
      this.setInitUrl(attributes['data-image-init'], 'image')
    }

  }

  showMediaEdit = () => {
    this.setState({showMediaEdit: true});
  }

  closeMediaEdit = () => {
    this.setState({showMediaEdit: false});
  }

  getVideoContainer = (video, show, events) => {
    const { attributes } = this.props;
    let ev = { pointerEvents: 'none' }
    if (events) {
      ev = { pointerEvents: 'all' };
    }
    return (
      <>
        { attributes && attributes['data-img'] && show === false ? <img src={attributes['data-img']} alt="img-placeholder" /> :
          <div className="creatomate-video-container" style={ev}>
            {(video[0] === 'link' || video[0] === 'video-url' || video[0] === 'own') &&
              <video src={video[1]} crossOrigin="anonymous" controls="controls" controlsList="nodownload" />
            }
          </div>
        }
      </>
    )
  }

  getImageContainer = (image, show, events) => {
    const { attributes } = this.props;
    let ev = { pointerEvents: 'none' }
    if (events) {
      ev = { pointerEvents: 'all' };
    }
      return (
        <>
          { attributes && attributes['data-img'] && show === false ? <img src={attributes['data-img']} alt="img-placeholder" /> :
            <div className="creatomate-image-container" style={ev}>
              {(image[0] === 'image-url' || image[0] === 'own') &&
                <img src={image[1]} alt="Image" style={{ width: "100%" }} />
              }
            </div>
          }
        </>
      )


  }

  setFile = (name, value) => {
    const { videoEdit, imageEdit } = this.state;
    if (name == 'upload-video') {
      this.setState({videoFile:value[0]});
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const {videoEdit} = this.state;
        let buffer = e.target.result;
        let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
        let url = window.URL.createObjectURL(videoBlob);
        videoEdit[1] = url ;
        this.setState({videoEdit:videoEdit, imageEdit: []});
      };
      reader.readAsArrayBuffer(value[0]);
    }
  }

  changeSource = (name, value) => {
    if (value === 'medialibrary'){
      this.setState({ showMedia: true });
    } else {
      if (name === "video-source") {
        const {videoEdit} = this.state;
        videoEdit[0] = value;
        videoEdit[1] = '';
        this.setState({videoEdit: videoEdit, imageEdit: [], showMedia: false});
      } else if (name === 'image-source') {
        const {imageEdit} = this.state;
        imageEdit[0] = value;
        imageEdit[1] = '';
        this.setState({imageEdit: imageEdit, videoEdit: [], showMedia: false});
      }

    }
  }


  setUrl = (name, value) => {
    if (name === 'externVideoId') {
      const {videoEdit} = this.state;
      videoEdit[0] = 'video-url';
      videoEdit[1] = value;
      this.setState({videoEdit: videoEdit, imageEdit: []});
    } else if (name === 'externImageId') {
      const {imageEdit} = this.state;
      imageEdit[0] = 'image-url';
      imageEdit[1] = value;
      this.setState({imageEdit: imageEdit, videoEdit: []});
    }
  }

  checkVideoSrc = video => {
    // check source of video for blob
    if (video && (video[0] === 'video-url' || video[0] === 'image-url')) {
      return video[1].includes('blob')
    }
    return false;
  }

  checkImageSrc = image => {
    // check source of video for blob
    if (image && (image[0] === 'video-url' || image[0] === 'image-url')) {
      return image[1].includes('blob')
    }
    return false;
  }

  setInitUrl = (value, type) => {
    if(type === 'video') {
      const {videoEdit} = this.state;
      videoEdit[0] = 'image-url';
      videoEdit[1] = value;
      this.setState({videoEdit: videoEdit, imageEdit: []}, () => {
        const initialUpdate = true;
        this.setMedia(initialUpdate, 'video');
      });
    }

  }

  setMediaLibraryUrl = (value, type) => {
    if (type === 'video') {
      const {videoEdit} = this.state;
      videoEdit[0] = 'image-url';
      videoEdit[1] = value;
      this.setState({videoEdit: videoEdit, imageEdit: []}, () => {
        this.setMedia(false, 'video');
      });
    }
  }



  handleVideo = (fileKey, S3_LINKS, filename, thumb, copyright) => {
    let value = S3_LINKS + fileKey;
    this.setMediaLibraryUrl(value, 'video');
  }

  handleImage = (fileKey, S3_LINKS, filename, thumb, copyright) => {
    this.loadMedia(fileKey, S3_LINKS, filename, thumb, copyright);
  }

  loadMedia = (original, path, filename, thumb, copyright, files) => {
    const { clientImageRightsQuery } = this.props;

    let originalSrc = '';
    originalSrc = path + original;


    if (original === 'pixabay') {
      originalSrc = path;
      original = path;
    }

    const imgC = new Image();
    imgC.src = originalSrc;
    imgC.crossOrigin = 'anonymous';
    imgC.onload = () => {
      const originalImgWidth = imgC.width;
      const originalImgHeight = imgC.height;
      this.setState({
        showImageTooSmallError: false,
        showMedia: false,
        imageKey: original,
        imageThumb: thumb,
        showImageEditor: true,
        editorImageSrc: imgC,
        imageSrc: null,
        imgWidth: originalImgWidth,
        imgHeight: originalImgHeight,
      });
      if (clientImageRightsQuery) {
        this.imagePermissionQuery();
      }

    };
  };

  handleOnChange = (name, value) => {
    const { attributes, imageUpload, id, clientImageRightsQuery } = this.props;
    const { imageEdit, updateParam } = this.state;
    const file = value?.[0];

    if (file) {
      const img = new Image();
      img.src = createObjectURL(file);
      img.onload = () => {
        let minWidth = imageEdit[3] ? imageEdit[3] : 0;
        let minHeight = imageEdit[4] ? imageEdit[4] : 0;
        const originalImgWidth = img.width;
        const originalImgHeight = img.height;
        if (originalImgWidth < minWidth || originalImgHeight < minHeight) {
          this.setState({ showImageTooSmallError: true, showMedia: false });
        } else if (originalImgWidth === minWidth && originalImgHeight === minHeight && !clientImageRightsQuery) {
          imageEdit[0] = 'image-url';
          imageEdit[1] = img?.src;

          this.setState({ showImageTooSmallError: false, showMedia: false, showImageEditor: false, showMediaEdit: false, imageEdit: imageEdit, image: imageEdit, videoEdit: [], video: [] }, () => {


            let param = 'image-url' + '#aaa#' + img?.src + '#aaa#' + imageEdit[2] + '#aaa#' + imageEdit[3] + '#aaa#' + imageEdit[4];
            const overflow = false;
            const default_image = false;
            const errors = [{overflow, default_image}];
            const type = 'creatomate';
            updateParam(id, attributes['data-name'], param, errors, '', '', null, false, type);

          // Todo test image upload and update params
            imageUpload(
              id,
              file,
              null,
              attributes['data-name'],
              null,
              originalImgWidth,
              originalImgHeight,
              null,
              null,
              null,
              null,
              null,
              'image-url',
              null,
              null,
              imageEdit[2],
              minWidth,
              minHeight
            );
          });
        } else {
          this.setState({
            showImageTooSmallError: false,
            showMedia: false,
            imageKey: null,
            showImageEditor: true,
            editorImageSrc: img,
            imageSrc: file,
            imgWidth: originalImgWidth,
            imgHeight: originalImgHeight,
          });
          if (clientImageRightsQuery) {
            this.imagePermissionQuery();
          }
        }
      };
    }
  };

  imageUpload = image => {
    const data = this.cropperRef.current.getData();
    const { imageUpload, id, attributes, updateParam } = this.props;
    const { imageSrc, imgWidth, imgHeight, imageEdit, imageKey, imageThumb } = this.state;
    const imageBlob = this.DataURIToBlob(image);
    const imageUrl = URL.createObjectURL(imageBlob);

    let param = 'image-url' + '#aaa#' + imageUrl + '#aaa#' + imageEdit[2] + '#aaa#' + imageEdit[3] + '#aaa#' + imageEdit[4];
    const overflow = false;
    const default_image = false;
    const errors = [{overflow, default_image}];
    const type = 'creatomate';
    updateParam(id, attributes['data-name'], param, errors, '', '', null, false, type);
    imageEdit[0] = 'image-url';
    imageEdit[1] = imageUrl;
    this.setState({imageEdit: imageEdit, videoEdit: []});



    imageUpload(
      id,
      imageSrc,
      imageKey,
      attributes['data-name'],
      data,
      imgWidth,
      imgHeight,
      null,
      imageThumb,
      null,
      null,
      null,
      'image-url',
      null,
      null,
      imageEdit[2],
      imageEdit[3],
      imageEdit[4]
    );


    this.setState({
      showImageTooSmallError: false,
      showMedia: false,
      showImageEditor: false,
      showMediaEdit: false,
      xZoom: 0,
      editorImageSrc: '',
    });
  };

  imagePermissionQuery = () => {
    const {
      intl: { messages },
    } = this.props;
    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '100%' }}>
            <h1>{messages.image_permission_title}</h1>
            <p>{messages.image_permission_warning}</p>
            <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="primary"
                onClick={() => {
                  onClose();
                  this.handleIEclose();
                }}
              >
                {messages.no}
              </Button>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="warning"
                onClick={() => {
                  onClose();
                }}
              >
                {messages.yes}
              </Button>
            </div>
          </div>
        );
      },
      childrenElement: () => <div />,
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  handleIEclose = () => {
    this.setState({showImageEditor: false });
  };

  DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  setDuration = (name, value) => {
    this.setState((prevState) => {
      const { imageEdit } = prevState;

      return {
        imageEdit: [
          'image-url',
          imageEdit?.[1],
          value,
          imageEdit?.[3],
          imageEdit?.[4],
        ],
      };
    });
  };

  setVideoResolution = (name, value) => {
    this.setState((prevState) => {
      const { videoEdit } = prevState;

      return {
        videoEdit: [
          'video-url',
          videoEdit?.[1],
          undefined,
          name === "width" ? value : videoEdit?.[3] ?? 0,
          name === "height" ? value : videoEdit?.[4] ?? 0,
        ],
      };
    });
  };

  setImageResolution = (name, value) => {
    this.setState((prevState) => {
      const { imageEdit } = prevState;

      return {
        imageEdit: [
          'image-url',
          imageEdit?.[1],
          imageEdit?.[2],
          name === "width" ? value : imageEdit?.[3] ?? 0,
          name === "height" ? value : imageEdit?.[4] ?? 0,
        ],
      };
    });
  };

  getTabHeaderList = () => {
    const {intl: { messages }} = this.props;
    const result = [];

    result.push(messages.creatomate_video);
    result.push(messages.creatomate_image);

    return result;
  };

  getTabKeepMounted = () => {
    const keepMounted = [0, 1];
    return keepMounted;
  };

  getTabContentList = () => {
    const result = [];
    const {
      intl: {messages},
    } = this.props;
    const { showMedia, videoEdit, imageEdit } = this.state;

    result.push(
      <>
        <Row className="video-upload-tab" style={{padding:"25px"}}>
          <Col md={4}>
            <FormInput
              name="width"
              label={messages.image_video_width}
              value={videoEdit?.[3]}
              onChange={this.setVideoResolution}
              type="number"
              infoText={messages.video_resolution_info_text}
            />
            <FormInput
              name="height"
              label={messages.image_video_height}
              value={videoEdit?.[4]}
              onChange={this.setVideoResolution}
              type="number"
              infoText={messages.video_resolution_info_text}
            />
            <FormAutocomplete
              name="video-source"
              label={messages.choose_video_source}
              options={[
                { label: '-', value: '' },
                { label: 'Video-Url', value: 'image-url' },
                { label: messages.editor.upload_video, value: 'own' },
                { label: messages.editor.mediathek, value: 'medialibrary' },
              ]}
              value={videoEdit[0] || ''}
              onChange={(name, value)=> {this.changeSource(name, value)}}
            />
            {videoEdit[0] !== 'own' &&
              <FormInput
                name="externVideoId"
                label={messages.url_to_video_file}
                value={videoEdit[1] || ''}
                onChange={this.setUrl}

              />
            }
            {videoEdit[0] === 'own' &&
              <FormFile
                label={messages.editor.choice_mp4_file}
                name='upload-video'
                onChange={(name, value)=>{this.setFile(name, value)}}
                allowTypes="video/mp4"
              />
            }
            <EditorSettingsContext.Consumer>
              {context => (
                <Button onClick={context.showSettingsF}>{messages.further_settings}</Button>
              )}
            </EditorSettingsContext.Consumer>
          </Col>
          <Col md={8} className="h-100">
            <div style={{minHeight: '50%', border: '2px dashed #ccc', padding: '10px'}}>
              <p>{messages.editor.edition_preview}</p>
              {videoEdit[0] && videoEdit[1] && this.getVideoContainer(videoEdit, true, true)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {showMedia &&
              <MediaLibraryCreacheck
                handleMedia={this.handleVideo}
                neededHeight={0}
                neededWidth={0}
                neededType="video"
              />
            }
          </Col>
        </Row>
        <Row className="text-right">
          <Col>
            <div className="modal-default-style">
              <Button bsStyle='success' bsSize='large' onClick={()=> {this.setMedia(false, 'video')}}>{messages.editor.take_on}</Button>
              <Button bsStyle='danger' bsSize='large' onClick={this.closeMediaEdit}>{messages.editor.abort}</Button>
            </div>
          </Col>
        </Row>
    </>
      )
    result.push(
      <>
        <Row className="image-upload-tab" style={{padding:"25px"}}>
          <Col md={4}>
            <FormInput
              name="duration"
              label={messages.duration_image}
              value={imageEdit?.[2]}
              onChange={this.setDuration}
              type="number"
            />
            <FormInput
              name="width"
              label={messages.image_video_width}
              value={imageEdit?.[3]}
              onChange={this.setImageResolution}
              type="number"
              infoText={messages.image_resolution_info_text}
            />
            <FormInput
              name="height"
              label={messages.image_video_height}
              value={imageEdit?.[4]}
              onChange={this.setImageResolution}
              type="number"
              infoText={messages.image_resolution_info_text}
            />
            <FormAutocomplete
              name="image-source"
              label={messages.choose_image_source}
              options={[
                { label: '-', value: '' },
                { label: messages.upload_image, value: 'own' },
                { label: messages.editor.mediathek, value: 'medialibrary' },
              ]}
              value={imageEdit[0] || ''}
              onChange={(name, value)=> {this.changeSource(name, value)}}
            />

            {imageEdit[0] !== 'own' &&
              <FormInput
                name="externImageId"
                label={messages.url_to_image_file}
                value={imageEdit[1] !== undefined && imageEdit[1] !== "undefined" ? imageEdit[1] : ''}
                onChange={this.setUrl}
              />
            }

            {imageEdit[0] === 'own' &&
              <FormFile
                label={messages.choice_image_file}
                name='upload-image'
                onChange={(name, value)=>{this.handleOnChange(name, value)}}
                allowTypes="image/jpeg, image/png"
              />
            }
            <EditorSettingsContext.Consumer>
              {context => (
                <Button onClick={context.showSettingsF}>{messages.further_settings}</Button>
              )}
            </EditorSettingsContext.Consumer>
          </Col>
          <Col md={8} className="h-100">
            <div style={{minHeight: '50%', border: '2px dashed #ccc', padding: '10px'}}>
              <p>{messages.editor.edition_preview}</p>
              {imageEdit.length > 0 && imageEdit?.[1] && imageEdit?.[1] !== "undefined" && this.getImageContainer(imageEdit, true, true)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {showMedia &&
              <MediaLibraryCreacheck
                handleMedia={this.handleImage}
                neededHeight={parseInt(imageEdit?.[3] ? imageEdit?.[3] : 0, 10)}
                neededWidth={parseInt(imageEdit?.[4] ? imageEdit?.[4] : 0, 10)}
                neededType="image"
              />
            }
          </Col>
        </Row>
        <Row className="text-right">
          <Col>
            <div className="modal-default-style">
              <Button bsStyle='success' bsSize='large' onClick={()=> {this.setMedia(false, 'image')}}>{messages.editor.take_on}</Button>
              <Button bsStyle='danger' bsSize='large' onClick={this.closeMediaEdit}>{messages.editor.abort}</Button>
            </div>
          </Col>
        </Row>
      </>
    )
    return result;
  }


  render() {
    const { name: Tag, attributes, intl: { messages }} = this.props;
    const { video, videoEdit, showMediaEdit, showMedia, image, showImageEditor, editorImageSrc, xZoom, imageEdit } = this.state;
    let invalidVideoSource = this.checkVideoSrc(video);
    let invalidImageSource = this.checkImageSrc(image);

    const enable = { display: 'block' };
    const ratio = (typeof parseInt(imageEdit[3], 10) === 'number' && parseInt(imageEdit[3], 10) !== 0 && typeof parseInt(imageEdit[4], 10) === 'number' && parseInt(imageEdit[4], 10) !== 0) ? parseInt(imageEdit[4], 10) / parseInt(imageEdit[3], 10) : 1;
    const canvasWidth = parseFloat(document.getElementsByTagName('body')[0].offsetWidth.toFixed(0)) - 200;
    const possibleZooms = typeof parseInt(imageEdit[3], 10) === 'number' && parseInt(imageEdit[3], 10) !== 0
      ? parseFloat((parseInt(imageEdit[3], 10) / canvasWidth).toFixed(0))
      : 1;

    return (
      <>
        <Tag {...attributes} onClick={this.showMediaEdit} data-tip={messages.editor.video_settings} data-for="top">
          <div className="wrap">

            {(Array.isArray(video) && video.length > 0 && invalidVideoSource) ||
            (Array.isArray(image) && image.length > 0 && invalidImageSource) ? (
              <p className="uploadError">
                <i className="far fa-exclamation-triangle" />
              </p>
            ) : (Array.isArray(video) && video.length > 0 && !invalidVideoSource) ||
            (Array.isArray(image) && image.length > 0 && !invalidImageSource) ? (
              <p className="uploadSuccess">
                <i className="fas fa-check"></i>
              </p>
            ) : <></>}


            {
              video.length > 0 ? (
                this.getVideoContainer(video, false, false)
              ) :  image.length > 0 && image?.[1] && image?.[1] !== "undefined" ?
                this.getImageContainer(image, false, false)
                : (
                   attributes['data-text'] ? (
                      <>
                        <i className="fad fa-film" />
                        <p className="added_video_text">{attributes['data-text']}</p>
                      </>
                    ) : (
                          <>
                            <i className="fad fa-film" />
                            <p className="default_video_text">{messages.editor.add_video}</p>
                          </>
                        )
                  )
            }
          </div>
        </Tag>
      {showMediaEdit && (
        <ContextModal>
          <div id="contextModalWrap">
            <div id="contextModalStage" className="row">
              <Row>
                <Col>
                  <Tabs tabs={this.getTabHeaderList()} tabContent={this.getTabContentList()} keepMounted={this.getTabKeepMounted()} />
                </Col>
              </Row>
            </div>
          </div>
        </ContextModal>
      )}

      {showImageEditor && (
        <ContextModal style={enable}>
          <EditorApplyStyles />
          <div id="contextModalWrap">
            <div id="contextModalStage" className="image-editor">
              <ImageEditorRc
                ref={this.cropperRef}
                crossOrigin="true" // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
                src={editorImageSrc.src}
                style={{ height: '80%', width: '100%' }}
                aspectRatio={1 / ratio}
                className="image-edit"
                scalable
                movable
                zoomable
                rotatable
                toggleDragModeOnDblclick={false}
                imageName="cropy.jpg"
                saveImage={this.imageUpload.bind(this)} // it has to catch the returned data and do it whatever you want
                responseType="image/jpeg" // 'blob/base64'
                viewMode={2}
                dragMode="move"
                zoomOnTouch={false}
                zoomOnWheel={false}
                minCropBoxWidth={parseInt(imageEdit?.[3] ? imageEdit?.[3] : undefined, 10)}
                minCropBoxHeight={parseInt(imageEdit?.[4] ? imageEdit?.[4] : undefined, 10)}
                maxCropBoxWidth={parseInt(imageEdit?.[3] ? imageEdit?.[3] : undefined, 10)}
                maxCropBoxHeight={parseInt(imageEdit?.[4] ? imageEdit?.[4] : undefined, 10)}
              />
              <Loader />
              <Button bsStyle="warning" bsSize="large" onClick={this.handleIEclose} className="cancelIE">
                {messages.editor.abort}
              </Button>
              <Slider
                step={0.01}
                min={0}
                max={parseFloat(possibleZooms)}
                defaultValue={xZoom}
                value={xZoom}
                onChange={x => {
                  const newXstate = parseFloat(x.toFixed(2));
                  const newX = newXstate - this.state.xZoom;

                  this.setState({ xZoom: newXstate });
                  this.cropperRef.current.zoom(newX);
                }}
              />
            </div>
          </div>
          <ReactTooltip id="bottom" place="bottom" effect="solid" />
        </ContextModal>
      )}
    </>
    )
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    clientImageRightsQuery: state.login.user?.parent?.image_rights_query,
  };
};


export default withRouter(
  injectIntl(
    connect(
      mapStateToProps
    )(Creatomate)
  )
);
