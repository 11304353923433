import React, { createRef } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import _, { findIndex as _findIndex, forEach as _forEach, cloneDeep as _cloneDeep, find as _find } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import FormInput from 'components/forms/FormInput';
import Axios from 'axios';
import HTML from 'html-parse-stringify';
import EditorCanvas from 'cc-editor/components/EditorCanvas';
import PreviewBlock from './PreviewBlock';
import EditorBlock from './EditorBlock';
import { getBlockTopFromRaster, getRasterSlots, getSiblings } from './actions/Raster';
import EditorApplyStyles from './EditorApplyStyles';
import { getPageNameIndex } from './actions/TemplateSettings';
import Loader from '../../components/layout/Loader';
import Pagination from '../../components/layout/Pagination';
import FormAutocomplete from '../../components/forms/FormAutocomplete';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

/**
 * Extracts attribute value
 * from a HTML code string like
 * <tagName attributeName=" 123 " />
 * @param {string} str HTML code to parse
 * @param {string} attributeName to look for
 * @returns number
 */
const getNumericValue = (str, attributeName) => {
  const re = new RegExp(`${attributeName}="\\s*([^"\\s]*)`);
  const match = re.exec(str);
  return match ? Number(match[1]) : 0;
};

/**
 * Extracts attribute value
 * from a HTML code string like
 * <tagName attributeName=" abc " />
 * @param {string} str HTML code to parse
 * @param {string} attributeName to look for
 * @returns mixed
 */
const getAttributeValue = (str, attributeName) => {
  const re = new RegExp(`${attributeName}\\(([^)]+)\\)`);
  const match = re.exec(str);
  return match ? match[1] : null;
};

class EditorPage extends React.Component {
  constructor(props) {
    super(props);

    const { blocks } = props;

    this.pageRef = createRef();
    this.sectionRef = createRef();
    this.state = {
      blocks: [],
      bgColor: {},
      bgColorE: {},
      blocksPerPage: 4,
      page: 1,
      loading: false,
      allPages: Math.ceil(blocks.length / 4),
      activeDrags: 0,
      deltaPosition: [],
      controlledPosition: {
        x: -400,
        y: 200,
      },
      draggedBlockId: '',
      showFilter: false,
      blockForFilter: '',
      filter: {
        grayscale: 0,
        sepia: 0,
        blur: 0,
        brightness: 1,
        contrast: 1,
        shadow: 0,
        rotate: 0,
        invert: 0,
        opacity: 1,
        saturate: 1,
      },
      filterAsString: '',
      showUrl: false,
      imgUrl: '',
      blockForUrl: undefined,
      inputRange: { htmlFor: '', id: '', name: '', min: 0, max: 1, step: 0.1, defaultValue: '', message: '' },
      outputRange: [],
      currentFilterData: '',
      blockImages: [],
      indexOfImage: 0,
    };
    this.handleDrag = this.handleDrag.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onStop = this.onStop.bind(this);
    this.adjustXPos = this.adjustXPos.bind(this);
    this.adjustYPos = this.adjustYPos.bind(this);
    this.onControlledDrag = this.onControlledDrag.bind(this);
    this.onControlledDragStop = this.onControlledDragStop.bind(this);
  }

  onStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }

  onStop() {
    this.setState({ activeDrags: --this.state.activeDrags }, () => {
      this.updateDragPosition(this.state.draggedBlockId);
    });
  }

  // For controlled component
  onControlledDrag(e, position) {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  }

  onControlledDragStop(e, position) {
    this.onControlledDrag(e, position);
    this.onStop();
  }

  getDraggedBlockId(id) {
    this.setState({ draggedBlockId: id });
  }

  initializeDeltaPositions = () => {
    const { blocks } = this.props;
    const { deltaPosition } = this.state;
    const _deltaPosition = _cloneDeep(deltaPosition);

    _.forEach(blocks, block => {
      let blockTemplate = _cloneDeep(block.template);
      blockTemplate = blockTemplate.toLowerCase();
      const index = _.findIndex(deltaPosition, data => data.blockId === block.id);
      if (index === -1) {
        let posX = getNumericValue(blockTemplate, 'defaultpositionx') || 0;
        let posY = getNumericValue(blockTemplate, 'defaultpositiony') || 0;
        if (block.position_x) {
          posX = block.position_x;
        }
        if (block.position_y) {
          posY = block.position_y;
        }
        const positions = {
          blockId: block.id,
          x: posX,
          y: posY,
        };
        _deltaPosition.push(positions);
      }
    });
    this.setState({ deltaPosition: _deltaPosition });
  };

  updateDragPosition = id => {
    const { blocks, updateBlock } = this.props;
    const data = _cloneDeep(blocks);
    const { deltaPosition } = this.state;
    const block = _find(data, { id });
    const index = _.findIndex(deltaPosition, data => data.blockId === id);
    block.position_x = deltaPosition[index].x;
    block.position_y = deltaPosition[index].y;
    updateBlock(id, data, true);
  };

  changePage = selectedPage => {
    let blocksPaginated = [];
    const { blocks } = this.props;
    const { blocksPerPage } = this.state;
    blocksPaginated = blocks.filter(
      (i, index) =>
        index >= blocksPerPage * (selectedPage - 1) && index < blocksPerPage * (selectedPage - 1) + blocksPerPage
    );
    this.setState({
      blocks: blocksPaginated,
      // loading: true,
      page: selectedPage,
      allPages: Math.ceil(blocks.length / 4),
    });
    // this.hideLoading();
  };

  updateBackground = (bgColorID, blockId) => {
    const { bgColor } = this.state;
    const newBgColor = bgColor;
    newBgColor[blockId] = bgColorID;
    this.setState({ bgColor: newBgColor });
  };

  componentDidMount = () => {
    this.setFilterSlider('default');
    const { updatePageSize, settings, currentPage, pagesCount, blocks } = this.props;
    const pageNameIndex = getPageNameIndex(currentPage, settings, pagesCount);
    updatePageSize(this.pageRef.current.offsetWidth, this.pageRef.current.offsetHeight);
    settings.pages[pageNameIndex].endless ? this.changePage(1) : this.setState({ blocks });
    this.initializeDeltaPositions();
    this.props.initDefaultPosition();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      setActiveHeight,
      type,
      goToLastPageState,
      goToLastPage,
      resetPageState,
      resetPage,
      settings,
      currentPage,
      pagesCount,
      blocks,
      updateBlocksState,
    } = this.props;
    const { page, allPages } = this.state;
    if (type === 'active') {
      setActiveHeight(parseInt(this.pageRef.current.offsetHeight, 10));
    }
    const pageNameIndex = getPageNameIndex(currentPage, settings, pagesCount);
    if (settings.pages[pageNameIndex].endless) {
      if (prevProps.goToLastPageState !== goToLastPageState) {
        this.changePage(allPages);
        goToLastPage(false);
      } else if (prevProps.resetPageState !== resetPageState || (updateBlocksState || resetPageState)) {
        this.changePage(page);
        resetPage(false);
      }
    } else if (JSON.stringify(prevState.blocks) !== JSON.stringify(blocks)) {
      this.setState({ blocks });
      this.initializeDeltaPositions();
    }
  };

  switchBlock = e => {
    const { changeBlock } = this.props;
    changeBlock(e);
    this.forceUpdate();
  };

  hideLoading = () => {
    clearTimeout(timeout);
    const timeout = setTimeout(() => {
      this.setState({ loading: false, bottom: false, top: false });
    }, 200);
  };

  deleteBlock = e => {
    const { target } = e;
    const {
      removeContentBlock,
      intl: { messages },
    } = this.props;
    const { page } = this.state;

    /* check if block is parent of a child to set warning before delete */
    const sourceRasterId = target.getAttribute('data-block');
    const indexOfParent = _.findIndex(this.props.blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = this.props.blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    let warning = messages.editor.delete_selected_content_block;
    if (parent !== undefined && parent !== '') {
      warning = messages.editor.delete_selected_content_block + ' ' + messages.editor.delete_children_of_parents;
    }

    const options = {
      title: messages.editor.delete_content,
      message: warning,
      buttons: [
        {
          label: messages.editor.yes_delete,
          onClick: () => {
            this.changePage(page);
            removeContentBlock(target);
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  resBlock = e => {
    const {
      resetBlock,
      intl: { messages },
    } = this.props;
    const { target } = e;
    const options = {
      title: messages.editor.reset_content,
      message: messages.editor.reset_content_block_to_root,
      buttons: [
        {
          label: messages.editor.yes_reset,
          onClick: () => {
            resetBlock(target);
            this.forceUpdate();
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  pasteBlock = (e, type = '') => {
    const { pasteBlock } = this.props;
    pasteBlock(e, type);
    this.forceUpdate();
  };

  copyBlock = (e, type = '') => {
    const { copyBlock } = this.props;
    copyBlock(e, type);
    this.forceUpdate(() => {
      ReactTooltip.rebuild();
    });
  };

  resizeBlock = e => {
    const { resizeBlock } = this.props;
    resizeBlock(e.target);
    this.forceUpdate(() => {
      ReactTooltip.rebuild();
    });
  };

  gotoPage = () => {
    const { updateCurrentPage, currentPage } = this.props;
    updateCurrentPage(parseInt(currentPage, 10));
  };

  getIndexForImage = blockId => {
    const { blocks } = this.props;
    const bIndex = _findIndex(blocks, ['id', blockId]);

    if (typeof blocks[bIndex].params !== 'undefined' && bIndex !== -1) {
      if (blocks[bIndex].params.length === 1 && blocks[bIndex].params[0]?.thumb) {
        this.setState({ indexOfImage: 0, blockForFilter: blocks[bIndex] }, () => {
          this.filterImage(blockId);
        });
      } else {
        this.setState({ indexOfImage: undefined, blockForFilter: blocks[bIndex] }, () => {
          this.setState({ showFilter: true });
        });
      }
    }
  };

  setIndexOfImage = (name, value) => {
    const { blockForFilter } = this.state;
    const _indexOfImage = _.findIndex(blockForFilter.params, param => param.name === name);
    this.setState({ indexOfImage: _indexOfImage }, () => {
      this.filterImage(blockForFilter?.id);
    });
  };

  filterImage = blockId => {
    const { blocks } = this.props;
    const { indexOfImage } = this.state;
    const bIndex = _findIndex(blocks, ['id', blockId]);

    if (
      blocks[bIndex].params[indexOfImage] &&
      blocks[bIndex].params[indexOfImage].filter !== undefined &&
      blocks[bIndex].params[indexOfImage].filter !== null
    ) {
      this.setState({ blockForFilter: blocks[bIndex] }, () => {
        const { filter: source } = blocks[bIndex].params[indexOfImage];

        // default values
        const filter = {
          grayscale: 0,
          sepia: 0,
          blur: 0,
          brightness: 1,
          contrast: 1,
          rotate: 0,
          invert: 0,
          opacity: 1,
          saturate: 1,
        };

        Object.keys(filter).forEach(prop => {
          const attributeName = prop === 'rotate' ? 'hue-rotate' : prop; // special case
          filter[prop] = getAttributeValue(source, attributeName) ?? filter[prop];
        });

        this.setState({ filter }, () => {
          let _filterAsString = '';
          for (const [key, value] of Object.entries(filter)) {
            if (value !== 0) {
              if (key === 'rotate') {
                _filterAsString = _filterAsString + 'hue-rotate(' + value + 'deg) ';
              } else if (key === 'blur') {
                _filterAsString = _filterAsString + key + '(' + value + 'px) ';
              } else {
                _filterAsString = _filterAsString + key + '(' + value + ') ';
              }
            }
          }
          const _outputRange = [];
          _outputRange.push(<div />);
          this.setState({ filterAsString: _filterAsString, currentFilterData: '', outputRange: _outputRange }, ()=>{
            this.setState({ showFilter: true });
          });
        });
      });
    } else {
      const _outputRange = [];
      _outputRange.push(<div />);
      let _filterAsString = '';
      const _filter = {
        grayscale: 0,
        sepia: 0,
        blur: 0,
        brightness: 1,
        contrast: 1,
        shadow: 0,
        rotate: 0,
        invert: 0,
        opacity: 1,
        saturate: 1,
      };
      for (const [key, value] of Object.entries(_filter)) {
        if (value !== 0) {
          if (key === 'rotate') {
            _filterAsString = _filterAsString + 'hue-rotate(' + value + 'deg) ';
          } else if (key === 'blur') {
            _filterAsString = _filterAsString + key + '(' + value + 'px) ';
          } else {
            _filterAsString = _filterAsString + key + '(' + value + ') ';
          }
        }
      }
      this.setState(
        {
          blockForFilter: blocks[bIndex],
          filter: _filter,
          currentFilterData: '',
          outputRange: _outputRange,
          filterAsString: _filterAsString,
        },
        () => {
          this.setState({ showFilter: true });
        }
      );
    }
  };

  getImageUrlSettings = blockId => {
    const index = _findIndex(this.props.blocks, ['id', blockId]);
    this.setState({ blockForUrl: this.props.blocks[index] }, () => {
      if (this.props.blocks[index]?.params[0]?.url !== undefined && this.props.blocks[index].params[0].url !== null) {
        this.setState({ imgUrl: this.props.blocks[index]?.params[0]?.url }, () => {
          this.setState({ showUrl: true });
        });
      } else {
        this.setState({ imgUrl: '' }, () => {
          this.setState({ showUrl: true });
        });
      }
    });
  };

  extractImgAttributes = element => {
    const regex = /<img[\s\S]*?src="(.*?)"[\s\S]*?data-name="(.*?)"/;
    const match = element.match(regex);
    if (match) {
      const src = match[1];
      const dataName = match[2];
      return { src, dataName };
    }
    return null;
  };

  saveUrl = () => {
    const id = this.state.blockForUrl?.id;
    let value = '';
    let name = '';
    let errors = null;
    let thumb = '';
    let filter = '';

    if (this.state.blockForUrl?.params.length > 0) {
      value = this.state.blockForUrl?.params[0]?.value;
      name = this.state.blockForUrl?.params[0]?.name;
      errors = this.state.blockForUrl?.params[0]?.errors;
      thumb = this.state.blockForUrl?.params[0]?.thumb;
      filter = this.state.blockForUrl?.params[0]?.filter;
    } else {
      const { src, dataName } = this.extractImgAttributes(this.state.blockForUrl.template);
      value = src;
      name = dataName;
      errors = null;
      thumb = '';
      filter = '';
    }

    this.props.updateSpecialBlockAttributes(id, name, value, thumb, errors, filter, this.state.imgUrl);
    this.setState({ showUrl: false });
  };

  handleIEClose = () => {
    this.setState({ showFilter: false, showUrl: false });
  };

  saveFilter = () => {
    const { indexOfImage } = this.state;
    const { id } = this.state.blockForFilter;
    const { value } = this.state.blockForFilter.params[indexOfImage];
    const { name } = this.state.blockForFilter.params[indexOfImage];
    const errors =
      this.state.blockForFilter && this.state.blockForFilter.params[indexOfImage].errors !== undefined
        ? this.state.blockForFilter.errors
        : null;
    const thumb = this.state.blockForFilter.params[indexOfImage]?.thumb;
    const url = this.state.blockForFilter.params[indexOfImage]?.url;
    this.props.updateSpecialBlockAttributes(id, name, value, thumb, errors, this.state.filterAsString, url);
    this.setState({ showFilter: false });
  };

  setFilter = e => {
    const { filter } = this.state;
    const element = e.target;
    let _filterAsString = '';
    if (element.name === 'grayscale') {
      filter.grayscale = element.value;
    }
    if (element.name === 'sepia') {
      filter.sepia = element.value;
    }
    if (element.name === 'blur') {
      filter.blur = element.value;
    }
    if (element.name === 'brightness') {
      filter.brightness = element.value;
    }
    if (element.name === 'contrast') {
      filter.contrast = element.value;
    }
    if (element.name === 'drop-shadow') {
      filter.shadow = element.value;
    }
    if (element.name === 'hue-rotate') {
      filter.rotate = element.value;
    }
    if (element.name === 'invert') {
      filter.invert = element.value;
    }
    if (element.name === 'opacity') {
      filter.opacity = element.value;
    }
    if (element.name === 'saturate') {
      filter.saturate = element.value;
    }

    this.setState({ filter, currentFilterData: element.value }, () => {
      for (const [key, value] of Object.entries(this.state.filter)) {
        if (value !== 0) {
          if (key === 'rotate') {
            _filterAsString = _filterAsString + 'hue-rotate(' + value + 'deg) ';
          } else if (key === 'blur') {
            _filterAsString = _filterAsString + key + '(' + value + 'px) ';
          } else {
            _filterAsString = _filterAsString + key + '(' + value + ') ';
          }
        }
      }
      this.setState({ filterAsString: _filterAsString });
    });
  };

  setFilterSlider = (nameF, valueF) => {
    const {
      intl: { messages },
    } = this.props;
    const { filter } = this.state;
    /* default if sepia, grayscale, invert, opacity */
    const min = 0;
    let max = 1;
    let step = 0.1;

    /* default for all */
    const htmlFor = valueF;
    const id = valueF;
    const name = valueF;
    let _default = 0;

    /* var */
    let _defaultValue = 0;
    let message = '';

    switch (valueF) {
      case 'blur':
        max = 10;
        step = 1;
        _defaultValue = filter.blur;
        message = messages.editor.filter_blur;
        break;
      case 'brightness':
        max = 3;
        _defaultValue = filter.brightness;
        message = messages.editor.filter_brightness;
        _default = 1;
        break;
      case 'contrast':
        max = 3;
        _defaultValue = filter.contrast;
        message = messages.editor.filter_contrast;
        _default = 1;
        break;
      case 'hue-rotate':
        max = 360;
        step = 1;
        _defaultValue = filter.rotate;
        message = messages.editor.filter_hueRotate;
        break;
      case 'saturate':
        max = 3;
        _defaultValue = filter.saturate;
        message = messages.editor.filter_saturate;
        _default = 1;
        break;
      case 'sepia':
        _defaultValue = filter.sepia;
        message = messages.editor.filter_sepia;
        break;
      case 'grayscale':
        _defaultValue = filter.grayscale;
        message = messages.editor.filter_grayscale;
        break;
      case 'invert':
        _defaultValue = filter.invert;
        message = messages.editor.filter_invert;
        break;
      case 'opacity':
        _defaultValue = filter.opacity;
        message = messages.editor.filter_opacity;
        _default = 1;
        break;
      default:
        break;
    }
    const _outputRange = [];
    if (nameF === 'default') {
      _outputRange.push(<div />);
    } else {
      this.setState({ currentFilterData: _defaultValue });
      _outputRange.push(
        <div key={_defaultValue}>
          <label htmlFor={htmlFor}>
            {message} {messages.editor.filter_default}: {_default}
          </label>
          <input
            type="range"
            id={id}
            name={name}
            min={min}
            max={max}
            defaultValue={_defaultValue}
            step={step}
            onChange={e => {
              this.setFilter(e);
            }}
          />
        </div>
      );
    }
    /* default if sepia, grayscale, invert, opacity */
    // let _inputRange = {htmlFor: htmlFor, id: id, name: name, min: min, max: max, step: step, defaultValue: defaultValue, message: message}

    this.setState({ outputRange: _outputRange });
  };

  adjustXPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = this.state.controlledPosition;
    this.setState({ controlledPosition: { x: x - 10, y } });
  }

  adjustYPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const { controlledPosition } = this.state;
    const { x, y } = controlledPosition;
    this.setState({ controlledPosition: { x, y: y - 10 } });
  }

  handleDrag(e, ui) {
    const { draggedBlockId, deltaPosition } = this.state;
    const _deltaPosition = _cloneDeep(deltaPosition);
    const index = _.findIndex(deltaPosition, data => data.blockId === draggedBlockId);
    /*
    let x = 0;
    let y = 0;
    if(index === -1){
      let positions = {
        blockId: draggedBlockId,
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
      _deltaPosition.push(positions);
    }else{
      _deltaPosition[index].x =  _deltaPosition[index].x + ui.deltaX;
      _deltaPosition[index].y =  _deltaPosition[index].y + ui.deltaY;
    }
     */

    _deltaPosition[index].x += ui.deltaX;
    _deltaPosition[index].y += ui.deltaY;

    this.setState({ deltaPosition: _deltaPosition });
  }

  render() {
    const {
      imageUpload,
      raster,
      usedMedia,
      trimBorderActive,
      type,
      grid,
      index,
      updateParam,
      updateInnerBlock,
      pageZoom,
      settings,
      stageWidth,
      pasteData,
      copiedBlock,
      currentPage,
      changeErrors,
      templateBlocks,
      appIntl,
      blockContainer,
      setBlockContainer,
      unsetBlockContainer,
      website,
      resetNavEntry,
      navEntry,
      pagesCount,
      pageHeight,
      pageWidth,
      marginWidth,
      evenOdd,
      paddingBottom,
      paddingTop,
      publisherInfo,
      rootUser,
      wlAdminUser,
      isSubmission,
      onlyWhitelabelAdminCanModifyBlocks,
      intl: { messages },
      filesUploading,
      saveDescription,
      uploadError,
      showKeyboard,
    } = this.props;
    const { bgColor, loading, blocksPerPage, page, outputRange, blockForFilter, indexOfImage } = this.state;
    const { colorSettings, fontSizeSettings } = this.props;
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const zoomEqualizer = 1 / parseFloat(pageZoom);
    const paddingStyle = '';
    const marginTop = paddingTop || 0;
    const marginBottom = paddingBottom || 0;
    const pageNameIndex = getPageNameIndex(currentPage, settings, pagesCount);
    const endless = !!settings.pages[pageNameIndex].endless;
    let blocks;
    if (endless) {
      blocks = this.state.blocks;
    } else {
      blocks = this.props.blocks;
    }
    const noCopies = _.findIndex(blocks, ['no_copies', true], 0);
    let fromIndex = _.findIndex(blocks, ['fixed', true], 0);
    const fixedBlocks = [];
    while (fromIndex !== -1) {
      fixedBlocks.push(_.find(blocks, ['fixed', true], fromIndex).id);
      fromIndex = _.findIndex(blocks, ['fixed', true], fromIndex + 1);
    }
    const freeSlots = getRasterSlots(raster, currentPage, settings.pages[pageNameIndex].page_space, 0, 0, endless);
    const rasterV = Math.floor((pageHeight - marginTop - marginBottom) / settings.pages[pageNameIndex].page_space);
    const styles = {
      transform: `scale(${pageZoom})`,
      width: pageWidth + 'px',
      height: pageHeight + 'px',
      marginLeft: (marginWidth - pageWidth) * pageZoom + 100 + 'px',
      /* display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center', */
    };
    const arrow = {
      display: 'block',
    };
    if (website) {
      // website
      styles.height = 'auto';
      styles.minHeight = pageHeight + 'px';
    }
    const styleOptions = {
      transform: `scale(${zoomEqualizer})`,
      // marginLeft: (-1 * paddingRight ? paddingRight : 0) +'px',
    };
    let pasteButton = '';
    let pasteButton2 = '';
    let blockPasteDisable = true;
    let blockPasteDisableBlockContainer = false;
    let copyAllButton = '';
    if (type === 'previous' || type === 'next') {
      styles.opacity = '0.5';
      styles.zIndex = 1;
      arrow.display = 'none';
    } else if (type === 'active') {
      // noCopies should be ignored for copy page
      // remove check noCopies if (blocks.length > 0 && noCopies === -1) {
      if (blocks.length > 0) {
        copyAllButton = (
          <Button
            bsSize="small"
            data-tip={messages.editor.copy_full_content}
            data-for="blockinfo2"
            data-block="all"
            onClick={this.copyBlock}
          >
            <i className="fas fa-clone" data-block="all" />
          </Button>
        );
      }
      if (pasteData) {
        let spaceNeeded = 0;
        let restrictedToPage = false;
        let excludePages = false;
        let oncePerPage = false;
        let oncePerVariant = false;
        let oncePerCategory = false;
        let oncePerDocument = false;
        let parentNotAvailable = false;
        let disableGroup = false;

        _.forEach(copiedBlock, block => {
          spaceNeeded += block.page_space_needed;
          if (restrictedToPage) {
            restrictedToPage = block.restricted_to_page;
          }
          if (block.exclude_pages) {
            if (block.exclude_pages.indexOf((currentPage + 1).toString()) >= 0) {
              excludePages = true;
            }
          }
          if (block.once_per_page) {
            if (_.findIndex(blocks, ['template_id', block.template_id]) >= 0) {
              oncePerPage = true;
            } else if (oncePerPage === false) {
              _.forEach(blocks, _block => {
                if (_.findIndex(_block.blocks, ['template_id', block.template_id]) >= 0) {
                  oncePerPage = true;
                }
              });
            }
          }
          if (
            typeof block.once_per_variant !== 'undefined' &&
            block.once_per_variant !== '' &&
            _findIndex(blocks, ['once_per_variant', block.once_per_variant]) !== -1
          ) {
            oncePerVariant = true;
          } else if (
            typeof block.once_per_variant !== 'undefined' &&
            block.once_per_variant !== '' &&
            _findIndex(blocks, ['once_per_variant', block.once_per_variant]) === -1
          ) {
            _.forEach(blocks, _block => {
              if (_.findIndex(_block.blocks, ['once_per_variant', block.once_per_variant]) >= 0) {
                oncePerVariant = true;
              }
            });
          }
          if (
            typeof block.once_per_category !== 'undefined' &&
            block.once_per_category !== '' &&
            _findIndex(blocks, ['once_per_category', block.once_per_category]) !== -1
          ) {
            oncePerCategory = true;
          } else if (
            typeof block.once_per_category !== 'undefined' &&
            block.once_per_category !== '' &&
            _findIndex(blocks, ['once_per_category', block.once_per_category]) === -1
          ) {
            _.forEach(blocks, _block => {
              if (_.findIndex(_block.blocks, ['once_per_category', block.once_per_category]) >= 0) {
                oncePerCategory = true;
              }
            });
          }
          if (block.once_per_document && _.findIndex(this.props.allUsedBlocks, ['id', block.template_id]) >= 0) {
            oncePerDocument = true;
          }
          if (block.block_combination_child !== '') {
            parentNotAvailable = true;
            const blockCombinationChild = block.block_combination_child.split(',');

            _forEach(blockCombinationChild, blockCC => {
              if (_findIndex(blocks, ['block_combination_parent', blockCC]) !== -1) {
                parentNotAvailable = false;
              }
            });

            if (parentNotAvailable === true) {
              _.forEach(blocks, _block => {
                _forEach(blockCombinationChild, blockCC => {
                  if (_findIndex(_block.blocks, ['block_combination_parent', blockCC]) !== -1) {
                    parentNotAvailable = false;
                  }
                });
              });
            }
          }

          if (block.disable_group !== '' && block.disable_group !== undefined) {
            let usedBlockVariant = blocks; // default perPage
            const splitBlockOptions = block.disable_group.split('#');
            const splitBlockVariants = splitBlockOptions[0].split(',');
            const splitDisableGroups = splitBlockOptions[1].split(',');

            _forEach(splitDisableGroups, (blockDG, i) => {
              if (splitBlockVariants[i] === 'D') {
                usedBlockVariant = this.props.allUsedBlocksContent;
              }
              let even = false;
              if (i % 2 === 0) {
                even = true;
              }
              if (even) {
                _forEach(usedBlockVariant, _usedBlock => {
                  let splitUsedBlockDisableGroups = [];
                  if (_usedBlock.disable_group !== '' && _usedBlock.disable_group !== undefined) {
                    splitUsedBlockDisableGroups = _usedBlock.disable_group.split('#');
                    const splitUsedDisableGroups = splitUsedBlockDisableGroups[1].split(',');
                    if (
                      splitUsedDisableGroups?.length > 0 &&
                      splitUsedDisableGroups[i + 1] === splitDisableGroups[i] &&
                      splitUsedDisableGroups[i + 1] !== 'x' &&
                      splitDisableGroups[i] !== 'x'
                    ) {
                      disableGroup = true;
                    }
                  }
                });

                if (disableGroup === false) {
                  _forEach(usedBlockVariant, _usedBlock => {
                    if (_usedBlock?.blocks?.length > 0) {
                      _.forEach(_usedBlock?.blocks, blockInsideContainer => {
                        let splitUsedBlockDisableGroupsIC = [];
                        if (
                          blockInsideContainer.disable_group !== '' &&
                          blockInsideContainer.disable_group !== undefined
                        ) {
                          splitUsedBlockDisableGroupsIC = blockInsideContainer.disable_group.split('#');
                          const splitUsedDisableGroupsIC = splitUsedBlockDisableGroupsIC[1].split(',');
                          if (
                            splitUsedDisableGroupsIC?.length > 0 &&
                            splitUsedDisableGroupsIC[i + 1] === splitDisableGroups[i] &&
                            splitUsedDisableGroupsIC[i + 1] !== 'x' &&
                            splitDisableGroups[i] !== 'x'
                          ) {
                            disableGroup = true;
                          }
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        });

        _.forEach(freeSlots, slot => {
          if (slot >= spaceNeeded) {
            blockPasteDisable = false;
          }
        });
        if (endless) {
          blockPasteDisable = false;
        }

        if (
          (restrictedToPage && restrictedToPage - 1 !== currentPage) ||
          excludePages ||
          oncePerPage ||
          oncePerVariant ||
          oncePerCategory ||
          oncePerDocument ||
          parentNotAvailable
        ) {
          blockPasteDisable = true;
          blockPasteDisableBlockContainer = true;
        }
        pasteButton2 = (
          <Button
            bsSize="small"
            data-tip={messages.editor.paste_content}
            disabled={blockPasteDisable}
            data-for="blockinfo2"
            data-block="-1"
            onClick={this.pasteBlock}
          >
            <i className="fas fa-sign-in fa-rotate-90" data-block="-1" />
          </Button>
        );
      }
    }
    let offsetTop;

    const options = [
      ['sepia', messages.editor.filter_sepia],
      ['grayscale', messages.editor.filter_grayscale],
      ['blur', messages.editor.filter_blur],
      ['brightness', messages.editor.filter_brightness],
      ['contrast', messages.editor.filter_contrast],
      ['hue-rotate', messages.editor.filter_hueRotate],
      ['invert', messages.editor.filter_invert],
      ['opacity', messages.editor.filter_opacity],
      ['saturate', messages.editor.filter_saturate],
    ];

    const _currentPage = 'page_' + (currentPage * 1 + 1);

    let pageColorClasses = '';
    let filteredColorSettings = [];
    filteredColorSettings = _.filter(colorSettings, setting => setting.id === _currentPage);
    _forEach(filteredColorSettings, (filteredColorSetting, i) => {
      pageColorClasses = pageColorClasses + ' ' + filteredColorSetting.color;
    });

    const imagesForFilterPreview = [];
    _forEach(blockForFilter?.params, (param, i) => {
      if (param.thumb) {
        let active = '5px solid transparent';
        if (indexOfImage === i) {
          active = '5px solid #28a745';
        }
        imagesForFilterPreview.push(
          <div
            key={i}
            style={{
              position: 'relative',
              border: active,
              margin: '5px',
            }}
          >
            <img
              key={i}
              src={param.thumb}
              alt={'Image' + i}
              style={{ width: '50px', height: '50px', margin: '1px', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => this.setIndexOfImage(param.name, param.value)}
            />
          </div>
        );
      }
    });
    return (
      <>
        {this.state.showFilter && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h1>{messages.editor.filter_settings}</h1>
                <h5>{messages.choose_image_for_filter}</h5>
                <div style={{ display: 'flex' }}>
                  {imagesForFilterPreview}
                </div>
                {(indexOfImage || indexOfImage === 0) && (
                  <>
                    <img
                      style={{ maxWidth: '50%', margin: '10px', filter: this.state.filterAsString }}
                      src={this.state.blockForFilter?.params[indexOfImage]?.value}
                      alt=""
                    />
                    <div>
                      <div>
                        <FormAutocomplete
                          onChange={(name, value) => {
                            this.setFilterSlider(name, value);
                          }}
                          value=""
                          label={messages.editor.filter_selection}
                          name="choise_filter"
                          className="choose_filter_for_image"
                          options={options.map(option => {
                            return { label: option[1], value: option[0] };
                          })}
                        />
                      </div>
                      <div>
                        <p style={{ fontSize: '14px' }}>
                          {this.state.currentFilterData &&
                            messages.editor.filter_set + ': ' + this.state.currentFilterData}
                        </p>
                        {outputRange}
                      </div>
                    </div>
                    <Button
                      bsStyle="success"
                      bsSize="large"
                      style={{ position: 'fixed', left: '8vw', top: '85%', zIndex: '100' }}
                      onClick={() => {
                        this.saveFilter();
                      }}
                    >
                      {messages.editor.use_filter}
                    </Button>
                  </>
                )}
                <Button bsStyle="danger" bsSize="large" onClick={this.handleIEClose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
        {this.state.showUrl && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h1>{messages.url_settings}</h1>
                <FormInput
                  type="text"
                  label="Url"
                  name="url"
                  onChange={(name, value) => {
                    this.setState({ imgUrl: value });
                  }}
                  value={this.state.imgUrl}
                />
                <Button
                  bsStyle="success"
                  bsSize="large"
                  style={{ position: 'fixed', left: '8vw', top: '85%', zIndex: '100' }}
                  onClick={() => {
                    this.saveUrl();
                  }}
                >
                  {messages.editor.use_filter}
                </Button>
                <Button bsStyle="danger" bsSize="large" onClick={this.handleIEClose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
        {paddingStyle.length > 0 ? <EditorApplyStyles styles={paddingStyle} update /> : null}
        <div
          ref={this.pageRef}
          id="page"
          className={type + grid + ' page seite_' + (currentPage + 1) + ' anzahl_' + pagesCount + evenOdd + pageColorClasses}
          style={styles}
          onClick={type !== 'active' ? this.gotoPage : undefined}
        >
          <div className={'ui_beschnitt' + trimBorderActive}>
            <div id="ui_beschnitt_oben" className="ui_beschnitt_oben" />
            <div id="ui_beschnitt_unten" className="ui_beschnitt_unten" />
            <div id="ui_beschnitt_links" className="ui_beschnitt_links" />
            <div id="ui_beschnitt_rechts" className="ui_beschnitt_rechts" />
          </div>
          {type === 'active' ? (
            <div className="block-options2" style={styleOptions}>
              {copyAllButton}
              {pasteButton2}
              <ReactTooltip id="blockinfo2" place="left" effect="solid" />
            </div>
          ) : null}
          {endless && loading ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </div>
          ) : (
            blocks.length > 0 &&
            blocks.map((block, i) => {
              // const sectionClass = sectionClasses[i] ? ' ' + sectionClasses[i] : '';
              const sectionClassPre = block.template ? block.template.match(/(?:data-section=").*?(?=\s*")/gm) : [''];
              const sectionClass = sectionClassPre ? ' ' + sectionClassPre[0].replace('data-section="', '') : '';
              offsetTop = block.page_space_needed !== 0 ? block.top : null;
              if (offsetTop === -1 || offsetTop === null || typeof offsetTop === 'undefined') {
                offsetTop = getBlockTopFromRaster(raster, currentPage, block.id);
                offsetTop = offsetTop < 0 ? 0 : offsetTop;
              }

              const siblings = getSiblings(raster, currentPage, block.id);
              let previousBlock = null;
              let nextBlock = null;
              if (siblings.up !== '') {
                previousBlock = _.find(blocks, ['id', siblings.up]);
              }
              if (siblings.down !== '') {
                nextBlock = _.find(blocks, ['id', siblings.down]);
              }
              let blockUp = null;
              let blockDown = null;
              let blockUpDisable = true;
              let blockDownDisable = true;
              let blockDeleteDisable = false;
              const blockEditDisable = false;
              let blockBiggerDisable = false;
              const blockSmallerDisable = false;
              let blockCopyDisable = false;
              let biggerButton = '';
              let smallerButton = '';
              let multiplePagesBlockClass = '';
              let bottomClass = '';
              const blockHeight = rasterV * block.page_space_needed;
              const vPos = parseFloat(marginTop) + (endless ? i : offsetTop) * rasterV;
              const verticalPosition = {
                top: vPos + 'px',
                height: blockHeight + 'px',
              };
              if (block.bottom) {
                verticalPosition.bottom = block.bottom + 'px';
                bottomClass = ' page-bottom';
              }
              if (block.once_per_document || block.no_copies) {
                blockCopyDisable = true;
              }
              if (typeof block.oversize !== 'undefined' && block.oversize !== 0) {
                multiplePagesBlockClass = ' page-span-' + block.oversize.toString() + ' ' + block.oversize_position;
              }
              if (block.bigger !== null && block.bigger !== undefined && type === 'active') {
                blockBiggerDisable = true;
                if (fixedBlocks.length > 0) {
                  let slotsBetween = [];
                  let offsetTopFBlock;
                  _.forEach(fixedBlocks, (fBlock, count) => {
                    offsetTopFBlock = getBlockTopFromRaster(raster, currentPage, fBlock);
                    if (offsetTopFBlock > offsetTop || fixedBlocks.length === count + 1) {
                      if (fixedBlocks.length === count + 1 && offsetTopFBlock <= offsetTop) {
                        slotsBetween = getRasterSlots(
                          raster,
                          currentPage,
                          settings.pages[pageNameIndex].page_space,
                          offsetTop,
                          0
                        );
                      } else {
                        slotsBetween = getRasterSlots(
                          raster,
                          currentPage,
                          settings.pages[pageNameIndex].page_space,
                          0,
                          0
                          // offsetTop,
                          // offsetTopFBlock
                        );
                      }
                      _.forEach(slotsBetween, slotB => {
                        if (slotB >= block.bigger_needed - block.page_space_needed) {
                          blockBiggerDisable = false;
                        }
                      });
                      return false;
                    }
                  });
                } else {
                  _.forEach(freeSlots, slot => {
                    if (slot >= block.bigger_needed - block.page_space_needed) {
                      blockBiggerDisable = false;
                    }
                  });
                }
                biggerButton = (
                  <Button
                    data-tip={messages.editor.enlarge_block}
                    disabled={blockBiggerDisable}
                    data-for="blockinfo"
                    data-block={block.id}
                    data-template={block.bigger}
                    onClick={this.resizeBlock}
                  >
                    <i className="fas fa-expand-alt" data-block={block.id} data-template={block.bigger} />
                  </Button>
                );
              }
              if (block.smaller !== null && block.smaller !== undefined && type === 'active') {
                smallerButton = (
                  <Button
                    data-tip={messages.editor.shrink_block}
                    disabled={blockSmallerDisable}
                    data-for="blockinfo"
                    data-block={block.id}
                    data-template={block.smaller}
                    onClick={this.resizeBlock}
                  >
                    <i className="fas fa-compress-alt" data-block={block.id} data-template={block.smaller} />
                  </Button>
                );
              }
              let error = '';
              let errorText = '';
              if (block.errors.overflow) {
                error = 'overflow';
                errorText = messages.editor.text_overflow;
              }
              if (block.errors.default_image) {
                error += ' default-image';
                errorText += messages.editor.initial_image_available;
              }
              if (
                siblings.up !== '' &&
                !block.fixed &&
                typeof previousBlock !== 'undefined' &&
                previousBlock !== null &&
                !previousBlock.fixed
              ) {
                blockUpDisable = false;
                blockUp = siblings.up;
              }
              if (
                siblings.down !== '' &&
                !block.fixed &&
                typeof nextBlock !== 'undefined' &&
                nextBlock !== null &&
                !nextBlock.fixed
              ) {
                blockDownDisable = false;
                blockDown = siblings.down;
              }
              if (block.not_deletable) {
                blockDeleteDisable = true;
              }
              if (pasteData && type === 'active') {
                pasteButton = (
                  <Button
                    data-tip={messages.editor.paste_content_after}
                    disabled={blockPasteDisable}
                    data-for="blockinfo"
                    data-block={block.id}
                    onClick={this.pasteBlock}
                  >
                    <i className="fas fa-sign-in fa-rotate-90" data-block={block.id} />
                  </Button>
                );
              }
              let parentClass = '';
              if (block.parent_class !== undefined && block.parent_class !== null) {
                parentClass = ' ' + block.parent_class;
              }
              const heightClass = ' height_' + block.page_space_needed;
              let containerClass = '';
              let containerSelect = '';

              if (block.container) {
                containerClass = ' block-container';
                containerSelect = () => setBlockContainer(block.id);
                if (blockContainer === block.id) {
                  containerClass += ' active';
                }
              }
              let inlineClass = '';
              if (block.inline_block) {
                inlineClass = ' inline-block col-section';
              }
              let navId = '';
              if (block.nav_link) {
                navId = block.nav_link.replace('#', '') + block.id;
              }

              let blockTemplate = _cloneDeep(block.template);
              blockTemplate = blockTemplate.toLowerCase();

              let positionX; // block.position_x || getNumericValue(block.template, "defaultPositionX") || 0;
              let positionY; // block.position_y || getNumericValue(block.template, "defaultPositionX") || 0;

              if (block.position_x !== undefined && block.position_x !== null) {
                positionX = block.position_x;
              } else {
                positionX = getNumericValue(blockTemplate, 'defaultpositionx') || 0;
              }
              if (block.position_y !== undefined && block.position_y !== null) {
                positionY = block.position_y;
              } else {
                positionY = getNumericValue(blockTemplate, 'defaultpositiony') || 0;
              }

              let bounds = 'parent';
              const blockNames = ['dragtop', 'dragleft', 'dragright', 'dragbottom'];
              if (blockNames.some(blockName => blockTemplate.includes(blockName))) {
                bounds = { top: 0, left: 0, right: 0, bottom: 0 };
                blockNames.forEach(blockName => {
                  if (blockTemplate.includes(blockName)) {
                    const propName = blockName.substring(4).toLowerCase();
                    bounds[propName] = getNumericValue(blockTemplate, blockName);
                  }
                });
              }

              let sectionColorClasses = '';
              let filteredSectionColorSettings = [];
              filteredSectionColorSettings = _.filter(colorSettings, setting => setting.id === block?.id);

              _forEach(filteredSectionColorSettings, (filteredSectionColorSetting, i) => {
                sectionColorClasses = sectionColorClasses + ' ' + filteredSectionColorSetting.color;
              });


              let sectionFontSize = ' ';
              sectionFontSize = ' ' + (fontSizeSettings[(block?.id)] ?? '') + ' ';

              return (
                <section
                  ref={this.sectionRef}
                  id={navId || block.id}
                  style={verticalPosition}
                  className={
                    bottomClass +
                    multiplePagesBlockClass +
                    parentClass +
                    heightClass +
                    containerClass +
                    inlineClass +
                    sectionClass +
                    sectionColorClasses +
                    sectionFontSize
                  }
                  key={block.id}
                >
                  {block.template &&
                  (block.template.includes('draggable') || block.template.includes('allowdraganddrop')) ? (
                    <Draggable
                      handle="i"
                      onDrag={this.handleDrag}
                      bounds={bounds}
                      defaultPosition={{
                        x: positionX,
                        y: positionY,
                      }}
                      {...dragHandlers} // bounds={{ top: -100, left: -100, right: 100, bottom: 100 }}
                    >
                      <div
                        onMouseDownCapture={() => {
                          this.getDraggedBlockId(block.id);
                        }}
                      >
                        <i className="fas fa-arrows" style={arrow} />
                        {type === 'active' ? (
                          <>
                            <EditorBlock
                              type={type}
                              appIntl={appIntl}
                              data={block}
                              settings={settings}
                              updateParam={updateParam}
                              updateInnerBlock={updateInnerBlock}
                              zoomEqualizer={zoomEqualizer}
                              index={index + '.' + i}
                              key={block.id}
                              i={i}
                              pageZoom={pageZoom}
                              stageWidth={stageWidth}
                              changeErrors={changeErrors}
                              imageUpload={imageUpload}
                              usedMedia={usedMedia}
                              copyBlock={this.copyBlock}
                              pasteBlock={this.pasteBlock}
                              templateBlocks={templateBlocks}
                              containerSelect={containerSelect}
                              unsetBlockContainer={unsetBlockContainer}
                              blockContainer={blockContainer}
                              rasterV={rasterV}
                              pasteData={pasteData}
                              copiedBlock={copiedBlock}
                              navEntry={navEntry}
                              resetNavEntry={resetNavEntry}
                              publisherInfo={publisherInfo}
                              bgColor={bgColor[block.id]}
                              filesUploading={filesUploading}
                              rootUser={rootUser}
                              wlAdminUser={wlAdminUser}
                              isSubmission={isSubmission}
                              onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
                              saveDescription={saveDescription}
                              uploadError={uploadError}
                              updateBackgroundE={this.updateBackgroundE}
                              fitAuto={this.props.fitAuto}
                              getDeviceType={this.props.getDeviceType}
                              showKeyboard={showKeyboard}
                              resizeBlock={this.props.resizeBlock}
                              blockBiggerDisable={blockBiggerDisable}
                              autoResizeSupported
                              blockPasteDisableBlockContainer={blockPasteDisableBlockContainer}
                              setSelectedColor={this.props.setSelectedColor}
                              currentPage={currentPage}
                              getColorSettings={this.props.getColorSettings}
                              deleteSelectedColor={this.props.deleteSelectedColor}
                              colorSettings={this.props.colorSettings}
                              setFontSizeSettings={this.props.setFontSizeSettings}
                              fontSizeSettings={this.props.fontSizeSettings}
                            />
                          </>
                        ) : (
                          <PreviewBlock
                            type={type}
                            appIntl={appIntl}
                            data={block}
                            index={index + '.' + i}
                            rasterV={rasterV}
                            setFontSizeSettings={this.props.setFontSizeSettings}
                            fontSizeSettings={this.props.fontSizeSettings}
                            usedMedia={usedMedia}
                          />
                        )}
                      </div>
                    </Draggable>
                  ) : type === 'active' ? (
                    <EditorBlock
                      type={type}
                      appIntl={appIntl}
                      data={block}
                      settings={settings}
                      updateParam={updateParam}
                      updateInnerBlock={updateInnerBlock}
                      zoomEqualizer={zoomEqualizer}
                      index={index + '.' + i}
                      key={block.id}
                      i={i}
                      pageZoom={pageZoom}
                      stageWidth={stageWidth}
                      changeErrors={changeErrors}
                      imageUpload={imageUpload}
                      usedMedia={usedMedia}
                      copyBlock={this.copyBlock}
                      pasteBlock={this.pasteBlock}
                      templateBlocks={templateBlocks}
                      containerSelect={containerSelect}
                      unsetBlockContainer={unsetBlockContainer}
                      blockContainer={blockContainer}
                      rasterV={rasterV}
                      pasteData={pasteData}
                      copiedBlock={copiedBlock}
                      navEntry={navEntry}
                      resetNavEntry={resetNavEntry}
                      publisherInfo={publisherInfo}
                      bgColor={bgColor[block.id]}
                      filesUploading={filesUploading}
                      rootUser={rootUser}
                      wlAdminUser={wlAdminUser}
                      isSubmission={isSubmission}
                      onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
                      saveDescription={saveDescription}
                      uploadError={uploadError}
                      fitAuto={this.props.fitAuto}
                      getDeviceType={this.props.getDeviceType}
                      showKeyboard={showKeyboard}
                      resizeBlock={this.props.resizeBlock}
                      blockBiggerDisable={blockBiggerDisable}
                      autoResizeSupported
                      blockPasteDisableBlockContainer={blockPasteDisableBlockContainer}
                      setSelectedColor={this.props.setSelectedColor}
                      currentPage={currentPage}
                      getColorSettings={this.props.getColorSettings}
                      deleteSelectedColor={this.props.deleteSelectedColor}
                      colorSettings={this.props.colorSettings}
                      setFontSizeSettings={this.props.setFontSizeSettings}
                      fontSizeSettings={this.props.fontSizeSettings}
                    />
                  ) : (
                    <PreviewBlock
                      type={type}
                      appIntl={appIntl}
                      data={block}
                      index={index + '.' + i}
                      rasterV={rasterV}
                      ausgabe={'test' + currentPage}
                      setFontSizeSettings={this.props.setFontSizeSettings}
                      fontSizeSettings={this.props.fontSizeSettings}
                      usedMedia={usedMedia}
                    />
                  )}

                  {type === 'active' && !block.no_options ? (
                    <div className="block-options" style={styleOptions}>
                      <ButtonGroup vertical bsSize="small">
                        {block.container ? (
                          blockContainer === block.id ? (
                            <Button
                              className="select-container"
                              data-tip={messages.editor.deselect_element}
                              data-for="blockinfo"
                              onClick={() => unsetBlockContainer()}
                            >
                              <i className="fas fa-layer-minus" />
                            </Button>
                          ) : (
                            <Button
                              className="select-container"
                              data-tip={messages.editor.select_element}
                              data-for="blockinfo"
                              onClick={() => setBlockContainer(block.id)}
                            >
                              <i className="fas fa-layer-plus" />
                            </Button>
                          )
                        ) : null}
                        {block.fixed ? (
                          <Button
                            className="is-fixed"
                            data-tip={messages.editor.block_can_not_move}
                            data-for="blockinfo"
                          >
                            <i className="far fa-lock" />
                          </Button>
                        ) : (
                          <Button
                            data-tip={messages.editor.up}
                            data-for="blockinfo"
                            disabled={blockUpDisable}
                            data-block={block.id}
                            data-target={blockUp}
                            onClick={this.switchBlock}
                          >
                            <i className="far fa-angle-up" data-block={block.id} data-target={blockUp} />
                          </Button>
                        )}
                        <div className="toggle-edit btn btn-default" disabled={blockEditDisable} data-block={block.id}>
                          <i className="fas fa-edit" data-block={block.id} />
                          <ButtonGroup className="edit-block" bsSize="small" vertical>
                            <Button
                              data-tip={messages.editor.reset_block}
                              data-for="blockinfo"
                              data-block={block.id}
                              data-template={block.template_id}
                              onClick={this.resBlock}
                            >
                              <i className="fas fa-retweet" data-block={block.id} data-template={block.template_id} />
                            </Button>
                            {block.template && block.template.includes('<img') /* && block.params.length > 0 */ && (
                              <>
                                <Button
                                  data-tip={messages.editor.filter_settings}
                                  data-for="blockinfo"
                                  data-block={block.id}
                                  data-template={block.template_id}
                                  onClick={() => this.getIndexForImage(block.id)}
                                >
                                  <i
                                    className="fad fa-layer-group"
                                    data-block={block.id}
                                    data-template={block.template_id}
                                  />
                                </Button>
                                <Button
                                  data-tip={messages.url_settings}
                                  data-for="blockinfo"
                                  data-block={block.id}
                                  data-template={block.template_id}
                                  onClick={() => this.getImageUrlSettings(block.id)}
                                >
                                  <i className="fad fa-link" data-block={block.id} data-template={block.template_id} />
                                </Button>
                              </>
                            )}

                            {block.template && block.template.includes('data-background=') && settings.back_colors && (
                              <Button data-block={block.id} data-parent={block.id} className="toggle_bg">
                                <i className="fas fa-fill-drip" data-block={block.id} data-parent={block.id} />
                                <ButtonGroup className="edit-color" bsSize="small" vertical>
                                  {settings.target &&
                                    settings.target.back_colors &&
                                    settings.target.back_colors.map(bgColor => {
                                      const getBackgroundAttr = block.template.match(
                                        /(?:data-background=").*?(?=\s*")/gm
                                      );
                                      if (
                                        getBackgroundAttr &&
                                        (getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                          getBackgroundAttr[0].includes('true'))
                                      ) {
                                        return (
                                          <Button
                                            data-tip={messages.editor.change_backgroundcolor}
                                            data-for="blockinfo"
                                            data-block={bgColor.id}
                                            data-parent={block.id}
                                            onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                            style={{ padding: 4 }}
                                          >
                                            <p
                                              style={{
                                                width: 20,
                                                height: 20,
                                                padding: 0,
                                                margin: 0,
                                                background: bgColor.color,
                                              }}
                                            />
                                          </Button>
                                        );
                                      }
                                      return null;
                                    })}
                                  {settings.back_colors &&
                                    settings.back_colors.map(bgColor => {
                                      const getBackgroundAttr = block.template.match(
                                        /(?:data-background=").*?(?=\s*")/gm
                                      );
                                      if (
                                        getBackgroundAttr &&
                                        (getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                          getBackgroundAttr[0].includes('true'))
                                      ) {
                                        return (
                                          <Button
                                            data-tip={messages.editor.change_backgroundcolor}
                                            data-for="blockinfo"
                                            data-block={bgColor.id}
                                            data-parent={block.id}
                                            onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                            style={{ padding: 4 }}
                                          >
                                            <p
                                              style={{
                                                width: 20,
                                                height: 20,
                                                padding: 0,
                                                margin: 0,
                                                background: bgColor.color,
                                              }}
                                            />
                                          </Button>
                                        );
                                      }
                                      return null;
                                    })}
                                </ButtonGroup>
                              </Button>
                            )}
                            <Button
                              data-tip={messages.editor.copy_block}
                              data-for="blockinfo"
                              disabled={blockCopyDisable || (!isSubmission && !wlAdminUser && onlyWhitelabelAdminCanModifyBlocks.includes(block.id))}
                              data-block={block.id}
                              onClick={this.copyBlock}
                            >
                              <i className="fas fa-clone" data-block={block.id} />
                            </Button>
                            {biggerButton}
                            {smallerButton}
                            <Button
                              data-tip={messages.editor.delete_block}
                              data-for="blockinfo"
                              disabled={blockDeleteDisable || (!isSubmission && !wlAdminUser && onlyWhitelabelAdminCanModifyBlocks.includes(block.id))}
                              data-block={block.id}
                              data-space={block.page_space_needed}
                              onClick={
                                blockContainer === block.id
                                  ? e => {
                                      unsetBlockContainer();
                                      this.deleteBlock(e, sectionClass, i);
                                    }
                                  : e => {
                                      this.deleteBlock(e, sectionClass, i);
                                    }
                              }
                            >
                              <i className="fas fa-trash" data-block={block.id} data-space={block.page_space_needed} />
                            </Button>
                          </ButtonGroup>
                        </div>
                        {pasteButton}
                        {block.fixed ? null : (
                          <Button
                            data-tip={messages.editor.down}
                            data-for="blockinfo"
                            disabled={blockDownDisable}
                            data-block={block.id}
                            data-target={blockDown}
                            onClick={this.switchBlock}
                          >
                            <i className="far fa-angle-down" data-block={block.id} data-target={blockDown} />
                          </Button>
                        )}
                      </ButtonGroup>
                      <ReactTooltip id="blockinfo" place="right" effect="solid" />
                    </div>
                  ) : type === 'active' && block.no_options && !block.container ? (
                    <div className="block-options" style={styleOptions}>
                      <ButtonGroup vertical bsSize="small">
                        <div className="toggle-edit btn btn-default" disabled={blockEditDisable} data-block={block.id}>
                          <i className="fas fa-edit" data-block={block.id} />
                          <ButtonGroup className="edit-block" bsSize="small" vertical>
                            <Button
                              data-tip={messages.editor.reset_block}
                              data-for="blockinfo"
                              data-block={block.id}
                              data-template={block.template_id}
                              onClick={this.resBlock}
                            >
                              <i className="fas fa-retweet" data-block={block.id} data-template={block.template_id} />
                            </Button>
                            {block.template && block.template.includes('<img') && (
                              <>
                                <Button
                                  data-tip={messages.editor.filter_settings}
                                  data-for="blockinfo"
                                  data-block={block.id}
                                  data-template={block.template_id}
                                  onClick={() => this.getIndexForImage(block.id)}
                                >
                                  <i
                                    className="fad fa-layer-group"
                                    data-block={block.id}
                                    data-template={block.template_id}
                                  />
                                </Button>
                              </>
                            )}
                            {biggerButton}
                            {smallerButton}
                          </ButtonGroup>
                        </div>
                        {pasteButton}
                        {block.fixed ? null : (
                          <Button
                            data-tip={messages.editor.down}
                            data-for="blockinfo"
                            disabled={blockDownDisable}
                            data-block={block.id}
                            data-target={blockDown}
                            onClick={this.switchBlock}
                          >
                            <i className="far fa-angle-down" data-block={block.id} data-target={blockDown} />
                          </Button>
                        )}
                      </ButtonGroup>
                      <ReactTooltip id="blockinfo" place="right" effect="solid" />
                    </div>
                  ) : !block.container ? (
                    <div className="block-options" style={styleOptions}>
                      <ButtonGroup vertical bsSize="small">
                        <Button
                          data-tip={messages.editor.reset_block}
                          data-for="blockinfo"
                          data-block={block.id}
                          data-template={block.template_id}
                          onClick={this.resBlock}
                        >
                          <i className="fas fa-retweet" data-block={block.id} data-template={block.template_id} />
                        </Button>
                      </ButtonGroup>
                    </div>
                  ) : null}

                  {type === 'active' && block.no_options && block.container ? (
                      <div className="block-options" style={styleOptions}>
                        <ButtonGroup vertical bsSize="small">
                          {block.container ? (
                            blockContainer === block.id ? (
                              <Button
                                className="select-container"
                                data-tip={messages.editor.deselect_element}
                                data-for="blockinfo"
                                onClick={() => unsetBlockContainer()}
                              >
                                <i className="fas fa-layer-minus" />
                              </Button>
                            ) : (
                              <Button
                                className="select-container"
                                data-tip={messages.editor.select_element}
                                data-for="blockinfo"
                                onClick={() => setBlockContainer(block.id)}
                              >
                                <i className="fas fa-layer-plus" />
                              </Button>
                            )
                          ) : null}
                        </ButtonGroup>
                      </div>
                  ) : null}
                </section>
              );
            })
          )}
          {endless && (
            <div className="edition-pagination">
              <Pagination
                count={this.props.blocks.length}
                resPerPage={blocksPerPage}
                activePage={page}
                handleSelect={this.changePage}
                isDropDown
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({ appIntl: state.intl });

export default withRouter(injectIntl(connect(mapStateToProps)(EditorPage)));
