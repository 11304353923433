import axios from 'axios';

export const GET_SUBSCRIPTION_PRICES = 'GET_SUBSCRIPTION_PRICES';
export const GET_SUBSCRIPTION_PRICES_PENDING = 'GET_SUBSCRIPTION_PRICES_PENDING';
export const GET_SUBSCRIPTION_PRICES_FULFILLED = 'GET_SUBSCRIPTION_PRICES_FULFILLED';

export const ADD_SUBSCRIPTION_PRICE = 'ADD_SUBSCRIPTION_PRICE';
export const ADD_SUBSCRIPTION_PRICE_PENDING = 'ADD_SUBSCRIPTION_PRICE_PENDING';
export const ADD_SUBSCRIPTION_PRICE_FULFILLED = 'ADD_SUBSCRIPTION_PRICE_FULFILLED';

export const EDIT_SUBSCRIPTION_PRICE = 'EDIT_SUBSCRIPTION_PRICE';
export const EDIT_SUBSCRIPTION_PRICE_PENDING = 'EDIT_SUBSCRIPTION_PRICE_PENDING';
export const EDIT_SUBSCRIPTION_PRICE_FULFILLED = 'EDIT_SUBSCRIPTION_PRICE_FULFILLED';

export const DELETE_SUBSCRIPTION_PRICE = 'DELETE_SUBSCRIPTION_PRICE';
export const DELETE_SUBSCRIPTION_PRICE_PENDING = 'DELETE_SUBSCRIPTION_PRICE_PENDING';
export const DELETE_SUBSCRIPTION_PRICE_FULFILLED = 'DELETE_SUBSCRIPTION_PRICE_FULFILLED';

export const DEACTIVATE_SUBSCRIPTION_PRICE = 'DEACTIVATE_SUBSCRIPTION_PRICE';
export const DEACTIVATE_SUBSCRIPTION_PRICE_PENDING = 'DEACTIVATE_SUBSCRIPTION_PRICE_PENDING';
export const DEACTIVATE_SUBSCRIPTION_PRICE_FULFILLED = 'DEACTIVATE_SUBSCRIPTION_PRICE_FULFILLED';

export const ACTIVATE_SUBSCRIPTION_PRICE = 'ACTIVATE_SUBSCRIPTION_PRICE';
export const ACTIVATE_SUBSCRIPTION_PRICE_PENDING = 'ACTIVATE_SUBSCRIPTION_PRICE_PENDING';
export const ACTIVATE_SUBSCRIPTION_PRICE_FULFILLED = 'ACTIVATE_SUBSCRIPTION_PRICE_FULFILLED';

export const CANCEL_SUBSCRIPTION_PRICE_FOR_USER = 'DEACTIVATE_SUBSCRIPTION_PRICE_FOR_USER';
export const CANCEL_SUBSCRIPTION_PRICE_FOR_USER_PENDING = 'DEACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_PENDING';
export const CANCEL_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED = 'DEACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED';
export const CANCEL_SUBSCRIPTION_PRICE_FOR_USER_REJECTED = 'DEACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_REJECTED';
export const ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER = 'ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER';
export const ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_PENDING = 'ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_PENDING';
export const ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED = 'ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED';
export const ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_REJECTED = 'ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_REJECTED';
export const EDIT_SUBSCRIPTION_PRICE_FOR_USER = 'EDIT_SUBSCRIPTION_PRICE_FOR_USER';
export const EDIT_SUBSCRIPTION_PRICE_FOR_USER_PENDING = 'EDIT_SUBSCRIPTION_PRICE_FOR_USER_PENDING';
export const EDIT_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED = 'EDIT_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED';
export const EDIT_SUBSCRIPTION_PRICE_FOR_USER_REJECTED = 'EDIT_SUBSCRIPTION_PRICE_FOR_USER_REJECTED';

export const SET_VARIABLES_SUBSCRIPTION_PRICE = 'SET_VARIABLES_SUBSCRIPTION_PRICE';
export const GET_SINGLE_SUBSCRIPTION_PRICE = 'GET_SINGLE_SUBSCRIPTION_PRICE';

const PREFIX = '/api/subscriptions/prices';
const api = (verb, url, data = {}) => axios[verb](PREFIX + url, data).then(({ data }) => data);

// export function getSubscriptionPricesR() {
//   return {
//     type: GET_SUBSCRIPTION_PRICES,
//     payload: api('get', '/list/prices.json'),
//   };
// }

// export function getSingleSubscriptionPriceR(id) {
//   return {
//     type: GET_SINGLE_SUBSCRIPTION_PRICE,
//     payload: api('get', `/views/${id}/price.json`),
//   };
// }

export function editSubscriptionPriceR(id, data) {
  return {
    type: EDIT_SUBSCRIPTION_PRICE,
    payload: api('put', `/prices/${id}.json`, data),
  };
}

export function addSubscriptionPriceR(data) {
  return {
    type: ADD_SUBSCRIPTION_PRICE,
    payload: api('post', '/prices.json', data),
  };
}

export function deleteSubscriptionPriceR(id) {
  return {
    type: DELETE_SUBSCRIPTION_PRICE,
    payload: api('delete', `/prices/${id}.json`),
  };
}

export const deactivateSubscriptionPriceR = (id, data) => ({
  type: DEACTIVATE_SUBSCRIPTION_PRICE,
  payload: api('put', `/deactivates/${id}/price.json`, data),
});

export const activateSubscriptionPriceR = (id, data) => ({
  type: ACTIVATE_SUBSCRIPTION_PRICE,
  payload: api('put', `/activates/${id}/price.json`, data),
});

export const cancelSubscriptionPriceForUserR = (id, data) => ({
  type: CANCEL_SUBSCRIPTION_PRICE_FOR_USER,
  payload: api('delete', `/prices/${id}.json`, data),
});

export const editSubscriptionPriceForUserR = (id, data) => ({
  type: EDIT_SUBSCRIPTION_PRICE_FOR_USER,
  payload: api('put', `/prices/${id}.json`, data),
});

export function addSubscriptionPriceForUserR(data) {
  return {
    type: ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER,
    payload: api('post', '/prices.json', data),
  };
}

export const setVariablesSubscriptionPrice = (key, id) => ({
  type: SET_VARIABLES_SUBSCRIPTION_PRICE,
  payload: [key, id],
});
