import React, { Component, createRef } from 'react';
import _, { cloneDeep as _cloneDeep, findIndex as _findIndex, find as _find, forEach as _forEach } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { Button, ButtonGroup } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EditorBlock from '../EditorBlock';
import PreviewBlock from '../PreviewBlock';
import { removeBlock, resetBlock } from '../actions/Blocks';
import { setLoginForwardUrl } from '../../../actions/LoginActions';
import Draggable from "react-draggable";
import {
  getCampaignsR,
  getEditionR,
  initTemplateR,
  save_ContentR,
  saveToR,
  setChangesR,
  setVariables,
} from '../../../actions/EditorActions';

const nodeRef = createRef(null);

/**
 * Extracts attribute value
 * from a HTML code string like
 * <tagName attributeName=" 123 " />
 * @param {string} str HTML code to parse
 * @param {string} attributeName to look for
 * @returns number
 */
const getNumericValue = (str, attributeName) => {
  const re = new RegExp(`${attributeName}="\\s*([^"\\s]*)`);
  const match = re.exec(str);
  return match ? Number(match[1]) : 0;
};

class DraggableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: {},
      activeDrags: 0,
      deltaPosition: [],
      controlledPosition: {
        x: -400,
        y: 200
      },
      draggedBlockId: "",
    };
    this.handleDrag = this.handleDrag.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onStop = this.onStop.bind(this);
    this.adjustXPos = this.adjustXPos.bind(this);
    this.adjustYPos = this.adjustYPos.bind(this);
    this.onControlledDrag = this.onControlledDrag.bind(this);
    this.onControlledDragStop = this.onControlledDragStop.bind(this);

  }



  handleDrag(e, ui) {
    const { draggedBlockId, deltaPosition } = this.state;
    const _deltaPosition = _cloneDeep(deltaPosition);
    const index = _.findIndex(deltaPosition, data => data.blockId === draggedBlockId);
    _deltaPosition[index].x =  _deltaPosition[index].x + ui.deltaX;
    _deltaPosition[index].y =  _deltaPosition[index].y + ui.deltaY;

    this.setState({deltaPosition:_deltaPosition});
  }

  onStart() {
    this.setState({ activeDrags: ++this.state.activeDrags });
  }

  onStop() {
    this.setState({ activeDrags: --this.state.activeDrags }, () =>{
      this.updateDragPosition(this.state.draggedBlockId);
    });

  }
  // For controlled component
  adjustXPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = this.state.controlledPosition;
    this.setState({ controlledPosition: { x: x - 10, y } });
  }

  adjustYPos(e) {
    e.preventDefault();
    e.stopPropagation();
    const { controlledPosition } = this.state;
    const { x, y } = controlledPosition;
    this.setState({ controlledPosition: { x, y: y - 10 } });
  }

  onControlledDrag(e, position) {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  }

  onControlledDragStop(e, position) {
    this.onControlledDrag(e, position);
    this.onStop();
  }

  getDraggedBlockId(id){
    this.setState({draggedBlockId: id})
  }

  componentDidMount = () => {
    this.initializeDeltaPositions();
  };


  componentDidUpdate = (prevProp) => {
    if(prevProp.blocks !== this.props.blocks){
      this.initializeDeltaPositions();
    }
  };




  updateBackground = (bgColorID, blockId) => {
    const newBgColor = this.state.bgColor;
    newBgColor[blockId] = bgColorID;
    this.setState({ bgColor: newBgColor });
  };

  imageUpload = (id, image, imageKey, name, data, width, height, filename, thumb, copyright) => {
    const { imageUpload } = this.props;

    imageUpload(id, image, imageKey, name, data, width, height, filename, thumb, copyright, this.updateParams);
  };

  updateParam = (id, name, value, errors, name2 = '', value2 = '', page) => {
    const error = {};
    let params = [{ name, value, error }];
    if (name2 !== '' && value2 !== '') {
      params = [{ name, value, error }, { name: name2, value: value2, error: errors }];
    }
    this.updateParams(id, params, errors, page);
    return true;
  };

  updateParams = (blockId, paramList, errors, page = null, initialUpdate) => {
    const { blocks, updateInnerBlock, spaceConsumed, usedMedia, id } = this.props;
    const data = _cloneDeep(blocks);

    const element = _find(data, { id: blockId });
    const { params } = element;

    /*
        if (errors !== null) {
          paramList.error = element.errors;
          _forEach(errors, ({ overflow, default_image }) => {
            paramList.error.overflow = overflow;
            paramList.error.default_image = default_image;
          });
        }
     */

    _forEach(paramList, ({ name, value, error }) => {
      const paramIndex = _findIndex(params, ['name', name]);
      const paramValue = { name, value, errors: paramList.error };
      if (paramIndex >= 0) {
        // parameter was found
        params[paramIndex] = paramValue;
      } else {
        // parameter was not found, append the parameter with its value
        params.push(paramValue);
      }
    });

    if (initialUpdate) {
      updateInnerBlock(id, paramList, spaceConsumed, usedMedia, page, null, blockId, initialUpdate);
    } else {
      updateInnerBlock(id, data, spaceConsumed, usedMedia, page, null, blockId);
    }
  };

  removeBlocksDefault = (sourceRasterId, sourceSpace) => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock, id } = this.props;
    const changes = removeBlock(
      0,
      [{ blocks, space_consumed: spaceConsumed }],
      null,
      sourceRasterId,
      sourceSpace,
      true,
      usedMedia
    );
    updateInnerBlock(
      this.props.id,
      changes.contentPages[0].blocks,
      changes.contentPages[0].space_consumed,
      changes.usedMedia
    );
    // remove child of block inside container
    this.removeBlocksChildAuto(sourceRasterId);

    // remove colorSetting of block inside container
    this.props.deleteSelectedColor(sourceRasterId, id);
  };

  removeBlocksChildAuto = sourceRasterId => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const indexOfParent = _.findIndex(blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    if (parent !== undefined && parent !== '') {
      _forEach(blocks, (block, iB) => {
        const blockCombinationChild = block.block_combination_child.split(',');
        _forEach(blockCombinationChild, (blockCC, iBC) => {
          if (blockCC === parent) {
            this.setDelay(block.id, block.page_space_needed);
          }
        });
      });
    }
  };

  setDelay = (blockId, blockPageSpaceNeeded) => {
    const timeOut = window.setTimeout(() => {
      this.removeBlocksChild(blockId, blockPageSpaceNeeded);
    }, 100);
  };

  removeBlocksChild = (sourceRasterId, sourceSpace) => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    this.props.deleteSelectedColor(sourceRasterId);
    const changes = removeBlock(
      0,
      [{ blocks, space_consumed: spaceConsumed }],
      null,
      sourceRasterId,
      sourceSpace,
      true,
      usedMedia
    );
    updateInnerBlock(
      this.props.id,
      changes.contentPages[0].blocks,
      changes.contentPages[0].space_consumed,
      changes.usedMedia
    );
  };

  updateDragPosition = (id) => {
    const { blocks, updateInnerBlock, spaceConsumed, usedMedia } = this.props;
    const { deltaPosition } = this.state;
    const data = _cloneDeep(blocks);
    const block = _find(data, { id });
    const index = _.findIndex(deltaPosition, data => data.blockId === id);
    block.position_x = deltaPosition[index].x;
    block.position_y =  deltaPosition[index].y;
    updateInnerBlock(this.props.id, data, null, null, null, true);
  };

  initializeDeltaPositions = () => {
    const { blocks } = this.props;
    const { deltaPosition } = this.state;
    const _deltaPosition = _cloneDeep(deltaPosition);
    _.forEach(blocks, (block, i) => {
      const index = _.findIndex(deltaPosition, data => data.blockId === block.id);
      if (index === -1) {
        let blockTemplate = _cloneDeep(block.template);
        blockTemplate = blockTemplate.toLowerCase();
        let posX = getNumericValue(blockTemplate, "defaultpositionx") || 0;
        let posY = getNumericValue(blockTemplate, "defaultpositiony") || 0;
        if(block.position_x){
          posX = block.position_x;
        }
        if(block.position_y){
          posY = block.position_y;
        }
        let positions = {
          blockId: block.id,
          x: posX,
          y: posY,
        }
        _deltaPosition.push(positions);
      }
    });
    this.setState({deltaPosition: _deltaPosition});
  };

  deleteBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const { target } = e;
    const sourceRasterId = target.getAttribute('data-block');
    const {
      intl: { messages },
    } = this.props;
    const sourceSpace = parseInt(target.getAttribute('data-space'));

    /* check if block is parent of a child to set warning before delete */
    const indexOfParent = _.findIndex(blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    let warning = messages.editor.delete_selected_content_block;
    if (parent !== undefined && parent !== '') {
      warning = messages.editor.delete_selected_content_block + ' ' + messages.editor.delete_children_of_parents;
    }

    const options = {
      title: messages.editor.delete_content + '?',
      message: warning,
      buttons: [
        {
          label: messages.editor.yes_delete,
          onClick: () => {
            this.removeBlocksDefault(sourceRasterId, sourceSpace);
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  copyBlock = e => {
    const { copyBlock } = this.props;
    copyBlock(e, 'inner');
    this.forceUpdate(() => {});
  };

  pasteBlock = e => {
    const { pasteBlock } = this.props;
    pasteBlock(e, 'inner');
    this.forceUpdate(() => {});
  };

  moveBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const { target } = e;
    const sourceIndex = target.getAttribute('data-index');
    const targetIndex = target.getAttribute('data-target');
    const changedBlocks = _cloneDeep(blocks);
    changedBlocks[targetIndex] = _cloneDeep(blocks[sourceIndex]);
    changedBlocks[sourceIndex] = _cloneDeep(blocks[targetIndex]);
    updateInnerBlock(this.props.id, changedBlocks, spaceConsumed, usedMedia);
  };

  resBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock, templateBlocks } = this.props;
    const { target } = e;
    const {
      intl: { messages },
    } = this.props;
    const options = {
      title: messages.editor.reset_content + '?',
      message: messages.editor.reset_content_block_to_root,
      buttons: [
        {
          label: messages.editor.yes_reset,
          onClick: () => {
            const rasterId = target.getAttribute('data-block');
            const templateId = target.getAttribute('data-template');
            const changes = resetBlock(rasterId, templateId, [{ blocks }], 0, templateBlocks, usedMedia);
            updateInnerBlock(this.props.id, changes.contentPages[0].blocks, spaceConsumed, changes.usedMedia);

            this.forceUpdate();
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  getSortedBlocksCopy = (cRow, cCol, blocks) => {

    let col = 1;
    let cRowInit = cRow;
    let sortedBlock = [];
    for(let i = 0; i < blocks.length; i++){
      if(blocks[i].page_space_needed <= cRow){
        for(let a = 0; a < sortedBlock.length; a++){
          if(sortedBlock.length > 0 && sortedBlock[a].col === col){
            sortedBlock[a].R = cRow - blocks[i].page_space_needed;
          }
        }
        cRow = cRow - blocks[i].page_space_needed;
      }
      else{
        col = col + 1;
        cRow = cRowInit;
        cRow = cRow - blocks[i].page_space_needed;
      }
      sortedBlock.push({id:blocks[i].id, col: col, R:cRow, disabled: true});
    }
    return sortedBlock;
  }

  getMaxFreeSpaceInContainer = (_sortedBlocks, spaceNeeded) => {
    let freeRow;
    let maxFilledCol; // erhalte die höchste reihe in der sich ein block befindet
    let sortedBlocksNew = [];
    _forEach(_sortedBlocks, (sBlock, i) => {
      maxFilledCol = sBlock.col;
    });
    // more than 1 col is free
    if(maxFilledCol+1 < this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded){
      _forEach(_sortedBlocks, (sBlock, i) => {
        sBlock.disabled = false;
      });
      sortedBlocksNew = _sortedBlocks
    }

    // only 1 col is free
    if(maxFilledCol < this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded){

      _forEach(_sortedBlocks, (sBlock, i) => {
        sBlock.disabled = true;
      });
      for(let i = 1; i <= maxFilledCol ; i++){
        let filteredBlocks = _.filter(_sortedBlocks, x => x.col === i);
        filteredBlocks[filteredBlocks.length-1].disabled = false;
        sortedBlocksNew = sortedBlocksNew.concat(filteredBlocks);

      }
    }
    // only a part of col is free
    // add cols are full -> first tests seemed to work
    //before add -> if(maxFilledCol === this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded){
    if(maxFilledCol === this.props.data.container_cols){
      _forEach(_sortedBlocks, (sBlock, i) => {
        if(sBlock.R < spaceNeeded){
          sBlock.disabled = true;
        }else{
          sBlock.disabled = false;
        }
        sortedBlocksNew = _sortedBlocks
      });

    }
    return sortedBlocksNew;
  }

  render() {
    const { bgColor } = this.state;
    const {
      blocks,
      type,
      appIntl,
      pageZoom,
      spaceConsumed,
      spaceTotal,
      settings,
      index,
      id,
      usedMedia,
      containerSelect,
      rasterV,
      pasteData,
      copiedBlock,
      attributes,
      showKeyboard,
      data
    } = this.props;
    const zoomEqualizer = 1 / parseFloat(pageZoom);
    const styleOptions = {
      transform: `scale(${zoomEqualizer})`,
    };
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { deltaPosition, controlledPosition } = this.state;

    let pasteButton = '';
    let pasteDisable = false;

    let _sortedBlocks;
    let _blocks;

    if(copiedBlock !== null && copiedBlock !== undefined) {
      _sortedBlocks = this.getSortedBlocksCopy(data.container_space, data.container_cols, data.blocks);
      _blocks = this.getMaxFreeSpaceInContainer(_sortedBlocks, copiedBlock[0].page_space_needed)
    }


    return (
            <div style={{height:"100%", width:"100%"}} className={`DraggableContent ${attributes.className}`} onClick={containerSelect}>
              {!blocks
                ? null
                : blocks.map((block, i) => {
                  const sectionClassPre = block.template ? block.template.match(/(?:data-section=\").*?(?=\s*\")/gm) : [''];
                  const sectionClass = sectionClassPre ? ' ' + sectionClassPre[0].replace('data-section="', '') : '';
                  const {
                    intl: { messages },
                  } = this.props;
                  let blockUpDisable = true;
                  let blockDownDisable = true;
                  const blockDeleteDisable = false;
                  const blockEditDisable = false;
                  const blockCopyDisable = false;
                  if (pasteData && type === 'active') {
                    pasteButton = (
                      <Button
                        data-tip={messages.editor.paste_content_after}
                        disabled={this.props.blockPasteDisable === false ? _blocks ? _blocks[i]?.disabled : true : this.props.blockPasteDisable}
                        data-for="blockinfo"
                        data-block={block.id}
                        data-parent={this.props.id}
                        onClick={this.pasteBlock}
                      >
                        <i className="fas fa-sign-in fa-rotate-90" data-block={block.id} data-parent={this.props.id} />
                      </Button>
                    );
                  }
                  const blockHeight = rasterV * block.page_space_needed;
                  if (type !== 'active') {
                  };
                  const innerBlockHeight = {
                    height: blockHeight + 'px',
                  };
                  if (i > 0) {
                    blockUpDisable = false;
                  }
                  if (i < blocks.length - 1) {
                    blockDownDisable = false;
                  }
                  const sectionRef = createRef();
                  let error = '';
                  let errorText = '';
                  if (block.errors.overflow) {
                    error = 'overflow';
                    errorText = messages.editor.text_overflow;
                  }
                  if (block.errors.default_image) {
                    error += ' default-image';
                    errorText += messages.editor.initial_image_available;
                  }
                  let inlineClass = '';
                  if (block.inline_block) {
                    inlineClass = ' inline-block col-section';
                  }
                  const heightClass = ' height_' + block.page_space_needed;
                  let positionX = undefined; //block.position_x || getNumericValue(block.template, "defaultPositionX") || 0;
                  let positionY = undefined; //block.position_y || getNumericValue(block.template, "defaultPositionX") || 0;

                  let blockTemplate = _cloneDeep(block.template);
                  blockTemplate = blockTemplate.toLowerCase();

                  if(block.position_x !== undefined && block.position_x !== null){
                    positionX = block.position_x;
                  }else{
                    positionX = getNumericValue(blockTemplate, "defaultpositionx") || 0;
                  }
                  if(block.position_y !== undefined && block.position_y !== null){
                    positionY = block.position_y;
                  }else{
                    positionY = getNumericValue(blockTemplate, "defaultpositiony") || 0;
                  }

                  let bounds = "parent";

                  const blockNames = ['dragtop', 'dragleft', 'dragright', 'dragbottom'];
                  if (blockNames.some(blockName => blockTemplate.includes(blockName))) {
                    bounds = { top: 0, left: 0, right: 0, bottom: 0 };
                    blockNames.forEach(blockName => {
                      if (blockTemplate.includes(blockName)) {
                        const propName = blockName.substring(4).toLowerCase();
                        bounds[propName] = getNumericValue(blockTemplate, blockName);
                      }
                    });
                  }

                  let sectionColor = '';
                  if (this.props.getColorSettings) {
                    sectionColor = this.props.getColorSettings(block?.id, id);
                  }

                  let sectionFontSize = ' ';
                  sectionFontSize = ' ' + (this.props.fontSizeSettings[(block?.id)] ?? ' ') + ' ';

                  return (
                    <section
                      ref={sectionRef}
                      style={innerBlockHeight}
                      data-tip={errorText}
                      data-for="errorinfo"
                      className={heightClass + inlineClass + sectionClass + sectionColor + sectionFontSize}
                      onMouseOver={()=>{this.getDraggedBlockId(block.id)}}

                    >
                      <Draggable handle="i" onDrag={this.handleDrag}
                                 bounds={bounds} defaultPosition={{ x: positionX , y: positionY }} {...dragHandlers} //bounds={{ top: -100, left: -100, right: 100, bottom: 100 }}
                      >
                      <div onMouseOver={() => {
                        this.getDraggedBlockId(block.id)
                        }}>
                        <i className="fas fa-arrows"></i>
                        {type === 'active' ? (
                          <EditorBlock
                            appIntl={appIntl}
                            data={block}
                            settings={settings}
                            updateParam={this.updateParam}
                            zoomEqualizer={zoomEqualizer}
                            index={index + '.' + id + '.' + i}
                            pageZoom={pageZoom}
                            imageUpload={this.imageUpload}
                            usedMedia={usedMedia}
                            bgColor={bgColor[block.id]}
                            key={id + '.' + i}
                            showKeyboard={showKeyboard}
                            resizeBlock={this.props.resizeBlock}
                            blockBiggerDisable={this.props.blockBiggerDisable}
                            autoResizeSupported={false}
                            getDeviceType={this.props.getDeviceType}
                            setFontSizeSettings={this.props.setFontSizeSettings}
                            fontSizeSettings={this.props.fontSizeSettings}
                            currentPage={this.props.currentPage}
                            setSelectedColor={this.props.setSelectedColor}
                            getColorSettings={this.props.getColorSettings}
                            deleteSelectedColor={this.props.deleteSelectedColor}
                            blockContainerId={id}
                            colorSettings={this.props.colorSettings}
                          />
                        ) : (
                          <PreviewBlock
                            appIntl={appIntl}
                            data={block}
                            index={index + '.' + id + '.' + i}
                            key={id + '.' + i}
                            bgColor={bgColor}
                            setFontSizeSettings={this.props.setFontSizeSettings}
                            fontSizeSettings={this.props.fontSizeSettings}
                            updateParam={this.updateParam}
                            currentPage={this.props.currentPage}
                            setSelectedColor={this.props.setSelectedColor}
                            blockContainerId={id}
                            usedMedia={usedMedia}
                          />
                        )}
                        {type === 'active' ? (
                          <div className="block-options" style={styleOptions}>
                            <ButtonGroup vertical bsSize="small">
                              <Button
                                data-tip={messages.editor.up}
                                data-for="blockinnerinfo"
                                disabled={blockUpDisable}
                                data-block={block.id}
                                data-index={i}
                                data-target={i - 1}
                                onClick={this.moveBlock}
                              >
                                <i className="far fa-angle-up" data-block={block.id} data-index={i} data-target={i - 1} />
                              </Button>
                              <Button className="toggle-edit" disabled={blockEditDisable} data-block={block.id}>
                                <i className="fas fa-edit" data-block={block.id} />
                                <ButtonGroup className="edit-block" bsSize="small" vertical>
                                  <Button
                                    data-tip={messages.editor.reset_block}
                                    data-for="blockinnerinfo"
                                    data-block={block.id}
                                    data-template={block.template_id}
                                    onClick={this.resBlock}
                                  >
                                    <i className="fas fa-retweet" data-block={block.id} data-template={block.template_id} />
                                  </Button>
                                  <Button
                                    data-tip={messages.editor.copy_block}
                                    data-for="blockinnerinfo"
                                    disabled={blockCopyDisable}
                                    data-block={block.id}
                                    data-parent={this.props.id}
                                    onClick={this.copyBlock}
                                  >
                                    <i className="fas fa-clone" data-block={block.id} data-parent={this.props.id} />
                                  </Button>
                                  {block.template && block.template.includes('data-background=') && (
                                    <Button data-block={block.id} data-parent={this.props.id} className="toggle_bg">
                                      <i className="fas fa-fill-drip" data-block={block.id} data-parent={this.props.id} />
                                      <ButtonGroup className="edit-color" bsSize="small" vertical>
                                        {this.props.settings.target &&
                                        this.props.settings.target.back_colors &&
                                        this.props.settings.target.back_colors.map(bgColor => {
                                          const getBackgroundAttr = block.template.match(
                                            /(?:data-background=\").*?(?=\s*\")/gm
                                          );
                                          if (
                                            getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                            getBackgroundAttr[0].includes('true')
                                          ) {
                                            return (
                                              <Button
                                                data-tip={messages.editor.change_backgroundcolor}
                                                data-for="blockinfo"
                                                data-block={bgColor.id}
                                                data-parent={block.id}
                                                onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                                style={{ padding: 4 }}
                                              >
                                                <p
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    padding: 0,
                                                    margin: 0,
                                                    background: bgColor.color,
                                                  }}
                                                />
                                              </Button>
                                            );
                                          }
                                          return null;
                                        })}
                                        {this.props.settings.back_colors &&
                                        this.props.settings.back_colors.map(bgColor => {
                                          const getBackgroundAttr = block.template.match(
                                            /(?:data-background=\").*?(?=\s*\")/gm
                                          );
                                          if (
                                            getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                            getBackgroundAttr[0].includes('true')
                                          ) {
                                            return (
                                              <Button
                                                data-tip={messages.editor.change_backgroundcolor}
                                                data-for="blockinfo"
                                                data-block={bgColor.id}
                                                data-parent={this.props.id}
                                                onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                                style={{ padding: 4 }}
                                              >
                                                <p
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    padding: 0,
                                                    margin: 0,
                                                    background: bgColor.color,
                                                  }}
                                                />
                                              </Button>
                                            );
                                          }
                                          return null;
                                        })}
                                      </ButtonGroup>
                                    </Button>
                                  )}
                                  {/* biggerButton}
                          {smallerButton */}
                                  <Button
                                    data-tip={messages.editor.delete_block}
                                    data-for="blockinnerinfo"
                                    disabled={blockDeleteDisable}
                                    data-block={block.id}
                                    data-space={block.page_space_needed}
                                    onClick={this.deleteBlock}
                                  >
                                    <i className="fas fa-trash" data-block={block.id} data-space={block.page_space_needed} />
                                  </Button>
                                </ButtonGroup>
                              </Button>
                              {pasteButton}
                              <Button
                                data-tip={messages.editor.down}
                                data-for="blockinnerinfo"
                                disabled={blockDownDisable}
                                data-block={block.id}
                                data-index={i}
                                data-target={i + 1}
                                onClick={this.moveBlock}
                              >
                                <i className="far fa-angle-down" data-block={block.id} data-index={i} data-target={i + 1} />
                              </Button>
                            </ButtonGroup>
                            <ReactTooltip id="blockinnerinfo" place="left" effect="solid" />
                          </div>
                        ) : null}
                      </div>
                      </Draggable>
                    </section>
                  );
                })}

        {/*
        <Draggable bounds="parent" {...dragHandlers}>
          <div className="box" style={{width:"100px", height:"100px", backgroundColor:"pink", cursor:"move"}}>
            I can only be moved within my offsetParent.
            <br />
            <br />
            Both parent padding and child margin work properly.
          </div>
        </Draggable>
        */}
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
    editionRV: state.editor.editionRV,
    pendingR: state.editor.pendingR,
    savingR: state.editor.savingR,
    unsavedR: state.editor.unsavedR,
    linkDataR: state.editor.linkDataR,
    campaignOptionsR: state.editor.campaignOptionsR,
    forwardingR: state.editor.forwardingR,
    previewingR: state.editor.previewingR,
    exportingR: state.editor.exportingR,
    printingR: state.editor.printingR,
    printx: state.editor.printx,
    changesR: state.editor.changesR,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoginForwardUrl: forwardUrl => {
      dispatch(setLoginForwardUrl(forwardUrl));
    },
    getEditionR: data => {
      dispatch(getEditionR(data));
    },
    save_ContentR: (data, datab) => {
      dispatch(save_ContentR(data, datab));
    },
    setVariables: data => {
      dispatch(setVariables(data));
    },
    saveToR: data => {
      dispatch(saveToR(data));
    },
    initTemplateR: (data, datab) => {
      dispatch(initTemplateR(data, datab));
    },
    getCampaignsR: () => {
      dispatch(getCampaignsR());
    },
    setChangesR: data => {
      dispatch(setChangesR(data));
    },
  };
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DraggableContainer)
  )
);
