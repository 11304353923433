import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';
import { isUndefined as _isUndefined, isNull as _isNull, toString as _toString } from 'lodash';

export default class FormInputConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !_isUndefined(props.value) && !_isNull(props.value) ? _toString(props.value) : '',
      tempValue: !_isUndefined(props.value) && !_isNull(props.value) ? _toString(props.value) : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => (this.state.focused ? 'focused ' : '');
  checkRequired = () => (this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '');
  checkError = () => (this.props.validation.valid ? '' : 'has-error ');
  checkAccess = () => this.props.meta.acl.read;
  checkReadOnly = () => !this.props.meta.acl.write;

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  handleTempChange = (value) => {
    this.setState({ tempValue: value });
  };

  handleConfirm = () => {
    const { name, value, ...restProps } = this.props;

    // Alle data-* Attribute sammeln
    const dataset = Object.keys(restProps)
      .filter((key) => key.startsWith("data-"))
      .reduce((acc, key) => {
        acc[key] = restProps[key];
        return acc;
      }, {});

    const event = {
      target: {
        name: name,
        value: this.state.tempValue,
        getAttribute: (attr) => dataset[attr] || null,
      },
    };

    this.setState({ value: this.state.tempValue });
    this.props.onChange(event);
  };

  onBlur = (value) => {
    this.setState({ focused: false, tempValue: value });
    this.props.onBlur(this.props.name, value);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    return disabled || !meta.acl.write;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
      this.setState({ value: this.props.value, tempValue: this.props.value });
    }
  }

  render() {
    const {
      label,
      name,
      type,
      step,
      validation,
      infoText,
      prefixAddon,
      suffixAddon,
      autocomplete,
      min,
      max,
      readonly,
      style,
      useFormGroupDefault,
      maxLength,
      placeholder,
      className,
    } = this.props;
    const { value, tempValue } = this.state;

    const input = (
      <input
        ref={this.inputRef}
        value={tempValue || ''}
        type={type || 'text'}
        step={type === 'number' ? step : undefined}
        min={type === 'number' && min !== undefined ? min : undefined}
        max={type === 'number' && max !== undefined ? max : undefined}
        maxLength={maxLength ? maxLength : undefined}
        className={`form-control ${className}`}
        name={name}
        onChange={(e) => this.handleTempChange(e.target.value)}
        onFocus={() => this.setState({ focused: true })}
        onBlur={(e) => this.onBlur(e.target.value)}
        disabled={this.checkDisabled()}
        autoComplete={autocomplete}
        readOnly={readonly}
        placeholder={placeholder}
      />
    );

    return (
      <div className={'form-group-confirm'}>
        <div
          className={`form-group ${useFormGroupDefault ? 'form-group-default' : ''}
                      ${this.checkFocused()} ${this.checkRequired()} ${this.checkError()}
                      ${this.checkDisabled() ? 'disabled ' : ''}`}
          style={style}
          onClick={this.catchFocus}
        >
          <label>
            {label}
            {!!infoText && (
              <span className="m-l-5">
                <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                  <i className="far fa-question-circle cursor-help" />
                </OverlayTrigger>
              </span>
            )}
          </label>
          <div className="input-group">
            {prefixAddon && <span className="input-group-addon">{prefixAddon}</span>}
            {input}
            {suffixAddon && <span className="input-group-addon">{suffixAddon}</span>}
          </div>

          {!validation.valid && (
            <Overlay show={this.state.focused} target={this.inputRef.current} placement="top">
              <Popover className="popover-modal" id={`error_${name}_${label}`}>
                {validation.helpText}
              </Popover>
            </Overlay>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.handleConfirm}
          disabled={this.checkDisabled()}
        >
          <i className="fa-light fa-check" />
        </button>
      </div>
    );
  }
}

FormInputConfirm.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  type: 'text',
  step: 1,
  prefixAddon: '',
  suffixAddon: '',
  infoText: '',
  onBlur: () => {},
  autocomplete: null,
  useFormGroupDefault: true,
  className: '',
};
