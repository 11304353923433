import React, {Component, forwardRef, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Flipper } from 'react-flip-toolkit';
import LinksList from './../../../components/layout/mainMenus/common/LinksList';
import DropdownProfile from 'components/layout/mainMenus/common/DropdownProfile';
import CartMenu from 'components/layout/mainMenus/common/CartMenu';
import { submitLogout } from 'actions/LoginActions';
import may from 'js/security';
import {Button, FormControl} from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { setCompany } from '../../../actions/CompanyActions';
import { listDispatchHelper } from './../../../actions/ListActions';
import { isExternal } from 'helpers/http';
import {communicationVideoModule} from '@chedri/base';
import {getUpdateNotificationR} from "actions/ListActions";
import {getDescriptionInCurrentLanguage} from "helpers/intl";


class Default extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      company: '',
      refreshUpdateNotifications: true,
      currentIndexNotification: 0,
      initializeInterval: true,
    };
    this.setCompany = this.setCompany.bind(this);
    this.interval = null;
  }

  fetchCompanies = () =>
    axios
      .get(
        '/api/companies.json?offset=0&limit=50&search=&sort=relevance&dir=desc&page=1&filters%5Btype%5D=company'
      )
      .then(({ data }) => {
        this.setState({ companies: data.list });
      });

  setCompany = event => {
    this.setState({ company: event.target.value });
    this.props.setCompany({ company: event.target.value });
    this.props.updateClientList(event.target.value);
  };

  componentDidUpdate = (prevProps) => {
    const { user, appIntl, doChangeLocale, pending, updateNotification } = this.props;
    const { refreshUpdateNotifications, initializeInterval } = this.state;

    if (!prevProps.user.logged && user.logged) this.fetchCompanies();
    /* initialize UpdateNotifications */

    if (user.logged && refreshUpdateNotifications) {
      this.setState({refreshUpdateNotifications: false}, () => {
        this.props.getUpdateNotificationR();
        /* set Update Notifications Interval */
        const waitForUpdateNotifications = window.setInterval(() => {
            this.props.getUpdateNotificationR();
        }, 3600000);
      })

    }

    if (user.logged && pending !== prevProps.pending && pending === false) {
      if (updateNotification && updateNotification.length > 0) {
        if (initializeInterval) {
          this.interval = setInterval(() => {
            this.setState((prevState) => ({
              currentIndexNotification: (prevState.currentIndexNotification + 1) % updateNotification.length,
              initializeInterval: false,
            }));
          }, 20000);
        }
      }
    }
  }



  componentWillUnmount() {
    clearInterval(this.interval);
  }


  navClick = (e, link) => {
    e.preventDefault();
    e.stopPropagation();

    const { history, layout, handleMouseLeave } = this.props;

    if (isExternal(link)) {
      window.location = link;
      console.log("isexternal link")
    } else {
      history.push(link);
      console.log("historypush")
    }
  };

  handleNext = () => {
    const { currentIndexNotification } = this.state;
    const { updateNotification } = this.props;

    if (updateNotification) {
      this.setState({
        currentIndexNotification: (currentIndexNotification + 1) % updateNotification.length,
      });
    }
  };

  handlePrev = () => {
    const { currentIndexNotification } = this.state;
    const { updateNotification } = this.props;

    if (updateNotification) {
      this.setState({
        currentIndexNotification: (currentIndexNotification - 1 + updateNotification.length) % updateNotification.length,
      });
    }
  };

  render() {
    const {
      forwardedRef,
      appIntl: { urlPrefix },
      layout,
      user,
      scrolled,
      menuVersion,
      menuBackground,
      sidebarVisible,
      logoUrl,
      toggleSidebar,
      topNavLinks,
      languages,
      onMouseEnter,
      onMouseLeave,
      currentIndex,
      prevIndex,
      animatingOut,
      preview,
      roles,
      isInCall,
      ccToolbar
    } = this.props;
    const {intl: { messages }, appIntl, updateNotification} = this.props;
    const { companies, company, currentIndexNotification } = this.state;
    // if (may('ROLE_ROOT_EMPLOYEE', roles)) this.fetchCompanies();


    let updateNotificationClass = "";
    let updateNotificationClass_2 = "";
    let _updateNotification = false;
    if (updateNotification && updateNotification.length > 0) {
      //updateNotificationClass = "main-container-update-n-c";
      updateNotificationClass_2 = "main-container-update-div ";
      _updateNotification = true;
    }

    return (
      <nav className="horizontal-menu mv-default" role="navigation" ref={forwardedRef}>
        <div
          style={{display: ccToolbar}}
          className={updateNotificationClass_2 + `header no-border header-${menuVersion} header-${menuBackground} ${
            preview ? 'header-preview ' : ''
          }`}
          id="horizontal-menu"
        >
          {/* responsive menu */}
          {_updateNotification ?
          <div className="update-notification-container">
            <div className="notification-prev" onClick={() => this.handlePrev()}>
              <i className="fa-2x far fa-chevron-left" />
            </div>
            <div className="update-notification_i">
              <i className="fa-2x fad fa-lightbulb-exclamation"></i>
            </div>
            <div className="update-notification_p">
              {updateNotification &&
                <p>
                  <span>{currentIndexNotification + 1} / {updateNotification.length} - </span>
                  {getDescriptionInCurrentLanguage(
                    updateNotification[currentIndexNotification],
                    appIntl
                  )}
                </p>
              }
            </div>
            <div className="notification-right" onClick={() => this.handleNext()}>
              <i className="fa-2x far fa-chevron-right" />
            </div>
          </div> : <></>}
          <div className="container-fluid relative menu">
            <div className="pull-left full-height visible-sm visible-xs">
              <div className="header-inner ownWidth">
                <a
                  href="#toggleSidebar"
                  className="btn-link toggle-sidebar visible-sm-inline-block visible-xs-inline-block padding-5"
                  onClick={toggleSidebar}
                >
                  <div id="nav-burger-icon" className={(sidebarVisible ? 'open ' : '') + (scrolled ? 'scrolled ' : '')}>
                    <span />
                    <span />
                    <span />
                    <span />
                  </div>
                </a>
              </div>
            </div>
            {!user.logged ?
              <span style={{cursor: "pointer", zIndex: "1000"}}
                    className="icon-thumbnail icon-login"
                    onClick={e => {
                      this.navClick(e, this.props.appIntl.urlPrefix + 'login')
                    }}
              >
             <i className="far fa-user-circle"></i>
            </span>
              :
              <span style={{cursor: "pointer", zIndex: "1000"}}
                    className="icon-thumbnail icon-login"
                    onClick={() => !isInCall && this.props.submitLogout()}
              >
                 <i className="fa fa-power-off" />
              </span>
            }

            <div className="pull-center hidden-md hidden-lg">
              <div className="header-inner">
                <div className="brand inline">
                  <Link to={urlPrefix}>
                    <img src={logoUrl} alt="logo" className="nav-logo" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* desktop menu */}
          <div className="sm-table hidden-xs hidden-sm">
            <div className="header-inner">
              <div className="brand inline">
                <Link to={urlPrefix}>
                  {/* when it comes to the left padding, it's important to have it when the left menu is there */}
                  <img src={logoUrl} alt="logo" className="nav-logo" />
                </Link>
              </div>
            </div>
          </div>
          <div className="pull-right hidden-xs hidden-sm">
            <div className="bar-inner header-inner">
              <Link to={urlPrefix + "account"}>
                <div className="mainMenuLogo"
                  style={{
                    backgroundImage: "url('"+logoUrl+"')",
                    width:"210px",
                    height:"50px",
                    position:"absolute",
                    left:0,
                    backgroundRepeat:"no-repeat",
                    backgroundPosition:"center",
                    backgroundSize:"90px"
                  }}>
                </div>
              </Link>
              <Flipper flipKey={currentIndex} spring="veryGentle">
                <LinksList
                  flipId="main"
                  layout={layout}
                  elements={topNavLinks}
                  currentIndex={currentIndex}
                  prevIndex={prevIndex}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  animatingOut={animatingOut}
                  prepend={
                    [
                      /*<AccountSearchMenu
                      key="search"
                      label="Suchen"
                    />,*/
                    ]
                  }
                  append={[
                    user.logged ? (
                      <CartMenu
                        key="cart"
                        label={messages.cart}
                        layout={layout}
                        currentIndex={currentIndex}
                        prevIndex={prevIndex}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        animatingOut={animatingOut}
                        languages={languages}
                      />
                    ) : (
                      <Fragment key="nocart"/>
                    ),

                    <DropdownProfile
                      key="user"
                      layout={layout}
                      currentIndex={currentIndex}
                      prevIndex={prevIndex}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      animatingOut={animatingOut}
                    />,
                  ]}
                />
              </Flipper>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const DefaultForExport = forwardRef((props, ref) => <Default forwardedRef={ref} {...props} />);
DefaultForExport.displayName = 'MenuDefault';

//const mapStateToProps = state => ({ roles: state.auth.roles, appIntl: state.intl,  });

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    roles:  state.auth.roles,
    user: state.login.user,
    isInCall: communicationVideoModule.selectors.isInCall(state),
    ccToolbar: state.layout.ccToolbar,
    projectConfig: state.data.projectConfig,
    updateNotification: state.list.updates.updateNotification,
    pending: state.list.updates.pending
  };
};




const mapDispatchToProps = dispatch => {
  return {
    submitLogout: () => {
      dispatch(submitLogout());
    },
    setCompany: data => {
      dispatch(setCompany(data));
    },
    getUpdateNotificationR: () => {
      dispatch(getUpdateNotificationR());
    },
    updateClientList: client => {
      dispatch(
        listDispatchHelper(
          'clients',
          '/api/clients.json',
          { offset: 0, limit: 20, search: client, sort: '', dir: '', page: 1, filters: { type: 'company', isDeleted: false } },
          dispatch
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultForExport);
