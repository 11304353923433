/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["tr"],
  "messages": {
    "editor": {
      "first_name": "İlk isim",
      "name": "İsim",
      "subject": "Konu",
      "message": "Mesaj",
      "custom_dropdown": "Özelleştirilmiş açılır menü",
      "custom_label": "Kendi etiketlemeniz",
      "set_autoplay": "Otomatik Oynatma",
      "autoplay_info": "Dikkat! Bazı tarayıcılar çoğu durumda otomatik oynatmaya izin vermez. Ancak, sessiz otomatik oynatmaya her zaman izin verilir.",
      "set_muted": "Sessiz",
      "data_protection_officer": "Veri Koruma Görevlisi",
      "data_protection_authority": "Veri koruma otoritesi",
      "button_font_color": "Yazı tipi rengi",
      "set_label_dropdown": "Lütfen açılır menü için bir etiket atayın",
      "set_label_custom_field": "Lütfen giriş alanı için bir etiket atayın",
      "empty_field": "Serbest alan",
      "custom_field": "Kullanıcı tanımlı alan",
      "change_label": "Etiketi değiştir",
      "edit_label": "Etiketi düzenle",
      "salutation": "Selamlama",
      "title": "Başlık",
      "link_title": "Bağlantı adı",
      "main_text": "Ana metin",
      "more_text": "daha fazla metin",
      "create_text": "Metin oluşturun",
      "edit_text_slider": "Henüz hiçbir metin öğesi oluşturulmadı",
      "edit_rss_elements": "Henüz kalıp elemanı seçilmedi, eklemek için buraya tıklayın.",
      "rss_settings": "RSS besleme ayarları",
      "select_rss": "Şablonlardan bir RSS beslemesi seçebilirsiniz",
      "available_rss": "Mevcut RSS Beslemeleri",
      "load_rss_part_1": "Aşağıdaki RSS beslemesini seçtiniz.",
      "load_rss_part_2": "Beslemeleri yüklemek için 'RSS Beslemelerini Al' düğmesine tıklayın",
      "load_rss_feeds": "RSS Beslemelerini Alma",
      "color": "Renk",
      "background_color": "Arka plan rengi",
      "distance_to_border": "Kenara olan mesafe",
      "color_of_qrcode": "QR kod rengi",
      "click_me": "Beni tıklayın",
      "new_menu_item": "Yeni menü öğesi",
      "external": "dış",
      "imprint": "Künye",
      "privacy": "Veri koruma",
      "january": "Ocak",
      "february": "ŞUBAT",
      "march": "Mart",
      "april": "Nisan",
      "may": "Mayıs",
      "june": "Haziran",
      "july": "Temmuz",
      "august": "Ağustos",
      "september": "Eylül",
      "october": "EKİM",
      "november": "Kasım",
      "december": "Aralık",
      "monday": "Pazartesi",
      "tuesday": "Salı",
      "wednesday": "Çarşamba",
      "thursday": "Perşembe",
      "friday": "Cuma",
      "saturday": "Cumartesi",
      "sunday": "Pazar",
      "edit_date": "Randevu düzenleme",
      "oclock": "Saat",
      "filter_selection": "Filtre seçimi",
      "use_filter": "Başvurmak",
      "sample_picture_found": "Örnek görüntü bulundu",
      "already_edited_in_another_tab": "Bu baskı zaten başka bir sekmede düzenlenmiş olabilir.",
      "information_for_contact": "İletişim formu için veriler",
      "mail_receiver": "E-posta alıcısı",
      "google_analytics": "Google Analytics",
      "measurement_id": "Google Analytics için Ölçüm Kimliği",
      "new_picture_inserted": "Yeni resim eklendi",
      "text_changed": "Metin değiştirildi",
      "edit_title": "Başlığı düzenlemek için buraya tıklayın",
      "edit_description": "Resim açıklamasını düzenlemek için buraya tıklayın",
      "edit_copyright": "Telif hakkını düzenlemek için buraya tıklayın",
      "no_changes": "Değişiklik yok",
      "changes_on_document": "Belgede yapılan değişiklikler",
      "changes_on_page": "Sayfa değişiklikleri",
      "page": "Sayfa",
      "choose_button_label": "Lütfen düğme için bir etiket seçin",
      "edit_calendly": "Calendly ayarlarını düzenlemek için buraya tıklayın",
      "exchanged_with_page": "sayfa ile değiştirildi",
      "upload_failed": "Yükleme başarısız",
      "try_again": "Lütfen tekrar deneyin",
      "saving_failed": "Kaydetme başarısız",
      "new_page_inserted": " sayfadan sonra eklenen yeni sayfa/sayfalar",
      "delete_pages": "Sayfaları sil",
      "all_content_deleted": "Sayfalar ve içlerindeki tüm içerik silinecektir.",
      "yes_delete": "Evet, silin",
      "pages_deleted": " Sayfaya göre silinen sayfa/sayfalar",
      "rather_not": "Olmasa daha iyi.",
      "new_here": "Burada yeni misin?",
      "first_start": "Görünüşe göre yeni editörümüzü ilk kez açtınız. Bir tanıtım ister misiniz?",
      "yes_absolutely": "Evet, kesinlikle.",
      "im_fine": "İdare edebilirim.",
      "no_page_definitions_found": "Sayfa tanımı bulunamadı!",
      "move_up": " Sayfaları yukarı taşı",
      "side_position_fixed": "Yan konum sabit",
      "generated_in_pdf": "Sayfalar PDF'de otomatik olarak oluşturulur",
      "move_down": " Sayfaları aşağı taşı",
      "by": "için",
      "add_page": " Sayfa ekleme",
      "remove_page": " Sayfaları kaldırın",
      "unsaved_changes": "Kaydedilmemiş değişiklikleriniz var",
      "i_have_it": "Tamam, şimdi anladım!",
      "document_pictures": "Belgedeki görüntüler",
      "used_images": "Kullanılan görseller",
      "take_a_photo": "Bir fotoğraf çekin",
      "unused_images": "Kullanılmayan resimler",
      "content_is_saved": "İçindekiler kaydedilir",
      "page_arrangement": "Yan düzenleme",
      "print_sheet_view": "Sayfa görünümünü yazdır",
      "view_safety_distance": "Güvenlik mesafesinin görünümü",
      "view_structure": "Yapı görünümü",
      "start_tutorial": "Öğreticiyi başlat",
      "adjust_by_window": "Sayfayı pencereye uyarla",
      "adjust_by_width": "Sayfayı genişliğe göre ayarlama",
      "adjust_by_height": "Sayfayı yüksekliğe ayarlayın",
      "delete_content": "İçeriği sil",
      "delete_selected_content_block": "Bu, seçilen içerik bloğunu ve içeriğini (resimler, metinler) kaldırır.",
      "delete_children_of_parents": "Lütfen bunun bir üst öğe olduğunu unutmayın. Bunu silerseniz, ilişkili tüm alt öğeler de silinecektir.",
      "reset_content": "İçeriği sıfırla",
      "reset_content_block_to_root": "Bu, seçilen içerik bloğunu ve içeriğini (resimler, metinler) orijinal durumlarına sıfırlar.",
      "yes_reset": "Evet, sıfırla",
      "copy_full_content": "Tüm sayfa içeriğini kopyalama",
      "paste_content": "Panodaki içeriği yapıştırma",
      "enlarge_block": "Bloğu büyüt",
      "shrink_block": "Blok boyutunu küçültme",
      "text_overflow": "Metin taşması",
      "initial_image_available": " İlk görüntü mevcut",
      "paste_content_after": "Bunlardan sonra panodaki içeriği yapıştırın",
      "deselect_element": "Seçimi kaldırılan öğe",
      "select_element": "Öğe seçin",
      "block_can_not_move": "Blok hareket ettirilemez",
      "up": "Zirveye",
      "reset_block": "Sıfırlama bloğu",
      "change_backgroundcolor": "Arka plan rengini değiştirme",
      "change_fontcolor": "Yazı tipi rengini değiştirme",
      "copy_block": "Kopya bloğu",
      "delete_block": "Bloğu sil",
      "down": "Aşağı doğru",
      "product_in_cart": "Ürün alışveriş sepetinde",
      "added_to_cart": " alışveriş sepetinize eklendi.",
      "great": "Harika!",
      "to_cart": "Alışveriş sepetine",
      "are_you_sure": "Emin misin?",
      "reset_to_selected_status": "Belge seçilen duruma sıfırlanır",
      "abort": "İptal",
      "reset_to_version": "Sürüme sıfırla",
      "choose_a_campaign": "Kampanya seçin",
      "name_for_edition": "Bu baskının adı",
      "save_to": "Farklı kaydet...",
      "save": "Kaydet",
      "settings": "Ayarlar",
      "short_summary": "Kısa özet",
      "keywords_separated": "Virgülle ayrılmış anahtar kelimeler",
      "serial_letter": "Posta birleştirme",
      "activate_serial_letter": "Adres mektup birleştirmeyi etkinleştirin",
      "serial_letter_database": "Adres mektup birleştirme veritabanı",
      "serial_letter_database_upload_info": "Yükleme şu dosya formatlarını destekler - .xlsx, .xls ve .csv",
      "serial_letter_info": "Lütfen yer tutucuları veritabanındaki ilk satırın hücrelerine girin. Bunun altına ilgili değerleri girin. Örneğin aşağıdaki gibi:",
      "preview_of_database": "Veritabanı önizlemesi",
      "serial_letter_database_upload": "Yükle",
      "no_error": "Hata olmadan",
      "error": "Hata",
      "information_for_imprint": "Baskı için bilgiler",
      "responsible_for_content": "İçerikten sorumlu",
      "company_association": "Şirket / Dernek",
      "street": "Sokak",
      "zip_code": "Posta kodu",
      "city": "Yer",
      "email": "E-posta adresi",
      "phone_number": "Telefon",
      "data_protection_information": "Veri koruma hakkında bilgi",
      "responsible": "Sorumlu kişi",
      "change": "Değişim",
      "export_image": "Görüntüyü dışa aktar",
      "preview": "Önizleme",
      "preview_button": "Düğme önizlemesi",
      "print_pdf": "PDF Yazdır",
      "initialize": "Başlat!",
      "order": "Sipariş",
      "versions": "Versiyonlar",
      "currently_used_image": "Şu anda kullanılan görüntü",
      "upload_new_image": "Yeni resim yükle",
      "new_cut": "Recut",
      "take_on": "Devralmak",
      "choose_file": "Dosya seçiniz",
      "image_description": "Resim açıklaması",
      "image_upload": "Resim yükleme",
      "mediathek": "Medya kütüphanesi",
      "pixabay": "Pixabay",
      "add_content": "İçerik ekleyin",
      "copied_content_block": "İçerik bloğu kopyalandı",
      "unsaved_content": "Kaydedilmemiş değişiklikleriniz var! Editörden gerçekten ayrılmak istiyor musunuz?",
      "filter_settings": "Filtre ayarları",
      "filter_grayscale": "Greyscale",
      "filter_sepia": "Sepya",
      "filter_blur": "bulanıklık",
      "filter_brightness": "Parlaklık",
      "filter_contrast": "Kontrast",
      "filter_hueRotate": "Renk tonunu döndürme",
      "filter_invert": "Renkleri ters çevirin",
      "filter_opacity": "Şeffaflık",
      "filter_saturate": "Doygunluk",
      "filter_default": "Standart",
      "filter_set": "Üretimi durduruldu",
      "work_surface": "Çalışma yüzeyi burada yer almaktadır.",
      "work_surface_description": "Burası tasarım sürecinin gerçekleştiği yerdir. Görüntüler, metinler ve öğeler buraya eklenebilir ve düzenlenebilir.",
      "page_navigation": "Burada sayfa navigasyonunu bulacaksınız",
      "page_navigation_description": "Burada tek tek sayfalar arasında geçiş yapabilir ve sayfa ekleyebilir, çıkarabilir veya değiştirebilirsiniz.",
      "content_blocks": "Burada içerik bloklarını bulacaksınız",
      "content_blocks_description": "Burada, üzerlerine tıklayarak reklam materyalinize çeşitli resim ve metin öğeleri ekleyebilirsiniz. Reklam materyalinde yeterli alan yoksa, gereksiz öğeleri istediğiniz zaman silebilirsiniz.",
      "screen_view": "Ekran görünümünü buradan değiştirebilirsiniz.",
      "screen_view_description": "Burada web medyanızın görünümünü değiştirebilirsiniz. 100 her zaman reklam malzemesinin orijinal boyutunu temsil eder.",
      "error_display": "Hatalar burada görüntülenir.",
      "error_display_description": "Bir metin taşması veya diğer teknik hatalar bulunursa sistem sizi burada bilgilendirir.",
      "options": "Burada reklam materyalleriniz için seçenekler bulacaksınız.",
      "options_description": "Bir önizleme PDF'si oluşturmak için önizleme düğmesine veya reklam materyalini alışveriş sepetine yerleştirmek için sipariş düğmesine tıklayın. 'Farklı kaydet' ile reklam materyalinizi başka bir kampanyaya kopyalayabilirsiniz.",
      "tutorial_end": "Editörümüz özetle buydu!",
      "tutorial_end_description": "Artık reklam malzemelerinizi kurumsal tasarıma uygun olarak hızlı ve kolay bir şekilde tasarlayabilirsiniz.",
      "content_block_copied": "İçerik bloğu kopyalandı",
      "content_blocks_switched": "İçerik blokları değiştirildi",
      "content_block_reset": "İçerik bloğu sıfırlama",
      "content_block_resize": "İçerik bloğu boyutu değiştirildi",
      "content_block_removed": "İçerik bloğu silindi",
      "no_place": "Oda yok",
      "content_block_inserted": "İçerik bloğu eklendi",
      "no_dates_available": "Mevcut tarih yok, tarih eklemek için buraya tıklayın.",
      "new_date": "Yeni tarih",
      "edit_dates": "Randevuları düzenle",
      "date_description": "Randevunun açıklaması",
      "add": "Ekle",
      "no_dates_availableTwo": "Mevcut tarih yok",
      "date_title": "Atama unvanı",
      "date_place": "Randevu yeri",
      "date_from": "Am/Dn",
      "date_to": "Ta ki",
      "no_forms": "Henüz kalıp elemanı seçilmedi, eklemek için buraya tıklayın",
      "read_privacy_policy": "Gizlilik politikasını okudum ve anladım.",
      "contact_form_settings": "İletişim formu ayarları",
      "dropdown_settings": "Açılır menü ayarları",
      "add_options": "Seçenekler ekleyin",
      "dropdown_label": "Açılır etiket",
      "dropdown_option_fields": "Açılır radyo düğmeleri",
      "pls_choose": "Lütfen bir form öğesi seçin",
      "pls_chooseTwo": "Lütfen istediğiniz iletişim formu öğelerini seçin",
      "send": "Gönderme",
      "google_maps_element_settings": "Konum ayarları Google Haritalar",
      "no_location": "Henüz bir adres eklemediniz, düzenlemek için buraya tıklayın",
      "found_textoverflow": "Metin taşması bulundu",
      "minimum_size": "Minimum boyut",
      "too_low_resolution": "Resmin çözünürlüğü çok düşük!",
      "set_scale_free": "Ücretsiz ölçeklendirmeyi etkinleştirin",
      "unset_scale_free": "Serbest ölçeklendirmeyi devre dışı bırak",
      "no_images_selected": "Henüz resim seçilmedi, eklemek için buraya tıklayın",
      "gallery_settings": "Galeri ayarları",
      "add_image": "Resim ekle",
      "add_image_front": "Başlangıçta resim ekleyin",
      "add_image_back": "Sonuna resim ekleyin",
      "slider_images_invert": "Resim sırasını ters çevir",
      "image_text_settings": "Resim metni ayarları",
      "change_slider": "Başlığı veya açıklamayı değiştirmek için kaydırıcıdaki ilgili metne tıklayın",
      "change_image_title": "Resim başlığını değiştir",
      "change_image_description": "Resim açıklamasını değiştir",
      "image_copyright": "Resim telif hakkı",
      "slider_settings": "Kaydırıcı ayarları",
      "new_titel": "Yeni başlık",
      "new_link_title": "Yeni bağlantı başlığı",
      "edit_text_title": "Başlığı düzenle",
      "edit_text_link": "Bağlantıyı düzenle",
      "edit_link_title": "Bağlantı başlığını düzenle",
      "edit_text_text": "Metni düzenle",
      "add_element": "Öğe ekle",
      "add_element_before": "Ön tarafa öğe ekleyin",
      "add_element_after": "Arkaya eleman ekleyin",
      "edit_text_of_element": "Metinleri düzenlemek için kaydırıcıdaki ilgili metne tıklayın",
      "invert_elements": "Ters çevirme dizisi",
      "edit_nav": "Navigasyonu düzenle",
      "term": "Tanımlama",
      "link": "Bağlantı",
      "actions": "Eylemler",
      "content_of_QRCode": "QR kodunda hangi içerik yer almalıdır?",
      "address_of_website": "Web sitesinin adresini girin:",
      "name_surname": "Adı ve soyadı",
      "business_card": "Kartvizit",
      "must_url": "Bir URL olmalıdır",
      "position": "Pozisyon",
      "phone": "Telefon",
      "website": "Web sitesi",
      "address": "Adres",
      "email_message": "E-posta mesajı",
      "text": "Metin",
      "recipient_email_address": "Alıcı e-posta adresi",
      "reference": "Konu",
      "content": "İçindekiler",
      "your_text": "Sizin metniniz",
      "add_QRCode": "QR kodu ekleyin",
      "no_preview_of_twitter": "Maalesef Twitter'ı burada görüntülemek mümkün değil, lütfen web sitesi önizleme işlevini kullanın",
      "no_preview_of_calendly": "Maalesef Calendly'yi burada görüntülemek mümkün değil, lütfen web sitesi önizleme işlevini kullanın",
      "twitter_link": "Twitter bağlantısı gönderildi:",
      "social_media_settings": "Sosyal medya ayarları",
      "calendly_settings": "Calendly ayarları",
      "button_settings": "Düğme ayarları",
      "action_button_content_text": "Eylem düğmesinin ayarlarını düzenlemek için buraya tıklayın",
      "action_button_link_element": "Bir öğeye referans",
      "action_button_external_link": "Harici bir bağlantıya referans",
      "action_button_or": "veya",
      "button_color": "Düğme rengi",
      "add_social_media_account": "Henüz sosyal medya hesabı eklenmemiş, bir tane seçmek için buraya tıklayın.",
      "choice_of_provider": "Sosyal medya sağlayıcılarının seçimi",
      "embed_code": "Sosyal medya kanalından gömme kodu",
      "link_to_your_page": "Sayfanıza şu adresten bağlantı verin",
      "link_to_your_calendly_page": "Calendly sayfanıza bağlantı",
      "link_to_your_page_two": "Sayfanıza bağlantı",
      "video_settings": "Video ayarları",
      "add_video": "Henüz video seçilmedi, bir tane eklemek için buraya tıklayın",
      "choice_video_provider": "Video sağlayıcılarının seçimi",
      "upload_video": "Video yükle",
      "choice_mp4_file": "Video (.mp4) dosyasını seçin",
      "nav_type": "Tip",
      "nav_type_url": "URL",
      "nav_type_page": "Sayfa",
      "nav_type_page_prefix": "Sayfa"
    },
    "medialibrary": {
      "new_folder": "Yeni klasör",
      "sorting": "Sıralama",
      "directories": "Dizinler",
      "medialibrary": "Medya kütüphanesi",
      "media": "Medya",
      "upload_media": "Medya yükleme",
      "sorting_description": "Lütfen sıralamanın tanımlanan başlığa göre değil dosya adına göre yapıldığını unutmayın.",
      "sorting_info_text": "Klasörler ve medya için bir sıralama düzeni seçin. Sıralama kümesi tüm klasör görünümlerinde de geçerlidir.",
      "paste": "Ekleme",
      "info": "Lütfen dikkat!",
      "info_text": "Bir veya daha fazla dosya zaten mevcut. Bunlar tekrar yüklenmemiştir.",
      "back_to_root": "Kök dizine geri dönün",
      "directory_by": "Dizin",
      "created_by": "Tarafından oluşturuldu",
      "no_folder_created": "Hiçbir klasör oluşturulmadı",
      "no_media_found": "Klasörde medya yok",
      "upload_processing": "Yüklemeleriniz işlendi",
      "not_uploaded": "Medya yüklenemedi!",
      "close": "Kapat",
      "preview": "Görüntü/video önizleme",
      "name": "İsim",
      "upload_info": "Bilgi",
      "options": "Seçenekler",
      "status": "Durum",
      "adjust_meta": "Meta verileri özelleştirme",
      "title": "Başlık",
      "description": "Açıklama",
      "save": "Kaydet",
      "create_new_directory": "Yeni dizin oluşturun",
      "directory_name": "Dizin adı",
      "rename_folder": "Klasörü yeniden adlandır",
      "copyright": "Telif Hakkı",
      "upload": "Yükle",
      "wastebasket": "Atık kağıt sepeti",
      "are_you_sure_erase_file": "Dosyayı kalıcı olarak silmek istediğinizden emin misiniz?",
      "are_you_sure_erase_folder": "Klasörü geri alınamaz şekilde silmek istediğinizden emin misiniz?",
      "erase": "Silme"
    },
    "meta": {
      "campaigns": {
        "title": "Kampanyalar",
        "description": "Kampanyalar",
        "name": "Kampanyalar"
      },
      "productShop": {
        "title": "Mağaza",
        "description": "Ürün mü arıyorsunuz? O zaman doğru yere geldiniz.",
        "name": "Makale"
      },
      "carShop": {
        "title": "Araç mağazası",
        "description": "Hayalinizdeki arabayı mı arıyorsunuz? O zaman şimdi araçlarımıza bir göz atın.",
        "name": "Araçlar"
      },
      "car_detail_search": {
        "title": "Hangi arabanın size uygun olduğundan emin değil misiniz? Mükemmel arabayı bulmak için araba arama özelliğimizi kullanın",
        "description": "Belirli gereksinimleri olan bir araç arıyorsunuz, ancak tam olarak hangi araca ihtiyacınız olduğunu bilmiyor musunuz? Araç tipini, aktarma organlarını ve donanım seviyesini seçin.",
        "name": "Detaylı arama"
      },
      "car_brands": {
        "title": "Markanızı seçin",
        "description": "Markanızı seçin ve piyasadaki en iyi teklifleri keşfedin.",
        "name": "Marka seçimi"
      },
      "car_models": {
        "title": "MarkaAdı} ürününüzün modelini seçin",
        "description": "En popüler {modelOne} ile {modelTwo} arasından bir model seçin.",
        "name": "Modeller"
      },
      "car_model_drive": {
        "title": "MarkaAdı} {modelAdı} ürününüz için yakıt türünü ve şanzımanı seçin",
        "description": "Elimizde {fuelTypes} motorlu {brandName} {modelName} var. Mevcut vites kutusu tipleri {gearboxTypes}.",
        "name": "Yakıt türü"
      },
      "car_model_equipment": {
        "title": "MarkaAdı} {modelAdı} ürününüz için ekipman seviyesini seçin",
        "description": "Budget, Family, Comfort veya Business arasından seçim yapın. En popüler olan Comfort, park sensörleri, Bluetooth ve daha fazlasını sunar.",
        "name": "Ekipman"
      },
      "favorites": {
        "title": "Favorileriniz, daha sonrası için kaydedildi",
        "description": "En iyi ürünlerimizden bazılarını daha sonrası için saklamış olmanız harika. Şimdi istediğinizi seçmeyi deneyin.",
        "name": "Favoriler"
      },
      "cart": {
        "title": "Alışveriş sepeti",
        "description": "Alışveriş sepeti",
        "name": "Alışveriş sepeti"
      },
      "checkout": {
        "title": "Vezne",
        "description": "Vezne",
        "name": "Vezne"
      },
      "clients": {
        "title": "Müşteriler",
        "description": "Müşteriler",
        "name": "Müşteriler"
      },
      "client_details": {
        "title": "Şirket",
        "description": "Şirket",
        "name": "Şirket"
      },
      "leads": {
        "title": "Liderler",
        "description": "Liderler",
        "name": "Liderler"
      },
      "lead_details": {
        "title": "Kurşun",
        "description": "Kurşun",
        "name": "Kurşun"
      },
      "cars": {
        "title": "Araçlar",
        "description": "Araçlar",
        "name": "Araçlar"
      },
      "car_overview": {
        "title": "Araca genel bakış",
        "description": "Araca genel bakış",
        "name": "Araca genel bakış"
      },
      "car_details": {
        "title": "Araç",
        "description": "Araç",
        "name": "Araç"
      },
      "pages": {
        "title": "Sayfa",
        "description": "Sayfa",
        "name": "Sayfa"
      },
      "page_details": {
        "title": "Sayfa",
        "description": "Sayfa",
        "name": "Sayfa"
      },
      "profile": {
        "title": "Profil",
        "description": "Profil",
        "name": "Profil"
      },
      "locations": {
        "title": "Konumlar",
        "description": "Konumlar",
        "name": "Konumlar"
      },
      "company_locations": {
        "title": "Konumlar",
        "description": "Konumlar",
        "name": "Konumlar"
      },
      "data_push": {
        "title": "Veri itme",
        "description": "Veri itme",
        "name": "Veri itme"
      },
      "white_label_projects": {
        "title": "Beyaz etiket projeleri",
        "description": "Beyaz etiket projeleri",
        "name": "Beyaz etiket projeleri"
      },
      "users": {
        "title": "Kullanıcılar",
        "description": "Kullanıcılar",
        "name": "Kullanıcılar"
      },
      "user_details": {
        "title": "Kullanıcılar",
        "description": "Kullanıcılar",
        "name": "Kullanıcılar"
      },
      "user_import": {
        "title": "Kullanıcıları içe aktarma",
        "description": "Kullanıcıları içe aktarma",
        "name": "Kullanıcıları içe aktarma"
      },
      "teams": {
        "title": "Takımlar",
        "description": "Takımlar",
        "name": "Takımlar"
      },
      "questionnaires": {
        "title": "Anketler",
        "description": "Anketler",
        "name": "Anketler"
      },
      "questionnaire_details": {
        "title": "Anket",
        "description": "Anket",
        "name": "Anket"
      },
      "document_types": {
        "title": "Belge türleri",
        "description": "Belge türleri",
        "name": "Belge türleri"
      },
      "document_type_details": {
        "title": "Belge türü",
        "description": "Belge türü",
        "name": "Belge türü"
      },
      "templates": {
        "title": "Şablonlar",
        "description": "Şablonlar",
        "name": "Şablonlar"
      },
      "cms_elements": {
        "title": "CMS öğeleri",
        "description": "CMS öğeleri",
        "name": "CMS öğeleri"
      },
      "cms_element_details": {
        "title": "CMS öğesi",
        "description": "CMS öğesi",
        "name": "CMS öğesi"
      },
      "brands": {
        "title": "Markalar",
        "description": "Markalar",
        "name": "Markalar"
      },
      "models": {
        "title": "Modeller",
        "description": "Modeller",
        "name": "Modeller"
      },
      "categories": {
        "title": "Kategoriler",
        "description": "Kategoriler",
        "name": "Kategoriler"
      },
      "category_details": {
        "title": "Kategori",
        "description": "Kategori",
        "name": "Kategori",
        "authorization_on_mobile_device": "Mobil cihazlarda yetkilendirme",
        "hide_category": "Kategori gizle"
      },
      "attributes": {
        "title": "Nitelikler",
        "description": "Nitelikler",
        "name": "Nitelikler"
      },
      "attribute_details": {
        "title": "Öznitelik",
        "description": "Öznitelik",
        "name": "Öznitelik"
      },
      "attribute_groups": {
        "title": "Öznitelik grupları",
        "description": "Öznitelik grupları",
        "name": "Öznitelik grupları"
      },
      "dynamic_data_definitions": {
        "title": "Dinamik veri tanımları",
        "description": "Dinamik veri tanımları",
        "name": "Dinamik veri tanımları"
      },
      "pipelines": {
        "title": "Boru Hatları",
        "description": "Boru Hatları",
        "name": "Boru Hatları"
      },
      "pipeline_details": {
        "title": "Boru Hattı",
        "description": "Boru Hattı",
        "name": "Boru Hattı"
      },
      "items": {
        "title": "Ürünler",
        "description": "Ürünler",
        "name": "Ürünler"
      },
      "item_details": {
        "title": "Ürün",
        "description": "Ürün",
        "name": "Ürün"
      },
      "calendar": {
        "title": "Takvim",
        "description": "Takvim",
        "name": "Takvim"
      },
      "orders": {
        "title": "Siparişler",
        "description": "Siparişler",
        "name": "Siparişler"
      },
      "settings": {
        "title": "Ayarlar",
        "description": "Ayarlar",
        "name": "Ayarlar"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Hesap",
        "description": "Hesap",
        "name": "Hesap"
      },
      "forgot_password": {
        "linktext": "Şifrenizi mi unuttunuz?",
        "title": "Şifremi unuttum",
        "description": "Şifremi unuttum",
        "name": "Şifremi unuttum"
      },
      "renew_password": {
        "title": "Şifre yenileme",
        "description": "Şifre yenileme",
        "name": "Şifre yenileme"
      },
      "new_password_after_reset": {
        "title": "Sıfırlamadan sonra yeni şifre",
        "description": "Sıfırlamadan sonra yeni şifre",
        "name": "Sıfırlamadan sonra yeni şifre"
      },
      "pricing": {
        "title": "Fiyatlar",
        "description": "Fiyatlar",
        "name": "Fiyatlar"
      },
      "signedup": {
        "title": "Kayıtlı",
        "description": "Kayıtlı",
        "name": "Kayıtlı"
      },
      "go_pro": {
        "title": "Go Pro",
        "description": "Go Pro",
        "name": "Go Pro"
      },
      "paypal": {
        "title": "Paypal ile ödeme",
        "description": "Paypal ile ödeme",
        "name": "Paypal ile ödeme"
      },
      "lead_catchers": {
        "title": "Lider yakalayıcılar",
        "description": "Lider yakalayıcılar",
        "name": "Lider yakalayıcılar"
      },
      "bills": {
        "title": "Faturalar",
        "description": "Faturalar",
        "name": "Faturalar"
      },
      "provisionServiceDocuments": {
        "title": "Komisyonlar"
      },
      "commissionServiceDocuments": {
        "title": "Komisyon belgeleri"
      },
      "integrations": {
        "title": "Entegrasyonlar",
        "description": "Entegrasyonlar",
        "name": "Entegrasyonlar"
      },
      "order_complete": {
        "title": "Sipariş tamamlandı. Çok teşekkür ederim",
        "description": "Sipariş tamamlandı. Çok teşekkür ederim",
        "name": "Sipariş tamamlandı. Çok teşekkür ederim"
      },
      "order_details": {
        "title": "Sipariş detayları",
        "description": "Sipariş detayları",
        "name": "Sipariş detayları"
      },
      "my_company": {
        "title": "Benim şirketim",
        "description": "Benim şirketim",
        "name": "Benim şirketim"
      },
      "white_label_plugins": {
        "title": "Beyaz etiket eklentileri",
        "description": "Beyaz etiket eklentileri",
        "name": "Beyaz etiket eklentileri"
      },
      "plugins": {
        "title": "Creacheck Eklentileri",
        "description": "Creacheck Eklentileri",
        "name": "Creacheck Eklentileri"
      },
      "bill_details": {
        "title": "Fatura detayları",
        "description": "Fatura detayları",
        "name": "Fatura detayları"
      },
      "activate_plugin": {
        "title": "Eklentiyi etkinleştirin",
        "description": "Eklentiyi etkinleştirin",
        "name": "Eklentiyi etkinleştirin"
      },
      "model_groups": {
        "title": "Model grupları",
        "description": "Model grupları",
        "name": "Model grupları"
      },
      "model_group_details": {
        "title": "Model grubu ayrıntıları",
        "description": "Model grubu ayrıntıları",
        "name": "Model grubu ayrıntıları"
      },
      "model_assets": {
        "title": "Model görüntüleri",
        "description": "Model görüntüleri",
        "name": "Model görüntüleri"
      },
      "model_asset_details": {
        "title": "Model Resim detayları",
        "description": "Model Resim detayları",
        "name": "Model Resim detayları"
      },
      "lead_per_mail_rules": {
        "title": "E-posta kurallarına göre liderlik edin",
        "description": "E-posta kurallarına göre liderlik edin",
        "name": "E-posta kurallarına göre liderlik edin"
      },
      "car_rental": {
        "title": "Şehir} içinde güvenli ve hızlı rezervasyon. Aralarından seçim yapabileceğiniz {sayı} araba",
        "description": "Aracınızı hızlı ve güvenli bir şekilde ayırtın. İnanın bize, araç kiralamak hiç bu kadar hızlı ve kolay olmamıştı.",
        "name": "İzin Verme"
      },
      "failed_refunds": {
        "title": "Başarısız geri ödemeler",
        "description": "Başarısız geri ödemeler",
        "name": "Başarısız geri ödemeler"
      },
      "companySignup": {
        "title": "Şirketler için kayıt",
        "description": "İşletmenizi kaydedin ve otomobil bayileri için en son ekosistemden yararlanın",
        "name": "Şirketler için kayıt"
      },
      "price_negotiations": {
        "title": "Fiyat görüşmeleri",
        "description": "Fiyat görüşmeleri",
        "name": "Fiyat görüşmeleri"
      },
      "price_negotiation_details": {
        "title": "Fiyat pazarlığı",
        "description": "Fiyat pazarlığı",
        "name": "Fiyat pazarlığı"
      },
      "communities": {
        "title": "Topluluklar listesi",
        "description": "Topluluklar listesi",
        "name": "Topluluklar listesi"
      },
      "car_service_locations": {
        "title": "Servis randevularının mevcut olduğu yerler",
        "description": "Konumlar",
        "name": "Konumlar"
      },
      "car_service_resources": {
        "title": "Bir hizmet danışmanı seçin",
        "description": "Hizmet danışmanı",
        "name": "Hizmet danışmanı"
      },
      "car_service_free_slots": {
        "title": "Aracınızı park etmek için boş bir zaman dilimi seçin",
        "description": "Zaman penceresi",
        "name": "Zaman penceresi"
      },
      "car_service_client_data": {
        "title": "Randevunuzu almak için giriş yapın veya kaydolun",
        "description": "Giriş",
        "name": "Giriş"
      },
      "car_service_car_data": {
        "title": "Servis rezervasyonu için araç bilgilerini girin",
        "description": "Araç verileri",
        "name": "Araç verileri"
      },
      "car_service_summary": {
        "title": "Son adım - Verilerinizi ve seçiminizi kontrol edin",
        "description": "Kontrol et",
        "name": "Kontrol et"
      },
      "car_services": {
        "title": "Atölye hizmetleri",
        "description": "HİZMETLER",
        "name": "HİZMETLER"
      },
      "car_service_stations": {
        "title": "Atölye iş istasyonları",
        "description": "İş İstasyonları",
        "name": "İş İstasyonları"
      },
      "document_templates": {
        "title": "Belge şablonları",
        "description": "Belge şablonları",
        "name": "Belge şablonları"
      },
      "document_template_details": {
        "title": "Belge şablonu",
        "description": "Belge şablonu",
        "name": "Belge şablonu"
      }
    },
    "sort": {
      "relevance": "Alaka düzeyi",
      "username": "e-posta",
      "lastName": "Soyadı",
      "cdate": "Önce en yeni",
      "cdateDesc": "Önce en yeni",
      "dueDate": "Son tarih",
      "fav": "En popüler",
      "priceAsc": "En düşük fiyat",
      "rentPriceAsc": "En düşük fiyat",
      "priceDesc": "En yüksek fiyat",
      "rentPriceDesc": "En yüksek fiyat",
      "priority": "Öncelik",
      "mdate": "Son güncelleme",
      "countryName": "Ülke",
      "vat": "KDV",
      "sumGross": "Miktar"
    },
    "jobTypes": {
      "wedding": "Düğün",
      "family_portrait": "Aile portresi",
      "maternity": "Annelik",
      "newborn": "Yeni doğan bebek",
      "couple": "Çift",
      "pet": "Evcil hayvan",
      "commercial_advertising": "Ticari / Reklam",
      "fashion": "Moda",
      "model_portfolio": "Model portföyü",
      "event": "Etkinlik",
      "headshots": "Vesikalıklar",
      "real_estate_and_architecture": "Gayrimenkul ve mimarlık",
      "other": "Diğer"
    },
    "pipelineEvent": {
      "afterLeadCreated": "kurşuna göre oluşturuldu",
      "afterJobAccepted": "işten sonra kabul edildi",
      "beforeJobScheduled": "planlanan işten önce",
      "afterJobScheduled": "planlanan işten sonra",
      "afterGalleryCreated": "galeriye göre oluşturuldu",
      "afterPreviousStage": "önceki aşamadan sonra"
    },
    "paymentEvents": {
      "beforeJobDate": "iş tarihinden önce",
      "onJobDate": "iş tarihinde",
      "afterJobDate": "iş tarihine göre",
      "afterInvoiceDate": "fatura tarihinden sonra"
    },
    "autocomplete": {
      "placeholder": "Seçiniz...",
      "noResultsText": "Sonuç bulunamadı",
      "loadingPlaceholder": "Yükleniyor"
    },
    "salesDoc": {
      "debtor_number": "Müşteri numarası",
      "order_number": "Sipariş numarası",
      "order_totals": "Sipariş toplamları",
      "quote_from": "Kimden",
      "quote": "Teklif",
      "quote_for": "Teklif için",
      "quotes": "Teklifler",
      "new_quote": "Yeni teklif",
      "invoice_from": "Kimden",
      "invoice": "Fatura",
      "invoice_date": "Fatura tarihi",
      "tax_invoice": "Fatura",
      "order": "Sipariş",
      "credit_note": "Kredi notu",
      "credit_notes": "Kredi notları",
      "invoice_for": "Fatura için",
      "invoices": "Faturalar",
      "new_invoice": "Yeni fatura",
      "cancellation_invoice_to": "İptal faturası için",
      "cancellation_invoice": "İptal faturası",
      "to_refunded": "Hala geri ödeme yapılacak:",
      "positions": "Pozisyonlar",
      "issued": "Yayınlandı",
      "cancelled": "STORNO",
      "billing_period": "Fatura dönemi",
      "billing_period_not_valid": "Geçersiz fatura dönemi",
      "pro_rate": "orantılı",
      "name": "İsim",
      "description": "Açıklama",
      "quantity": "Miktar",
      "unit_price": "Birim fiyat",
      "inc_tax": "Vergiler dahil",
      "exc_tax": "Vergiler olmadan",
      "subtotal": "Ara Toplam",
      "total": "Toplam tutar",
      "amount_due": "Toplam net",
      "total_due": "Fatura tutarı",
      "target": "Müşteri",
      "due_date": "Son ödeme tarihi",
      "to_pay": "Hala ödenecek:",
      "payed": "Ücretli",
      "payment": "Ödeme",
      "download_csv_ready": "CSV dışa aktarımı tamamlandı",
      "no_vat_eu_country": "UstG § 4 No. 1b uyarınca vergiden muaf Topluluk içi teslimatlar.",
      "no_vat_non_eu_country": "§ 6 UstG uyarınca vergisiz ihracat teslimatı",
      "thanks_for_order": "Siparişiniz için teşekkür ederiz. Aksi belirtilmedikçe, yukarıdaki hizmetler bu fatura tarihinde gerçekleştirilecektir.",
      "bill_by_date": "Lütfen fatura numarasını belirterek tutarı %tarih% 'e kadar aşağıdaki hesaba aktarın:",
      "intended_use": "Kullanım amacı",
      "paid_with_cash_cc": "Fatura nakit olarak / EC kartı ile ödenmiştir",
      "amount_from_credit_card": "Tutar% miktarı kredi kartınızdan çekilecektir.",
      "amount_from_paypal": "Tutar% miktarı PayPal hesabınızdan çekilecektir.",
      "debit_from_account": "Tutar% miktarı hesabınızdan çekilecektir.",
      "register_and_taxes": "Ticaret sicili ve vergiler",
      "managing_director": "Genel Müdür",
      "product": "Ürün"
    },
    "provisionDoc": {
      "provision_calculation": "Komisyon hesaplama",
      "client": "Müşteri",
      "invoice_no_date": "Fatura no / tarih",
      "payedDate": "ödenen",
      "serviceType": "Hizmet Türü",
      "amount": "Miktar",
      "provisionRate": "Komisyon oranı",
      "provisionValue": "Komisyon",
      "totalAmount": "Toplam tutar",
      "provision": "Komisyon",
      "provisions": "Komisyonlar",
      "provision_for": "için Komisyon",
      "download_pdf": "İndir PDF"
    },
    "paymentStatus": {
      "late": "Geç",
      "paid": "Ücretli",
      "unpaid": "Ödenmemiş",
      "inProgress": "Devam ediyor"
    },
    "shopItem": {
      "plus_shipping": "artı nakliye masrafları",
      "variants_possible": "Çeşitli seçim seçenekleri mevcuttur"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "SEPA otomatik ödeme talimatı (otomatik ödeme yetkilendirmesi)",
      "mandate_reference": "Yetki referansı",
      "will_be_communicated_separately": "ayrıca bildirilecektir",
      "payee": "Alacaklı",
      "creditor": "Alacaklı",
      "payer": "Ödeyen",
      "i_allow_payee_direct_debit": "İşbu belge ile alacaklıya, bir sonraki bildirime kadar hesabımdan otomatik ödeme yoluyla ödeme alma yetkisi veriyorum. Aynı zamanda, bankama alacaklı tarafından hesabımdan çekilen otomatik ödemeleri onurlandırması talimatını veriyorum.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Not: Borçlandırma tarihinden itibaren sekiz hafta içinde borçlandırılan tutarın iadesini talep edebilirim. Bankam ile mutabık kalınan koşullar geçerlidir.",
      "document_issued_electronically_and_valid_without_signature": "Bu mektup otomatik olarak oluşturulmuştur ve imzasız olarak da geçerlidir",
      "shortening_notice_period": "İhbar süresinin kısaltılması",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "SEPA otomatik ödemesinin önceden bildirilmesi için son tarih bir güne indirilmiştir.",
      "i_issue_sepa_direct_debit_mandate": "İşbu belge ile SEPA otomatik ödeme talimatı veriyorum"
    },
    "sad": {
      "offer": "Teklif",
      "modification": "Değişiklik",
      "order": "Sipariş",
      "offers": "Teklifler",
      "orders": "Siparişler",
      "offer_short": "Ang.",
      "order_short": "En iyisi.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Dönüşüm oranı",
      "last_offer_date": "Son teklif",
      "last_offer_date_short": "Son Ang.",
      "car_data": "Araç verileri",
      "class": "Sınıf",
      "model_name": "Model adı",
      "model_name_short": "Model adı (kısa)",
      "model_range": "Seri",
      "body_design": "Vücut şekli",
      "car_color": "Renk",
      "base_price": "Baz fiyat",
      "list_price": "Liste fiyatı",
      "optional_equipment": "Özel ekipman",
      "incl": "dahil.",
      "factory_discount": "Fabrika arazisi",
      "cash_discount": "Nakit indirimi",
      "volume_discount": "Miktar indirimi",
      "transportation_costs": "Transfer maliyetleri",
      "price_reduction": "Fiyat indirimi",
      "car_image": "Konfigüratörden araç görüntüsü",
      "discount": "Emlak",
      "sales_price": "Satış fiyatı",
      "max_speed": "Maksimum hız",
      "car_acceleration": "Hızlanma (0 - 100)",
      "online_code": "Çevrimiçi kod",
      "sales_activity": "Satış faaliyeti",
      "last_modified": "Son değişiklik",
      "offer_date": "Teklif tarihi",
      "order_date": "Sipariş tarihi",
      "order_location": "Sipariş konumu",
      "pre_contract_nr": "V-Sipariş no.",
      "pre_contract_date": "V sipariş verisi.",
      "contract_nr": "Sipariş no.",
      "shipment_place": "Teslimat yeri",
      "shipment_key": "Teslimat anahtarı",
      "special_agreement": "Özel anlaşma",
      "contact_person": "İletişim kurulacak kişi",
      "customer_account_number": "Borçlu hesap no.",
      "financing": "Finansman",
      "leasing": "Leasing",
      "financing_product": "Ürün",
      "paid_deposit": "Peşinat",
      "paid_deposit_leasing": "Özel leasing ödemesi",
      "credit_amount": "Toplam kredi tutarı",
      "monthly_rate": "Aylık taksit",
      "contract_duration": "Çalışma Zamanı",
      "total_mileage": "Kilometre",
      "purchase_price": "Satın alma fiyatı",
      "annual_percentage_rate": "Efektif yıllık faiz oranı",
      "nominal_interest_percentage_rate": "Borç faizi sabit p.a.",
      "debit_interest_percentage_rate": "Nominal faiz p.a.",
      "total_amount": "Toplam tutar",
      "final_instalment": "Son taksit",
      "application_date": "Başvuru tarihi",
      "application_approval_date": "Yetkilendirme tarihi",
      "application_rejection_date": "Ret tarihi",
      "client_private": "Özel",
      "client_business": "Ticari",
      "monthly_leasing_rate": "Aylık kira taksiti",
      "monthly_financing_rate": "Aylık finansman taksiti",
      "monthly_insurance_rate": "Aylık motorlu taşıt sigorta primi",
      "no_winleas_data_available": "WinLEAS verisi mevcut değil",
      "winleas_data_available": "Finansman (F) ve/veya leasing (L) verileri WinLEAS'ten temin edilebilir",
      "discount_cash": "Nakit ambulans",
      "discount_volume": "Miktar/geri dönüşümcü indirimi",
      "discount_environmental": "Çevresel bonus",
      "discount_additional": "Ek koşullar",
      "deduction": "İndirim",
      "deduction_mbl": "MBL indirimi",
      "deduction_mbl_interest": "Faiz indirimi MBL",
      "deduction_consultant": "İndirim danışmanı",
      "deduction_consultant_interest": "Faiz oranı iskonto danışmanı",
      "commission": "Komisyon",
      "commission_standard": "Standart",
      "commission_addition_deduction": "Yukarı işaretle / aşağı işaretle",
      "commission_subsequent_scope_of_supply": "Sonraki. Teslimat kapsamı",
      "commission_total": "Toplam",
      "subsidy": "Sübvansiyon",
      "subsidy_contract_partner": "Sübvansiyon Başkan Yardımcısı",
      "subsidy_factory": "Sübvansiyon tesisi",
      "subsidy_mbvd": "Sübvansiyon MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Eylem",
      "service_rate": "Hizmet oranı",
      "residual_value": "Artık değer",
      "table_residual_value": "Artık tablo değeri",
      "residual_value_change": "Kalıntı değerdeki değişim",
      "ref": "ilgili",
      "net": "net",
      "gross": "brüt",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Not kaydet",
      "notes": "Notlar",
      "salesman": "Satıcı",
      "salesman_blacklist": "Satıcı (Kara Liste)",
      "salesman_number": "Satıcı no.",
      "salesman_number_short": "Hayır.",
      "enterprise_number": "Şirket no.",
      "Mercedes": "Mercdes",
      "Smart": "Akıllı",
      "equipment_line": "Hat",
      "packages": "Paketler",
      "upholstery": "Döşeme",
      "single_equipments": "Diğer",
      "included_in": "içinde bulunan",
      "store": "Şube",
      "save_filterset": "Filtre setini kaydet",
      "internal_activity": "Dahili iş",
      "offer_made": "Teklif oluşturuldu",
      "in_progress": "Devam ediyor",
      "predecessor_offer": "Önceki teklif / kopya",
      "lost": "İş kaybı",
      "ordered": "Sipariş tamamlandı",
      "canceled": "Sipariş iptal edildi"
    },
    "rad": {
      "earnings": "Verim",
      "occupancy_rate": "Kullanım",
      "amount": "Miktar"
    },
    "external_account_status": {
      "login_fail": "Lütfen erişim verilerini kontrol edin! Aksi takdirde daha fazla veri okunamaz.",
      "login_warning": "Lütfen erişim verilerini kontrol edin!",
      "login_valid": "Verilere doğru erişin",
      "last_successful_login": "Son başarılı giriş"
    },
    "categoryitems_stock": "Parça",
    "initialize": "Başlangıç",
    "customer": "Şirket",
    "customer_": "Müşteri",
    "select_mandant": "Müşteri seçin",
    "initial_title": "Reklam mecrasının ilk başlığı",
    "will_be_shown_to_the_user_in_frontend": "Ön uçta kullanıcılara gösterilir",
    "producttemplate_data_saved": "Şablon verileri kaydedildi",
    "product_size": "Ürün formatı / boyutu",
    "template_upload_files": "Yüklenecek dosya/dosyaları seçin",
    "example_of_product_size": "örn: DIN A4, DIN uzun...",
    "output_format": "Çıktı biçimi",
    "product_size_width": "Piksel cinsinden genişlik",
    "product_size_height": "Piksel cinsinden yükseklik",
    "template_folder": "Şablon yolu",
    "template_dpi": "Çıktı için DPI",
    "template_upload": "Yükle",
    "template_pageSpace": "Izgara alanları",
    "output_exportType": "Çıkış tipi",
    "template_fonts": "Yazı Tipleri",
    "template_fontSizeMin": "Yazı tipi boyutu min",
    "template_styles": "CSS ve LESS verileri",
    "template_fontSizeMax": "Maksimum yazı tipi boyutu",
    "template_init_amount_of_pages": "İlk sayfa sayısı",
    "template_page_add_increase": "Sadakatsizlik",
    "template_pageMaxAmount": "Maksimum sayfa sayısı",
    "template_pageMinAmount": "Sayfa sayısı min",
    "template_pageTop": "Üst dolgu",
    "template_pageBottom": "Alt dolgu",
    "template_pageFirst": "Sayfa numarası Başlık sayfası",
    "template_pageFirstFixed": "Ön sayfa düzeltildi",
    "template_pageLast": "Sayfa numarası Son sayfa",
    "template_pageLastFixed": "Son sayfa düzeltildi",
    "template_endless": "Sonsuz sayfa",
    "template_fixed": "Sayfa düzeltildi",
    "template_sample_page": "Sayfa sayısı Standart içerik",
    "font_colors": "Metin renkleri",
    "back_colors": "Arka plan renkleri",
    "client_logo": "Şirket logosu",
    "template_pageNumber": "Sayfa sayısı",
    "page_names": "Sayfa ayarları",
    "add_contentBlock": "İçerik bloğu ekleme",
    "css": "Genel CSS",
    "cssPDF": "Yalnızca PDF çıktısı için CSS",
    "cssPrintPDF": "Yalnızca yazdırılabilir PDF çıktısı için CSS",
    "cssWebsite": "Web sayfası olarak çıktı için CSS",
    "chosenItem": "Güncel ürün seçimi",
    "pdf_price": "PDF fiyatı",
    "auflagen": "Koşullar",
    "print_prices": "Baz fiyatlar",
    "proof_base_price": "Baz fiyat veri kontrolü",
    "new_proof_base_price": "Yeni baz fiyat veri kontrolü",
    "get_vendor_price": "Çağrı fiyatları",
    "add_variant_line": "Satır ekle",
    "remove_all_item_variant": "Tüm baskıları sil",
    "get_vendor_prices": "Fiyat çağrısı tedarikçisi",
    "get_vendor_id": "Tedarikçi ürün kimliğini sorgula",
    "no_vendor_id": "Tedarikçi ürün kimliği seti yok!",
    "cc_item_id": "Ürün Kimliği CreaCheck",
    "vendor_item_id": "Ürün Kimliği Tedarikçi",
    "vendor_item_name": "Ürün adı Tedarikçi",
    "vendor_item_missing_attributes": "Bu özellik kombinasyonu için herhangi bir fiyat bulunamamıştır.",
    "vendor_item_save_attributes": "Lütfen nitelikleri seçin!",
    "vendor_item_must_define_attributes": "Varyantlar oluşturmak için öznitelikleri tanımlamanız gerekir.",
    "vendor_item_has_no_attributes": "Bu ürün grubunun hiçbir özelliği yoktur",
    "vendor_item_all_n_quantities": "Tüm {quantityCount} miktarlarını gösterir",
    "vendor": "Tedarikçi",
    "internal_item_id": "Dahili Kimlik",
    "select_vendor_product": "Ürün grubunu seçin",
    "item_type": "Ürün tipi",
    "print_item": "Basılı ürün",
    "website_item": "Web sitesi",
    "normal_item": "Diğer ürün",
    "not_more_pages": "Olası sayfa sayısından daha fazla sayfa adı oluşturamazsınız!",
    "info_title_page": "Başlık sayfası taşınamaz veya silinemez",
    "info_last_page": "Son sayfa taşınamaz veya silinemez",
    "page_definition_page_count_info": "Sayfa numarası 0 = tanımsız tüm sayfalar için varsayılan değer, son sayfa için son",
    "padding_on_top": "Üst aralık",
    "padding_on_bottom": "Aşağıdaki mesafeler",
    "padding_on_left": "Sol boşluk",
    "padding_on_right": "Sağ boşluk",
    "delimitation_of_grid_blocks": "Izgara blokları sınırsız olarak eklenebilir. Böylece sayfa uzadıkça uzar.",
    "fixed_page": "Sayfa sabittir ve taşınamaz.",
    "page_definition": "Sayfa tanımı",
    "add_definition": "Tanım ekle",
    "page_default_value": "Sayfa numarası 0 = tanımsız tüm sayfalar için varsayılan değer",
    "page_count": "Sayfa sayısı",
    "page_widths": "Yan genişlikler",
    "page_heights": "Yan yükseklikler",
    "no_special_formatting": "Özel biçimlendirme yok",
    "min_max_font_size": "Min-/Maks-FontSize",
    "activate_min_max_font_size": "Min-/Max-FontSize'ı etkinleştirme veya devre dışı bırakma",
    "filename": "Dosya adı",
    "for_user": "Kullanıcılar için",
    "user_can_use_font": "Kullanıcı bu yazı tipini editörde kullanabilir",
    "import_successful": "İçe aktarma tamamlandı, diğer sekmelere bakın",
    "import_resets_template": "İçe aktarma şablonu sıfırlar, yeniden başlatılması gerekir, bu şablona sahip mevcut tüm müşteri sürümleri kullanılamaz hale gelir!!!!",
    "problem": "Bir sorun meydana geldi",
    "reading_pages": "Okuma sayfaları",
    "done": "Bitti",
    "versions": "Versiyonlar",
    "template_not_edited": "Bu şablon henüz düzenlenmemiştir.",
    "dataProof_infoText": "Manuel veri kontrolünde uzmanlarımız baskı öncesi aşamasının tüm teknik kriterlerini sizin için kontrol eder. Bunlar arasında basılabilirlik, tüm teknik özelliklere uygunluk ve öznel renk etkisi yer alır. Veri kontrolü hakkında daha ayrıntılı bilgiyi SSS bölümünde bulabilirsiniz.",
    "media_library": "Medya kütüphanesi",
    "count": "Miktar",
    "welcome_message": "Merhaba, {isim}",
    "email": "e-posta",
    "website": "Web sitesi",
    "phone": "Telefon",
    "fax": "faks",
    "password": "şifre",
    "repeat_password": "Şifreyi tekrarla",
    "company": "Şirket",
    "company_name": "Şirket adı",
    "company_data_timeline_tab_title": "Şirket verilerinin zaman çizelgesi",
    "company_data_success": "Şirket verileri başarıyla değiştirildi!",
    "company_data_error": "Şirket verileri değiştirilirken hata oluştu!",
    "updated_by": "Tarafından güncellendi",
    "companies": "Şirketler",
    "first_name": "İlk isim",
    "last_name": "Soyadı",
    "street": "Sokak",
    "house_number": "Ev numarası",
    "apartment_number": "Daire numarası",
    "address_line2": "Adres satırı 2",
    "zip_code": "Posta kodu",
    "city": "Şehir",
    "country": "Ülke",
    "currency": "Para Birimi",
    "previous_year": "Önceki yıl",
    "vat_name": "KDV Adı",
    "vat_id_name": "KDV Kimlik Adı",
    "vat_id": "KDV - IdNr",
    "vat_rates": "KDV oranları",
    "coc_number": "COC numarası",
    "registered_at": "Şu adreste kayıtlı",
    "page": "Sayfa",
    "page_of": "itibaren",
    "save": "Kaydet",
    "save_and_email": "Kaydet ve e-posta ile gönder",
    "add": "Ekle",
    "add_client": "Şirket ekle",
    "update_company_data": "Şirket adını değiştirin",
    "add_user": "Kullanıcı ekle",
    "add_product": "Ürün ekle",
    "add_producttemplate": "Şablon ekle",
    "add_question": "Soru ekle",
    "add_option": "Seçenek ekle",
    "add_questionnaire": "Anket ekleyin",
    "add_template": "Şablon ekle",
    "add_position": "Pozisyon ekle",
    "add_pipeline": "Boru hattı ekleyin",
    "add_quote": "Teklif ekle",
    "add_invoice": "Fatura ekle",
    "add_stage": "Seviye ekle",
    "add_gallery": "Galeri ekle",
    "add_job": "İş ekle",
    "add_payment_schedule": "Ödeme planı ekle",
    "add_payment": "Ödeme ekle",
    "add_location": "Konum ekle",
    "add_data_definition": "Veri tanımı ekleme",
    "add_car": "Araç ekle",
    "add_document": "Belge ekleyin",
    "add_document_type": "Belge türü ekle",
    "add_team": "Ekip ekle",
    "add_brand": "Marka ekle",
    "add_model": "Model ekle",
    "add_model_group": "Model grubu ekle",
    "add_model_asset": "Model resmi ekleme",
    "add_category": "Kategori ekle",
    "add_attribute": "Mülk ekle",
    "add_attribute_group": "Öznitelik grubu ekleme",
    "add_value": "Değer katın",
    "add_variant": "Varyant ekle",
    "add_lead": "Kurşun ekleyin",
    "add_task": "Görev ekle",
    "add_page": "Sayfa ekle",
    "add_data_push": "Veri itme ekleyin",
    "add_white_label": "Beyaz etiket projesi ekleyin",
    "add_menu_element": "Menü öğesi ekle",
    "add_secondary_menu_element": "İkincil menü öğesi ekleme",
    "add_cms_element": "CMS öğesi ekleyin",
    "add_lead_catcher": "Lider yakalayıcı ekle",
    "add_white_label_plugin": "Beyaz etiket eklentisi ekleyin",
    "add_lead_per_mail_rule": "E-posta kuralı ile müşteri adayı ekleme",
    "add_message": "Bir mesaj ekleyin",
    "add_car_service": "Hizmet ekle",
    "add_car_service_station": "İş istasyonu ekle",
    "add_team_member": "Ekip üyesi ekle",
    "add_document_template": "Belge şablonu ekleme",
    "add_lead_autoresponse": "Müşteri adayı otomatik yanıtı ekleyin",
    "add_main_menu_version": "Ana menü sürümü ekle",
    "add_lead_tagger": "Lider etiketleyici ekle",
    "add_lead_assignment_strategy": "Atama stratejisi ekleyin",
    "add_escalation_rule": "Yükseltme kuralı ekleyin",
    "add_coupon": "Kupon ekle",
    "add_company": "Şirket ekle",
    "add_item": "Ürün ekle",
    "add_manual_entry": "Manuel giriş ekleyin",
    "add_allowed_domain": "Yetkili alan adı ekle",
    "update_allowed_domain": "Yetkili alan adını güncelle",
    "send_message": "Mesaj gönder",
    "send": "Gönder",
    "details": "DETAYLAR",
    "delete": "Silme",
    "undelete": "Geri Yükleme",
    "undelete_user_confirm": "Kullanıcıyı geri yüklemek mi?",
    "or": "veya",
    "actions": "Eylemler",
    "client": "Şirket",
    "job": "İş",
    "active_jobs": "Aktif işler",
    "questionnaire": "Anket",
    "review_and_send": "Kontrol edin ve gönderin",
    "download": "İndir",
    "duplicate": "Yinelenen",
    "client_name": "İsim",
    "address": "Adres",
    "title": "Başlık",
    "uploading": "Yükle",
    "status": "Durum",
    "next_stage": "Sonraki seviye",
    "type": "Tip",
    "schedule": "Zaman Çizelgesi",
    "location": "Konum",
    "sent_on": "Gönderildi",
    "name": "İsim",
    "description": "Açıklama",
    "bill_number": "Fatura numarası",
    "quote_number": "Teklif numarası",
    "do_sort": "Sırala",
    "date": "Tarih",
    "gallery": "Galeri",
    "questionnaires": "Anketler",
    "job_takes_whole_day": "Bu iş bütün günümü alıyor.",
    "from": "Kimden",
    "to": "için",
    "question": "Soru",
    "subject": "Konu",
    "content": "İçindekiler",
    "template": "Şablon",
    "stage_has_due_date": "Bu aşamanın bir bitiş tarihi vardır",
    "stage_sends_automated_email": "Bu aşama otomatik bir e-posta gönderir",
    "day": "Gün",
    "days": "Günler",
    "hours": "Saatler",
    "review": "Kontrol et",
    "timezone": "Saat dilimi",
    "client_since": "Şirket eklendi",
    "user_since": "Kullanıcı beri",
    "payment_instructions": "Ödeme talimatları",
    "bank_name": "Banka adı",
    "debtor_number": "Borçlu Kimliği",
    "account_holder": "Hesap sahibi",
    "account_number": "Hesap numarası",
    "bank_location": "Banka konumu",
    "bank_code_number": "Banka Sıralama Kodu",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Şirket logosu",
    "percent_of_order": "Siparişin yüzdesi",
    "percent_of_remaining_balance": "Kalan tutarın yüzde biri",
    "amount": "Miktar",
    "due": "Neden",
    "payment_schedule": "Ödeme planı",
    "record_payment": "Günlük ödeme",
    "mark_as_unpaid": "Ödenmemiş olarak işaretle",
    "close": "Kapat",
    "yes": "Evet",
    "want_to_mark_the_payment_as_paid": "Bu ödemeyi gerçekten ödendi olarak işaretlemek istiyor musunuz?",
    "want_to_mark_the_payment_as_unpaid": "Bu ödemeyi gerçekten ödenmemiş olarak işaretlemek istiyor musunuz?",
    "payment_marked_as_paid": "Ödeme kaydedildi",
    "payment_marked_as_unpaid": "Ödenmedi olarak işaretlenen ödeme",
    "overdue_payments": "Gecikmiş ödemeler",
    "next_payment": "Sonraki ödeme",
    "quote_accepted": "Teklif kabul edildi",
    "quote_rejected": "Teklif reddedildi",
    "todays_tasks": "Bugünün görevleri",
    "overdue_tasks": "Gecikmiş görevler",
    "overdue_and_upcoming_payments": "Gecikmiş ve yaklaşan ödemeler",
    "jobs_in_progress": "Devam eden işler",
    "appointments": "Tarihler",
    "appointment": "Tarih",
    "last_update": "Son güncelleme",
    "mark_as_completed": "Tamamlandı olarak işaretle",
    "want_to_mark_stage_complete": "Aşamayı tamamlandı olarak işaretlemek ister misiniz?",
    "stage_marked_complete": "Aşama tamamlandı olarak işaretlendi",
    "gallery_download": "Resmi indir",
    "gallery_sharing": "Galeriyi paylaş",
    "gallery_social_media": "Sosyal medyadaki görseller",
    "preview": "Önizleme",
    "invoice_preview": "Fatura önizlemesi",
    "preview_pdf": "PDF Önizleme",
    "message": "Mesaj",
    "signup": "Kayıt Olun",
    "continue": "Devam et",
    "password_assistance": "Şifre yardımı",
    "password_renewing": "Yeni şifre",
    "not_any_user_information": "Kullanıcı bilgisi yok.",
    "failed_redirection": "Yönlendirme başarısız oldu.",
    "renew_password": "Giriş yapmadan önce yeni bir şifre belirlemeniz gerekmektedir. Size az önce talimatları içeren bir e-posta gönderdik.",
    "get_new_password": "Yeni şifre iste",
    "set_new_password": "Yeni şifrenizi belirleyin",
    "forgot_password": {
      "infotext": "Şifrenizi unuttuysanız, buradan sıfırlayabilirsiniz. Lütfen aşağıdaki forma kayıtlı e-posta adresinizi girin ve \"Devam\" butonuna tıklayın. Daha sonra size daha fazla bilgi içeren bir e-posta göndereceğiz.",
      "infotext_robethood": "Şifrenizi unuttuysanız, buradan sıfırlayabilirsiniz. Lütfen aşağıdaki forma kayıtlı e-posta adresinizi girin ve \"Devam\" butonuna tıklayın. Daha sonra size daha fazla bilgi içeren bir e-posta göndereceğiz.",
      "info_email_not_used": "Hesabınızla ilişkili e-posta adresini artık kullanmıyorsanız, lütfen iletişim formumuzu kullanarak bizimle iletişime geçin: {link}.",
      "info_email_not_used_robethood": "Hesabınızla ilişkili e-posta adresini artık kullanmıyorsanız, lütfen iletişim formumuz aracılığıyla bizimle iletişime geçin: {link}.",
      "enter_new_password": "Creacheck'e giriş yapmak için önünüzde sadece bu adım var. Yeni şifrenizi iki kez girmeniz yeterli. Bu kadar basit."
    },
    "thanks_for_signing_up": "Kaydolduğunuz için teşekkür ederiz",
    "confirm_your_email": "E-posta adresinizi onaylayın",
    "signed_up_gratulations": "CreaCheck'e hoş geldiniz. Kayıt işlemini tamamlamanız için size bir e-posta gönderdik. Lütfen kaydınızı onaylayın.",
    "signed_up_gratulations_robethood": "Web sitemize hoş geldiniz. Kayıt işlemini tamamlamanız için size bir e-posta gönderdik. Lütfen kaydınızı onaylayın.",
    "check_email_for_message_from_us": "Lütfen e-posta gelen kutunuzu kontrol edin.",
    "check_email_for_message_from_us_robethood": "Lütfen e-posta gelen kutunuzu kontrol edin",
    "new_clients": "Yeni müşteriler",
    "users": "Kullanıcılar",
    "user": "Kullanıcılar",
    "won_from_count_leads": "Say} ipuçlarından kazanıldı",
    "deals_won_out_of_count": "Count} tarafından kazanılan anlaşmalar",
    "new_badge": "Yeni",
    "answer_badge": "Cevap",
    "accept": "Kabul et",
    "reject": "Reddet",
    "quote_rejected_on_date": "Teklif {tarih} tarihinde reddedildi",
    "quote_accepted_on_date": "Teklif {tarih} tarihinde kabul edildi",
    "want_to_accept_the_quote": "Teklifi kabul etmek istiyor musunuz? Kararınız doğrudan yükleniciye bildirilecektir.",
    "want_to_reject_the_quote": "Teklifi reddetmek istiyor musunuz? Kararınız doğrudan yükleniciye bildirilecektir.",
    "want_to_convert_quote_to_invoice": "Bu teklifi bir faturaya dönüştürmek ister misiniz?",
    "convert_quote_to_invoice": "Teklifi faturaya dönüştürün",
    "convert_to_invoice": "Faturaya dönüştürme",
    "convert_quote_from_template": "Teklifi başka bir şablona dönüştürme",
    "completed_on_date": "Tarih} tarihinde tamamlandı",
    "due_date_on_date": "Son tarih {tarih}",
    "due_date": "Son tarih",
    "current_stages_due_date": "Mevcut aşamanın bitiş tarihi",
    "go_pro": "Go Pro",
    "recent_activity": "Son etkinlik",
    "load_more": "Daha fazla yükle",
    "your_galleries": "Galerileriniz",
    "unread_by_you": "Senin tarafından okunmamış",
    "count_images": "{sayı} resimler",
    "view": "Görünüm",
    "filled_out_on": "Dolduruldu",
    "filled_out_by": "Tarafından dolduruldu",
    "you_answered_the_questionnaire_on_date": "Anketi {tarih} tarihinde yanıtladınız",
    "company_sent_a_questionnaire": "{şirket} bir anket gönderdi ve cevaplarını istiyor",
    "allow_to_download_full_res": "Müşterinin görüntüleri tam çözünürlükte indirmesine izin verin",
    "allow_to_share_with_friends": "Müşterinin galeriyi arkadaşlarıyla paylaşmasına izin verin",
    "allow_to_share_on_social_media": "Müşterinin görüntüleri sosyal medya aracılığıyla paylaşmasına izin verin",
    "got_it": "TAMAM.",
    "learn_more": "Daha fazla bilgi edinin",
    "this_website_uses_cookies": "Web sitemiz, web sitemizde en iyi deneyimi yaşamanızı sağlamak için çerezleri kullanır.",
    "are_you_sure": "Emin misin?",
    "no": "Hayır",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Kapalı</span> - lütfen bir zaman dilimi ekleyin</span>",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Aktif değil</span> - lütfen bir zaman dilimi ekleyin</span>",
    "note": "Not",
    "change_password": "Şifre değiştirme",
    "roles": "Roller ve haklar",
    "active_set": "Güncel abonelik",
    "infos": "Hakkında bilgi",
    "documents": "Belgeler",
    "document": "Belge",
    "financial": "Finansal",
    "bookingAccountNumber": "Hesap gönderme",
    "costAccountNumber": "Maliyet merkezi",
    "locations": "Konumlar",
    "white_label": "Beyaz etiket",
    "show_password_text": "Parola metnini göster",
    "parent": "Ebeveyn",
    "change_parent": "Ebeveyni değiştir",
    "cancel": "İptal",
    "timeline_undo": "Geri al",
    "confirm_action_ramifications": "Lütfen eyleminizin sonuçlarını anladığınızı onaylayın",
    "client_will_not_be_visible_in_search_results": "Seçilen müşteri artık müşteri aramanızda görünmüyor",
    "changing_parent_takes_a_while": "Öncülün değiştirilmesi biraz zaman alabilir",
    "load_message_from_template": "Şablondan mesaj yükle",
    "questions": "Sorular",
    "search": "Arama",
    "search_for": "Aramak için",
    "dynamic_data_definitions": "Dinamik veri tanımları",
    "dynamic_data_definition": "Dinamik veri tanımı",
    "data_identifier": "Veri tanımlayıcı",
    "cars": "Araçlar",
    "rentals": "Kiralık",
    "no_car_available": "El arabası mevcut değil",
    "file": "Dosya",
    "files": "Dosyalar",
    "teams": "Takımlar",
    "team": "Takım",
    "document_types": "Belge türleri",
    "document_type": "Belge türü",
    "system_type": "Sistem tipi",
    "brands": "Markalar",
    "models": "Modeller",
    "model": "Model",
    "model_groups": "Model grupları",
    "model_group": "Model grubu",
    "model_assets": "Model görüntüleri",
    "model_asset": "Model görüntüsü",
    "categories": "Kategoriler",
    "attributes": "Nitelikler",
    "attribute": "Öznitelik",
    "values": "Değerler",
    "value": "Değer",
    "select_category": "Kategori seçiniz",
    "position": "Pozisyon",
    "color": "Renk",
    "car": "Ürün",
    "category": "Kategori",
    "brand": "Marka",
    "required": "Zorunlu alan",
    "multilanguage": "Birden fazla dil",
    "seo": "SEO",
    "page_title": "Sayfa başlığı",
    "keywords": "Anahtar Kelimeler",
    "item": "Makale",
    "sku": "SKU",
    "variants": "Varyantlar",
    "variant": "Varyant",
    "price": "Fiyat",
    "prices": "Fiyatlar",
    "variant_attributes": "Varyant nitelikleri",
    "no_value_set": "Değer ayarı yok",
    "identification": "Tanımlama",
    "templates": "Şablonlar",
    "vin": "FIN",
    "leads": "Liderler",
    "lead": "Kurşun",
    "order_it": "Sipariş",
    "order": "Sipariş",
    "orders": "Siparişler",
    "images": "Resimler",
    "image": "Resim",
    "upc": "UPC",
    "visibility": "Görünürlük",
    "show_item_online": "Ürün çevrimiçi olarak görülebilir",
    "use_as_resource": "Kaynak olarak kullanın",
    "stock_and_prices": "Envanter ve fiyatlar",
    "sales_price": "Satış fiyatı",
    "sales_point": "Dağıtım",
    "warehouse": "Depo",
    "stock_level": "Stok",
    "rent_price": "Kira bedeli",
    "pseudo_price": "Sözde fiyat",
    "express_price": "Ekspres fiyat",
    "overnight_price": "Gecelik fiyat",
    "sameday_price": "Aynı gün fiyatı",
    "basic_price": "Baz fiyat",
    "weekday_price": "Hafta İçi Fiyat",
    "weekend_price": "Hafta sonu fiyatı",
    "week_price": "Haftalık fiyat",
    "month_price": "Aylık fiyat",
    "unit_of_measure": "Ölçüm birimi",
    "basic_unit": "Temel birim",
    "packaging_unit": "Paketleme birimi",
    "subscribe": "Abone Olun",
    "all": "Tümü",
    "pipeline": "Boru Hattı",
    "pipelines": "Boru Hatları",
    "default_team": "Standart ekip",
    "select_pipeline": "Bir boru hattı seçin",
    "please_select_pipeline_first": "Lütfen önce bir boru hattı seçin",
    "view_type": "Görünüm",
    "stage": "Seviye",
    "stages": "Adımlar",
    "properties": "Özellikler",
    "unassigned": "Tahsis olmadan",
    "assignee": "İşlemci",
    "order_type": "Sipariş türü",
    "internal_comment": "İç yorum",
    "comments": "Yorumlar",
    "comment": "Yorum",
    "no_comments_yet": "Henüz yorum yok",
    "show_all_comments": "Tüm yorumları göster",
    "people": "Kişiler",
    "watchers": "Gözlemci",
    "dates": "Tarihler",
    "date_created": "Oluşturuldu",
    "date_updated": "Güncellenmiş",
    "closed": "Kapalı",
    "start_watching_lead": "Bu ipucunu izlemeye başlayın",
    "stop_watching_lead": "Şu kurşunu izlemeyi bırak.",
    "no_longer_watching_lead": "Artık bu kurşunu gözlemlemiyorsunuz",
    "watching_lead": "Bu ipucunu gözlemliyorsunuz",
    "wrote_comment": "Bir yorum yazdı",
    "replied_to_comment": "Bir yoruma yanıt verdi",
    "language": "Dil",
    "languages": "Diller",
    "primary_language": "Ana dil",
    "secondary_languages": "İkincil diller",
    "won": "Kazandı",
    "lost": "Kayıp",
    "change_pipeline": "Boru hattını değiştirin",
    "start_pipeline": "Boru hattını başlat",
    "pipeline_started": "Boru hattı başlatıldı",
    "change_stage": "Seviyeyi değiştir",
    "switch_team": "Değişim ekibi",
    "open": "Açık",
    "budget": "Bütçe",
    "always_available": "Her zaman hazır",
    "default_location": "Standart konum",
    "selected_category_has_no_attributes": "Seçilen kategorinin hiçbir özelliği yok",
    "car_overview": "Araca genel bakış",
    "make_reservation": "Rezervasyon yaptırın",
    "reservation": "Rezervasyon",
    "cms_pages": "Sayfalar",
    "grid_fluid": "Sıvı",
    "grid_normal": "Normal",
    "cms_add_container": "Konteyner ekleyin",
    "cms_add_row": "Satır ekle",
    "cms_add_element": "Öğe ekle",
    "media_files": "Medya dosyaları",
    "upload": "Yükle",
    "page_design": "Sayfa tasarımı",
    "translations": "Çeviriler",
    "translate": "Tercüme et",
    "category_filters": "Kategori filtresi",
    "attribute_groups": "Öznitelik grupları",
    "select_attribute": "Nitelik seçin",
    "display": "Ekran",
    "select_multiple": "Çoklu seçim",
    "use_null_as_min_val": "0'ı minimum olarak ayarlayın",
    "filter": "Filtreler",
    "do_filter": "Filtre",
    "mobile_phone": "Cep telefonu",
    "mark_lead_won": "Liderliği kazanıldı olarak işaretle",
    "mark_lead_lost": "Kurşunu kayıp olarak işaretle",
    "mark_lead_deleted": "Kurşunu silindi olarak işaretle",
    "reopen_lead": "Kurşunu yeniden aç",
    "won_reason": "Kazanma nedeni",
    "lost_reason": "Kaybetme nedeni",
    "delete_reason": "Silinme nedeni",
    "reopen_reason": "Yeniden açılma nedeni",
    "no_reason_specified": "Sebep belirtilmemiş",
    "lead_marked_won": "Kurşun işaretli kazandı. Tebrikler",
    "lead_marked_lost": "Kayıp işaretli kurşun",
    "lead_marked_deleted": "Silindi olarak işaretlenen kurşun",
    "lead_reopened": "Kurşun yeniden açıldı",
    "stage_changed": "Kurşun seviyesi değişti",
    "pipeline_changed": "Potansiyel müşteri hattı değişti",
    "team_changed": "Başka bir ekibe atanan lider",
    "current_pipeline": "Mevcut boru hattı",
    "current_stage": "Mevcut seviye",
    "file_extension": "Uzatma",
    "file_size": "Boyut",
    "file_uploaded": "Yüklendi",
    "no_client_assigned": "Atanmış müşteri yok",
    "no_products_assigned": "Atanmış ürün yok",
    "no_order_assigned": "Atanmış bir sıra yok",
    "planned_duration_in_days": "Planlanan süre (gün)",
    "lead_budget_must_be_set": "Lider bütçe belirlenmelidir",
    "client_must_be_assigned": "Müşteri atanmalıdır",
    "order_must_be_assigned": "Sipariş atanmalıdır",
    "product_must_be_assigned": "Ürün atanmalıdır",
    "lead_has_to_have_following_documents": "Lider aşağıdaki belgelere sahip olmalıdır (belgeler geçerli olmalıdır)",
    "client_has_to_have_following_documents": "Müşteri aşağıdaki belgelere sahip olmalıdır (belgeler geçerli olmalıdır)",
    "count_days": "{sayı, çoğul, bir {# gün} diğer {# gün}}",
    "longer": "Daha uzun",
    "date_from": "Tarih itibaren",
    "time_from": "Şu andan itibaren",
    "date_to": "Tarih kadar",
    "time_to": "Zamana kadar",
    "rate": "Oran",
    "car_rental_weekday": "Kiralama - Hafta İçi Ücreti",
    "car_rental_weekend": "Kiralama - Hafta Sonu Ücreti",
    "car_rental_week": "Kiralama - haftalık taksit",
    "car_rental_month": "Kira - Aylık taksit",
    "document_overdue_please_review": "Belge gecikti. Lütfen kontrol edin",
    "count_cars": "{sayı, çoğul, bir {# araç} diğer {# araçlar}}",
    "count_products": "{sayı, çoğul, bir {# ürün} diğer {# ürünler}}",
    "we_found_count_cars": "Bir {# araç} diğer {# araç}} bulduk {sayım, çoğul, bir {# araç}}",
    "we_found_count_products": "Sayım, çoğul, bir {# ürün} diğer {# ürün}} bulduk",
    "delete_all_filters": "Tüm filtreleri sil",
    "image_recognition_tags": "Resimden tanınan etiketler",
    "username": "Kullanıcı adı",
    "handover_must_be_performed": "Araç devir teslim protokolü tamamlanmalıdır",
    "document_pages": "Belge sayfaları",
    "pages": "Sayfalar",
    "handover": "Devir Teslim",
    "mileage": "Kilometre",
    "client_documents": "Müşteri belgeleri",
    "lead_documents": "Kurşun belgeler",
    "fuel_level": "Yakıt seviyesi",
    "start_editing": "Düzenlemeye başlayın",
    "design": "Tasarım",
    "responsive": "Duyarlı",
    "back": "Geri",
    "use_text_description": "Metin açıklaması kullanın",
    "use_visual_builder": "Görsel oluşturucu kullanın",
    "set_image": "Görüntüyü ayarla",
    "select_image": "Resim seçiniz",
    "uploaded_by_me": "Benim tarafımdan yüklendi",
    "current_item": "Güncel makale",
    "current_page": "Geçerli sayfa",
    "difficulties": "Zorluklar",
    "damages": "Hasar",
    "damage_note": "Hasar bildirimi",
    "no_damages_in_protocol": "Kütükte hasar yok",
    "add_damage": "Hasar ekleyin",
    "my_company": "Benim şirketim",
    "user_administration": "Kullanıcı yönetimi",
    "client_administration": "İletişim yönetimi",
    "crm_administraion": "CRM yönetimi",
    "content_administration": "İçerik yönetimi",
    "product_administration": "Ürün yönetimi",
    "producttemplate_administration": "Şablon yönetimi",
    "car_service_administration": "Atölye yönetimi",
    "site_administration": "Web sitesi yönetimi",
    "data_push": "Veri itme",
    "client_has_no_ancestors": "Müşterinin atası yok",
    "domain": "Etki Alanı",
    "entity": "Varlık",
    "event": "Etkinlik",
    "endpoint_url": "Uç Nokta URL'si",
    "authentication": "Kimlik Doğrulama",
    "headers": "Başlıklar",
    "none": "Hiçbiri",
    "social_media": "Sosyal medya",
    "style": "Stil",
    "html_header": "HTML başlığı",
    "custom_html_header": "Özelleştirilmiş HTML başlığı",
    "css_file_name": "Kullanıcı tanımlı CSS dosya adı",
    "custom_css": "Özel CSS",
    "custom_less": "Kullanıcı tanımlı LESS",
    "custom_font_folder": "Yazı tipi dizinini seçin",
    "custom_font": "Özel yazı tipi yükleme",
    "custom_css_mail": "E-postalar için özelleştirilmiş CSS",
    "main_menu": "Ana menü",
    "menu_css_class": "Ana menü CSS sınıfı",
    "menu_css_class_scroll": "Kaydırma sırasında ana menü CSS sınıfı",
    "white_label_projects": "Beyaz etiket projeleri",
    "imprint": "Künye",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "Genel kiralama şartları ve koşulları",
    "car_sales_terms_of_service": "İkinci El Araçlar için Genel Satış Hüküm ve Koşulları",
    "item_sales_terms_of_service": "Genel Satış Hüküm ve Koşulları Ürünler",
    "privacy_policy": "Veri koruma",
    "cancellation_policy": "İptal politikası",
    "footer": "Altbilgi",
    "hide_footer": "Altbilgiyi gizle",
    "unknown_property": "Bilinmeyen parametre",
    "unknown": "Bilinmiyor",
    "price_from": "itibaren",
    "price_to": "kadar",
    "slider_from": "itibaren",
    "slider_to": "kadar",
    "within": "içinde",
    "of": "itibaren",
    "within_count_km_of_place": "yere} {say} km mesafede",
    "click_to_start_geolocation": "Coğrafi konumlandırmayı başlatmak için buraya tıklayın",
    "geolocation_error": "Konumunuzu algılamada bir sorun yaşadık veya konumunuz bilinmiyor. Lütfen konumunuzu manuel olarak girin.",
    "count_km_from": "km uzaklıkta",
    "car_power": "Performans",
    "car_power_unit": "PS",
    "car_gear": "Şanzıman",
    "car_capacity": "Yer Değiştirme",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Yakıt türü",
    "car_type": "Araç tipi",
    "car_num_previous_owners": "Araç sahiplerinin sayısı",
    "car_num_doors": "Kapı sayısı",
    "car_num_seats": "Koltuk sayısı",
    "car_emission_class": "Kirletici sınıfı",
    "car_condition": "Araç durumu",
    "car_construction_year": "İnşaat yılı",
    "car_color_manufacturer": "Renk (üretici)",
    "car_parking_assistance": "Park yardımı",
    "car_interior_material": "İç donanım malzemesi",
    "car_airbags": "Hava Yastıkları",
    "car_air_conditioning": "Klima",
    "car_environment_badge": "Çevre rozeti",
    "car_co2_emissions": "CO2 emisyonları",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Tüketim",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "birleşik",
    "combined_short": "tarak.",
    "urban": "kentsel",
    "extra_urban": "ŞEHİR DIŞI",
    "energy_efficiency_class": "Enerji verimliliği sınıfı",
    "technical_data": "Teknik veriler",
    "first_registration": "İlk kayıt",
    "proceed_to_checkout": "Ödeme",
    "home": "Ana Sayfa",
    "checkout": "Vezne",
    "your_cart": "Alışveriş arabanız",
    "your_car": "Arabanız",
    "your_data": "Verileriniz",
    "services": "Hizmet",
    "shipping_information": "Transfer",
    "payment_details": "Ödeme",
    "summary": "Özet",
    "find_out_more": "Daha fazla bilgi edinin",
    "qty": "Miktar",
    "checkout_next": "Daha ileri",
    "checkout_previous": "Geri",
    "please_select": "Lütfen seçiniz",
    "enum_many_glue": ",",
    "enum_last_glue": " ve",
    "enum_end": " seçin",
    "show_details": "Detayları göster",
    "add_to_cart": "Alışveriş sepetine ekle",
    "show_cart": "Alışveriş sepetini göster",
    "cart": "Alışveriş sepeti",
    "missingQuantity": {
      "cart": "alişveri̇ş sepeti̇"
    },
    "cart_is_empty": "Alışveriş sepeti boş",
    "item_already_in_cart": "Bu ürün zaten alışveriş sepetinizde",
    "remove": "Silme",
    "removed_from_cart": "alışveriş sepetinden silindi",
    "continue_with_login": "Giriş ile devam edin",
    "dont_have_an_account": "Henüz hesap yok",
    "continue_without_login": "Giriş yapmadan devam et",
    "already_have_an_account_enter_username_and_password": "Zaten bir hesabınız var mı? Lütfen aşağıdaki forma kullanıcı adınızı ve şifrenizi girin.",
    "continue_without_login_and_register": "Satın alma işlemine devam edebilir ve hesabınızı buradan oluşturabilirsiniz.",
    "continue_booking_without_login_and_register": "Rezervasyon işlemine devam edebilir ve hesabınızı buradan oluşturabilirsiniz",
    "online_discount": "Online indirim",
    "your_personal_data": "Kişisel verileriniz",
    "login_data": "Verilere erişim",
    "shipping": "Nakliye",
    "payment_cash": "Nakit",
    "payment_creditCard": "Kredi kartı",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Banka havalesi",
    "payment_bill": "Fatura",
    "choose_different_payment_method": "Başka bir ödeme yöntemi seçin",
    "i_will_pick_up_my_car": "Arabamı alacağım.",
    "i_will_pick_up_my_order": "Siparişimi alacağım",
    "i_want_my_car_delivered": "Aracımın teslim edilmesini istiyorum.",
    "i_want_my_order_delivered": "Siparişimin teslim edilmesini istiyorum",
    "we_could_not_calculate_route_to_address": "Belirtilen adrese giden bir rota hesaplayamadık. Lütfen kontrol edin ve tekrar deneyin.",
    "credit_card_holder": "Kredi kartı tutucusu",
    "credit_card_number": "Kredi kartı numarası",
    "car_registration": "Araç kaydı",
    "car_insurance": "Araba sigortası",
    "service_provided_in_country_by_company": "Ülke}'de {şirket} tarafından sağlanan hizmet",
    "i_wish_to_have_car_registered": "Evet, yetkilendirme hizmetini kullanmak istiyorum",
    "i_wish_to_get_car_insurance": "Araba sigortası yaptırmak istiyorum",
    "today": "Bugün",
    "in_10_days": "10 gün içinde",
    "your_money_is_safe_with_us": "Paranız bizimle güvende",
    "car_transport": "Araba taşımacılığı",
    "delivery": "Teslimat",
    "decide_car_delivery_or_pickup": "Aracınızı kendiniz mi almak yoksa teslim mi ettirmek istediğinize karar vermek size kalmış. Her iki durumda da araç mükemmel durumda, temiz ve benzin deposu dolu olacaktır.",
    "secured_payment_until_car_is_delivered": "Araç teslimine kadar güvenli ödeme süreci",
    "e_payment_to_reserve_car_and_secure_services": "Aracı rezerve etmek ve seçilen hizmetleri güvence altına almak için e-ödeme",
    "e_payment_credit_card_paypal": "Elektronik ödeme (kredi kartı veya PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Araç için güvenli ödeme seçenekleri (banka havalesi, tahsilatta nakit)",
    "buy_car_by_clicking_button": "Aşağıdaki butona tıklayarak bu aracı şimdi satın alabilirsiniz",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Sonu {last4} ile biten ve {expMonth}/{expYear} ile sona eren, {cardHolder} adına düzenlenmiş kredi kartı ödemesi",
    "click_on_paypal_checkout_to_continue": "Devam etmek için lütfen Paypal Checkout butonuna tıklayınız.",
    "paypal_is_approved_continue_to_summary": "PayPal ödemeniz onaylandı. Siparişi tamamlamak ve ödemeyi onaylamak için lütfen özet ile devam edin.",
    "paypal_buttons_loading": "PayPal düğmelerini yükle...",
    "please_wait_saving_order": "Siparişi kaydedin. Lütfen bekleyin...",
    "error_loading_paypal_scripts": "PayPal komut dosyaları yüklenemedi. Lütfen bu açılır pencereyi kapatın ve tekrar açın.",
    "link": "Bağlantı",
    "hide_on_devices": "Cihazlarda gizle",
    "mobile_phones": "Cep telefonları",
    "tablets": "Tabletler",
    "desktop_pcs": "Masaüstü Bilgisayarlar",
    "show_content_on_mouse_over": "Fare üzerine geldiğinde içeriği göster",
    "menu_content": "Menü içeriği",
    "link_to_page": "Sayfa bağlantısı",
    "page_has_no_translations": "Bu sayfanın çevirisi yok",
    "search_results": "Arama sonuçları",
    "press_enter_to_search": "Aramak için enter tuşuna basın",
    "no_results_found": "Sonuç bulunamadı",
    "create_new_entry_now": "Artık yeni bir giriş oluşturabilirsiniz:",
    "no_results_for_your_search": "Arama sorgunuz için herhangi bir sonuç bulamadık",
    "change_image": "Resmi değiştir",
    "employee_data": "Çalışan verileri",
    "newsletter": "Haber bültenlerini almak için izin",
    "editorAutosave": "Düzenleyicide otomatik kaydetme",
    "is_profile_public": "Profil herkese açık mı?",
    "parent_page": "Ebeveyn sayfası",
    "cms_elements": "CMS öğeleri",
    "cms_element": "CMS öğesi",
    "new_lead_from_website": "Web sitesinden yeni ipucu",
    "website_settings": "Web sitesi ayarları",
    "profile": "Profil",
    "my_bills": "Faturalarım",
    "integrations": "Entegrasyonlar",
    "email_templates": "E-posta şablonları",
    "cms_templates": "CMS şablonları",
    "cms_template": "CMS şablonu",
    "select_brand": "Marka seçiniz",
    "all_brands_visible": "Tüm markalar görünür",
    "contact_us": "Bize ulaşın",
    "thank_you": "Çok teşekkür ederim",
    "thank_you_for_contacting_us": "Bizimle iletişime geçtiğiniz için teşekkür ederiz. Talebinizi aldık ve mümkün olan en kısa sürede size geri döneceğiz",
    "cms_standard": "Standart",
    "select_element": "CMS öğesini seçin",
    "divider": "Bölücü",
    "testimonial": "Tavsiye",
    "hero_unit": "Kahraman öğesi",
    "image_carousel": "Kaydırıcı",
    "call_to_action": "Eylem çağrısı",
    "bar_counter": "Bar tezgahı",
    "products": "Ürünler",
    "producttemplates": "Şablonlar",
    "single_product": "Tek ürün",
    "single_producttemplate": "Tek şablon",
    "lead_form": "Kurşun formu",
    "image_carousel_element": "Kaydırıcı görüntüsü",
    "cms_grid": "Konteyner",
    "cms_row": "Hat",
    "cms_col": "Sütun",
    "text_color": "Metin rengi",
    "bar_counter_now": "Şimdi",
    "shadow": "Gölge",
    "no_shadow": "Gölge yok",
    "small": "Küçük",
    "big": "Büyük",
    "rounded_corners": "Yuvarlatılmış köşeler",
    "no_rounded_corners": "Yuvarlatılmış köşeler yok",
    "image_position": "Görüntü konumu",
    "icon": "Simge",
    "icon_class": "Simge sınıfı (CSS)",
    "position_top": "Yukarıda",
    "position_left": "Bağlantılar",
    "bar_color": "Çubuk rengi",
    "bar_background_color": "Çubuk arka plan rengi",
    "button_icon": "Düğme sembolü",
    "button_text": "Düğme metni",
    "full_height": "Tam yükseklik",
    "padding_top": "Yukarıdaki mesafe",
    "padding_bottom": "Aşağıdaki mesafe",
    "lead_source": "Kurşun kaynağı",
    "change_item": "Makaleyi değiştir",
    "show_infos_on_top": "Yukarıdaki bilgileri göster",
    "show_infos_on_bottom": "Bilgileri aşağıda göster",
    "condensed": "Yoğunlaştırılmış",
    "assign_item": "Ürün atama",
    "image_animation": "Görüntü animasyonu",
    "no_animation": "Animasyon yok",
    "fade_in": "İçeri gir",
    "from_left": "Soldan",
    "from_right": "Sağdan",
    "from_top": "Yukarıdan",
    "from_bottom": "Aşağıdan",
    "image_gallery": "Resim galerisi",
    "elements": "Elementler",
    "accordion": "Akordeon",
    "map": "Harita",
    "height": "Yükseklik",
    "video": "Video",
    "link_to_video": "Video bağlantısı",
    "video_possible_sources": "Aşağıdaki portallardan videolar desteklenir:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Otomatik oynatma",
    "aspect_ratio": "En boy oranı",
    "image_compare": "Görüntüyü karşılaştır",
    "second_image": "İkinci resim",
    "login_form": "Giriş formu",
    "button_signin": "Giriş yap",
    "alternative_login": "...veya ile giriş yapın:",
    "alternative_login_long": "Sosyal giriş fonksiyonumuzu kullanın",
    "login_with": "ile giriş yapın",
    "show_facebook_login": "Facebook girişini göster",
    "show_google_login": "Google girişini göster",
    "signup_form": "Kayıt formu",
    "signup_legal_explanation": "Kayıt - yasal beyan",
    "signup_agreement_text": "Kaydolarak {şartları} kabul ediyorum.",
    "contact_agreement_text": "Veri_kullanım}'ı okudum ve anladım.",
    "signup_agreement_text_terms": "Genel Hüküm ve Koşullar",
    "signup_agreement_text_data_use": "Gizlilik Politikası",
    "signup_agreement_text_cancellation_policy_and_form": "İptal politikası ve iptal formu",
    "will_become_link_with_title_title": "başlığı olan bir bağlantı: {title}",
    "show_only_when_user_logged_id": "Yalnızca kullanıcı oturum açmışsa görüntülenir",
    "car_rental_form": "Araç kiralama formu",
    "car_search_form": "Araç arama formu",
    "car_search_funnel": "Araç arama konfigüratörü",
    "background_image": "Arka plan görüntüsü",
    "background_color": "Arka plan rengi",
    "same_col_height": "Aynı sütun yüksekliği",
    "pick_up_and_drop_off": "Toplama ve iade",
    "pick_up_time": "Teslim alma saati",
    "drop_off_time": "Dönüş süresi",
    "get_a_quote": "Fiyatları belirleyin",
    "select_location": "Lütfen bir konum seçin",
    "type_address_to_start_search": "Aramayı başlatmak için lütfen bir adres girin",
    "menu_version": "Menü versiyonu",
    "menu_transparent": "Şeffaf",
    "menu_dark": "Karanlık",
    "menu_light": "Işık",
    "logo_version": "Logo versiyonu",
    "logo_default": "Standart logo kullanın",
    "logo_scroll": "Kaydırma logosunu kullanın",
    "select_a_pick_up_location": "Lütfen önce bir teslim alma yeri seçin",
    "could_not_find_location_please_enter_more_details": "Bir konum bulamadık. Lütfen teslim alma konumunuzu diğer ayrıntılarla birlikte girin",
    "client_not_found": "Müşteri bulunamadı",
    "order_will_directly_be_marked_paid": "Sipariş hemen ödendi olarak işaretlenir",
    "invoice_will_directly_be_marked_paid": "Fatura hemen ödendi olarak işaretlenir",
    "rental_period": "Kiralama süresi",
    "change_booking_dates": "Rezervasyon verilerini değiştir",
    "per_day": "günlük",
    "buy_now": "Şimdi satın al",
    "book_now": "Şimdi rezervasyon yaptırın",
    "rent_car_now": "Şimdi kirala",
    "commit_to_buy": "Ödeme yükümlülüğü içeren sipariş",
    "back_to_cart": "Alışveriş sepetine geri dön",
    "equipment": "Ekipman",
    "technical_specifications": "Teknik veriler",
    "cancel_checkout_question": "Ödeme iptal mi?",
    "how_it_works": "Nasıl çalışır",
    "secure_payment": "Güvenli ödeme",
    "top_services": "En iyi hizmetler",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Bu arabayı satın aldığınızda evrak işlerinden korkmayın. Dilerseniz ruhsat, sigorta ve araç kredisini organize edebiliriz. Ödeme sırasında ihtiyacınız olan hizmeti seçmeniz yeterlidir.",
    "need_more_info_question": "Daha fazla bilgiye mi ihtiyacınız var?",
    "contact_dealer": "Bayi ile iletişime geçin",
    "move_around_to_see_changes": "Değişiklikleri görmek için hareket edin",
    "lead_catchers": "Lider yakalayıcılar",
    "lead_catcher": "Lider yakalayıcı",
    "advanced_routing": "Gelişmiş yönlendirme",
    "add_route": "Rota ekle",
    "source": "Kaynak",
    "can_be_ordered_online": "Online sipariş edilebilir",
    "count_years_warranty": "{say} {say, çoğul, bir {yıl} diğer {yıllar}} Garanti",
    "amount_online_advantage": "{miktar} Çevrimiçi avantaj",
    "your_order": "Siparişiniz",
    "file_name": "Dosya adı",
    "tags": "Etiketler",
    "save_and_set_image": "Kaydetme ve ayarlama",
    "choose_or_drop_file_to_upload": "Yükleme sayfası üzerinde bir dosya seçin veya sürükleyin.",
    "media_formats_supported": "JPG ve PNG desteklenmektedir.",
    "max_file_upload_size": "Maksimum dosya boyutu 15 MB'dir",
    "no_files_found": "Dosya bulunamadı.",
    "upload_file_by_dragging_over": "Bir dosyayı sayfanın üzerine sürükleyerek yükleme",
    "your_car_is_ready_to_check_out": "Hayalinizdeki araba sizin için hazır",
    "choosing_one_of_our_cars_is_a_good_decision": "Hayalinizdeki araca sadece birkaç adım kaldı. Online avantajlarımızdan yararlanın.",
    "your_car_will_be_delivered_to_you": "Aracınız istediğiniz yere teslim edilir",
    "your_will_get_a_amount_discount_for_ordering_online": "Online siparişler için {miktar} indirim alırsınız",
    "you_will_receive_extended_warranty": "Uzatılmış bir garanti alırsınız",
    "you_will_get_exclusive_offers_when_using_our_app": "Mobil uygulamamızı kullandığınızda özel teklifler alırsınız",
    "you_dont_risk_when_ordering_online": "İnternetten sipariş verdiğinizde fazla riske girmezsiniz.",
    "your_car_rent_is_nearly_done": "Kiralık aracınız neredeyse hazır!",
    "please_continue_with_booking_and_payment": "Lütfen rezervasyon ve ödeme ile devam edin. Aracınızın seçilen teslim alma tarihinde hazır olmasını sağlayacağız. Online rezervasyon size zaman kazandırır!",
    "please_provide_address_delivery_method_payment": "Lütfen adresinizi girin, gönderim yöntemini ve ödeme ayrıntılarını seçin.",
    "your_information_is_safe_with_us": "Bilgileriniz bizimle güvende.",
    "we_offer_parcel_shipment_for_just_amount": "Size kolaylık sağlamak için, tüm siparişlerimiz için yalnızca {tutar} karşılığında tek paket teslimat sunuyoruz.",
    "free_delivery_on_all_our_orders": "Tüm siparişlerimizde ücretsiz teslimat sunuyoruz.",
    "get_your_car_delivered": "Teslimat",
    "cant_pick_your_car_yourself_we_got_you_covered": "Hayalinizdeki arabayı bulursanız ve kendiniz alamazsanız ne olur? Endişelenmeyin. Bizim bir çözümümüz var. Aracınız tescil edilip sigortalandıktan sonra, bir sürücü sizinle bir teslimat randevusu ayarlayacak ve aracınızı garaj yolunuza park edecektir.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Elbette, sizi şahsen karşılamayı ve yeni aracınızı göstermeyi dört gözle bekliyoruz.",
    "we_can_complete_your_order_in_shop_for_pickup": "Ancak dilerseniz siparişinizi mağazamızda tamamlayabiliriz ve vaktiniz olduğunda teslim alabilirsiniz.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Siparişinizi tamamladığımızda sizi bilgilendireceğiz. Daha sonra sabırla teslim almanızı bekleyeceğiz.",
    "we_will_ship_your_order_to_provided_address": "Siparişinizi DHL ile belirttiğiniz adrese göndereceğiz. Siparişiniz gönderildiğinde sizi bilgilendireceğiz. Ayrıca bir sipariş takip numarası alacaksınız.",
    "we_calculated_route_from_car_to_you": "Aracın bulunduğu yerden verdiğiniz adrese kadar bir rota hesapladık. Görünüşe göre şoförümüzün aracı kapınıza getirmek için {mesafe} km sürmesi gerekiyor.",
    "service_includes": "Hizmet şunları içerir:",
    "driver_will_call_you_and_schedule_delivery": "Şoförümüz sizi arayacak ve size en uygun zamanda aracı teslim edecektir",
    "your_car_will_be_washed": "Arabanız yıkanıyor",
    "your_car_will_be_fully_tanked": "Arabanız benzinle dolduruldu",
    "you_will_get_quick_tour_around_car": "Arabada kısa bir tur atarsınız",
    "you_can_pick_up_car_yourself": "Herhangi bir sorunuz var mı? Ekibimiz size yardımcı olmaktan mutluluk duyacaktır.",
    "only_one_step_left": "Sadece bir adım daha!",
    "check_if_everything_is_correct_and_provide_payment_data": "Lütfen her şeyin doğru olduğunu kontrol edin, bize ödeme bilgilerinizi verin ve siparişi gönderin.",
    "terms_of_contract_for_additional_services": "Ek hizmetler için sözleşme koşulları",
    "terms_of_use_marketplace": "Pazar yeri kullanım koşulları",
    "for_the_sales_of_used_vehicles": "İkinci el araç satışı",
    "for_the_rental_of_vehicles": "Araç kiralama",
    "your_payment_schedule": "Ödeme koşusu",
    "shipping_pickup": "Koleksiyon",
    "shipping_parcel": "Paket",
    "shipping_transport": "Nakliye",
    "car_interior": "İç donanımlar",
    "car_exterior": "Dış donanımlar",
    "we_are_processing_your_order_please_wait": "Siparişinizi işleme alıyoruz. Lütfen bekleyiniz...",
    "we_are_processing_your_booking_please_wait": "Rezervasyonunuzu işleme alıyoruz. Lütfen bekleyiniz...",
    "your_order_was_completed_successfully": "Siparişiniz başarıyla tamamlanmıştır.",
    "we_created_a_user_profile": "Sizin için bir kullanıcı profili oluşturduk",
    "check_your_email": "Gelen kutunuzu kontrol edin",
    "your_orders": "{sayı, çoğul, bir {sipariş numaranız} diğer {sipariş numaralarınız}}:",
    "first_experience_with_our_shop_saved_profile": "Görünüşe göre bu, çevrimiçi mağazamızla ilk deneyiminiz. Profil bilgilerinizi veritabanımıza başarıyla kaydettik. Siparişinizi işleme almaya devam etmek için lütfen posta kutunuzu kontrol edin ve e-posta adresinizi onaylayın.",
    "order_details_sent_to_email_check_history_for_details": "Tüm sipariş detayları e-posta adresinize gönderilmiştir. Sipariş geçmişinizi ve sipariş durumunuzu istediğiniz zaman kullanıcı hesabınızdan görüntüleyebilirsiniz.",
    "timeline": "Zaman Çizelgesi",
    "promotions": "Eylemler",
    "payment_method": "Ödeme yöntemi",
    "first_payment": "1. Ödeme",
    "second_payment": "2. Ödeme",
    "shipping_method": "Nakliye yöntemi",
    "payment_1_e_payment_to_secure_your_order": "Aracınızı rezerve etmek için elektronik ödeme yoluyla depozito yatırın.",
    "payment_2_choose_payment_method_for_the_car": "Aracınız için bir ödeme yöntemi seçin.",
    "payment_escrow": "Emanet hesabı",
    "payment_car_credit": "Finansman",
    "full_refund": "Tam para iadesi",
    "partial_refund": "Kısmi geri ödeme",
    "cancel_order": "Siparişi iptal et",
    "create_shipping": "Gönderim oluştur",
    "schedule_car_registration": "Yetkilendirme",
    "schedule_transport": "Ulaşım planı",
    "is_published": "Yayınlandı",
    "no_salesman_available": "Şu anda satıcı mevcut değil",
    "any_questions": "Sorusu olan?",
    "feel_free_to_ask_our_online_sales_team": "Online satış ekibimizle iletişime geçmeniz yeterli",
    "please_complete_your_details": "Lütfen bilgilerinizi tamamlayın",
    "registration_is_stressfull_get_your_car_registered": "Bazı insanlar için araba kaydettirmek stresli ve zaman alıcıdır. Zorluk çekmeden aracınızı kendi adınıza kaydettirin.",
    "review_your_order": "Siparişinizi kontrol edin",
    "in_order_to_confirm_identity_please_complete_your_details": "Kimliğinizi doğrulamak için bazı ek bilgilere ihtiyacımız var:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "Satıcı, siparişiniz kabul edildikten sonra size ayrıntılı ödeme talimatları verecektir.",
    "nationality": "Uyruk",
    "date_of_birth": "Doğum tarihi",
    "place_of_birth": "Doğum yeri",
    "discover_more": "Daha fazlasını keşfedin",
    "more_of_product": "Daha fazla {ürün}",
    "more_of_product_in_city": "Daha fazla {ürün} {şehir} içinde",
    "order_placed": "Sipariş verildi",
    "show_order_details": "Sipariş ayrıntılarını göster",
    "white_label_plugins": "Beyaz etiket eklentileri",
    "white_label_plugin": "Beyaz etiket eklentisi",
    "enhance_chedri_project_with_plugins": "Eklentilerle creacheck deneyimini iyileştirin",
    "plugin": "Eklenti",
    "plugins": "Eklentiler",
    "enhance_your_project_with_plugins": "Creacheck işlevselliğinin ne kadarına ihtiyacınız olduğunu seçebilirsiniz. Mevcut eklentileri, işlevlerini ve fiyatlarını kontrol edin.",
    "week": "hafta",
    "month": "ay",
    "quarter": "Çeyrek",
    "year": "Yıl",
    "more_infos": "Daha fazla bilgi",
    "less_infos": "Daha az bilgi",
    "activate_plugin": "Eklentiyi etkinleştirin",
    "partially_activate_plugin": "Eklenti aktivasyonunu imzala",
    "terminate_plugin": "Eklentiden çık",
    "detailed_description": "Detaylı Açıklama",
    "installation_instructions": "Kurulum talimatları",
    "you_are_not_an_adminitrator": "Yönetici değilsiniz",
    "creditor_id": "Alacaklı Kimliği",
    "i_understand_the_cost_and_want_activate_plugin": "Maliyetleri anlıyorum ve eklentiyi etkinleştirmek istiyorum",
    "i_understand_the_running_cost_when_terminating_plugin": "Fatura dönemi sonunda tahsil edilecek masrafları anlıyorum",
    "are_you_sure_you_want_to_terminate_plugin": "Eklentiyi gerçekten durdurmak istiyor musunuz?",
    "security_settings": "Güvenlik ayarları",
    "limit_write_rights": "Yazma izinlerini kısıtlama",
    "limit_read_rights": "Okuma haklarını kısıtlayın",
    "edit_your_company_data": "Şirket bilgilerinizi buradan düzenleyebilirsiniz. Faturalandırma amacıyla kullanılan iletişim ve finansal verileri değiştirin",
    "latest_order": "Son sipariş",
    "latest_orders": "Son siparişler",
    "latest_invoices": "Son faturalar",
    "latest_editions": "Son düzenleme",
    "overdue_invoices": "Vadesi gelen faturalar",
    "view_all": "Hepsini göster",
    "i_accept": "Kabul ediyorum.",
    "accept_terms_before_continuing": "Devam etmeden önce, hüküm ve koşullarımızı ve gizlilik politikamızı kabul etmelisiniz",
    "approve_car_sale": "Araç satışına izin verin",
    "enter_vin_number": "Aracın şasi numarasını girin",
    "mark_as_paid": "Ödendi olarak işaretle",
    "enter_ein_number": "Elektronik sigorta onayını girin",
    "send_payment_instructions": "Ödeme talimatlarını gönderin",
    "submit_vin_number": "Aracın şasi numarasını gönderin",
    "approve_and_inform_client": "Müşteriyi yetkilendirin ve bilgilendirin",
    "submit_ein_number": "Elektronik sigorta onayı gönderin",
    "ein": "Elektronik sigorta onayı",
    "payment_reference": "Ödeme referansı",
    "upload_sales_cotract": "Satış sözleşmesini yükle",
    "cancellation_reason": "İptal nedeni",
    "enter_bank_data": "Banka bilgilerini girin",
    "pickup_invite": "Teslim almaya davet",
    "mark_picked_up": "Toplandı olarak işaretle",
    "refunds": "Para İadeleri",
    "sent_on_date": "Tarih} tarihinde gönderildi",
    "not_sent_yet": "Henüz gönderilmedi",
    "pick_up_location": "Teslim alma yeri",
    "emails": "E-postalar",
    "use_following_list_to_personalize_your_emails": "E-postaları kişiselleştirmek için aşağıdaki listeyi kullanın:",
    "recipients_first_name": "Alıcının ilk adı",
    "recipients_last_name": "Alıcının soyadı",
    "senders_company_name": "Gönderenin şirket adı",
    "system_mail_registration_description": "Yeni bir kullanıcı web sitesine kaydolduğunda gönderilir",
    "system_mail_invitation_description": "Bir çalışan e-posta adresiyle yeni bir kullanıcı oluşturduğunda gönderilir",
    "system_mail_car_rental_pickup_reminder_description": "Müşteri kiralık aracını teslim almadan bir gün önce gönderilir",
    "system_mail_car_rental_dropoff_reminder_description": "Müşteri kiralık aracını teslim etmeden bir gün önce gönderilir",
    "terms_of_use": "Kullanım Koşulları",
    "contract": "Sözleşme",
    "order_data_processing": "Sipariş işleme sözleşmesi",
    "i_read_and_accept_plugins_terms_of_use": "Kullanım koşullarını okudum, anladım ve açıkça kabul ediyorum.",
    "i_read_and_accept_plugins_contract": "Sözleşmeyi okudum, anladım ve açıkça kabul ediyorum.",
    "i_read_and_accept_plugins_order_data_processing": "Sipariş verilerinin işlenmesini okudum, anladım ve açıkça kabul ediyorum.",
    "send_activation_request": "Etkinleştirme isteği gönder",
    "missing_companys_bank_data": "Görünüşe göre şirketinizin banka bilgileri eksik.",
    "you_can_fill_missing_data_in_settings": "Eksik verileri {settings} bölümüne girebilirsiniz.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Eklentileri kendiniz etkinleştirme yetkisine sahip değilsiniz. Eklentiyi etkinleştirmek için düğmeye tıklayın. Yetkili kullanıcıdan aktivasyon işlemini tamamlaması istenir.",
    "not_authorized_click_below_to_request_plugin_activation": "Eklentileri etkinleştirme yetkiniz yok. Yetkili kullanıcılara bir aktivasyon isteği göndermek için düğmeye tıklayın.",
    "select": "Seçin",
    "enter_app_secret": "Uygulama sırrını girin",
    "enter_page_access_token": "Sayfa erişim belirtecini girin",
    "legal": "Yasal",
    "activation_pending": "Etkinleştirme bekleniyor",
    "termination_pending": "İptal bekleniyor",
    "form_contains_errors": "Formda bazı sorunlar var",
    "internal_car_reference": "Dahili araç referansı",
    "system_debtor_number": "Sistem borçlu numarası",
    "internal_company_reference": "Şirket içi referans",
    "internal_location_reference": "Dahili konum referansı",
    "show_notes": "Gösteri notları",
    "show_my_notes": "Notlarımı göster",
    "show_by_me": "Benden göster",
    "items": "Ürünler",
    "send_text_message": "SMS Gönder",
    "wrote_message": "Yazılı mesaj",
    "sent_text_message": "Bir SMS gönderildi",
    "wrote_note": "Yazılı not",
    "please_check_document_check_expiration_date": "Lütfen belgeyi kontrol edin. Belgeyi gördüğünüzden emin olun. Son kullanma tarihini kontrol edin",
    "i_reviewed_the_document": "Belgeyi kontrol ettim",
    "receive_updates_in_messenger": "Güncellemeleri Messenger'da almak ister misiniz?",
    "weve_received_your_request_and_will_contact_you": "Talebinizi aldık ve mümkün olan en kısa sürede sizinle iletişime geçeceğiz",
    "check_your_email_to_confirm": "Bu iletişim formunun gönderildiğini onaylamak için lütfen e-postanızı kontrol edin.",
    "response_text": "Cevap metni",
    "entries": "Girişler",
    "timeline_block": "Zaman çizelgesi bloğu",
    "external_accounts": "Harici hesaplar",
    "send_copy_to_me": "Bir kopyasını bana gönder",
    "user_tracking": "Kullanıcı takibi",
    "select_model": "Model seçin",
    "select_model_group": "Model grubunu seçin",
    "equipment_level_budget": "Bütçe",
    "equipment_level_family": "Aile",
    "equipment_level_comfort": "Konfor",
    "equipment_level_business": "İş Dünyası",
    "salutation": "Selamlama",
    "getting_started": "İlk adımlar",
    "set_your_locations": "Konumlarınızı tanımlayın",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Her araç, müşterilerin seçebileceği bir konum gerektirir. İstediğiniz tüm konumları tanımlayabileceğiniz ayarlara gidin. Burada her bir konumda hangi hizmetleri sunacağınıza karar verebilir, çalışma saatlerini belirleyebilir ve müşterilerin işletmenizi bulmasını kolaylaştırmak için fotoğraflar yükleyebilirsiniz.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Artık müşterileriniz nerede iş yaptığınızı bildiğine göre, sizin için kimin çalıştığını bize bildirin. Creacheck teknolojilerini kullanarak işbirliği yapabilmeleri için lütfen kullanıcılar oluşturun",
    "cms_content": "CMS içeriği",
    "this_is_blank_page_use_editor_to_create_great_one": "Bu boş bir sayfadır. Görsel editörümüzde grafiklerle, güzel tipografiyle ve tüm cihazlarla uyumlu güzel bir sayfa oluşturabilirsiniz.",
    "cars_to_sell": "Satılık Araçlar",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Sizin işiniz araç satmak. Bizim işimiz bunu sizin için kolaylaştırmak. Sistemimize henüz herhangi bir araç girmediniz. Sadece uygun araç içe aktarma işlevini kullanın veya araçlarınızı manuel olarak girin.",
    "create_manually": "Manuel olarak oluşturun",
    "import": "İthalat",
    "car_import": "İthal araçlar",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Öğrendiğimize göre, çoğu araba satıcısı arabalarını sunmak için mobile.de'yi kullanıyor. Yani büyük olasılıkla web hizmetine erişiminiz var. Lütfen kullanıcı adınızı ve şifrenizi girin. Tüm araçlarınızı otomatik olarak içe aktaracağız ve listelemenizi senkronize tutacağız. Birden fazla giriş bilgileriniz varsa, profilinize daha fazlasını girebilirsiniz.",
    "we_will_import_count_cars_for_this_username": "Bu kullanıcı adı için {count} araçlarını içe aktaracağız",
    "mobile_de_username": "Mobile.de Satıcı API kullanıcı adı",
    "mobile_de_password": "Mobile.de Satıcı API Şifresi",
    "missing_bank_data": "Eksik banka bilgileri",
    "we_dont_have_your_bank_data": "Görünüşe göre banka bilgileriniz bizde yok",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Aylık faturalandırmamız SEPA otomatik ödemesine dayanmaktadır. Herhangi bir banka bilgimiz olmadığından, eklentilerimizin çoğunu kullanamazsınız. Lütfen ayarlara gidin ve banka bilgilerinizi girin.",
    "fill_in_bank_data": "Banka bilgilerini girin",
    "sepa_mandate": "SEPA yetkisi",
    "sepa_mandates": "SEPA yetkileri",
    "missing_sepa_mandate": "Bir SEPA yetkisini kaçırıyoruz",
    "changed_bank_data_please_generate_new_sepa_mandate": "Görünüşe göre yakın zamanda banka hesap numaranızı değiştirmişsiniz. Aylık faturalandırma sürecimizi otomatikleştirmek ve sizin için daha az stres yaratmak için lütfen yeni bir SEPA talimatı oluşturun.",
    "generate_sepa_mandate_now": "Yeni bir SEPA yetkisi oluşturun",
    "columns": "Sütunlar",
    "send_doc_as_message": "Belgeyi müşteriye gönderme (e-posta)",
    "alternative_recepient": "Alternatif alıcı",
    "enter_email_address": "Lütfen geçerli bir e-posta adresi girin",
    "job_title": "İş unvanı",
    "service_point": "Hizmet",
    "rental_point": "Kiralama tabanı",
    "seller_data": "Satıcı verileri",
    "car_location": "Araç konumu",
    "save_data_before_nav_to_pathname": "Verileriniz kaydedilmeyecektir. Kaydetmeden {pathname}'e geçmek ister misiniz?",
    "share": "Paylaş",
    "assignee_changed_from_assignee_to_assignee": "Devralan %oldAssignee%'den %newAssignee% olarak değiştirildi",
    "stage_changed_from_name_to_name": "Seviye %oldName%'den %newName%'e değiştirildi",
    "handover_filled_out": "Araç devir teslim protokolü tamamlandı",
    "data_changed": "Veriler güncellendi",
    "key": "Anahtar",
    "old_value": "Eski değer",
    "new_value": "Yeni değer",
    "no_details_available": "Detaylı bilgi mevcut değildir",
    "services_we_offer_in_city": "Sizin için {şehir}'de sunduğumuz hizmetler",
    "damages_present": "Hasar mevcut",
    "user_is_online": "Kullanıcı çevrimiçi",
    "user_is_offline": "Kullanıcı çevrimdışı",
    "click_to_start_chat": "Sohbeti başlatmak için buraya tıklayın",
    "click_to_view_chat_history": "Sohbet geçmişini görüntülemek için buraya tıklayın",
    "theme_colors": "Tema renkleri",
    "logos": "Logolar",
    "send_invitation_with_username_and_password": "Kullanıcı adı ve şifre ile davetiye gönder (sadece yeni kullanıcılar için)",
    "cookie_settings": "Çerez detayları",
    "accept_only_necessary_cookies": "Yalnızca gerekli çerezleri kabul edin",
    "accept_all_cookies": "Tüm çerezleri kabul et",
    "privacy_preference_center": "Veri koruma tercih merkezi",
    "your_privacy": "Gizliliğiniz",
    "basic_information_on_cookies": "Bir web sitesini ziyaret ettiğinizde, bilgiler genellikle çerezler şeklinde tarayıcınızda saklanabilir veya alınabilir. Bu bilgiler sizinle, tercihlerinizle veya cihazınızla ilgili olabilir. Bu bilgiler esas olarak web sitesinin beklendiği gibi çalışmasını sağlamak için kullanılır. Bu bilgiler genellikle sizi doğrudan tanımlamaz, ancak daha kişiselleştirilmiş bir web deneyimi sağlayabilir. Gizlilik hakkınıza saygı duyduğumuz için, belirli çerez türlerine izin vermemeyi seçebilirsiniz. Daha fazla bilgi edinmek ve varsayılan ayarlarımızı değiştirmek için bir kategori seçin. Ancak, bazı çerez türlerinin engellenmesi web sitesi deneyiminizi ve sunabileceğimiz hizmetleri etkileyebilir.",
    "necessary_cookies": "Gerekli çerezler",
    "performance_cookies": "Performans çerezleri",
    "targeting_cookies": "Çerezleri hedefleme",
    "always_active": "Her zaman aktif",
    "cookies_used": "Kullanılan çerezler",
    "necessary_cookies_information": "Bu çerezler web sitesinin çalışması için gereklidir ve sistemlerimizde devre dışı bırakılamaz. Genellikle yalnızca gizlilik tercihlerinizi ayarlamak, oturum açmak veya formları doldurmak gibi bir hizmet talebiyle ilgili olarak yaptığınız işlemlere yanıt olarak ayarlanırlar. Tarayıcınızı bu çerezleri engelleyecek veya sizi uyaracak şekilde ayarlayabilirsiniz, ancak web sitesinin bazı bölümleri çalışmayacaktır. Bu çerezler kişisel olarak tanımlanabilir herhangi bir bilgi saklamaz.",
    "save_settings": "Ayarları kaydet",
    "save_and_continue": "Kaydet ve devam et",
    "priority": "Öncelik",
    "closed_leads": "Kapalı uçlar",
    "show": "Göstergeler",
    "hide": "Saklambaç",
    "first_registration_from": "İlk kayıt",
    "first_registration_to": "İlk kayıt tarihine kadar",
    "power_from": "Motor gücü",
    "power_to": "Motor gücü şu kadar",
    "mileage_from": "Şu mesafeden",
    "mileage_to": "Şuraya kadar kilometre",
    "included_mileage": "{mileage} kilometre",
    "really_cancel": "Gerçekten iptal mi?",
    "unsaved_information_will_be_lost": "Kaydedilmemiş veriler kaybolur",
    "client_signature": "Müşteri imzası",
    "another_photo": "Başka bir fotoğraf",
    "try_again": "Tekrar deneyin",
    "back_to_vehicle": "Araca geri dönelim",
    "image_upload_finished": "Görüntülerin yüklenmesi tamamlandı",
    "you_have_no_internet_connection": "İnternet bağlantınız yok",
    "waiting_to_reconnect": "Yeniden bağlantı için bekleniyor",
    "plus_count_more": "+ {sayı} daha fazla",
    "document_is_missing": "Bir belge eksik",
    "upload_document_name_now": "'{name}' belgesini şimdi yükleyin",
    "lead_per_mail": "E-posta ile liderlik kuralı",
    "create_rules_to_forward_emails": "E-postaları diğer kullanıcılara kopya olarak iletmek için kurallar oluşturun. 'Veri e-posta eklentisi' etkinleştirilirse, XML formatında önemli bilgiler içeren e-postaları doğrudan Customer One'a da iletebilirsiniz.",
    "lead_per_mail_rules": "E-posta kurallarına göre liderlik edin",
    "include_xml_data_in_email": "E-postaya XML formatında veri ekleme",
    "sad_activity": "SAD etkinliği",
    "e_payments": "E-ödemeler",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "Stripe Connect ile kredi kartı ödemelerini sizin adınıza işleyebiliriz. \"Stripe'a Bağlan\" seçeneğine tıkladıktan sonra, Creacheck platformuna bağlanmak için mevcut Stripe hesabınızı kullanabilir veya henüz bir Stripe hesabınız yoksa yeni bir Stripe hesabı oluşturabilirsiniz. Kendi Stripe hesabınızla, tüm ödeme geçmişinizi görebilir ve istediğiniz zaman para çekebilirsiniz.",
    "connect_with_stripe": "Stripe ile Bağlanın",
    "stripe_accounts": "Stripe hesapları",
    "stripe_account_connected": "Stripe hesabınız artık bağlı",
    "stripe_accounts_are_connected": "Stripe hesabınız şimdi {projeAdı}'na bağlandı. {ProjeAdı} artık sizin adınıza kredi kartı ödemelerini kabul edebilir.",
    "money_won": "Kazanılan para",
    "leads_won": "Kazanılan müşteri adayları",
    "offered_by": "Araba satıcınız",
    "pay_with_saved_credit_card": "Kayıtlı kredi kartınızla ödeme yapın",
    "pay_with_new_credit_card": "Yeni bir kredi kartı ile ödeme yapın",
    "settings": "Ayarlar",
    "account": "Fonksiyonlar",
    "clients": "Şirketler",
    "payments": "Ödemeler",
    "calendar": "Takvim",
    "contact": "Bize ulaşın",
    "login": "Giriş yap",
    "logout": "Oturumu Kapat",
    "previous_odometer_reading": "Önceki kilometre sayacı değeri",
    "current_odometer_reading": "Mevcut kilometre",
    "odometer_reading": "Kilometre",
    "tank_volume": "Tank hacmi",
    "vehicle_fuel_costs": "Araç yakıt ikmali maliyetleri",
    "mileage_surcharge": "Ek kilometre ücreti",
    "insurance_self_participation": "Sigorta fazlası",
    "additional_costs_due": "Ödenmesi gereken ek maliyetler",
    "no_additional_costs": "Ek maliyet yok",
    "charge_customer_additional_costs": "Müşteriden ek ücret talep etmek",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Dikkat! Müşteriden herhangi bir ek ücret talep etmemeye karar verdiniz",
    "cancel_booking": "Rezervasyonu iptal et",
    "no_invoices_found": "Fatura bulunamadı",
    "upload_drivers_license": "Ehliyet yükleyin",
    "order_details": "Sipariş detayları",
    "shipping_address": "Nakliye adresi",
    "order_overview": "Siparişe genel bakış",
    "booked_by_mistake": "Yanlışlıkla rezerve edildi",
    "booked_wrong_dates": "Yanlış tarihler rezerve edildi",
    "found_better_price": "Daha iyi fiyat bulundu",
    "change_of_plans": "Plan değişikliği",
    "client_called_to_cancel": "Müşteri aradı ve iptal etmek istedi",
    "client_wrote_email_to_cancel": "Müşteri bir e-posta yazdı ve iptal etmek istedi",
    "car_damaged_before_transport": "Araç nakliyeden önce hasar görmüş",
    "car_damaged_in_transport": "Araç nakliye sırasında hasar gördü",
    "item_not_available": "Makale artık mevcut değil",
    "other_reason": "Diğer",
    "license_plate_number": "Plaka",
    "open_in_google_maps": "Google Haritalar'da aç",
    "access_identifier": "Erişim kodu",
    "branch": "Şube",
    "new": "Yeni",
    "in_progress": "Devam ediyor",
    "failed": "Başarısız",
    "not_started": "Başlamadı",
    "finished": "Bitti",
    "failed_refunds": "Başarısız geri ödemeler",
    "list_all_failed_refund_payments": "Tüm başarısız geri ödemeleri ayrıntılarıyla listeleyin",
    "rent_sync_vamoso_reservation_success": "Vamoso sisteminde başarıyla rezerve edildi. Rezervasyon numarası %reservationId%",
    "rent_sync_vamoso_reservation_failure": "Vamoso sisteminde aracı rezerve etmek mümkün değildi. Sistem aşağıdaki hata mesajını döndürdü: \"%errorText%\"",
    "rent_sync_vamoso_cancellation_success": "Vamoso sistemindeki rezervasyon başarıyla iptal edildi",
    "rent_sync_vamoso_cancellation_failure": "Vamoso sisteminde rezervasyonu iptal etmek mümkün değildi. Sistem aşağıdaki hata mesajını döndürdü: \"%errorText%\"",
    "rent_sync_vamoso_booking_success": "Vamoso sisteminde başarıyla rezerve edildi",
    "rent_sync_vamoso_booking_failure": "Vamoso sisteminde rezervasyon yapmak mümkün değildi. Sistem aşağıdaki hata mesajını döndürdü: \"%errorText%\"",
    "rent_sync_vamoso_return_success": "Araç Vamoso sisteminde başarılı bir şekilde iade edildi olarak işaretlendi",
    "rent_sync_vamoso_return_failure": "Aracı Vamoso sistemine iade etmek mümkün olmadı. Sistem aşağıdaki hata mesajını verdi: \"%errorText%\"",
    "view_lead_details": "Müşteri adayı ayrıntılarını göster",
    "change_category": "Kategori değiştir",
    "json_url_parameters": "JSON URL parametreleri",
    "layout_version": "Düzen versiyonu",
    "layout_wide": "Geniş",
    "layout_narrow": "Dar",
    "layout_detailed": "Detaylı",
    "layout_slider": "Kaydırıcı",
    "layout_list": "Liste",
    "search_version": "Arama modülü sürümü",
    "not_sure_go_to_advisory_mode": "Emin değilim: işte {link}",
    "link_to_advisory_mode": "Danışmanlık modu",
    "desired_pickup_location": "İstediğiniz teslim alma yeri",
    "incl_short": "dahil.",
    "included": "Dahil",
    "included_services": "Dahil olan hizmetler",
    "insurance": "Sigorta",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Hasar başına {miktar} aşan sorumluluk ve tam kapsamlı sigorta",
    "tyres_suitable_for_winter": "Kış lastikleri",
    "additional_driver": "Ek sürücü",
    "on_request": "istek üzerine",
    "navigation_system": "Navigasyon sistemi",
    "child_seat": "Çocuk koltuğu",
    "order_comments_and_special_wishes": "Daha fazla dilek",
    "ordering_as_person": "Özel bir birey olarak sipariş veriyorum",
    "ordering_as_company": "Şirket olarak sipariş veriyorum",
    "filter_by_date": "Tarihe göre filtrele",
    "car_rental_max_days_count_exceeded": "Maalesef, seçilen süre maksimum kiralama süremiz olan {say, çoğul, bir {# gün} diğer {# gün}}'ü aşmaktadır.",
    "require_document_number": "Talep belge numarası",
    "require_document_issuing_city": "Belgenin düzenlendiği yeri talep edin",
    "require_document_issuing_authority": "Belgeyi düzenleyen makamı talep edin",
    "require_document_issue_date": "Belgenin düzenlenme tarihini talep edin",
    "require_document_expiration_date": "Belgenin son geçerlilik tarihini talep edin",
    "doc_number": "Belge numarası",
    "doc_issuing_city": "Belgenin düzenlendiği yer",
    "doc_issuing_authority": "Belgeyi düzenleyen makam",
    "doc_issue_date": "Belgenin düzenlenme tarihi",
    "doc_expiration_date": "Belgenin son geçerlilik tarihi",
    "chedri_plugins": "Creacheck Eklentileri",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Yazılımımızı kullanmak için istediğiniz işlevleri etkinleştirmeniz gerekir. Her işlev, genellikle bir ödeme yükümlülüğü (ücret) ile birlikte bir eklenti şeklinde teslim edilir.<br/>Bir eklentiyi etkinleştirdiğinizde, eklenti hüküm ve koşullarını kabul etmeniz istenecektir. Ancak, devam etmeden önce, şirketiniz adına hareket etme yetkisine sahip olduğunuzu onaylamanız gerekir.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Şirketim {şirket} adına yasal olarak bağlayıcı sözleşmeler imzalamaya yetkili olduğumu beyan ederim.",
    "your_company_was_successfully_registered": "Sizi ve şirketinizi sistemimize başarıyla kaydettik",
    "check_email_for_more_instructions_about_your_company_registration": "Verdiğiniz e-posta adresine nasıl devam edeceğinize dair bilgi gönderdik. Lütfen gelen kutunuzu kontrol edin. Herhangi bir sorunuz varsa veya nasıl devam edeceğinizi bilmiyorsanız, lütfen bizimle iletişime geçin.",
    "dependent_on": "Bağlı olarak",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Plugin}'in işlevselliğini daha da geliştiren başka eklentilerimiz de var.",
    "finish_plugin_configuration": "Eklenti yapılandırmasından çıkın",
    "it_seems_that_you_havent_finished_configuring_plugins": "Etkinleştirilmiş eklentilerin yapılandırmasını henüz tamamlamadığınız anlaşılıyor. Bazı eklentiler için harici giriş verileri tanımlanmalıdır (örneğin mobile.de arayüzü veya Vamoso giriş verileri için). Lütfen ayarlara gidin ve eksik hesapları ekleyin.",
    "lead_created_from_an_email": "Bu müşteri adayı bir e-postadan oluşturuldu",
    "show_original_message": "Orijinal mesajı göster",
    "we_found_count_similar_leads": "Bir {# benzer ipucu} diğer {# benzer ipucu}} bulduk {sayım, çoğul, bir {# benzer ipucu}}",
    "show_similiar_leads": "Benzer potansiyel müşterileri göster",
    "new_lead_from_failed_refund": "Başarısız geri ödeme nedeniyle yeni müşteri adayı",
    "you_can_apply_company_data_from_company_profile": "Şirket verilerini şirket profilinden aktarabilirsiniz",
    "load_company_data": "Şirket verilerini yükle",
    "company_lookup": "Şirket arama",
    "match_lead_tags": "Müşteri adayı etiketlerini senkronize edin",
    "is_active": "Aktif",
    "is_renew_password_at_next_login": "Bir sonraki girişte şifreyi yenileyin",
    "no_overdue_tasks": "Gecikmiş görev yok",
    "great_job": "Aferin sana!",
    "no_leads_for_today": "Bugün sizin için ipucu yok",
    "address_not_set": "Adres ayarlanmamış",
    "statistics": "İstatistikler",
    "select_assignee": "Bir editör seçin",
    "kroschke_order_in_progress": "Araç kimliği %kroschkeVehicleId% olan Kroschke siparişi başarıyla başlatıldı",
    "in_original": "Orijinalinde",
    "a_copy": "Bir kopya",
    "mark_documents_sent": "Belgeleri gönderildi olarak işaretleyin",
    "i_sent_the_documents": "Belgeleri gönderdim",
    "documents_were_sent": "Belgeler gönderildi",
    "documents_sent": "Gönderilen belgeler",
    "define_custom_license_plate_number": "Tercih edilen plakayı tanımlayın",
    "about_car_registration": "Yetkilendirme hizmeti şunları içerir",
    "i_would_like_individual_license_plate_number": "Evet, istediğim karakteri seçmek istiyorum",
    "we_gather_necessary_documents": "Ruhsatlandırma makamınıza kayıt",
    "we_produce_license_plates": "Plakaların temini ve montajı",
    "license_plate_reservation_number": "Plakanın rezervasyon numarası",
    "with_custom_license_plate_number": "Kişiselleştirilmiş plaka ile",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Sipariş verdikten sonra, istediğiniz plakayı yerel kayıt ofisinizde çevrimiçi olarak rezerve etmeniz gerekir.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Bunu yaptıktan sonra, lütfen istediğiniz plaka numarasını ve rezervasyon numaranızı (kayıt ofisinden aldığınız) bize bildirin.",
    "clarify": "Açıklığa kavuşturun",
    "notification": "İletişim",
    "license_plate_reservation_data_missing": "Plaka için rezervasyon verileri eksik",
    "license_plate_reservation_number_filled_out": "Girilen plakalar için rezervasyon verileri. Plaka numarası: %licensePlateNumber%. Rezervasyon numarası: %reservationNumber%",
    "distance_planned": "Planlanan rota",
    "distance_driven": "Seyahat edilen rota",
    "planned_pickup_date": "Planlanan toplama tarihi",
    "pickup_date": "Toplama tarihi",
    "planned_delivery_date": "Planlanan teslimat",
    "your_initial_offer": "İlk teklifiniz",
    "your_next_offer": "Bir sonraki teklifiniz",
    "car_number": "Araç numarası",
    "send_price_proposal": "Fiyat teklifi gönderin",
    "our_price": "Bizim fiyatımız",
    "purchase_price": "Satın alma fiyatı",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Fiyatlarımızı sizin için optimize etmeye çalışıyoruz, ancak fiyatın hala çok yüksek olduğunu düşünüyorsanız, sizin için ne yapabileceğimizi bize bildirin.",
    "select_the_car_you_love": "İstediğiniz aracı seçin",
    "sign_up_or_log_in_if_you_already_have_an_account": "Zaten bir hesabınız varsa kaydolun veya giriş yapın",
    "enter_desired_price": "İstediğiniz fiyatı girin",
    "send_the_form_our_employee_will_contact_you": "Formu gönderin. Çalışanımız en kısa sürede sizinle iletişime geçecektir",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Bir fiyat üzerinde anlaşırsak, aracı doğrudan web sitemizden satın alma seçeneğiniz vardır",
    "you_have_to_log_in_to_proceed": "Devam etmek için giriş yapmalısınız",
    "rent_a_car": "Araç kiralama",
    "pending_price_negotiations_count": "Bekleyen {sayım, çoğul, bir {# fiyat teklifi} diğer {# fiyat teklifleri}}",
    "my_price_negotiation": "Benim fiyat önerim",
    "price_negotiation": "Fiyat teklifi",
    "price_negotiations": "Fiyat önerileri",
    "new_price_negotiation_request": "Yeni fiyat görüşmesi talebi",
    "expired": "Süresi doldu",
    "first_offer": "İlk teklif",
    "current_offer": "Güncel teklif",
    "expires": "Sona erdi",
    "accept_the_offer": "Teklifi kabul et",
    "send_a_new_offer": "Yeni teklif gönder",
    "your_new_offer": "Yeni teklifiniz",
    "your_project_dashboard": "Proje} kontrol paneliniz",
    "add_my_vehicle": "Aracımı ekle",
    "no_reply_yet": "Henüz cevap yok",
    "employee_replied": "Çalışan cevap verdi",
    "price_negotiation_details": "Fiyat teklifi detayları",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Mevcut teklifiniz {miktar}'dır. Anlaştığınız fiyat ile ödeme işlemine geçebilirsiniz.",
    "show_timeline": "Geçmişi göster",
    "communities": "Topluluklar",
    "gain_coverage_with_community": "Yeni platformlarla erişiminizi artırın. Tek bir tıklama ile yeni müşteriler kazanın",
    "community": "Topluluk",
    "is_community": "Topluluk aktif",
    "join_community": "Topluluğa Katılın",
    "leave_community": "Topluluğu terk edin",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Creacheck'te tamamen yeni olan bir şey de topluluklardır. Creacheck yazılımı sayesinde, birden fazla şirketin katılabileceği platformlar oluşturmak mümkündür.</p><p>Böyle bir platform Jahreswagen.de'dir. Jahreswagen'in tekliflerine göz atabilirsiniz. Üye olmak istiyorsanız, topluluk ayarlarına gidin ve Jahreswagen topluluğuna katılın.</p><p>Üyelik ücretsizdir. Ancak, işlem ücretleri geçerlidir.</p><p className='m-t-20 alert alert-info'>Bir topluluk için kendi fikriniz varsa, bizimle iletişime geçin. Sizin için bir çözüm bulacağız.</p></div>",
    "car_description": "Araç açıklaması",
    "marketplace_terms": "Pazar yeri kullanım koşulları",
    "online_appointment_booking": "Online randevu rezervasyonu",
    "my_online_appointments": "Çevrimiçi randevularım",
    "employee": "Çalışanlar",
    "choice_of_date": "Tarih seçimi",
    "works": "Çalışma",
    "make_selection": "Seçiniz",
    "remove_selection": "Kaldırmak",
    "tasks": "Görevler",
    "task": "Görev",
    "car_service": "Hizmet",
    "car_services": "HİZMETLER",
    "car_service_station": "İş İstasyonu",
    "car_service_stations": "İş İstasyonları",
    "services_offered_at_your_locations": "Lokasyonlarınızdaki hizmetleri tanımlayın.",
    "service_stations_where_your_employees_work": "İş istasyonlarını tanımlayın, böylece kullanımı takip edebilir ve dolu ve boş kaynakları görebilirsiniz.",
    "team_members": "Ekip üyeleri",
    "service_details": "Hizmet Detayları",
    "service_location": "Seçtiğiniz hizmet konumu",
    "service_drop_off_time": "Seçtiğiniz hizmet süresi",
    "car_service_appointments": "Atölye tarihleri",
    "car_service_booked_at": "Randevu alındı",
    "show_booking_details": "Rezervasyon detaylarını göster",
    "saving_draft": "Taslak kaydedildi",
    "draft_saved": "Taslak kaydedildi",
    "define_custom_page_templates_to_meet_your_cd": "Creacheck sistemini entegre şablonlarla tedarik ediyoruz. Burada kurumsal tasarımınıza daha iyi uyması için kendi şablonlarınızı oluşturabilirsiniz.",
    "edit_description": "Açıklama düzenle",
    "edit_language": "Dil düzenleme",
    "create_blank_page": "Boş bir sayfa oluşturun",
    "create_from_template": "Şablondan oluştur",
    "use_template": "Şablonu kullanın",
    "time_left": "Kalan süre",
    "animated_header": "Animasyonlu başlık",
    "header_size": "Başlık boyutu",
    "options": "Seçenekler",
    "effects": "Etkileri",
    "animation": "Animasyon",
    "parallax": "Paralaks",
    "animation_repeat": "Animasyonu tekrarla",
    "animation_intensity": "Animasyon yoğunluğu",
    "animation_duration": "Animasyon süresi",
    "animation_delay": "Animasyon gecikmesi",
    "animation_initial_opacity": "Animasyonun ilk opaklığı",
    "animation_speed_curve": "Animasyon hız eğrisi",
    "parallax_intensity": "Paralaks yoğunluğu",
    "animation_direction": "Paralaks yönü",
    "parallax_initial_opacity": "Paralaksın ilk örtme gücü",
    "append_html": "HTML ekleyin",
    "css_classes": "CSS sınıfları",
    "forward_url": "Bu URL'ye yönlendirin",
    "effect_fade": "İçeri gir",
    "effect_slide": "Kaydıraklar",
    "effect_zoom": "Yakınlaştır",
    "effect_bounce": "Sıçrama",
    "effect_flip": "Çevir",
    "effect_fold": "Kıvrımlar",
    "effect_roll": "Silindirler",
    "direction_center": "Merkez",
    "direction_left": "Bağlantılar",
    "direction_right": "Doğru.",
    "direction_top": "Yukarıda",
    "direction_bottom": "Aşağıda",
    "repeat_once": "Bir kez",
    "repeat_loop": "Döngü",
    "selected_template": "Seçilen şablon",
    "premade_layouts": "Prefabrik yerleşim planları",
    "your_existing_pages": "Mevcut sayfalarınız",
    "count_templates": "{count} Şablonlar",
    "background_attachment": "Arka plan eki",
    "background_position": "Arka plan pozisyonu",
    "background_repeat": "Arka Plan Tekrarı",
    "background_size": "Arka plan boyutu",
    "edit_mode": "Düzenleme modu",
    "effects_active": "Aktif etkiler",
    "document_templates": "Belge şablonları",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Müşterilerinize yazdırmak için kendi belge şablonlarınızı kodlayın. Ayrıca kendi fiyat teklifi ve fatura versiyonlarınızı da tasarlayabilirsiniz.",
    "currencies": "Para Birimleri",
    "document_template": "Belge şablonu",
    "document_content": "Belge içeriği",
    "number_prefix": "Numara öneki",
    "sales_documents": "Satış belgeleri",
    "theme": "Tema",
    "choose_template": "Belge şablonunu seçin",
    "default": "Standart",
    "favorites": "Favoriler",
    "favorites_list": "Favori listesi",
    "require_client_address": "Müşterinin adresini talep edin",
    "require_client_email": "Müşterinin e-posta adresini talep edin",
    "require_client_phone": "Müşterinin telefon numarasını talep edin",
    "require_message": "İstek mesajı",
    "subscription": "Abonelik",
    "subscription_plan": "Abonelik planı",
    "main_plugin": "Ana eklenti",
    "target_organisation": "Hedef kuruluş",
    "reference_number": "Referans numarası",
    "show_invoice": "Faturayı göster",
    "delivery_date": "Teslim tarihi",
    "test_drive": "Test sürüşü",
    "find_your_perfect_car": "Mükemmel aracı bulun",
    "select_car_design": "Tasarım seçin",
    "multiple_selection_possible": "Çoklu seçim mümkün",
    "fuel_type": "Yakıt",
    "select_equipment_level": "Ekipman seviyesini seçin",
    "engine": "Motorizasyon",
    "like_budget": "Bütçe gibi",
    "like_family": "Aile Gibi",
    "like_comfort": "Konfor gibi",
    "we_did_not_find_any_results_for_your_search": "Üzgünüz, aramanız eşleşmedi",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "Seçilen dönem için bu konumda herhangi bir araç bulamadık",
    "we_did_not_find_any_cars_for_your_seaech": "Maalesef arama sorgunuz için herhangi bir araç bulamadık",
    "all_cars": "Tüm arabaları göster",
    "search_for_cars": "Araç arama",
    "used_car_search": "İkinci el araba arama",
    "dropdown": "Açılır liste",
    "checkboxes": "Onay kutusu",
    "multiple_choice": "Çoklu seçim",
    "text_input": "Metin girişi",
    "number_input": "Numara girişi",
    "multiline_input": "Çok satırlı metin girişi",
    "paragraph": "Bölüm",
    "change_questionnaire": "Değişim anketi",
    "answers_to_a_questionnaire": "Bir ankete verilen yanıtlar",
    "request_filling_out_questionnaire": "Müşterilerinize bir anket gönderin",
    "send_questionnaire_as_message": "Anketi mesaj olarak gönderin",
    "ingoing": "Gelen",
    "outgoing": "Giden",
    "vat_short": "KDV.",
    "vat": "Katma değer vergisi",
    "price_net": "{fiyat} net",
    "environment": "Çevre",
    "inquire": "Sorular",
    "continue_to_product_name": "Continue to {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "Bu ve diğer araçları {company} adresinde bulabilirsiniz.",
    "more_vehicles_based_on_your_search": "Aramanıza göre daha fazla araç",
    "add_to_favorites": "Favori olarak kaydet",
    "remove_from_favorites": "Sık kullanılanlardan kaldır",
    "share_the_offer": "Paylaşım teklifi",
    "any_question_the_company_team_is_at_your_disposal": "Herhangi bir sorunuz var mı? Şirket} ekibi hizmetinizdedir.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Bu aracı istiyorsunuz ama fiyatı uygun değil mi? Fiyat teklifinizi gönderin.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Bu aracı daha uzun bir süre kullanmak istiyorsunuz, {projeAdı} adresinde hayalinizdeki aracı kiralama olanağına sahipsiniz.",
    "uploaded_video": "Video yükle",
    "lead_autoresponse": "Kurşun otomatik yanıt",
    "automatically_respond_to_incoming_leads": "Gelen potansiyel müşterilere otomatik olarak yanıt verir. Otomatik yanıtlayıcının etkin olduğu haftanın günlerini ve saatlerini tanımlayın.",
    "client_gave_count_answers_to_the_name_questionnaire": "Müşteri \"{isim}\" anketine {say} cevap verdi",
    "new_lead_from_comms_center": "Kapalı iletişimden sonra yeni müşteri adayı",
    "customer_chat_ended": "Bir müşteri ile sohbet sona erdi",
    "chatter_welcome_message": "Çok rica ederim. Sohbetimizi denediğiniz için teşekkür ederiz. Bugün size nasıl yardımcı olabilirim?",
    "please_rate_us": "Lütfen bizi değerlendirin",
    "reservation_fee": "Rezervasyon ücreti",
    "item_name": "Makale adı",
    "deposit": "Peşinat",
    "balance_due": "Ödenmesi gereken kalan tutar",
    "authorize": "Yetkilendirme",
    "authorized_by_name_on_date": "İsim} tarafından {tarih} tarihinde onaylanmıştır.",
    "auto_generated": "Otomatik olarak oluşturulur",
    "not_authorized": "Yetkili değil",
    "settings_instructions": "Ayarlar için talimatlar",
    "payment_config": "Ödeme yapılandırması",
    "projects": "Projeler",
    "invoice_address": "Fatura adresi",
    "deliver_adress": "Teslimat adresi",
    "your_contract_partner": "Sözleşmeli ortağınız",
    "remaining_balance": "Kalan tutar",
    "item_filter_is_active": "Çevrimiçi görünür",
    "item_filter_is_resource": "Kaynak mı",
    "item_filter_is_subscription": "Finansman",
    "cancelled_by_name_on_date": "İsim} tarafından {tarih} tarihinde iptal edildi",
    "add_credit_card_data_in_next_step": "Bir sonraki adımda kredi kartı bilgilerinizi ekleyebilirsiniz",
    "upon_collection": "Koleksiyonda",
    "leads_manually_set_to_high_priority": "Manuel olarak yüksek önceliğe ayarlanmış müşteri adayları",
    "leads_with_no_reaction_for_2_hours_since_creation": "Oluşturulduktan sonra 2 saat boyunca yanıt verilmeyen müşteri adayları",
    "leads_that_were_not_updated_for_2_days": "İki gündür güncellenmemiş müşteri adayları",
    "use_this_element_as_reference": "Bu öğeyi referans olarak kullanın",
    "you_can_reuse_this_element_on_multiple_pages": "Birden fazla web sitesinde yeniden kullanılan içeriğe referans verilebilir. Express Page Builder için öğeler referans olarak tanımlanamaz. Bu tür öğeler her zaman kopyalanır",
    "company_data": "Şirket verileri",
    "company_singup_company_data_hint": "Şirketinizi oluşturabilmemiz ve size bir alan adı atayabilmemiz için aşağıdaki bilgilere ihtiyacımız var.",
    "company_singup_personal_data_hint": "<p>Kendi kullanıcı verilerinizle erişiminizi alabilmeniz için aşağıdaki bilgilere ihtiyacımız var:<br/>Daha sonra kişisel erişim verilerinizi içeren bir e-posta alacaksınız.</p>",
    "your_test_domaine": "Test alan adınız",
    "your_test_domaine_hint": "<p>Lütfen projenizin barındırılacağı bir alan adı oluşturun. Alan adı kayıttan hemen sonra kullanılabilir olacaktır.<br/>Projeleri sizin belirlediğiniz bir alan adı altında da barındırabileceğimizi unutmayın. Bizimle daha sonra iletişime geçmeniz yeterlidir.</p>",
    "features": "Özellikler",
    "forms": "Formlar",
    "pricings": "Fiyatlar",
    "testimonials": "Referanslar",
    "show_your_page_structure_and_responsive_visibility": "Sayfa yapınızı etiketlerle ve duyarlı görünürlükle görüntüler",
    "toggle_edit_mode_for_page_preview": "Sayfa önizlemesi için düzenleme modunu değiştirme",
    "toggle_animation_and_parallax_effects": "Animasyon ve paralaks efektlerini etkinleştirme ve devre dışı bırakma",
    "use_quick_page_builder_to_create_content_faster": "Çok daha hızlı içerik oluşturmak için Hızlı Sayfa Oluşturucu'yu kullanın",
    "cancel_invoice": "Faturayı iptal et",
    "refunded": "İade Edildi",
    "invoice_mark_refunded": "Faturayı iade edilebilir hale getirin",
    "denied_to_pay": "Ödemeyi reddetme",
    "invoice_mark_denied_to_pay": "Ödeme yapmayacağım",
    "registration_success": "Başarıyla kaydedildi",
    "no_images_yet": "Henüz fotoğraf yok.",
    "sortingPrice": "Sıralama fiyatı",
    "main_menu_version": "Ana menü sürümü",
    "main_menu_versions": "Ana menü sürümleri",
    "administration_of_main_menu_versions": "Ana menü sürümlerinin yönetimi",
    "featured": "Vurgulandı",
    "list_element_class": "Liste öğesi (sol) CSS sınıfı",
    "link_class": "Bağlantı CSS sınıfı",
    "width": "Genişlik",
    "primary_menu_elements": "Ana menü öğeleri",
    "secondary_menu_elements": "İkincil menü unsurları",
    "has_secondary_elements": "İkincil unsurlara sahiptir",
    "active": "aktif",
    "company_singup_wizard_step_subscription_plan": "Paketleri seçin",
    "company_singup_wizard_step_subscription_plan_headline": "Test etmek istediğiniz paketi seçin.",
    "company_singup_wizard_step_additional_plugins": "Ek paketler",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Aşağıdaki özellikleri test etmek istiyorum:",
    "company_singup_wizard_step_company_data": "Şirketinizin iletişim bilgileri",
    "company_singup_wizard_step_company_data_headline": "Şirketinizin adı ve iletişim bilgileri",
    "company_singup_wizard_step_personal_data": "Çalışan profiliniz",
    "company_singup_wizard_step_personal_data_headline": "Çalışan profiliniz",
    "company_singup_wizard_step_personal_data_hint": "Sizin için bir çalışan profili oluşturabilmemiz için kişisel bilgilerinize ihtiyacımız var. Bu hesap size %platformName% projeniz için yönetim hakları verir.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Lütfen projenizin barındırılacağı bir alan adı oluşturun. Alan adı kayıttan hemen sonra kullanılabilir olacaktır.<br/>Projeleri sizin belirlediğiniz bir alan adı altında da barındırabileceğimizi unutmayın. Bizimle daha sonra iletişime geçmeniz yeterli.</p>",
    "company_singup_wizard_free_trial_hint": "Tekliflerimizi bir ay boyunca ücretsiz ve yükümlülük altına girmeden test edin.",
    "company_signup_wizard_free_trial_plugin_hint": "O zaman bir ay ücretsiz test edin:",
    "included_in_subscription_plan": "Aboneliğe dahildir, aksi takdirde",
    "mostly_asked": "En sık sorulan sorular",
    "test_package": "Test paketi",
    "active_since": "beri aktif",
    "test_period_until": "Test aşamasına kadar",
    "navigate_to_plugins_and_configure": "Eklentilere genel bakış ve ayarlar için",
    "termination_date": "Fesih tarihi",
    "lead_taggers": "Lider Etiketleyiciler",
    "lead_tagger": "Lider Etiketleyici",
    "share_with": "İle paylaş",
    "password_weak": "Zayıf",
    "password_medium": "Orta",
    "password_strong": "Güçlü",
    "plugin_already_added_in_whitelabel_project": "Eklenti zaten beyaz etiket projesine atanmıştır.",
    "lead_channel": "Kanal",
    "lead_channel_web": "Web",
    "lead_channel_mobile": "Uygulama",
    "lead_channel_phone": "Telefon",
    "lead_channel_email": "E-posta",
    "lead_channel_personally": "Kişisel",
    "allow_others_to_share": "Başkalarının daha fazla kişiyle paylaşmasına izin verin",
    "additional_imprint_information": "Ek baskı bilgileri",
    "share_with_the_company_i_work_for": "Çalıştığım şirketle şunları paylaşıyorum",
    "share_with_all_companies_in_my_holding_structure": "Holding yapımdaki tüm şirketlerle birlikte, hisse",
    "latest_lead": "Son kurşun",
    "latest_edit": "Son düzenleme",
    "reaction_time": "Yanıt süresi",
    "no_reaction_time": "Tepki yok",
    "gt_2_days": "> 2 gün",
    "lt_5_minutes": "< 5 dakika",
    "lt_1_hour": "< 1 saat",
    "lt_2_hours": "< 2 saat",
    "lt_6_hours": "< 6 saat",
    "lt_1_day": "< 1 gün",
    "lt_2_days": "< 2 gün",
    "by_employee": "Çalışan tarafından",
    "by_company": "Şirket tarafından",
    "by_location": "Konuma göre",
    "by_source": "Kaynağa göre",
    "client_data": "Müşteri verileri",
    "show_in_lead_description": "Müşteri adayı açıklamasında göster",
    "this_answer_will_be_shown_in_lead_description": "Bu yanıt müşteri adayı açıklamasında görüntülenir",
    "this_answer_will_not_be_shown_in_lead_description": "Bu yanıt müşteri adayı açıklamasında görüntülenmez",
    "lead_assignment_strategy": "Lider atama stratejisi",
    "lead_assignment_strategies": "Lider atama stratejileri",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Çalışanlarınızın organizasyonlarına ve boru hatlarına göre potansiyel müşteri atama şeklini değiştirin.",
    "strategy": "Strateji",
    "deleted": "Silinmiş",
    "assign_to_me": "Bana atayın",
    "responsibilities": "Sorumluluklar",
    "escalation_rules": "Eskalasyon kuralları",
    "escalation_rule": "Eskalasyon kuralı",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Ekiplerinizin terk edilmiş veya ekip üyelerinin yanıt vermediği müşteri adaylarını belirlemek için kullanabileceği kuralları tanımlar.",
    "category_images": "Kategori resimleri",
    "use_document_template": "Belge şablonunu kullanma",
    "escalation_level_one": "Birinci aşama - çalışanları bilgilendirin",
    "escalation_level_two": "İkinci adım - ekip liderini bilgilendirin",
    "automatically_terminate_the_lead": "İpucunu otomatik olarak sonlandırın (ipucunu kayıp olarak işaretleyin)",
    "no_reaction": "Tepki yok",
    "no_reaction_for": "Tepki yok",
    "no_update": "Güncelleme yok",
    "no_update_for": "Güncelleme yok",
    "escalation_level": "Eskalasyon seviyesi",
    "time_settings": "Zaman ayarları",
    "pending": "Öne çıkanlar",
    "skipped": "Atlandı",
    "holding_structure": "Holding yapısı",
    "show_sibling_companies": "Kardeş şirketler gösteriyor",
    "show_descendant_companies": "Bağlı şirketleri göster",
    "subscription_type": "Finansman modeli",
    "no_subscription_type_selected": "En az bir finansman modeli seçilmelidir.",
    "financing": "Finansman",
    "monthly_payment": "Aylık taksit",
    "final_payment": "Son taksit",
    "interest_rate": "Faiz oranı",
    "availability": "Kullanılabilirlik",
    "delivery_time_month": "Teslimat süresi (ay olarak)",
    "delivery_time": "Teslimat süresi",
    "cancelation_period_month": "İptal süresi (ay olarak)",
    "enter_financing_data": "Finansman verilerini girin",
    "marketing_agency": "Pazarlama ajansı",
    "edit_company_read_and_write_rights": "Şirketin okuma ve yazma izinlerini düzenleme",
    "grant_data_access": "Veri erişimi sağlama",
    "deny_data_access": "Veri erişimini reddetme",
    "allow_data_access_for_a_single_company": "Tek bir şirket için veri erişimini etkinleştirin",
    "allow_data_access_for_a_whole_level": "Tüm bir seviye için veri erişimini etkinleştirin",
    "deny_data_access_for_a_single_company": "Tek bir şirket için veri erişimini reddetme",
    "deny_data_access_for_a_whole_level": "Tüm bir seviye için veri erişimini reddetme",
    "closing_costs": "Kapanış ücreti",
    "nominal_interest_rate": "Hedef faiz oranı",
    "has_rate_insurance": "Ödeme koruması",
    "is_user_based": "Kullanıcı tabanlıdır",
    "min_user_count": "Min kullanıcı sayısı",
    "max_user_count": "Maksimum kullanıcı sayısı",
    "price_per_user": "Kullanıcı başına fiyat",
    "monthly": "Aylık",
    "instantly": "Derhal",
    "quarterly": "Üç Aylık",
    "annual": "Yıllık",
    "monthly_fee": "Aylık ücret",
    "starting_fee": "Giriş ücreti",
    "included_templates_count": "Dahil edilen şablon sayısı",
    "simple_templates_count": "Dahil edilen şablon sayısı (tek)",
    "middle_templates_count": "Dahil edilen şablon sayısı (ortalama)",
    "complex_templates_count": "Dahil edilen şablon sayısı (Karmaşık)",
    "surcharge": "Ek ücret",
    "base_surcharge": "Temel ek ücret",
    "max_surcharge": "Maksimum ek ücret",
    "dataproof_surcharge": "Dataproof ek ücreti",
    "aws_domain_hosting": "AWS üzerinden alan adı barındırma",
    "domain_hosting": "Alan adı dahil web sitesi barındırma",
    "desired_domain": "İstenen alan",
    "redirect_uri": "Yönlendirme",
    "check_availability": "Müsaitliği kontrol edin",
    "cart_edition_title": "Atama:",
    "cart_recent_edition": "Son düzenleme:",
    "cart_campaign_title": "Kampanya:",
    "cart_last_saved": "Son kaydedilen:",
    "remove_item": "Ürünü çıkarın",
    "config": "Yapılandırma",
    "submit_config": "Yapılandırmayı onayla",
    "complete_order": "Siparişi kesinleştirin",
    "payment_method_description": "Lütfen istediğiniz ödeme yöntemini seçin",
    "cart_hint": "Not:",
    "cart_hint_description_part_1": "Lütfen buraya sadece genel yorumları girin. Ürünlerle ilgili yorumlar yalnızca şu seçeneği seçtiyseniz dikkate alınabilir",
    "cart_hint_description_part_2": "Manuel veri kontrolü",
    "cart_hint_description_part_3": "ve ilgili ürün için yorum girdiniz.",
    "your_message": "Bize mesajınız",
    "hint_dataproof_1": "Bir veya daha fazla kalem için uzmanımız tarafından manuel veri kontrolünden feragat ettiniz.",
    "hint_dataproof_2": "Bu durumda kendi tasarımınızın tüm sorumluluğunun size ait olduğunu belirtmek isteriz. Veri hataları, fotoğraflardaki yanlış renkler vb. durumlarda şikayet söz konusu değildir. Genel hüküm ve koşullarımız geçerlidir.",
    "hint_dataproof_3": "Manuel veri kontrolünü seçerseniz, dosyanız bir uzman tarafından herhangi bir hataya karşı kontrol edilecektir (teknik kontrol, yazım yok). Bir hatayı gözden kaçırmamız durumunda, sorumluluğu üstleniriz. Sizin için risk yok!",
    "hint_missing_order_quantity": "Sipariş miktarı eksik!",
    "missing_order_quantity": "Bir veya daha fazla ürün için sipariş miktarı seçmediniz.",
    "missing_invoice_shipping_address": "Fatura veya teslimat adresi eksik. Lütfen bir adres seçiniz.",
    "billing_address_form_is_not_submitted": "Fatura adresi gönderilmemiştir. Lütfen formu doldurunuz.",
    "shipping_address_form_is_not_submitted": "Teslimat adresi gönderilmedi. Lütfen formu doldurunuz.",
    "no_invoice_address": "Fatura adresi oluşturulmadı",
    "no_delivery_address": "Teslimat adresi oluşturulmadı",
    "use_invoice_contact": "Fatura iletişimini kullanın",
    "order_item": "Makale",
    "order_price": "Fiyat",
    "order_copies": "Baskı",
    "order_totals": "Sipariş toplamları",
    "subtotal": "Ara Toplam",
    "invoice_amount": "Fatura tutarı",
    "remainder_available": "Dağıtılmamış mevcut kalan miktar",
    "reduce_edition": "Yeni bir adres eklemek için öncelikle mevcut adresin kopya sayısını azaltmanız gerekir.",
    "split_delivery_item_hint": "Toplam miktarın dağılımı henüz kesinleşmemiştir. Miktar spesifikasyonu 1 ile dağıtılacak kalan miktar veya baskı adedi arasında bir değer olmalıdır; şu anda maksimum değer",
    "net_amount": "Net tutar",
    "gross_amount": "Brüt tutar",
    "is_debtor": "Alacaklı",
    "is_debtor_number_provider": "Borçlu numarası Sağlayıcı",
    "is_never_remind": "Hatırlatma göndermeyin",
    "is_demo": "Demo modu",
    "is_vendor": "Tedarikçi",
    "should_user_pay": "Kullanıcı ödeme yapmalıdır",
    "receive_payment_from_users": "Kullanıcılardan ödeme alma",
    "use_payment_receiver": "Alacaklı seçeneğini kullanın",
    "payment_receiver": "Alacaklı",
    "use_summary_invoice": "Toplu fatura kullanın",
    "credit_limit": "Kredi limiti",
    "commission_receiver": "Komisyon alıcısı",
    "budget_value": "Bütçe değeri",
    "billing_value": "Uzlaşma değeri",
    "coupon_billing": "Seçilen fatura dönemi için fatura değeri",
    "coupon_billing_period": "Fatura dönemi",
    "coupon_recharge_period": "Yükleme çeki",
    "coupon_print_type": "Kuponu kısıtla",
    "coupon_never": "Asla",
    "coupon_unset": "Kısıtlama yok",
    "coupon_print_pdf": "Yalnızca PDF yazdır",
    "recurring": "Yinelenen",
    "period": "Dönem",
    "without_end_date": "Bitiş tarihi olmadan",
    "budget_settings": "Bütçe ayarları",
    "no_budget_set": "Belirlenmiş bütçe yok",
    "current_period_budget_usage": "Cari dönemde bütçe kullanımı",
    "user_entered_no_message": "Kullanıcı bir mesaj girmedi",
    "coupons": "Kuponlar",
    "coupon": "Kupon",
    "create_discounts_and_subsidy_products": "Ortaklarınız için indirim kodları oluşturun ve ürünleri sübvanse edin",
    "authorization_status": "Yetkilendirme durumu",
    "user_budget": "Kullanıcı bütçesi",
    "manual_vat_check": "İndirimli KDV oranı kontrolü",
    "manual_data_check": "Manuel veri kontrolü",
    "completed": "Tamamlandı",
    "changes_requested": "Talep edilen değişiklikler",
    "rejected": "Reddedildi",
    "request_changes": "Değişiklik talep edin",
    "reply": "Cevaplar",
    "coupon_monetary": "Sabit indirim",
    "coupon_percentage": "Yüzde indirim",
    "calculations": "Hesaplamalar",
    "percent_discount_value": "Yüzde indirim",
    "monetary_discount_value": "Nakit indirimi",
    "company_budget": "Şirket bütçesi",
    "enter_0_to_ignore_this_setting": "Bu ayarı yok saymak için 0 girin",
    "min_order_value": "Minimum sipariş değeri",
    "max_order_discount": "Sipariş başına maksimum indirim",
    "coupon_budget_exceeded": "Kupon bütçesi aşıldı",
    "billing_target": "Faturalama hedefi",
    "billing_settings": "Faturalama ayarları",
    "dataproof_textarea": "Düşünmemiz gereken bir şey var mı?",
    "edit": "Düzenle",
    "edit_client": "Müşteri verilerini düzenleme",
    "edit_gallery": "Galeri ayrıntılarını düzenle",
    "edit_job": "İş ayrıntılarını düzenleme",
    "edit_position": "Pozisyonu düzenle",
    "edit_product": "Ürün düzenleme",
    "edit_producttemplate": "Şablonu düzenle",
    "edit_question": "Soruyu düzenle",
    "edit_template": "Şablonu düzenle",
    "edit_step": "Boru hattı aşamasını düzenleme",
    "edit_payment": "Ödemeyi düzenle",
    "edit_team": "Düzenleme ekibi",
    "edit_document_type": "Belge türünü düzenle",
    "edit_brand": "Markayı düzenle",
    "edit_model": "Modeli düzenle",
    "edit_category": "Kategori düzenle",
    "edit_attribute": "Özniteliği düzenle",
    "edit_data_push": "Veri itmeyi düzenleme",
    "edit_lead_catcher": "Baş yakalayıcıyı düzenle",
    "edit_external_account": "Harici hesabı düzenleme",
    "edit_comment": "Yorumu düzenle",
    "edit_delivery_date": "Teslimat tarihini düzenle",
    "edit_lead_tagger": "Lider etiketleyiciyi düzenle",
    "edit_commission_receiver": "Komisyon alıcısını düzenleme",
    "edit_payment_receiver": "Alacaklıyı düzenle",
    "edit_billing_target": "Faturalama hedefini düzenleme",
    "create_client": "Yeni şirket oluşturun",
    "create_gallery": "Yeni galeri oluştur",
    "create_job": "Yeni iş oluşturun",
    "create_product": "Yeni ürün oluşturma",
    "create_producttemplate": "Yeni şablon oluşturun",
    "create_questionnaire": "Yeni bir anket oluşturun",
    "create_question": "Yeni soru oluşturun",
    "create_template": "Yeni e-posta şablonu oluşturun",
    "create_cms_template": "Yeni CMS şablonu oluşturun",
    "create_pipeline": "Yeni boru hattı oluşturun",
    "create_step": "Yeni boru hattı aşaması oluşturun",
    "create_payment_schedule": "Yeni bir ödeme planı oluşturun",
    "create_payment": "Yeni ödeme oluşturun",
    "create_message": "Bir mesaj yazın",
    "create_text_message": "Kısa mesaj (SMS) yazın",
    "create_note": "Bir not yazın",
    "create_user": "Yeni kullanıcı oluştur",
    "create_users": "Kullanıcı oluştur",
    "create_doc_type": "Yeni belge türü oluşturma",
    "create_team": "Ekip oluşturun",
    "create_brand": "Marka yaratın",
    "create_model": "Model oluşturun",
    "create_category": "Kategori oluştur",
    "create_attribute": "Öznitelik oluştur",
    "create_attribute_group": "Öznitelik grubu oluşturma",
    "create_car": "Araç oluşturma",
    "create_item": "Ürün oluşturun",
    "create_lead": "Kurşun oluşturun",
    "assign_lead": "Lider atayın",
    "create_page": "Sayfa oluştur",
    "create_data_push": "Yeni veri itme oluşturun",
    "create_white_label_project": "Yeni bir beyaz etiket projesi oluşturun",
    "create_cms_element": "Yeni CMS öğesi oluşturun",
    "create_lead_catcher": "Yeni bir kurşun yakalayıcı oluşturun",
    "create_white_label_plugin": "Yeni beyaz etiket eklentisi oluşturun",
    "create_dynamic_data_definition": "Yeni bir dinamik veri tanımı oluşturma",
    "add_account": "Hesap ekle",
    "create_external_account": "Yeni harici hesap ekleme",
    "create_model_group": "Yeni model grubu oluşturma",
    "create_model_asset": "Yeni model görüntüsü oluşturma",
    "create_lead_per_mail_rule": "E-posta kuralı aracılığıyla yeni müşteri adayı oluşturma",
    "duplicate_page": "Yinelenen sayfa",
    "duplicate_cms_element": "Yinelenen CMS öğesi",
    "duplicate_cms_template": "Yinelenen CMS şablonu",
    "create_task": "Görev oluştur",
    "create_car_service": "Hizmet oluşturun",
    "create_car_service_station": "İş istasyonu oluşturun",
    "create_doc_template": "Yeni belge şablonu oluşturma",
    "create_lead_autoresponse": "Yeni müşteri adayı otomatik yanıtı oluşturun",
    "create_main_menu_version": "Ana menü sürümü oluştur",
    "create_lead_tagger": "Kurşun etiketleyici oluşturun",
    "create_lead_assignment_strategy": "Müşteri adayı atama stratejisi oluşturun",
    "create_escalation_rule": "Yükseltme kuralı oluşturma",
    "create_coupon": "Kupon oluştur",
    "client_data_saved": "Saklanan müşteri verileri",
    "user_data_saved": "Kullanıcı verileri kaydedildi",
    "job_data_saved": "İş verileri kaydedildi",
    "product_data_saved": "Ürün verileri kaydedildi",
    "template_data_saved": "Şablon verileri kaydedildi",
    "company_logo_saved": "Logo kaydedildi",
    "payment_schedule_data_saved": "Ödeme planı kaydedildi",
    "questionnaire_filled_out": "Tamamlanmış anket",
    "your_message_has_been_sent": "Mesajınız gönderildi",
    "email_with_password_reset_instructions_sent": "Şifre sıfırlama talimatlarını içeren e-posta gönderildi",
    "password_changed_after_reset": "Şifreniz değiştirildi. Tekrar hoş geldiniz",
    "quote_accepted_saved": "Teklif kabul edildi",
    "quote_rejected_saved": "Teklif reddedildi",
    "quote_saved": "Teklif kaydedildi",
    "message_sent": "Mesaj gönderildi",
    "note_saved": "Not kaydedildi",
    "holding_info_saved": "Saklanan bilgilerin tutulması",
    "new_password_set": "Yeni bir şifre belirlendi",
    "questionnaire_data_saved": "Anket kaydedildi",
    "doc_type_saved": "Kaydedilen belge türü",
    "team_saved": "Takım kurtarıldı",
    "document_saved": "Belge kaydedildi",
    "brand_saved": "Marka kaydedildi",
    "model_saved": "Model kaydedildi",
    "category_saved": "Kategori kaydedildi",
    "attribute_saved": "Kaydedilen öznitelik",
    "item_data_saved": "Ürün verileri kaydedildi",
    "car_data_saved": "Depolanan araç verileri",
    "variant_saved": "Varyant kaydedildi",
    "pipeline_saved": "Boru hattı kurtarıldı",
    "comment_saved": "Yorum kaydedildi",
    "lead_saved": "Kurşun kaydedildi",
    "order_saved": "Sipariş kaydedildi",
    "document_reviewed": "Belge gözden geçirildi",
    "locations_saved": "Kaydedilen konumlar",
    "email_confirmed": "E-posta onaylandı",
    "page_saved": "Sayfa kaydedildi",
    "template_saved": "Şablon kaydedildi",
    "data_push_saved": "Veri itme kaydedildi",
    "white_label_saved": "Beyaz etiket projesi kaydedildi",
    "attribute_group_saved": "Öznitelik grubu kaydedildi",
    "cms_element_saved": "CMS öğesi kaydedildi",
    "lead_catcher_saved": "Lider yakalayıcı kurtarıldı",
    "image_data_saved": "Görüntü verileri kaydedildi",
    "white_label_plugin_saved": "Beyaz etiket eklentisi kaydedildi",
    "dynamic_data_definition_saved": "Dinamik veri tanımı kaydedildi",
    "company_data_saved": "Saklanan şirket verileri",
    "contact_data_saved": "İletişim bilgileri kaydedildi",
    "no_changes_made": "Değişiklik yapılmadı",
    "car_order_approved": "Araba satışına izin verildi",
    "vin_number_updated": "Güncellenen aracın şasi numarası",
    "ein_number_updated": "Elektronik sigorta onayı güncellendi",
    "order_marked_as_paid": "Ödenmiş olarak işaretlenen sipariş",
    "payment_instructions_sent": "Ödeme talimatları gönderildi",
    "order_cancelled": "Sipariş iptal edildi",
    "sales_contract_uploaded": "Yüklenen satış sözleşmesi",
    "bank_data_updated": "Banka bilgileri güncellendi",
    "pickup_invite_sent": "Teslim alma davetiyesi gönderildi",
    "order_picked_up": "Sipariş alındı",
    "order_assignee_kroschke_documents_sent": "Araç satıcısı, aracı kaydettirmek için gerekli belgeleri gönderdi",
    "order_client_kroschke_documents_sent": "Müşteri, aracının tescili için gerekli belgeleri göndermiştir",
    "plugin_partial_activated": "Eklenti kısmen etkinleştirildi",
    "plugin_request_activate_sent": "Eklenti etkinleştirme isteği gönderildi",
    "plugin_activated": "Eklenti etkinleştirildi",
    "plugin_terminated": "Eklenti sonlandırıldı",
    "changes_saved": "Kaydedilen değişiklikler",
    "app_secret_saved": "Uygulama sırrı kaydedildi",
    "page_access_token_saved": "Sayfa Erişim Simgesi kaydedildi",
    "text_message_sent": "SMS gönderildi",
    "account_saved": "Hesap kaydedildi",
    "model_group_saved": "Model grubu kaydedildi",
    "model_asset_saved": "Model görüntüsü kaydedildi",
    "sepa_agreement_saved": "SEPA yetkisi kurtarıldı",
    "document_uploaded": "Bir belge yüklendi",
    "customer_sent_message": "Müşteri bir mesaj gönderdi",
    "lead_created": "Kurşun oluşturuldu",
    "lead_watching": "Uçların gözlemlenmesi ile başladı",
    "lead_unwatching": "Müşteri adaylarının izlenmesini sonlandırır",
    "lead_per_mail_rule_saved": "E-posta kuralı ile kaydedilen müşteri adayı",
    "drivers_license_uploaded": "Yüklenen ehliyet",
    "plugin_saved": "Eklenti kaydedildi",
    "settings_saved": "Ayarlar kaydedildi",
    "page_duplicated": "Sayfa çoğaltıldı",
    "cms_template_duplicated": "CMS şablonu çoğaltıldı",
    "cms_element_duplicated": "CMS öğesi çoğaltıldı",
    "price_negotiation_started": "Fiyat pazarlığı başladı",
    "new_price_negotiation_message_offer_sent": "Yeni fiyat pazarlığı teklifi gönderildi",
    "you_joined_community": "Topluluğa katıldınız",
    "you_left_community": "Topluluğu terk ettiniz",
    "car_service_booked": "Online randevu rezervasyonu",
    "car_service_saved": "Hizmet kaydedildi",
    "car_service_station_saved": "İş istasyonu kaydedildi",
    "team_member_added": "Ekip üyesi eklendi",
    "team_member_removed": "Ekip üyesi çıkarıldı",
    "doc_template_saved": "Belge şablonu kaydedildi",
    "invoice_issued": "Fatura kesildi",
    "request_to_fill_out_questionnaire_saved": "Kaydedilen bir anketi doldurma talebi",
    "request_to_fill_out_questionnaire_name_saved": "Bir anket doldurma talebi \"{name}\" kaydedildi",
    "lead_autoresponse_saved": "Kurşun otomatik yanıt kaydedildi",
    "main_menu_version_saved": "Ana menü sürümü kaydedildi",
    "lead_tagger_saved": "Lider Etiketleyici kaydedildi",
    "share_settings_updated": "Sürüm ayarları güncellendi",
    "lead_assignment_strategy_saved": "Kurşun tahsis stratejisi kaydedildi",
    "escalation_rule_saved": "Eskalasyon kuralı kaydedildi",
    "value_type": "Tip",
    "value_precision": "Doğruluk",
    "category_already_in_attribute": "Kategori zaten niteliğe atanmıştır.",
    "no_attributes_for_variants": "Varyantlar oluşturmak için öznitelikleri tanımlamanız gerekir.",
    "budget_saved": "Bütçe tasarrufu",
    "budget_removed": "Bütçe kaldırıldı",
    "coupon_saved": "Kaydedilen kupon",
    "authorization_data_saved": "Saklanan yetkilendirme verileri",
    "template_data": "Genel bilgi",
    "template_pages": "Sayfa ayarları",
    "template_editor": "Editör ve PDF ayarları",
    "template_timeline": "Zaman Çizelgesi",
    "template_blocks": "İçerik blokları",
    "template_import": "İthalat",
    "template_export": "İhracat",
    "template_generate": "Jeneratör (TEST)",
    "template_print_data": "Veri yazdırma",
    "template_import_select_template": "Şablon klasörünü seçin",
    "template_import_select_print_data_document": "Veri yazdırma belgesini seçin",
    "template_upload_print_data_title": "Yazdırma verilerini şablon dizinine yükleyin",
    "template_upload_print_data_actual_file_title": "Yüklenen baskı verileri",
    "template_upload_print_data_info": "S3 şablon dizinine bir dosya ekler. Bu dosya, ürünler oluşturulduğunda ve hiçbir yazdırma verisi oluşturulmadığında kullanılır.",
    "template_blocks_template": "HTML bloğu",
    "template_category_thumb": "Küçük resim simge sınıfı (örneğin: fad fa-circle)",
    "add_block_category": "Yeni kategori",
    "edit_block_category": "Kategori düzenle",
    "add_block_variant": "Yeni varyant",
    "edit_block_variant": "Varyantı düzenle",
    "add_block": "Yeni blok",
    "copy_block": "Kopya bloğu",
    "edit_block": "Düzenleme bloğu",
    "edit_takeit": "Devralmak",
    "edit_import": "İthalat",
    "edit_import_warning": "Emin misiniz? Bu, kaydedilmemiş tüm girdilerin üzerine yazacaktır!",
    "template_has_content_blocks": "Değişken içerik",
    "template_in_test": "Siparişler için test modu",
    "placeholder": "Adres mektup birleştirme için yer tutucu",
    "select_customer": "Seçkin müşteriler",
    "company_added": "Şirket ekledi",
    "company_removed": "Şirket kaldırıldı",
    "item_added": "Ürün eklendi",
    "item_removed": "Ürün kaldırıldı",
    "category_added": "Kategori eklendi",
    "category_removed": "Kategori kaldırıldı",
    "select_user_set": "Kullanıcı yetkilendirmesini seçin",
    "sent_reminders": "Gönderilen anılar",
    "number_of_sent_reminders": "Gönderilen hatırlatma sayısı",
    "set_number": "Set numarası",
    "email_to_the_customer_on_save": "Kaydederken müşteriye e-posta ile gönderin",
    "email_on_save": "Kaydederken e-posta",
    "shown_name": "Görünen ad",
    "filename_thumbnail": "Küçük resim için dosya adı",
    "name_of_imagefile": "Dizin olmadan görüntü dosyasının adı. Örnek: thumb1.jpg",
    "block_position": "Bloğun ızgara çizgilerindeki konumu, üst için 0, bloğu mutlak yapar",
    "defined_raster_space": "Önceden tanımlanmış ızgara konumu",
    "needed_raster_space": "Gerekli ızgara alanı",
    "size_group_info_text": "Aynı SizeGroup'a sahip tüm bloklar düzenleyicide boyut olarak değiştirilebilir",
    "size_group": "Boyut Grubu",
    "group_index_info_text": "Aynı SizeGroup ve aynı blok boyutuna sahip tüm bloklar indeks ile değiştirilebilir. Dizin 1'den itibaren.",
    "group_index": "Grup Endeksi",
    "bottom_info_text": "DİKKAT: alt, PX'te ayarlanan mesafe ile bloğu kesinlikle sayfanın altına konumlandırır",
    "bottom_label": "Bloğu kesinlikle en alta konumlandırın",
    "fixed_block_label": "Sabit blok",
    "fixed_block_info_text": " Blok hareket ettirilemez",
    "once_per_document_label": "Belge başına bir kez",
    "once_per_document_info_text": "tüm belgede yalnızca bir kez izin verilir",
    "no_copies_label": "Blok kopyalanamaz",
    "no_copies_info_text": "Blok kopyalanamaz",
    "no_options_label": "Herhangi bir seçenek gösterme",
    "no_options_info_text": "Blok eklendiğinde düzenleyicide hiçbir seçeneği yoktur.",
    "once_per_page_label": "Sayfa başına bir kez",
    "once_per_page_info_text": "sadece bir tarafta bir kez izin verilir",
    "once_per_variant_label": "Varyant başına bir kez",
    "once_per_variant_info_text": "Varyant başına yalnızca bir kez izin verilir",
    "once_per_category_label": "Kategori başına bir kez",
    "once_per_category_info_text": "kategori başına sadece bir tane izin verilir",
    "only_whitelabel_admin_can_modify_label": "Yalnızca beyaz etiket yöneticisi",
    "only_whitelabel_admin_can_modify_info_text": "Bu alan yalnızca beyaz etiket yöneticisi tarafından değiştirilebilir. Beyaz etiket yöneticisi bir şablon oluşturduğunda kullanılmalıdır.",
    "restricted_to_page_label": "İzin verilen sayfa",
    "restricted_to_page_info_text": "Yalnızca bu sayfada kullanılabilir, -1 = her yerde izin verilir",
    "exclude_pages_label": "Sayfaları hariç tut (örneğin: 1,2,3...)",
    "exclude_pages_info_text": "Bu sayfalarda izin verilmez, virgülle ayırın",
    "only_last_label": "Yalnızca son sayfa",
    "only_last_info_text": "Blok yalnızca son sayfaya eklenebilir.",
    "container_block_label": "Konteyner bloğu",
    "container_block_info_text": "Bu blok diğer bloklar için bir konteyner içerir",
    "inline_block_label": "Satır içi blok",
    "inline_block_info_text": "Blok, display:inline-block olarak değerlendirilir",
    "inner_label": "Blok konteyner içine yerleştirilebilir",
    "inner_info_text": "Blok, blok konteynerine yerleştirilebilir",
    "container_class_label": "Konteyner bloğu sınırı",
    "container_class_info_text": "Bloğun yerleştirilebileceği konteynerin sınıf adı.",
    "only_inside_block_label": "Sadece blok konteynerlere yerleştirilebilir",
    "only_inside_block_info_text": "Blok yalnızca blok konteynerlerine yerleştirilebilir",
    "container_space_label": "Konteyner bloğundaki ızgara boşlukları",
    "container_space_info_text": "Blok içinde kullanılabilir ızgara çizgileri (Sayfa ile aynı ızgara!)",
    "container_cols_label": "Konteyner bloğundaki sütunlar",
    "container_cols_info_text": "mevcut ızgara çizgileri bu değerle çarpılır",
    "website_only": "Yalnızca web siteleri için",
    "navName": "Navigasyon için isim",
    "nav_link_label": "Navigasyon için bağlantı",
    "nav_link_info_text": "Bloğun kimliğine bağlantı",
    "nav_options": "Navigasyon için seçenekler",
    "combi_parent_label": "Kombinasyon elemanı (üst)",
    "combi_parent_info_text": "Ana eleman, herhangi bir zamanda eklenebilir",
    "combi_child_label": "Kombinasyon elemanı (Çocuk)",
    "combi_child_info_text": "Alt öğeler yalnızca üst öğe ile birlikte ayarlanabilir",
    "disable_group_label": "Grup seçenekleri",
    "disable_group_info_text": "Burada birbirini dışlayan gruplar tanımlayabilirsiniz, örneğin P#Grup1,Grup2 ve D#Grup2,Grup1. Tanım parametresi - P = sayfa için geçerli, D = belge için geçerli.",
    "events": {
      "TimelineMailPasswordReset": "Müşteri şifre değişikliği talebinde bulundu",
      "TimelineMailRegistration": "Müşteri Creacheck.com'a kayıt oldu",
      "TimelineMailOauthRegistration": "Müşteri Creacheck.com'a sosyal medya girişi ile kayıt oldu",
      "TimelineMailInvitation": "Müşteri Creacheck.com'u kullanmaya davet edildi",
      "TimelineMailContactForm": "Müşteri iletişim formu aracılığıyla bir mesaj gönderdi",
      "TimelineMailInvitationAccepted": "Müşteri daveti kabul etti",
      "TimelineMailNewInvoice": "Müşteriye bir fatura düzenlediniz",
      "TimelineMailNewQuote": "Müşteriye bir teklif gönderdiniz",
      "TimelineMailNewMessage": "Müşteriye bir mesaj gönderdiniz",
      "TimelineQuoteAccepted": "Müşteri teklifinizi kabul etti",
      "TimelineQuoteRejected": "Maalesef müşteri teklifinizi reddetti",
      "TimelineOrderCreated": "Yeni sipariş",
      "TimelineMailNewCustomerMessage": "Müşteriden gelen e-posta mesajı",
      "TimelineMonitoringPaymentReceipt": "Gelen ödemeleri izleyin",
      "TimelineClosingManualDataCheck": "Manuel veri kontrolü kapatıldı",
      "TimelineUpdatedByForeignStatus": "Sipariş durumu güncellendi",
      "TimelineMailedForeignOrderStatusOrderCanceled": "tedarikçi tarafından iptal edildi",
      "TimelineMailedForeignOrderStatusInDelivery": "teslimatta",
      "TimelineMailedForeignOrderStatusInProduction": "üretimde",
      "TimelineMailedForeignOrderStatusInReview": "istişare içinde",
      "TimelineMailedForeignOrderStatusInOrder": "sırayla",
      "TimelineMailedOrderedWebAndDownloadPDF": "E-posta alıcılarına gönderilen web özellikli ve indirilebilir PDF.",
      "TimelineMailedOrderedDownloadPDF": "E-posta alıcılarına gönderilen PDF'yi indirin.",
      "TimelineMailedOrderedWebPDF": "E-posta alıcılarına gönderilen web özellikli.",
      "TimelineDomainHostingHasBeenAuthorized": "Alan adı barındırma yetkilendirildi",
      "TimelineDomainNotAvailable": "Alan adı artık mevcut değil",
      "TimelineDomainHostingDomainValidated": "Etki alanı doğrulandı",
      "TimelineExceptionCheckDomainAvailability": "0} etki alanı kullanılabilirliği kontrol edilirken hata oluştu. Hata: {2}",
      "TimelineDomainFailRegistering": "0} alan adının kaydı başarısız oldu",
      "TimelineExceptionRegisterDomain": "0} alan adının kaydı hata ile başarısız oldu: {2}",
      "TimelineDomainSuccessfullyRegistered": "Alan adı {0} başarıyla kaydedildi.",
      "TimelineDomainSuccessfullyRedirected": "Alan adı başarıyla yeniden yönlendirildi",
      "TimelineDomainCreatingCertificate": "0} etki alanı için sertifika hatayla oluşturuldu: {1}",
      "TimelineDomainCreatingDistributionFailed": "0} etki alanının dağıtımı hata ile başarısız oldu: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Alan adı {0} başarıyla barındırıldı",
      "TimelineDomainCreatingFailedHosting": "Alan adı {0} hata nedeniyle barındırılamadı: {1}",
      "TimelineDomainHostingCompleted": "Tamamlandı",
      "TimelineMissingClientDataFirstAndLastName": "Müşterinin adı ve soyadı eksik!",
      "TimelineMissingClientDataPhoneNumber": "Müşterinin eksik telefon numarası!",
      "TimelineDomainCreatingSuccessfullyFinished": "Etki Alanı {0} başarıyla tamamlandı"
    },
    "attributeName": {
      "select": "Seçim alanı",
      "html": "HTML alanı",
      "checkbox": "Onay kutusu",
      "text": "Metin alanı",
      "colors": "Renk seçenekleri",
      "number": "Sayı alanı",
      "boolean": "Evet/Hayır",
      "date": "Tarih"
    },
    "attributeType": {
      "select": "Seçmek için birkaç seçenek tanımlayabilirsiniz. Kullanıcı daha sonra bunlardan birini seçebilir",
      "html": "Kullanıcı Zengin İçerik Düzenleyicisi ile daha uzun bir metin yazabilir",
      "checkbox": "Seçmek için birkaç seçenek tanımlayabilirsiniz. Kullanıcı daha sonra bunlardan birkaçını seçebilir",
      "text": "Kullanıcı basit bir metin girebilir",
      "colors": "Kullanıcı burada tanımlanan renklerden birini seçebilir",
      "number": "Kullanıcı bir sayı girebilir",
      "boolean": "Kullanıcı evet ve hayır arasında seçim yapabilir",
      "date": "Kullanıcı bir tarih seçebilir"
    },
    "attributeValueType": {
      "string": "Metin",
      "number": "Sayı",
      "date": "Tarih"
    },
    "attributePrecision": {
      "year": "Yıl",
      "month": "ay",
      "day": "Gün",
      "time": "Günün saati"
    },
    "categoryDisplay": {
      "select": "Evet/Hayır",
      "number_range": "Sayı aralığı",
      "date_range": "Tarih aralığı",
      "list": "Liste seçimi"
    },
    "invoice_actions": {
      "tab_title": "Fatura üzerindeki işlemler",
      "refunded": {
        "title": "Manuel olarak iade edildi",
        "label": "İade edildi olarak işaretle",
        "info_text": "Bir iptal faturası oluşturmadan faturanın zaten iade edilmiş olarak manuel işaretlenmesi"
      },
      "denied_to_pay": {
        "title": "Ödeme reddedildi",
        "label": "Reddedildi olarak işaretle",
        "info_text": "Faturanın asla ödenmeyeceğini işaretleyin"
      },
      "amount_paid": {
        "title": "Ödenen tutarı girin",
        "placeholder": "Ödenen tutarı girin",
        "button": "Ödenen tutarı kaydet"
      },
      "sent_reminders": {
        "title": "Gönderilen hatırlatmalar",
        "placeholder": "Gönderilen hatırlatma sayısı",
        "button": "Numarayı kaydet"
      }
    },
    "mail": {
      "registration": {
        "subject": "şirket% ile kayıt olduğunuz için teşekkür ederiz",
        "subject_robethood": "Robethood Creatives Portal: Kayıt başarılı.",
        "explanation": "Kaydınız için teşekkür ederiz. Lütfen e-posta adresinizi 24 saat içinde onaylayın. Sadece onaylanmış bir e-posta adresi ile yeni bir İnternet şifresi veya değişiklikler hakkında bilgi alabilirsiniz.",
        "explanation_first_name_form": "<p>Kayıt işleminiz için teşekkür ederiz. Lütfen e-posta adresinizi 24 saat içinde onaylayın.</p><p>Sadece onaylanmış bir e-posta adresi ile yeni bir şifre veya değişiklikler hakkında bilgi alabilirsiniz.</p> <p>",
        "possible_actions": "E-posta adresiniz onaylandıktan sonra, sizin için hazırladığımız tüm işlevlere hemen erişebileceksiniz.",
        "possible_actions_first_name_form": "E-posta adresiniz onaylandıktan sonra, sizin için hazırladığımız tüm işlevlere hemen erişebileceksiniz.",
        "call_to_action": "Onaylayın"
      },
      "ordered_web_pdf": {
        "subject": "şirket% 'ten %orderId% siparişi için web özellikli PDF",
        "explanation": "Siparişiniz için teşekkür ederiz. Aşağıdaki düğmeye tıklayarak web özellikli PDF'yi açabilir ve indirebilirsiniz.",
        "explanation_first_name_form": "Siparişiniz için teşekkür ederiz. Aşağıdaki düğmeye tıklayarak web özellikli PDF'yi açabilir ve indirebilirsiniz.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "call_to_action": "Sipariş göster",
        "download": "Web özellikli PDF'i indirin"
      },
      "ordered_download_pdf": {
        "subject": "PDF for order %orderId% from %company%",
        "explanation": "Siparişiniz için teşekkür ederiz. PDF'i aşağıdaki butona tıklayarak açabilir ve indirebilirsiniz.",
        "explanation_first_name_form": "Siparişiniz için teşekkür ederiz. PDF'i aşağıdaki butona tıklayarak açabilir ve indirebilirsiniz.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "call_to_action": "Sipariş göster",
        "download": "İndir PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Şirket% 'ten %orderId% siparişi için manuel veri kontrolü siparişiniz",
        "explanation": "Siparişiniz ve manuel veri kontrolünü devreye aldığınız için teşekkür ederiz.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "call_to_action": "Durumu göster"
      },
      "order_reduced_vat_authorized": {
        "subject": "orderId% siparişi için indirimli KDV oranı başvurunuz onaylandı",
        "explanation": "Siparişiniz için teşekkür ederiz. İndirimli KDV oranı için siparişi onayladık.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "call_to_action": "Durumu göster"
      },
      "order_reduced_vat_rejected": {
        "subject": "orderId% siparişi için indirimli KDV oranı talebiniz onaylanmadı",
        "explanation": "Siparişiniz için teşekkür ederiz. Siparişinizi kontrol ettik ve indirimli KDV oranına uygun olmadığını tespit ettik. Normal KDV oranı uygulanacaktır.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
        "call_to_action": "Durumu göster"
      },
      "chedri_registration": {
        "subject": "Şirket% ile kaydınız",
        "subject_fiendly": "Şirket% ile kaydınız",
        "explanation": "<p>Merhaba %firstName% %lastName%,</p><p>Kaydınız için teşekkür ederiz.</p><p>Siz ve şirketiniz için başarılı bir şekilde bir test hesabı oluşturduk.</p>",
        "possible_actions": "E-posta adresiniz onaylandıktan sonra, kullanıcı adınız olarak <strong>%kullanıcıadı%</strong> ve kayıt sırasında seçtiğiniz şifre ile giriş yapabilirsiniz.",
        "call_to_action": "Onaylayın",
        "subject_friendly": "Şirket% ile kaydınız"
      },
      "invitation": {
        "subject": "Şirket% 'den davet",
        "explanation": "Şirket% adına, sizi çevrimiçi tasarım portalımıza davet etmekten mutluluk duyuyoruz.",
        "login_data": "Daveti kabul ederseniz, oturum açmak için aşağıdaki verileri kullanın: kullanıcı adınız olarak <strong>%kullanıcıadı%</strong> ve şifreniz olarak <strong>%şifre%</strong>. Lütfen giriş yaptıktan sonra şifrenizi değiştirdiğinizden emin olun.",
        "call_to_action": "Daveti kabul et",
        "explanation_first_name_form": "Şirket% adına, sizi çevrimiçi tasarım portalımıza davet etmekten mutluluk duyuyoruz.",
        "login_data_first_name_form": "Daveti kabul ederseniz, oturum açmak için aşağıdaki verileri kullanın: kullanıcı adınız olarak <strong>%kullanıcıadı%</strong> ve şifreniz olarak <strong>%şifre%</strong>. Lütfen giriş yaptıktan sonra şifrenizi değiştirdiğinizden emin olun."
      },
      "new_quote": {
        "subject": "şirket% 'ten yeni teklif",
        "explanation": "şirket% talep ettiğiniz bir hizmet için size bir fiyat teklifi gönderir.",
        "possible_actions": "Şimdi teklifi kontrol etme ve kabul etme seçeneğiniz var. Ayrıca teklifi reddedebilir ve nedenini açıklayan bir mesaj bırakabilirsiniz.",
        "call_to_action": "Teklifi kontrol edin"
      },
      "new_invoice": {
        "subject": "Şirket% 'ten yeni fatura",
        "explanation": "şirket% talep ettiğiniz bir hizmet için size bir fatura gönderecektir. Faturayı çevrimiçi portalımızda bulabilirsiniz. Faturayı bu mesaja da ekledik.",
        "explanation_first_name_form": "şirket% talep ettiğiniz bir hizmet için size bir fatura gönderecektir. Faturayı çevrimiçi portalımızda bulabilirsiniz. Faturayı bu mesaja da ekledik.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve faturalarınızı görüntüleyebilirsiniz.",
        "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve faturalarınızı görüntüleyebilirsiniz.",
        "call_to_action": "Faturayı göster"
      },
      "new_sales_commission": {
        "subject": "Şirket% 'ten yeni komisyon.",
        "explanation": "şirket% talep ettiğiniz bir hizmet için size bir komisyon gönderecektir. Komisyonu çevrimiçi portalımızda bulabilirsiniz. Ayrıca komisyonu bu mesaja ekledik.",
        "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve komisyonunuzu çevrimiçi olarak görüntüleyebilirsiniz.",
        "call_to_action": "Komisyon göster"
      },
      "new_questionnaire": {
        "subject": "şirket% sizden bir anket doldurmanızı istiyor",
        "explanation": "<p>Merhaba %firstName% %lastName%,</p><p>%company% size yeni bir anket gönderiyor.</p>",
        "possible_actions": "Anketi çevrimiçi olarak görüntüleme ve doldurma seçeneğiniz vardır.",
        "call_to_action": "Anketi görüntüle"
      },
      "questionnaire_filled_out": {
        "subject": "Anket henüz tamamlandı",
        "explanation": "<p>Merhaba %firstName% %lastName%,</p><p>%targetFirstName% tarafından talep edilen anket az önce tamamlandı.</p>",
        "possible_actions": "Cevapları öncü zaman çizelgesinde görüntüleyebilirsiniz.",
        "call_to_action": "Açık kurşun"
      },
      "contact_form_cms": {
        "subject": "isim%'den yeni kişi",
        "info": "Bir iletişim formunun gönderildiğine dair bildirim. Lütfen bunu derhal işleme alın ve müşterinin mümkün olan en iyi desteği almasını sağlayın."
      },
      "contact_form_edition": {
        "subject": "Yeni irtibat",
        "info": "Bir iletişim formunun gönderildiğine dair bildirim. Lütfen bunu derhal işleme alın ve müşterinin mümkün olan en iyi desteği almasını sağlayın.",
        "form_fields": "Tamamlanmış form alanları"
      },
      "password_renew": {
        "subject": "Şifre yenileme",
        "explanation": "<p>Merhaba %email%,<br><br>%platformAdı%'na giriş yapmayı denediniz. Şifrenizin süresi doldu. Gelecekte oturum açabilmek için yeni bir parola belirlemeniz gerekiyor. Yeni bir şifre girmek için aşağıdaki bağlantıya tıklayın.</p>",
        "possible_actions": "Yeni bir şifre belirleme seçeneğiniz vardır. Düğmeye tıklamanız yeterlidir.",
        "call_to_action": "Yeni şifre belirleme",
        "explanation_first_name_form": "<p>Merhaba %email%,<br><br>%platformAdı%'na giriş yapmayı denediniz. Şifrenizin süresi doldu. Gelecekte oturum açabilmek için yeni bir parola belirlemeniz gerekiyor. Yeni bir şifre girmek için aşağıdaki bağlantıya tıklayın.</p>",
        "possible_actions_first_name_form": "Yeni bir şifre belirleme seçeneğiniz vardır. Düğmeye tıklamanız yeterlidir."
      },
      "password_reset": {
        "subject": "Şifreyi sıfırla",
        "explanation": "<p>İyi günler %email%,</p><br /><p>Platformumuz aracılığıyla %platformAdi% 'ndan yeni bir şifre talep edilmiştir. Bu talebi yaptıysanız, lütfen aşağıdaki bağlantıya tıklayın.</p",
        "explanation_first_name_form": "<p>Merhaba %email%,</p><br /><p>Platformumuz aracılığıyla %platformAdi% 'ndan yeni bir şifre talep edilmiştir. Eğer bu talepte bulunduysanız aşağıdaki linke tıklayınız.</p",
        "possible_actions": "Yeni bir şifre belirleme seçeneğiniz vardır. Düğmeye tıklamanız yeterlidir.",
        "possible_actions_first_name_form": "Yeni bir şifre belirleme seçeneğiniz vardır. Düğmeye tıklamanız yeterlidir.",
        "call_to_action": "Yeni şifre belirleme"
      },
      "message": {
        "explanation": "şirket% size bir mesaj gönderdi",
        "possible_actions": "Bu düğmeye tıklayarak bu mesajı yanıtlayabilirsiniz:",
        "call_to_action": "Cevaplar"
      },
      "customerMessage": {
        "explanation": "Müşteri size bir mesaj gönderdi"
      },
      "lead_comment_mention": {
        "subject": "Onlar %title% kurşun içinde bahsedildi",
        "explanation": "yaratıcı% bir yorumda sizden bahsetti.",
        "call_to_action": "Açık kurşun"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Şimdi '%title%' liderine atandınız",
        "subject_unassigned": "Artık '%title%' liderine atanmıyorlar",
        "explanation_assigned": "Artık '%title%' liderine atandınız. Önceki düzenleyici %oldAssignee% idi.",
        "explanation_unassigned": "Artık '%title%' liderine atanmamışlardır. Yeni atanan kişi %newAssignee%'dir.",
        "call_to_action": "Açık kurşun"
      },
      "bank_data_reminder": {
        "subject": "Siparişiniz için banka bilgilerinize ihtiyacımız var",
        "explanation": "Banka bilgilerinin gerekli olduğu hizmetler için rezervasyon yaptınız.",
        "possible_actions": "Lütfen siparişe gidin ve eksik verileri girin.",
        "call_to_action": "Banka bilgilerini girin"
      },
      "order_approved": {
        "subject": "şirket% ile olan siparişiniz az önce onaylandı",
        "explanation": "Siparişinizin %orderId% onaylandığını bildirmekten memnuniyet duyarız.",
        "possible_actions": "Ayrıntıları görüntülemek için siparişinize gitmek isteyebilirsiniz.",
        "call_to_action": "Sipariş göster"
      },
      "sales_contract_uploaded": {
        "subject": "Siparişiniz için satın alma sözleşmesi şimdi hazır"
      },
      "order_cancelled": {
        "subject": "şirket% ile olan siparişiniz iptal edildi",
        "explanation": "Üzgünüz, ancak %orderId% siparişinizi iptal etmek zorunda kaldık.",
        "possible_actions": "Ayrıntıları görmek için lütfen siparişinize gidin.",
        "call_to_action": "Sipariş göster"
      },
      "payment_instructions": {
        "subject": "Siparişiniz için %company% adresinden ödeme bilgileri",
        "explanation": "Aşağıda %orderId% siparişiniz için ödeme bilgilerini bulacaksınız:",
        "possible_actions": "Ayrıntıları görmek için lütfen siparişinize gidin.",
        "call_to_action": "Sipariş göster"
      },
      "pickup_invite": {
        "subject": "Siparişiniz teslim alınmaya hazır",
        "explanation": "siparişId% numaralı siparişiniz teslim alınmaya hazır.",
        "possible_actions": "Ayrıntıları görmek için lütfen siparişinize gidin.",
        "call_to_action": "Sipariş göster"
      },
      "order_picked_up": {
        "subject": "Siparişiniz için teşekkür ederiz"
      },
      "request_plugin_activation": {
        "subject": "Eklenti aktivasyonu istendi",
        "explanation": "<p>Merhaba %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% %platformName% projesi için <strong>%pluginName%</strong> eklentisini etkinleştirmek istiyor.</p>",
        "possible_actions": "Aşağıdaki butona basarak giriş yapabilir ve aktivasyon formunu kullanabilirsiniz.",
        "call_to_action": "Eklentiyi etkinleştirin"
      },
      "plugin_activated": {
        "subject": "Eklenti etkinleştirildi",
        "explanation": "<p>Merhaba %firstName% %lastName%,</p><p>%platformName% projesi için %pluginName% 'in etkinleştirildiğini size bildirmek isteriz.</p>",
        "call_to_action": "İş başında görün"
      },
      "lead_data": {
        "subject": "Yeni müşteri adayı: %title%",
        "call_to_action": "Kurşun göster"
      },
      "order": {
        "item_sales": {
          "title": "Siparişiniz",
          "explanation": "Siparişinizin detayları aşağıda tekrar listelenmiştir.",
          "subject": "Şirket% ile siparişiniz",
          "possible_actions": "Siparişinizle ilgili tüm bilgileri müşteri hesabınızda da bulabilirsiniz.",
          "call_to_action": "Müşteri hesabında görüntüle"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Sipariş #%orderId%: Vamoso Eklentisi için Kira Senkronizasyonunda Hata: %errorType%",
        "explanation": "Maalesef eklentimiz Vamoso sisteminizde bir görevi gerçekleştiremedi. Lütfen rezervasyon durumunu manuel olarak kontrol edin. <br/>Hata türü: %errorType% <br/>Hata mesajı: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "Yarın teslim edilmesi gereken müşteri adaylarına atanırlar",
        "explanation": "Burada size atanan müşteri adaylarının bir listesini bulacaksınız. Bu müşteri adayları hala açık ve yarın teslim edilecek.",
        "explanation_first_name_form": "Burada size atanan müşteri adaylarının bir listesini bulacaksınız. Bu müşteri adayları hala açık ve yarın teslim edilecek."
      },
      "chat_history": {
        "subject": "Sohbet geçmişiniz",
        "explanation": "Bizimle iletişime geçtiğiniz için teşekkür ederiz. Lütfen %firstName% %lastName% ile sohbet geçmişinizi ekte bulabilirsiniz. Umarım tüm sorularınız veya sorunlarınız için size yardımcı olabilmişizdir. Başka sorularınız varsa, lütfen istediğiniz zaman bizimle tekrar iletişime geçmekten çekinmeyin.",
        "greatingchat_history": "Sohbet geçmişi",
        "greating": "platformAdi% sohbetini kullandığınız için teşekkür ederiz. Sohbet partneriniz %firstName% %lastName% idi Aşağıda %date% 'den itibaren sohbet geçmişini görebilirsiniz.",
        "adaption": "Umarım tüm soru ve sorunlarınızda size yardımcı olabilmişizdir. Başka sorularınız varsa, lütfen istediğiniz zaman bizimle tekrar iletişime geçmekten çekinmeyin.",
        "privacy_policy": "Gizlilik politikamızı <a href=\"%link%\" target=\"_blank\">'%link%</a> adresinde bulabilirsiniz.",
        "call_to_action": "Bizi tekrar ziyaret edin"
      },
      "lead_pool_notification": {
        "subject": "Havuzdaki yeni lider: %title%",
        "explanation": "Sistemde yeni bir müşteri adayı mevcut. Henüz bir çalışan atamadık. Ayrıntıları görüntülemek için aşağıdaki düğmeye tıklayın.",
        "call_to_action": "Açık kurşun"
      },
      "lead_escalation_level1": {
        "subject": "[LEVEL1] %name%",
        "explanation": "Bu e-postayı alıyorsunuz çünkü bir veya daha fazla müşteri adayı %name% kuralında tanımlanan ilk eskalasyon seviyesine ulaştı. Ne yapılması gerektiğini görmek için lütfen müşteri adaylarını kontrol edin."
      },
      "lead_escalation_level2": {
        "subject": "[LEVEL2] %name%",
        "explanation": "Bu e-postayı alıyorsunuz çünkü bir veya daha fazla müşteri adayı %name% kuralında tanımlanan ikinci eskalasyon seviyesine ulaştı. Lütfen müşteri adaylarını kontrol edin ve ekiplerinizi daha sıkı çalışmaları için motive etmeye çalışın."
      },
      "campaign_budget_auth_request": {
        "subject": "Uyarı: Kampanya bütçesi aşıldı.",
        "explanation": "kampanya bütçesi aşılmıştır.",
        "call_to_action": "Sipariş göster"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Uyarı: Kampanya bütçesinin yetkilendirilmesiyle ilgili sorun.",
        "explanation": "Kampanya bütçesinin yetkilendirilmesiyle ilgili bir sorun tespit edilmiştir.",
        "call_to_action": "Sipariş göster"
      },
      "user_budget_auth_request": {
        "subject": "Uyarı: Kullanıcı bütçesi aşıldı.",
        "explanation": "kullanıcı bütçesi aşılmıştır.",
        "call_to_action": "Sipariş göster"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Uyarı: Kullanıcı bütçesinin yetkilendirilmesiyle ilgili sorun",
        "explanation": "Kullanıcı bütçesinin yetkilendirilmesiyle ilgili bir sorun tespit edildi.",
        "call_to_action": "Sipariş göster"
      },
      "order_auth_changes_requested": {
        "subject": "Bilgi: Değişiklikler talep edildi",
        "call_to_action": "Sipariş göster"
      },
      "order_auth_changes_implemented": {
        "subject": "Bilgi: Yapılan değişiklikler",
        "call_to_action": "Sipariş göster"
      },
      "manage_notification": "E-posta bildirimlerini yönetin",
      "unsubscribe": "Abonelikten çık",
      "confidentiality_notice": "Gizlilik uyarısı: Bu e-posta yalnızca belirtilen alıcıya yöneliktir ve gizli ve/veya yasal olarak korunan bilgiler içerebilir. Doğru alıcı değilseniz veya bu e-postayı yanlışlıkla aldıysanız, lütfen göndereni derhal bilgilendirin ve bu e-postayı imha edin. Bu e-postanın izinsiz kopyalanması ve izinsiz iletilmesi yasaktır.",
      "no_legal_binding_notice": "Bu mesaj sadece bilgilendirme amaçlıdır ve yasal olarak bağlayıcı değildir. E-postaların kolay manipüle edilebilir olması nedeniyle, içerikle ilgili herhangi bir sorumluluk kabul edemeyiz.",
      "invoice_due_reminder": {
        "subject": "Ödeme hatırlatması",
        "explanation": "Ne yazık ki, aşağıdaki fatura için ödeme alındığını henüz tespit edemedik.",
        "explanation_first_name_form": "Ne yazık ki, aşağıdaki fatura için ödeme alındığını henüz tespit edemedik.",
        "request_payment": "Daha fazla masraftan kaçınmak için, toplam tutarın derhal ödenmesini talep ediyoruz. Bu faturanın ödenmesi için lütfen aşağıdaki banka bilgilerini kullanın:",
        "request_payment_first_name_form": "Daha fazla masraftan kaçınmak için lütfen toplam tutarı hemen ödeyin. Bu faturayı ödemek için lütfen aşağıdaki banka bilgilerini kullanın:",
        "contact": "Bu hatırlatma hakkında herhangi bir sorunuz varsa, lütfen %email% adresinden e-posta yoluyla veya %phone% yardım hattından telefonla bize ulaşın.",
        "contact_first_name_form": "Bu hatırlatma hakkında herhangi bir sorunuz varsa, lütfen %email% adresinden e-posta yoluyla veya %phone% yardım hattından telefonla bize ulaşın.",
        "bank_account": "Banka bilgileri",
        "bank_name": "Banka adı",
        "account_holder": "Hesap sahibi",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Banka Sıralama Kodu",
        "account_number": "Hesap numarası",
        "invoice_number": "Fatura no:"
      },
      "last_seen_reminder": {
        "subject": "Son görülme Hatırlatma",
        "user_not_seen": "'%clientUsername%' kullanıcısı %daysNotSeen% gün boyunca görülmedi.",
        "contact_user": "Her şeyin yolunda olduğundan emin olmak için lütfen onlarla iletişime geçin.",
        "contact_user_first_name_form": "Her şeyin yolunda olduğundan emin olmak için lütfen onlarla iletişime geçin.",
        "customer_profil": "Müşteri profili"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Sipariş - Lütfen müşteri hizmetleri ile iletişime geçin",
          "explanation": "Siparişiniz için teşekkür ederiz. Siparişinizle ilgili bir sorumuz var. Lütfen müşteri hizmetlerimizle iletişime geçin.",
          "explanation_first_name_form": "Siparişiniz için teşekkür ederiz. Siparişinizle ilgili bir sorumuz var. Lütfen müşteri hizmetlerimizle iletişime geçin.",
          "possible_actions": "-",
          "contact": "Bize ulaşın",
          "phone": "Telefon:",
          "email": "E-posta:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "Sipariş şu anda gönderiliyor",
          "explanation": "Siparişiniz üretildi ve teslimat için kuryeye teslim edildi.",
          "explanation_first_name_form": "Siparişiniz üretildi ve teslimat için kuryeye teslim edildi.",
          "possible_actions_tracking": "Teslimatı kurye hizmetinin web sitesinden takip edebilirsiniz.",
          "possible_actions_tracking_first_name_form": "Teslimatı kurye hizmetinin web sitesinden takip edebilirsiniz.",
          "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "call_to_action": "Sipariş detayları",
          "tracking_numbers": "Takip numaraları"
        },
        "in_production": {
          "subject": "Sipariş şu anda üretilmektedir",
          "explanation": "Siparişiniz için teşekkür ederiz. Siparişinizi verdik. Şu anda üretim aşamasındadır.",
          "explanation_first_name_form": "Siparişiniz için teşekkür ederiz. Siparişinizi verdik. Şu anda üretim aşamasındadır.",
          "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "call_to_action": "Sipariş detayları"
        },
        "invalid_print_data": {
          "subject": "Sipariş - geçersiz yazdırma verileri",
          "explanation": "Sipariş sırasında geçersiz yazdırma verileri bulundu.",
          "call_to_action": "Sipariş detayları"
        },
        "missing_print_data": {
          "subject": "Sipariş - eksik yazdırma verileri",
          "explanation": "Sipariş verildiğinde eksik baskı verileri bulundu.",
          "call_to_action": "Sipariş detayları"
        },
        "order_canceled": {
          "subject": "Sipariş iptal edildi",
          "explanation": "Siparişiniz başarıyla iptal edildi. Siparişinizi iptal etmek zorunda kaldığınız için üzgünüz ve gelecekte size tekrar yardımcı olabileceğimizi umuyoruz.",
          "explanation_first_name_form": "Siparişiniz başarıyla iptal edildi. Siparişinizi iptal etmek zorunda kaldığınız için üzgünüz ve gelecekte size tekrar yardımcı olabileceğimizi umuyoruz.",
          "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "call_to_action": "Sipariş detayları"
        },
        "payment_pending": {
          "subject": "Satın alma siparişi - ödenmemiş ödeme",
          "explanation": "Siparişiniz için henüz ödeme almadık.",
          "explanation_first_name_form": "Siparişiniz için henüz ödeme almadık.",
          "possible_actions": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "possible_actions_first_name_form": "İstediğiniz zaman %domain% adresindeki çevrimiçi portala giriş yapabilir ve siparişinizi görüntüleyebilirsiniz.",
          "call_to_action": "Sipariş detayları"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Lütfen e-postanızı onaylayın",
        "explanation": "<p>E-postanız web sitemizdeki iletişim formuna girilmiştir.</p><p>İletişim formumuzu doldurduysanız, lütfen Onayla düğmesine tıklayın.</p><p>Aksi takdirde, bu mesajı dikkate almayın.</p><p>",
        "call_to_action": "Onaylayın"
      },
      "contact_form_edition_confirm": {
        "subject": "Lütfen e-postanızı onaylayın",
        "explanation": "<p>E-posta adresiniz web sitemizdeki iletişim formuna girilmiştir.</p><p>İletişim formumuzu doldurduysanız, lütfen Onayla düğmesine tıklayın.</p><p>Aksi takdirde, bu mesajı dikkate almayın.</p><p",
        "call_to_action": "Onaylayın"
      },
      "order_content_check_status": {
        "subject": "orderId% siparişi için içerik kontrolü %status% olarak gerçekleşti",
        "explanation": "Siparişiniz için teşekkür ederiz. Siparişinizin içeriğini kontrol ettik ve %status% verdik.",
        "possible_actions": "Ayrıntıları görüntülemek için siparişinizi arayabilirsiniz.",
        "call_to_action": "Siparişi görüntüle",
        "reason": "Sebep"
      },
      "lead_follow_up_reminder": {
        "subject": "Lütfen atanan görevleri kontrol edin",
        "explanation": "Burada size atanan görevlerin bir listesini bulacaksınız. Bu görevler hala açıktır.",
        "explanation_first_name_form": "Burada size atanan görevlerin bir listesini bulacaksınız. Bu görevler hala açıktır."
      },
      "client_domain_authorization_code": {
        "subject": "Alan adı yetki kodu",
        "explanation": "clientDomain% alan adı için %itemName% aboneliğini iptal ettikten sonra, alan adınızı başka bir kayıt kuruluşuna aktarmak için kullanabileceğiniz bir yetki kodu vereceğiz.",
        "explanation_authorization_code": "Yetkilendirme kodu %authorizationCode%'dur"
      },
      "new_lead_notification": {
        "subject": "Yeni müşteri adayı: %title%",
        "explanation": "Yeni bir müşteri adayı oluşturuldu. Ayrıntıları görüntülemek için aşağıdaki düğmeye tıklayın.",
        "call_to_action": "Açık kurşun"
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Talebiniz gönderildi",
        "body": "<p>Bizimle iletişime geçtiğiniz için teşekkür ederiz!</p><p>Sorgunuzu aldık ve mümkün olan en kısa sürede size geri döneceğiz.</p><p"
      },
      "already_confirmed": {
        "heading": "Bu başvuru halihazırda onaylanmıştır",
        "body": "<p>Başvurunuzu aldık ve mümkün olan en kısa sürede sizinle iletişime geçeceğiz.</p>"
      },
      "not_found": {
        "heading": "Bulunamadı",
        "body": "<p>Lütfen buraya ulaşmak için e-postamızdaki düğmeyi tıkladığınızdan emin olun.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Talebiniz gönderildi",
        "body": "<p>Bizimle iletişime geçtiğiniz için teşekkür ederiz!</p><p>Sorgunuzu aldık ve mümkün olan en kısa sürede size geri döneceğiz.</p><p"
      },
      "already_sent": {
        "heading": "Bu başvuru halihazırda onaylanmıştır",
        "body": "<p>Başvurunuzu aldık ve mümkün olan en kısa sürede sizinle iletişime geçeceğiz.</p>"
      },
      "not_found": {
        "heading": "Bulunamadı",
        "body": "<p>Lütfen buraya ulaşmak için e-postamızdaki düğmeyi tıkladığınızdan emin olun.</p>"
      },
      "expired": {
        "heading": "Kodun süresi doldu",
        "body": "<p>Onay bağlantısı yalnızca 2 gün için geçerliydi. Lütfen iletişim formunu tekrar doldurun.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Temel bilgiler",
      "contact_person": "İletişim kurulacak kişi",
      "address_information": "Adres",
      "address_settings": "Adres ayarları",
      "bank_account": "Banka bilgileri",
      "company_information": "Şirket bilgileri",
      "user_roles": "Kullanıcı rolleri",
      "subscription_roles": "Kullanıcı aboneliği",
      "opening_hours": "Çalışma saatleri",
      "position_on_map": "Harita üzerindeki konum",
      "white_label_parent": "Öncül",
      "user_teams": "Kullanıcı ekipleri",
      "contact_data": "İletişim bilgileri",
      "permissions": "Yetkilendirmeler",
      "person_information": "Kullanıcı bilgileri"
    },
    "hints": {
      "small_hint_for_you": "Sizin için küçük bir ipucu",
      "task_is_automated": "Bu görev otomatikleştirilmiştir. Otomasyon görevi çalışana kadar beklemelisiniz. Bundan sonra, sizin için tamamlandı olarak işaretleyeceğiz.",
      "please_complete_previous_tasks": "Bu görevi şimdi düzenleyemezsiniz. Lütfen önce önceki görevlerle ilgilenin.",
      "companyLogoNew": "<p>Henüz logonuzu yüklemediniz. Yüklemek için bir resmi (jpeg veya png) bu sayfaya sürükleyip bırakmanız yeterlidir.</p>",
      "companyLogoChange": "<p>Bu sizin yüklediğiniz logodur. Değiştirmek için bu sayfaya bir resmi (jpeg veya png) sürükleyip bırakmanız yeterlidir.</p>)",
      "companyFinancial": "<div><p>Lütfen faturalama için gerekli bilgileri doldurun</p><p>Burada doldurduğunuz banka bilgileri teklif ve faturalarınızda altbilgi olarak görüntülenecektir</p></div>",
      "companyPaymentInfo": "<p>Bu bilgiler oluşturduğunuz her faturada görüntülenir.</p"
    },
    "vendors": {
      "association": "Müşteri verileri ile ilişkilendirme",
      "vendor_api": "Hizmet adı",
      "pricing": "Fiyatlandırma",
      "operating_mode": "Çalışma modu",
      "price_update_method": "Fiyat güncelleme yöntemi",
      "order_processing": "Sipariş işleme",
      "order_method": "Sipariş yöntemi",
      "order_email": "Siparişler için e-posta adresi",
      "phone_number": "Telefon numarası",
      "web_shop_url": "Web Mağazası URL'si",
      "trial": "deneme",
      "live": "canlı",
      "prices_by_vendor": "Fiyatlar sağlayıcı tarafından yönetilir",
      "prices_by_employee": "Fiyatlar çalışan tarafından kontrol edilir ve muhafaza edilir",
      "prices_by_api": "Fiyatlar bir API aracılığıyla güncellenir",
      "notified_by_email": "Gelen siparişlerden e-posta ile haberdar edilecek",
      "accepts_only_email": "Sadece e-posta ile sipariş kabul eder",
      "use_webshop": "Web mağazası üzerinden siparişler",
      "order_by_api": "API üzerinden siparişler",
      "order_by_api_and_webshop": "API veya web mağazası üzerinden siparişler"
    },
    "client_account": "Müşteri hesabı",
    "deleted_campaigns": "Silinen kampanyalar",
    "deleted_editions": "Silinmiş sürümler",
    "deleted_folders": "Silinmiş klasörler",
    "deleted_media": "Silinmiş medya",
    "root": "Kök dizin",
    "trash_bin": "Atık kağıt sepeti",
    "delete_trash_bin": "Boş atık kağıt sepeti",
    "pls_wait": "Lütfen bekleyin. İstenen eylem gerçekleştirilir.",
    "go_back": "Geri",
    "restore": "Geri Yükleme",
    "archived_campaigns": "Arşivlenmiş kampanyalar",
    "load_all_campaigns": "Tüm kampanyaları yükle",
    "load_creacheck_campaigns": "Creacheck kampanyalarını yükle",
    "load_flyeralarm_campaigns": "Flyeralarm kampanyalarını yükleyin",
    "load_designgenie_campaigns": "DesignGenie kampanyalarını yükleyin",
    "archived_editions": "Arşivlenmiş baskılar",
    "sorting_name": "İsim",
    "sorting_date_ASC": "Önce en yaşlı",
    "sorting_date_DESC": "Önce en yeni",
    "sorting_owner": "Şirket",
    "sorting_creator": "Tarafından oluşturuldu",
    "campaign_title": "Kampanya",
    "dataproof": "Veri kontrolü",
    "not_ordered": "sipariş edilmedi",
    "supplier_order": "Tedarikçi siparişi",
    "change_of_address": "Adres değişikliği",
    "not_available": "mevcut değil",
    "selected_shipping_method": "Seçilen gönderim yöntemi",
    "ordered_items": "Sipariş edilen makaleler",
    "standard": "Standart",
    "express": "Ekspres",
    "aws_iframe_hosting": "AWS Iframe Barındırma",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting Başlıklı iframe için HTML dosyası: %title%",
    "lead_stage_change_confirm_title": "Lider durumunu değiştir",
    "lead_stage_change_confirm_body": "Müşteri adayının durumunu gerçekten değiştirmek istiyor musunuz?",
    "iframe_implementation_file": "Iframe uygulama dosyası",
    "iframe_implementation_file_download_failed": "Iframe uygulama dosyası indirilemedi: hata%",
    "lead_stage_change_error_domain_hosting_in_progress": "Mevcut aşama devam ediyorsa veya yetkilendirme onaylanmamışsa hat değiştirilemez.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "Mevcut aşama devam ediyorsa veya yetkilendirme onaylanmamışsa hat değiştirilemez.",
    "allowed_domain_already_exists": "Alan adı %domain% zaten yetkili alan adları listesine dahil edilmiştir.",
    "allowed_domain_domain_name_should_not_be_empty": "Alan adı boş olmamalıdır.",
    "allowed_domains_title": "İzin verilen alan adları",
    "allowed_domains_description": "Burada, iframe'lerin uygulanması için kullanılabilecek alan adlarını ekleyebilirsiniz.",
    "allowed_domains_add": "Alan adı ekle",
    "allowed_domains_domain_name": "Alan adı",
    "allowed_domains_default_src": "Varsayılan-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Stil-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Çerçeve-Src",
    "created_at": "Üzerinde oluşturuldu",
    "created_by": "Tarafından oluşturuldu",
    "overnight": "Gece boyunca",
    "create_printData": "Yazdırma verileri oluşturma",
    "created_new_printData": "Yeni yazdırma verileri oluşturuldu",
    "uploaded_printData_successful": "Yazdırma verileri başarıyla yüklendi",
    "no_printData": "Yazdırma verisi yok",
    "no_images": "Görüntü dosyası mevcut değil",
    "not_the_original_printFile": "Not: Bu orijinal baskı dosyası değildir",
    "printDataFiles": "Veri dosyalarını yazdırma",
    "image_files": "Görüntü dosyaları",
    "customer_comment": "Müşteri yorumu",
    "actions_": " Eylemler",
    "import_success": "İçe aktarma işleminiz gerçekleştirildi",
    "import_rejected": "İthalatınız iptal edildi",
    "import_exec": "İçe aktarma işleminiz gerçekleştirilir",
    "import_successRE": "İçe aktarımınız sıfırlandı",
    "import_rejectedRE": "Süreç iptal edildi",
    "import_execRE": "İçe aktarımınız sıfırlanacaktır",
    "choice_csv": "Lütfen bir CSV dosyası seçin",
    "csv_content": "CSV içeriği",
    "warning_and_notes": "Uyarılar ve notlar",
    "start_remove": "Kaldırma işlemini başlatın",
    "reverse_remove": "Kaldırmayı geri al",
    "user_import_success": "İçe aktarma başarılı",
    "user_duplicate_import": "Mükerrer giriş bulundu",
    "start_import": "İthalatı başlat",
    "reverse_import": "İçe aktarmayı geri al",
    "upload_local_changes": "Yerel değişiklikleri yükleme",
    "dataproof_master_copies_upload": "Burada [Yükle] ile yerel olarak düzenlenmiş şablon/veri dosya(lar)ını yükleyebilirsiniz.",
    "edit_edition": "Reklam materyallerini düzenleme",
    "dataproof_master_copies_recreate": "Burada, [Baskı verisi oluştur] seçeneği kullanılarak şablon/baskı verisi dosyası/dosyaları orijinali temel alarak yeniden oluşturulabilir veya şablon/baskı verisi dosyası/dosyaları [Düzenle] seçeneği ile yerel olarak düzenlenebilir.",
    "hint": "Not",
    "dataproof_hint_master_copies_upload": "Son işlem tamamlandıktan sonra değişikliğin geçerli olması için yerel olarak değiştirilen şablon/baskı veri dosyalarının [Yükle] ile yüklenmesi gerekir.",
    "edit_": "Düzenle",
    "send_invoice": "Fatura gönder",
    "sended_invoice": "Fatura şu adrese gönderildi",
    "send_email_tracking": "E-posta gönderi takibi",
    "sended_email": "E-posta gönderildi!",
    "tracking": "Konsinye takibi",
    "send_tracking_to_customer": "Müşterilere gönderi takibi gönderme",
    "to_the_product": "Ürüne",
    "order_sended": "Sipariş gönderildi!",
    "failure_when_ordering": "Sipariş sırasında bir hata oluştu!",
    "please_create_orderhistory_email": "Sipariş e-posta ile gönderildi! Lütfen bir sipariş geçmişi oluşturun!",
    "new_status_set": "Yeni statü belirlendi!",
    "change_of_status": "Durum değişikliği",
    "statusQuery": "Durum sorgulama",
    "totalStatus": "Genel durum:",
    "printData": "Veri yazdırma",
    "current_printdata_sended": "Geçerli yazdırma verileri gönderildi!",
    "orderHistory": "Sipariş süreci",
    "orderHistory_created": "Sipariş geçmişi oluşturuldu!",
    "order_created": "Sipariş başarıyla oluşturuldu!",
    "enter_trackingLink": "İzleme bağlantısını girin",
    "query_orderStatus": "Sipariş durumunu sorgula",
    "foreignId": "Dış sipariş no.",
    "orderStatus": "Sipariş durumu",
    "orderDate": "Sipariş tarihi",
    "options_": "Seçenekler",
    "type_": "Nazik",
    "comment_": "Yorum",
    "orderOptions": "Sipariş seçenekleri",
    "ordering_by_webshop": "Web mağazası üzerinden sipariş",
    "ordering_by_email": "E-posta ile sipariş",
    "info_to_the_emailOrder": "E-posta siparişleri için ek bilgiler",
    "add_or_edit_foreignId": "Harici sipariş numarası ekleme veya düzenleme",
    "create_trackingLink": "Gönderi takibi için bağlantı oluşturun",
    "campaign": {
      "copy_edition": "Yinelenen",
      "created_by": "Tarafından yaratıldı:",
      "no_edition": "Henüz herhangi bir reklam materyali düzenlemediniz!",
      "configuration": "Genel seçenekler",
      "select_all": "Tüm ürünler",
      "budget": "Kampanya bütçesi",
      "access": "Erişim hakları",
      "inactive": "Kilit kampanyası",
      "delete": "Kampanyayı sil",
      "transfer_title": "Kampanyayı başka bir kullanıcıya aktarma",
      "campaign_owner": "Kampanya sahibi",
      "campaign_creator": "Kampanya yaratıcısı",
      "change_owner": "Kampanya sahibini değiştir",
      "change_creator": "Kampanya oluşturucusunu değiştirin",
      "order_all": "Tüm kampanyayı sipariş edin",
      "share": "Kampanyayı paylaşın",
      "archive": "Arşiv kampanyası",
      "recent_editions": "Son düzenleme",
      "marked_editions": "İşaretli reklam malzemeleri",
      "marked_campaigns": "Etiketli kampanyalar",
      "own_campaigns": "Kendi kampanyalarınız",
      "shared_editions": "Paylaşılan reklam materyalleri",
      "last_edited": "Düzenlendi:",
      "generated": "Oluşturuldu:",
      "edition_edit": "Editörü başlat",
      "edition_item": "Seçilen ürün",
      "submission_item": "Seçilen gönderim",
      "edition_configuration": "2. ayarlar",
      "edition_order": "Alışveriş sepetine ekle",
      "edition_reorder": "Yeniden sırala",
      "ordered": "Sipariş verildi!",
      "edition_delete": "Silme",
      "edition_preview": "Önizleme",
      "edition_edit_alt": "Düzenle",
      "edition_error_empty": "Düzenlenmemiş %s sayfanız var",
      "submissions": "Şablonlar",
      "new": "Yeni kampanya oluşturun",
      "back_to_list": "Tüm kampanyalar",
      "new_edition": "Yeni reklam materyalleri oluşturun",
      "settings": "Kampanya yönetme",
      "campaign_archived": "Kampanyanız arşivlendi",
      "successfully_archived": "Arşivleme başarılı",
      "save_settings": "Kaydet",
      "cancel_settings": "İptal",
      "title": "Kampanya adı",
      "edition_title": "Tanımlama",
      "publisher": "Yayıncının belirtilmesi",
      "creator": "Kampanya tarafından oluşturulmuştur:",
      "editions": "Reklam malzemesi",
      "edition": "Reklam malzemesi",
      "sidebar": "Kampanyalar",
      "mark_edition": "Reklam malzemelerini işaretleyin",
      "unmark_edition": "İşaretlemeyi kaldırın",
      "campaign_title_valid": "Lütfen kampanyanız için bir isim girin.",
      "campaign_publisher_valid": "Lütfen telif hakkı bilgilerinizi girin.",
      "choose_company_to_load_campaign": "Kampanyaları yüklemek için lütfen bir şirket seçin veya 'Tüm kampanyaları yükle' düğmesine tıklayın",
      "no_product_selected": "Seçili ürün yok",
      "new_edition_title": "Yeni baskı",
      "added_to_cart": "Kampanyanız alışveriş sepetine eklendi!",
      "edition_add_to_cart_title": "Ürün alışveriş sepetinde",
      "edition_add_to_cart_button_label": "Harika",
      "edition_add_to_cart_label": "Alışveriş sepetine",
      "edition_added_to_cart_message": " alışveriş sepetinize eklendi.",
      "edit_disabled": "Baskının düzenlenmesi, bir şablon güncellemesi nedeniyle geçici olarak devre dışı bırakıldı.",
      "export_image": "Görüntüyü dışa aktar",
      "unknown": "Bilinmiyor",
      "already_ordered": "Bu baskı zaten sipariş edilmiştir ve artık düzenlenemez. Ancak, baskıyı çoğaltabilir ve düzenleyebilirsiniz. Menüye bakınız",
      "modal_tabs": {
        "default_edition": "Genel reklam malzemeleri",
        "edition_from_submission": "Şablondan reklam malzemesi"
      }
    },
    "uom": {
      "piece": "Parça",
      "gram": "gram",
      "kilogram": "Kilogramme",
      "running_meter": "Koşu metre",
      "liter": "Litre",
      "package": "Paket",
      "kilometer": "Kilometre"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "doğrusal metre",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Bana atandı",
      "unassigned": "Tahsis olmadan",
      "pastdeadline": "Son teslim tarihi aşıldı",
      "duetoday": "Bugün teslim edilecek"
    },
    "leadPriority": {
      "critical": "Kritik",
      "high": "Yüksek",
      "normal": "Normal",
      "low": "Düşük"
    },
    "orderTypes": {
      "item_sales": "Satış",
      "car_rent": "Araba kiralama",
      "car_sales": "Araba satışları",
      "car_service": "Atölye randevusu"
    },
    "genderOptions": {
      "male": "Erkek",
      "female": "Kadın"
    },
    "salutationOptions": {
      "mr": "Bay",
      "mrs": "Bayan"
    },
    "team_service": "Servis ekibi",
    "team_dispo": "Tasfiye ekibi",
    "team_rent": "Kiralama ekibi",
    "team_sales": "Satış ekibi",
    "car_documents": "Araç belgeleri",
    "nav_module_chip": "Sat nav modülü / çip",
    "safety_vest": "Güvenlik yeleği",
    "parking_disk": "Park diski",
    "ice_scraper": "Buz kazıyıcı",
    "key_supplement_heating_taxis": "Anahtar eklentisi (park ısıtıcısı/taksi işareti)",
    "shelf_blind": "Şapka rafı / stor perde",
    "onboard_folder": "Yerleşik klasör",
    "first_aid_kit": "İlk yardım çantası",
    "warning_triangle": "Uyarı üçgeni",
    "tirefit_spare_wheel": "Tirefit / stepne",
    "handover_damage": {
      "front": "Ön",
      "back": "Arka",
      "left": "Bağlantılar",
      "right": "Doğru.",
      "top": "Yukarıda",
      "interior": "İç Mekan"
    },
    "dark": "Karanlık",
    "garage": "Bir garajda",
    "snow_ice": "Kar / Buz",
    "rain_wet": "Yağmur yağıyor / araba ıslak",
    "countries": {
      "de": "Almanya",
      "pl": "Polonya",
      "fr": "Fransa"
    },
    "cookie_description": {
      "PHPSESSID": "Geçerli tarayıcı oturumunuzu tanımlar",
      "tempTarget": "Aradığınız ürünleri ve ilgi alanlarınızı belirlemek için geçici hedef çerezini kullanıyoruz",
      "chedriCookiesBoxClosed": "Bu çerezi, çerez bilgilerimizi gördüğünüzü ve kabul ettiğinizi belirtmek için kullanırız",
      "chedriCookiesPerformance": "Performans çerezlerimizi kabul edip etmediğinizi belirlemek için kullanılır",
      "chedriCookiesTargeting": "Hedefleme çerezlerimizi kabul edip etmediğinizi belirlemek için kullanılır",
      "ga": "Google Analytics hizmetinde kullanıcıları ayırt etmek için kullanılır",
      "gid": "Google Analytics hizmetinde kullanıcıları ayırt etmek için kullanılır",
      "act": "Bu, analiz ve araştırmaları için kullanılan bir Facebook çerezidir",
      "c_user": "Kullanıcılar ve kullanıcıların ilgi alanlarıyla daha alakalı reklamlar görüntülemek için kullanılan kullanıcı girişleri için Facebook girişi",
      "datr": "Facebook tarafından kullanıcının tarayıcısını tanımlamak için kullanılır",
      "fr": "Hedefli reklamcılık için kullanılan benzersiz bir tarayıcı ve kullanıcı kimliği içerir",
      "sb": "Facebook tarafından arkadaş önerilerini geliştirmek için kullanılır",
      "xs": "Bir oturumu yönetmek için kullanılan bir Facebook çerezi. Facebook'ta kimliğinizi doğrulamak için c_user çerezi ile birlikte çalışır"
    },
    "cookie_ttl": {
      "6_months": "6 ay",
      "60_days": "60 gün",
      "2_years": "2 yıl",
      "1_day": "1 gün"
    },
    "upload_and_manage_media_files": "Burada medyanızı yükleyebilir ve yönetebilirsiniz.",
    "profile_information_avatar_contact_address": "Burada profil bilgilerinizi düzenleyebilirsiniz. Avatar, iletişim bilgileri, faturalama için adres, finansal bilgiler.",
    "define_sales_points_address_position_map_opening_hours": "Satış noktalarınızı tanımlayın: Adres, harita üzerindeki konum, çalışma saatleri. Ürünleri ve çalışanları yönetmek için önemlidir.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Sistem veya diğer kullanıcılar tarafından oluşturulan gelen ve giden faturaları kontrol edebilirsiniz. Ayrıntıları görüntüleyin ve PDF formatında indirin.",
    "found_api_interesting_personal_api_key_here": "API'mizi ilginç buluyor musunuz? Denemek ister misiniz? Kişisel API anahtarınızı burada bulabilirsiniz.",
    "need_our_data_in_own_system_configure_data_push": "Kendi sisteminizde bizim verilerimize mi ihtiyacınız var? Burada veri olaylarına abone olmak için veri itme hizmetlerini yapılandırabilirsiniz.",
    "projects_running_on_different_domains": "Farklı etki alanlarında yürütülen projeler",
    "administration_of_your_website_logo_menu_pages": "Web sitenizi yönetin. Logo, ana menü, sistem sayfaları",
    "create_users_manage_profile_financial_roles_teams": "Kuruluşunuzda çalışan kullanıcılar oluşturun, profillerini ve finansal bilgilerini yönetin, güvenlik rollerini gözden geçirip tanımlayın ve ekipler atayın.",
    "provision_service": "Rezervasyonlu hizmetler",
    "provision_service_description": "Burada yeni hizmet türleri oluşturabilir, düzenleyebilir veya silebilirsiniz",
    "provision_service_title": "Başlık",
    "provision_service_type": "İsim",
    "provision_service_description_text": "Açıklama",
    "provision_service_primary_rate": "Prim için yüzde olarak komisyon. sahibi",
    "provision_service_secondary_rate": "İkincil mal sahibi için yüzde olarak komisyon",
    "provision_service_type_status": "Durum",
    "add_provision_type": "Komisyon türü ekle",
    "edit_provision_type": "Düzenle",
    "create_provision_type": "Komisyon türü oluşturma",
    "service_type_onboarding": "Onboarding (kurulum)",
    "service_type_subscriptions": "Abonelikler (MRR, abonelikler ve kullanıcı ücretleri)",
    "service_type_additional_services": "Ek hizmetler (şablonlar)",
    "service_type_product_sales": "Ürün satışı",
    "sales": "Dağıtım",
    "primary_owner": "Birincil sahip",
    "secondary_owner": "İkincil mal sahibi",
    "provision_start": "Başlangıç",
    "provision_ending": "Bitiş",
    "provision_actions": "Eylemler",
    "add_provision_service": "Komisyon oluşturun",
    "edit_provision_service": "Düzenleme komisyonu",
    "save_provision_type": "Kaydet",
    "prov_service_client_name": "İsim",
    "provision_service_documents": "Komisyonlar",
    "commission_service_documents": "Komisyonlar - Belgeler",
    "assign_service_type": "Yeni hizmet türü atama",
    "provision_service_documents_description": "Tüm komisyonları buradan görüntüleyebilirsiniz",
    "provision_service_documents_download": "Belgeleri indirin",
    "provision_service_documents_download_start": "İndir",
    "provision_service_documents_name": "İsim",
    "provision_service_documents_title": "Başlık",
    "provision_service_documents_description_text": "Açıklama",
    "provision_service_documents_serviceType": "Tip",
    "provision_service_documents_provisionRate": "Komisyon oranı",
    "provision_service_documents_provisionValue": "Komisyon değeri",
    "provision_service_documents_provisionAssignee": "Alıcı",
    "provision_service_documents_provisionPayedDate": "ödenen",
    "provision_service_documents_IsActive": "Durum",
    "provision_service_documents_Client": "Müşteri",
    "provision_service_documents_choose_month": "Lütfen istediğiniz ayı seçin",
    "commission_service_documents_description": "Tüm komisyon faturalarını ve detaylarını buradan görüntüleyebilir ve indirebilirsiniz",
    "commission_service_documents_download": "Belgeleri indirin",
    "commission_service_documents_download_start": "İndir",
    "commission_client": "Müşteri",
    "commission_invoice_no": "Fatura numarası",
    "commission_payed_data": "ödenen",
    "commission_service_type": "Hizmet Türü",
    "commission_amount": "Miktar",
    "commission_provision": "Komisyon",
    "create_update_teams_assign_system_types": "Şirketiniz içinde ekipler oluşturun ve düzenleyin. Ekiplerinize sistem türleri atayın.",
    "create_document_types_use_for_process_automation": "Entegrasyonlarda veya süreç otomasyonunda kullanabilmek için kendi belge türlerinizi oluşturun.",
    "write_email_templates_send_to_clients": "Müşterilere mesaj gönderirken veya bazı iş akışlarını otomatikleştirmek için kullanılabilecek e-posta şablonları oluşturun.",
    "define_reusable_cms_elements": "Herhangi bir sayfada kullanılabilecek yeniden kullanılabilir öğeler tanımlayın.",
    "manage_brands_edit_name_description": "Ürün markalarını yönetin, adlarını ve açıklamalarını birden çok dilde düzenleyin.",
    "manage_car_models_and_aliases": "Kullanıcıların seçebileceği araç modellerini yönetin. İçe aktarma için takma adlar tanımlayın.",
    "display_and_manage_category_tree": "Kategori ağacını görüntüleyin ve değiştirin. Adları ve açıklamaları değiştirebilirsiniz.",
    "display_and_manage_product_templates": "Şablonlar oluşturup yönetin ve kullanıcıların yaratıcı olmalarını sağlayın.",
    "define_product_attributes_search_and_variants": "Ürünlerinizde belirtebileceğiniz ek öznitelikler tanımlayın. Kullanıcıların ürünleri aramasına yardımcı olmak için bu öznitelikleri kullanın. Özniteliklere göre ürün varyasyonları oluşturun.",
    "group_attributes_to_better_manage_and_inform": "Arka uçtaki kullanıcı deneyimini daha iyi yönetmek ve ürün özelliklerini müşterilerinize daha net bir şekilde sunmak için özellikleri gruplayabilirsiniz.",
    "entity_fields_missing_add_new": "Formlarınızda bazı alanlar eksikse, bunları buraya ekleyebilirsiniz. Pazarlama kampanyaları ve entegrasyonlar için kullanışlıdır.",
    "define_manage_pipelines_and_stages": "İş akışlarınız için boru hatlarını tanımlayın ve özelleştirin. Aşama ekleyin, düzenleyin ve kaldırın.",
    "create_questionnaires_for_workflows_and_campaigns": "İş akışlarında veya çeşitli pazarlama kampanyalarında kullanılabilecek anketler oluşturun.",
    "catch_leads_from_other_sources_with_custom_email": "Potansiyel Müşteri Yakalayıcımız ile birden fazla kaynaktan gelen potansiyel müşterileri yakalayın. İstediğiniz her kaynak için ayrı e-posta adresleri yapılandırın.",
    "group_car_models_into_families_for_users_to_easily_find": "Kullanıcıların araç aramasını daha da kolaylaştırmak için araç modellerini aileler halinde gruplandırın.",
    "enrich_car_search_functionality_with_own_model_images": "Araç arama işlevini kendi model fotoğraflarınızla zenginleştirin. Fotoğrafları modellere veya model gruplarına atayın.",
    "tag_leads_with_custom_regular_expressions": "Etiket sistemimizin işlevselliğini kullanıcı tanımlı etiketlerle genişletin. Düzenli ifadeler hakkında bilgi gereklidir.",
    "unknown_error": "Bilinmeyen hata oluştu",
    "username_required": "Lütfen kullanıcı adınızı girin",
    "email_required": "Lütfen e-posta adresinizi girin",
    "email_invalid": "E-posta geçersiz",
    "client_name_required": "Lütfen adınızı girin",
    "password_required": "Lütfen şifrenizi girin",
    "password_length": "Parola en az 8 karakterden oluşmalıdır",
    "repeat_password_required": "Lütfen şifrenizi tekrarlayın",
    "repeat_password_length": "Tekrarlanan parola en az 8 karakterden oluşmalıdır",
    "message_required": "Lütfen bir mesaj girin",
    "second_password_incorrect": "İkinci şifre birincisiyle aynı değil",
    "registration_not_confirmed": "Kaydınız henüz onaylanmadı. Lütfen posta kutunuzu kontrol edin ve e-posta adresinizi onaylamak için düğmeye tıklayın",
    "account_deleted": "Bu hesap silinmiştir!",
    "account_inactive": "Bu hesabın aboneliği iptal edildi!",
    "company_required": "Lütfen şirket adını girin",
    "first_name_required": "Lütfen ilk adı girin",
    "last_name_required": "Lütfen soyadınızı giriniz",
    "country_required": "Lütfen bir ülke seçin",
    "phone_required": "Lütfen telefon numarasını girin",
    "street_required": "Lütfen sokak adını girin",
    "house_number_required": "Lütfen ev numarasını girin",
    "zip_code_required": "Lütfen posta kodunu girin",
    "zip_code_invalid": "'{{ value }}' posta kodu geçerli bir posta kodu değil",
    "city_required": "Lütfen şehrin adını girin",
    "vat_id_required": "Lütfen satış vergisi kimliğini girin",
    "timezone_required": "Lütfen bir saat dilimi seçin",
    "select_job": "Lütfen bir iş seçin",
    "title_required": "Lütfen başlığı girin",
    "type_required": "Lütfen bir tür seçin",
    "location_required": "Lütfen konumu girin",
    "questionnaire_required": "Lütfen bir anket seçin",
    "subject_required": "Lütfen konuyu girin",
    "amount_required": "Lütfen tutarı giriniz",
    "time_count_required": "Lütfen zaman miktarını girin",
    "name_required": "Lütfen isim giriniz",
    "price_required": "Lütfen fiyatı giriniz",
    "description_required": "Lütfen açıklamayı girin",
    "question_required": "Lütfen soruyu girin",
    "content_required": "Lütfen içeriği girin",
    "template_required": "Lütfen bir şablon seçin",
    "payment_schedule_required": "Lütfen bir ödeme planı seçin",
    "answer_required": "Lütfen cevabı girin",
    "website_required": "Lütfen web sitesinin adresini girin",
    "fax_required": "Lütfen faks numarasını girin",
    "currency_required": "Lütfen bir para birimi seçin",
    "vat_name_required": "Lütfen KDV'nin adını girin",
    "vat_id_name_required": "Lütfen KDV Kimliğinin adını girin",
    "vat_rates_required": "Lütfen KDV oranlarını giriniz",
    "coc_number_required": "Lütfen COC numarasını girin",
    "registered_at_required": "Lütfen şirketinizin kayıtlı olduğu bilgileri girin",
    "bank_name_required": "Lütfen banka adını girin",
    "account_holder_required": "Lütfen hesap sahibinin tam adını girin",
    "account_number_required": "Lütfen hesap numarasını girin",
    "bank_location_required": "Lütfen banka konumunu girin",
    "bank_code_number_required": "Lütfen banka sıralama kodunu girin",
    "iban_required": "Lütfen IBAN numarasını girin",
    "min_message": "Bu değer çok kısa. {{limit }} olmalıdır. karakter veya daha fazla",
    "max_message": "Bu değer çok uzun. {{limit }} olmalıdır karakter veya daha azı",
    "min_max_length": "Bu değer {{ min }} ile {{ max }} arasında olmalıdır. karakter uzunluğunda",
    "greater_than_or_equal": "Bu değer {{ compared_value }} değerinden büyük veya eşit olmalıdır.",
    "price_type_invalid": "Fiyat türü geçersiz",
    "This value should not be blank": "Bu değer boş olmamalıdır",
    "required_message": "Bu değer boş olmamalıdır",
    "This value is not a valid URL": "Bu değer geçerli bir URL değil",
    "must_upload_a_file": "Bir dosya yüklemeniz gerekiyor",
    "file_format_not_supported": "Yüklemeye çalıştığınız dosya desteklenmiyor",
    "category_not_empty": "Kategori boş değil",
    "brand_has_items": "Markanın tahsis ettiği ürünler",
    "template_has_items": "Ürünler şablona atanır",
    "item_has_variants": "Ürünün atanmış varyantları vardır",
    "no_valid_variant": "Geçerli varyant yok",
    "no_stage_set": "Seviye belirlenmedi",
    "no_pipeline_set": "Hiçbir boru hattı belirlenmedi",
    "no_users_found": "Kullanıcı bulunamadı",
    "no_team_set": "Takım seti yok",
    "budget_required": "Lütfen bütçeyi tanımlayın",
    "client_required": "Lütfen bir müşteri atayın",
    "order_required": "Lütfen bir sipariş atayın",
    "item_required": "Lütfen bir ürün atayın",
    "document_required": "Lütfen belgelerin mevcut ve geçerli olduğundan emin olun",
    "brand_required": "Lütfen bir marka seçin",
    "model_required": "Lütfen bir model seçin",
    "order_type_invalid": "Sipariş türü geçerli değil",
    "item_already_reserved": "Bazı ürünler belirli günlerde mevcut değildir",
    "wrong_credentials": "Kullanıcı adınız veya şifreniz yanlış girilmiştir. Lütfen tekrar deneyin veya \"Şifremi unuttum\" seçeneğine tıklayın",
    "no_car_found": "Bu kurşun için araç bulunamadı",
    "data_push_event_invalid": "Veri itme olayı geçersiz",
    "data_push_auth_invalid": "Veri itme kimlik doğrulama türü geçersiz",
    "cart_type_invalid": "Alışveriş sepeti türü geçersiz",
    "system_type_invalid": "Sistem türü geçersiz",
    "menu_version_invalid": "Menü sürümü geçerli değil",
    "logo_version_invalid": "Logo sürümü geçerli değil",
    "payment_method_invalid": "Belirttiğiniz ödeme yöntemini işleyemedik",
    "nationality_required": "Lütfen uyruğunuzu girin",
    "place_of_birth_required": "Lütfen doğum yerinizi giriniz",
    "date_of_birth_required": "Lütfen doğum tarihinizi giriniz",
    "id_required": "Lütfen kimliği girin",
    "meta_identifier_required": "Lütfen bir tanımlayıcı seçin",
    "payment_status_invalid": "Ödeme durumu geçerli değil",
    "iban_invalid": "Girdiğiniz değer geçerli bir hesap numarası değil",
    "swift_bic_required": "Lütfen geçerli bir SWIFT/BIC numarası girin",
    "reference_required": "Lütfen bir referans girin",
    "service_status_invalid": "Servis durumu geçerli değil",
    "date_proposal_type_invalid": "Önerilen tarih türü geçerli değil",
    "mobile_phone_invalid": "Cep telefonu numarası geçersiz",
    "mobile_phone_required": "Lütfen cep telefonu numaranızı girin",
    "phone_invalid": "Telefon numarası geçersiz",
    "fax_invalid": "Faks numarası geçersiz",
    "salutation_required": "Lütfen bir selamlama seçin",
    "gender_required": "Lütfen bir cinsiyet seçin",
    "stripe_error": "Stripe'a bağlanırken hata oluştu",
    "stripe_connect_error_with_type": "CreaCheck'i Stripe hesabınıza bağlayamadık. Hata kodu: {type}.",
    "car_rental_pickup_time_invalid": "İstenen teslim alma saatinin lokasyonun çalışma saatleri dışında olması",
    "car_rental_drop_off_time_invalid": "Bir araç için talep edilen bırakma zamanının lokasyonun çalışma saatleri dışında olması",
    "accept_terms_and_cancellation_before_continuing": "Lütfen Genel Hüküm ve Koşullarımızı ve iptal politikamızı okuduğunuzu onaylayın",
    "problem_processing_your_credit_card": "Kredi kartınızı işleme koyarken bir sorun yaşıyoruz",
    "invoice_type_invalid": "Fatura türü geçersiz",
    "doc_number_required": "Lütfen belge numarasını girin",
    "doc_issuing_city_required": "Lütfen düzenleyen şehri belirtiniz",
    "doc_issuing_authority_required": "Lütfen düzenleyen makamı belirtiniz",
    "doc_issue_date_required": "Lütfen yayın tarihini giriniz",
    "doc_expiration_date_required": "Lütfen son kullanma tarihini girin",
    "test_domain_required": "Lütfen bir test alan adı girin",
    "iban_has_wrong_format": "IBAN formatı doğru değil",
    "car_cash_payment_data_missing_or_invalid": "Nakit ödeme verileri eksik veya geçersiz",
    "car_registration_service_only_available_in_germany": "Yetkilendirme hizmeti şu anda sadece Almanya'da mevcuttur",
    "car_transport_only_available_in_germany": "Nakliye hizmetimiz şu anda sadece Almanya'da mevcuttur",
    "target_type_invalid": "Hedef türü geçerli değil",
    "company_email_must_be_different_from_users_email": "Şirketinizin e-posta adresi sizin e-posta adresinizden farklı olmalıdır",
    "car_transport_distance_too_short": "Araçla taşıma sadece 100 km'den daha uzak yerlere mümkündür",
    "first_offer_price_too_low": "İlk teklifiniz çok düşüktü ve kabul edilmedi",
    "first_offer_price_too_high": "İlk teklifinizin orijinal fiyattan daha yüksek olması ve kabul edilmemesi",
    "current_offer_too_low": "Mevcut teklifiniz çok düşük",
    "not_your_turn_to_negotiate_the_price": "Teklifinizi şu anda güncelleyemezsiniz. Lütfen çalışanımızın yanıtını bekleyin",
    "the_question_must_be_answered": "\"{{ soru }}\" sorusu yanıtlanmalıdır",
    "validation_problems_with_item_attributes": "Niteliklerin doğru doldurulup doldurulmadığını kontrol edin. Oluşan sorunlar kırmızı ile işaretlenmiştir",
    "the_field_name_contains_following_errors": "fieldName} alanı aşağıdaki hatayı içeriyor: {helpText}",
    "plugin_required": "Lütfen bir abonelik planı seçin.",
    "invalidRegex": "Belirttiğiniz düzenli ifade geçersiz",
    "assignment_strategy_type_invalid": "Atama stratejisi türü geçerli değil",
    "cannot_remove_role_company": "ROLE_COMPANY kullanıcı rolünü kaldırmak mümkün değildir",
    "coupon_type_invalid": "Lütfen geçerli bir kupon türü seçin",
    "subscription_starting_fee": "Abonelik için giriş ücreti",
    "comms_center_monthly_fee": "Creacheck İletişim Merkezi",
    "comms_center_chat": "Creacheck İletişim Merkezi - Müşteri sohbeti",
    "comms_center_call": "Creacheck İletişim Merkezi - Müşteri çağrısı",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Ne yazık ki e-posta ve şifreyi bilmiyoruz",
    "access_denied": "Giriş yapmak için Creacheck.com erişimini kabul etmelisiniz",
    "oauth_email_missing": "Sosyal medya üzerinden giriş yapamıyoruz. Lütfen e-posta adresinizle giriş yapın.",
    "User already exists": "Girdiğiniz e-posta adresi veritabanımızda zaten mevcut. Şifrenizi mi unuttunuz?",
    "next": "Daha ileri",
    "create_comment": "Bir yorum yazın",
    "new_pipeline": "Yeni boru hattı",
    "new_stage": "Yeni seviye",
    "quantity": "Miktar",
    "login_error": "Oturum açarken hata oluştu",
    "not_found": "Bulunamadı",
    "deal_information": "Anlaşma bilgileri",
    "login_domain": "Giriş alanı",
    "login_domain_successfully_changed": "Oturum açma etki alanı başarıyla değiştirildi.",
    "missing_lead_information": "Lütfen eksik müşteri adayı bilgilerini girin",
    "missing_informations": "Eksik bilgi",
    "missing_client_signature": "Eksik müşteri imzası",
    "previous": "Daha önce",
    "current": "Güncel",
    "customCssInvalid": "Özel CSS geçersiz ({{ hata }})",
    "accountAndLogin": "Hesap & Giriş",
    "invalidEIN": "Geçersiz eVB numarası (elektronik sigorta onayı)",
    "radio": "Radyo",
    "airConditioning": "Klima",
    "parkingAssistanceRear": "Arka park yardımı",
    "airConditioningAutomatic": "Otomatik klima kontrolü",
    "navigation": "Sat Nav",
    "alloyRims": "Alüminyum jantlar",
    "parkingAssistanceFrontRear": "Ön ve arka park yardımı",
    "metallicPaint": "Metalik boya",
    "cruiseControl": "Hız sabitleyici",
    "seatHeating": "Koltuk ısıtması",
    "leatherSeats": "Deri koltuklar",
    "equipmentLevel": "Ekipman seviyesi",
    "motorPower": "Performans",
    "currentLocation": "Mevcut konum",
    "distance": "Mesafe",
    "selected": "seçilmiş",
    "noBrandSelected": "Seçilen marka yok",
    "showMore": "Daha fazla göster",
    "showLess": "Daha az göster",
    "optional": "Opsiyonel",
    "altogether": "Toplamda",
    "monday": "Pazartesi",
    "tuesday": "Salı",
    "wednesday": "Çarşamba",
    "thursday": "Perşembe",
    "friday": "Cuma",
    "saturday": "Cumartesi",
    "sunday": "Pazar",
    "payment_data_missing_or_invalid": "Ödeme verileri eksik veya geçersiz",
    "previousSearchRequests": "Önceki aramalar",
    "reset": "Sıfırla",
    "number_days": "{günler, çoğul, sıfır {# gün} bir {# gün} diğer {# gün}}",
    "number_months": "{aylar, çoğul, sıfır {# ay} bir {# ay} diğer {# ay}}",
    "outdated_browser": "Tarayıcınızın modası geçmiş!",
    "update_browser_text": "Lütfen tarayıcınızı bu web sitesini doğru görüntüleyecek şekilde güncelleyin.",
    "update_browser_btn_text": "Tarayıcıyı şimdi güncelleyin",
    "save_successfully": "Başarıyla kaydedildi",
    "add_quickly": "Çabuk ekleyin",
    "load_data": "Veri yükleyin",
    "car_data_import_vin_info": "Araç veri formunu otomatik olarak tamamlamak için geçerli VIN numarasını girin.",
    "car_data_import_missing_attr_values": "Bazı öznitelik değerleri eksiktir - bunlar formda işaretlenmiştir.",
    "vin_invalid": "FIN geçersiz",
    "others": "Diğer",
    "activate_plugin_to_enable_feature": "Bu işlev yalnızca aşağıdaki eklenti ile kullanılabilir. Bu işlevi kullanmaya devam etmek için eklentiyi etkinleştirin.",
    "car_data_import_failed": "Bu VIN için araç verilerini içe aktarmak mümkün olmadı. Belirtilen VIN'in doğru olup olmadığını kontrol edin.",
    "send_mail_via_outlook": "E-posta, Microsoft Outlook hesabınızla ({email}) gönderilir ve daha sonra \"Gönderilenler\" klasörünüzde bulunur.",
    "permission_required": "Gerekli izinler",
    "phone_call_permissions_required": "Bu uygulama arama yapabilmek için telefon hesaplarınıza erişmelidir",
    "calling": "Ara beni.",
    "callingTo": "Arayın",
    "startingCall": "Bir çağrı başlatın",
    "callEnded": "Arama sonlandırıldı",
    "inCall": "'Çağrı üzerine",
    "callCancelled": "Çağrı iptal edildi",
    "callRejected": "Çağrı reddedildi",
    "is_calling": "isim% arar...",
    "contacts": "İletişim",
    "addresses": "Adres defteri",
    "chat": "sohbet",
    "calls": "Çağrılar",
    "endCustomerNewMessage": "Yeni müşteriden mesaj",
    "returnToTheActiveCall": "Etkin çağrıya geri dönün",
    "agentStatus": "Temsilci durumu",
    "switchOnAgentNote": "Müşteri sohbetlerini ve görüntülü aramaları kabul etmek için etkinleştirin",
    "switchOffAgentNote": "Müşteri sohbetlerini ve görüntülü aramaları kabul etmemek için devre dışı bırakın",
    "activateAgent": "Müşteri sohbetlerinin kabulünü etkinleştirin",
    "deactivateAgent": "Müşteri sohbetlerinin kabulünü devre dışı bırakın",
    "startAudioCallWith": "Telefon görüşmesini {{to}} ile başlatın",
    "startVideoCallWith": "Video görüşmesini {{to}} ile başlatın",
    "terms_and_conditions": "Şartlar ve koşullar",
    "form_incorrect_data": "Girmiş olduğunuz veriler doğru değil. Lütfen girişlerinizi kontrol edin ve tekrar gönderin.",
    "accept_terms_and_condition_start_chat": "\"Sohbeti başlat\" seçeneğine tıklarsanız, hüküm ve koşulları kabul etmiş olursunuz",
    "start_chat": "Sohbet başlat",
    "connecting_with": "İle bağlantı kurun",
    "connected_with": "ile bağlantılı",
    "do_calling": "aramalar",
    "chat_inactivity_warning": "Sohbet çok uzun süredir aktif değil ve yakında kapatılacak.",
    "get_in_contact_with_us": "Bizimle iletişime geçin",
    "no_agent_available": "Maalesef hiçbir temsilci çevrimiçi değil. Lütfen mesaj bırakın, mümkün olan en kısa sürede sizinle iletişime geçeceğiz.",
    "hello": "Merhaba!",
    "we_are_here_for_you": "Sizin için buradayız ve sorularınızı veya geri bildirimlerinizi bekliyoruz.",
    "start_conversation": "Konuşmaya başlayın",
    "welcome_customer": "Benim adım {ilkisim} {soyisim}. Size nasıl yardımcı olabilirim?",
    "end_chat": "Çıkış",
    "send_request": "Soruşturma gönder",
    "checkout_payment_agreement": "İptal süresi başlamadan önce {şirketin} ek hizmetleri sağlamaya başlamasına izin veriyorum ve bu ek hizmetler tam olarak sağlandığında iptal hakkımı kaybedeceğimi anlıyorum.",
    "checkout_buy_order_legal_text": "Satın alma işlemi için {company} ({privacy_policy}) şirketinin {general_terms_and_conditions} ve {cancellation_policy_and_cancellation_form} kuralları geçerlidir",
    "checkout_services_terms_cancelation_legal_text": "Rezervasyonu yapılan ek hizmetler ({booked_services}) için {general_terms_and_conditions} ve {company} ({privacy_policy}) {cancellation_policy_and_cancellation_form} geçerlidir",
    "no_credit_cards": "Kredi kartı yok",
    "minimize_video": "Video görünümünü küçültün",
    "maximize_video": "Video görünümünü en üst düzeye çıkarın",
    "enable_fullscreen": "Tam ekran modunu etkinleştirme",
    "disable_fullscreen": "Tam ekran modunu devre dışı bırakma",
    "enable_screensharing": "Masaüstünü paylaşın",
    "disable_screensharing": "Masaüstü paylaşımını sonlandırın",
    "mute_microphone": "Mikrofonun sesini kapat",
    "unmute_microphone": "Mikrofonun sesini açma",
    "enable_camera": "Kamerayı açın",
    "disable_camera": "Kamerayı kapatın",
    "end_call": "Aramayı sonlandırın",
    "end_videocall": "Video akışını sonlandırma",
    "muted": "Sessiz",
    "overall_feedback": "Bizimle olan iletişiminizi nasıl değerlendirirsiniz?",
    "connection_quality_feedback": "Bağlantı kalitesini nasıl değerlendirirsiniz?",
    "send_feedback": "Geri bildirim gönderin",
    "customer_chat_ended_info": "Sohbetiniz sonlandırıldı",
    "checking_connection": "Bağlantı kontrol edildi",
    "call_again": "Tekrar arayın",
    "end_call_local_disconnected": "Bağlantı sorunları nedeniyle çağrı sonlandırma",
    "end_call_remote_disconnected": "RemoteUserName} sayfasındaki bağlantı sorunları nedeniyle çağrı sonlandırıldı",
    "network_disconnected_warning": "Şu anda internet bağlantısı bulunmamaktadır.",
    "network_disconnected": "Bağlantı kesildi",
    "network_want_reconnect": "Bağlantınız kesildi. Yeniden bağlanmak ister misiniz?",
    "leave_as_an_email_message": "Bize bir e-posta mesajı bırakın",
    "continue_call": "Aramaya devam et",
    "continue_text_chat": "Metin sohbetine devam et",
    "continue_conversation": "Konuşmaya devam edin",
    "disconnected": "Ayrı",
    "disconnect_reason": "Olası neden",
    "disconnect_reason_transport_close": "Bu sayfadan ayrılın",
    "disconnect_reason_ping_timeout": "İnternet bağlantısı yok",
    "customer_may_return": "Müşteri yakında geri gelebilir.",
    "mobile": "Cep telefonu",
    "headquarter": "Merkez",
    "extension-number": "Dahili numara",
    "add_contact": "Kişi ekle",
    "add_address": "Adres ekle",
    "choose_contact": "Kişi seçin",
    "number": "Sayı",
    "new_number": "Yeni numara",
    "address_mail": "Posta adresi",
    "address_delivery": "Teslimat adresi",
    "address_invoice": "Fatura adresi",
    "new_address": "Yeni adres",
    "find_address": "Adres bulun",
    "searching": "Arama...",
    "address_change": "Adres değişikliği",
    "address_ask_change": "Bu adresin değiştirilmesi, bu adrese sahip tüm kişileri etkileyecektir. Adresi değiştirmek ister misiniz?",
    "designation": "Tanımlama",
    "net": "Net",
    "gross": "Brüt",
    "exp_delivery_time": "beklenen teslimat süresi",
    "workdays": "İş günleri",
    "special_offer": "Eylem",
    "transportation_cost": "Nakliye masrafları",
    "discount": "İndirim",
    "total": "Toplam",
    "total_gross": "Toplam (KDV dahil)",
    "product_details": "Ürün detayları",
    "type_of_order": "Sipariş türü",
    "number_of_copies": "Baskı",
    "type_of_order_choices": "Düzenli baskı üretimi veya baskı verilerini PDF dosyası olarak indirme",
    "type_of_order_website": "Bu web sitesinin sağlanması",
    "print_production": "Baskı üretimi",
    "pdf_download": "PDF-İndir",
    "website_upload": "Web sitesini yükleyin",
    "production_time": "Üretim süresi",
    "production_time_description": "Lütfen istediğiniz seçeneği seçin",
    "production_standard": "Standart üretim",
    "production_express": "Ekspres üretim",
    "production_overnight": "Gecelik üretim",
    "choose_edition": "Seçkin baskı",
    "choose_edition_description": "Kademeli fiyatlı baskılar. Varsa indirimler ve promosyonlar vurgulanır.",
    "more_options": "Diğer seçenekler",
    "more_options_description": "Siparişinize başka seçenekler ekleyin.",
    "manual_data_review": "Manuel veri kontrolü (sigorta dahil)",
    "manual_item_entry": "Pozisyonun manuel olarak girilmesi",
    "web_enabled_pdf": "Baskı verileri web özellikli PDF olarak da mevcuttur.",
    "cart_production_type_change_warning_title": "Sirkülasyon ayarlanır",
    "cart_production_type_change_warning": "Seçilen baskı çalışması {{shippingType}} için mevcut değil. Mevcut maksimum baskı adedi olarak değiştirilecektir. Devam etmek istediğinizden emin misiniz?",
    "cart_no_warranty_without_manual_data_check": "Not: Manuel veri kontrolü seçilmemiştir.",
    "campaign_budget_exceed_warning": "Kampanya bütçesi aşıldı. Onay gerekiyor!",
    "user_budget_exceed_warning": "Kullanıcı bütçesi aşıldı. Serbest bırakma gerekli!",
    "order_quantity": "Sipariş miktarı",
    "data_check": "Veri kontrolü",
    "split_shipping": "Kısmi teslimat",
    "cart_not_logged_in": "Oturum açmış kullanıcı yok",
    "cart_contact_or_address_not_found_invoice": "Lütfen bir fatura adresi seçin",
    "cart_contact_or_address_not_found_shipping": "Lütfen bir teslimat adresi seçin",
    "cart_edtion_item_or_variant_not_found": "Sayı, makale veya varyant bulunamadı",
    "cart_edtion_item_not_available": "item_name% makalesi artık mevcut değil",
    "cart_no_print_type_found": "Baskı türü bulunamadı",
    "cart_print_type_invalid": "Baskı türü geçersiz:",
    "cart_no_shipping_type_found": "Gönderim yöntemi bulunamadı",
    "cart_checkout_info_missing": "Onay kutusu bilgileri eksik:",
    "cart_reduced_vat_rate_check_not_supported": "Desteklemeyen kalemler için indirimli KDV oranının kontrol edilmesi talep edildi:",
    "cart_no_price_found": "Fiyat bulunamadı",
    "cart_price_mismatch": "Fiyat, kullanıcıya sunduğumuz fiyatla uyuşmuyor",
    "cart_web_pdf_price_mismatch": "Web PDF fiyatı kullanıcıya sunduğumuz fiyatla uyuşmuyor",
    "cart_contact_or_address_not_found_split_shipping": "Lütfen bölünmüş gönderim için bir adres seçin",
    "cart_quantity_not_found_split_shipping": "Bölünmüş sevkiyat için miktar parametreleri bulunamadı",
    "cart_split_shipping_quantity_mismatch": "Bölünmüş sevkiyat miktarı üretim miktarıyla eşleşmiyor",
    "cart_coupons_mismatch": "Kullanılan kuponlar eşleşmiyor",
    "cart_coupon_cant_be_used": "İstenen kuponun süresi doldu veya kullanılamıyor",
    "cart_coupon_value_mismatch": "Kupon değeri doğru değil",
    "cart_camaign_budget_processing_error": "Kampanya bütçesi işlenirken beklenmeyen bir sorun oluştu",
    "cart_campaign_budget_has_changed": "Kampanya bütçesi değişti",
    "cart_user_budget_has_changed": "Kullanıcı bütçesi değişti",
    "cart_user_budget_processing_error": "Kullanıcı bütçesi işlenirken beklenmeyen bir sorun oluştu",
    "cart_domain_not_active": "Etki alanı etkin değil",
    "cart_missing_desired_domain": "İstenen etki alanının belirtilmesi eksik",
    "lead_monitor_order_payment_receipt": "Gelen ödemeleri izleyin",
    "lead_format_message_description_route53_register_domain": "0} alan adını kaydedin ve {1} alanına yönlendirin",
    "lead_title_route53_register_domain": "AWS alan adını kaydetme",
    "domain_not_available": "Alan adı mevcut değil",
    "invoice_payment_option": {
      "enabled": "Etkinleştirilmiş",
      "disabled": "Devre dışı bırakıldı",
      "inherited": "Miras",
      "title": "Faturanın ödenmesi",
      "loading": "Devralınan ayarı yükle",
      "effective_setting": "Etkili ayar"
    },
    "invoice_payment_threshold": {
      "title": "Eşik değer",
      "placeholder": "örnekte, 100",
      "no_effect": "Etkisi yok",
      "loading": "Devralınan ayarları yükleme",
      "info_text": "Fatura ile ödeme için minimum sipariş değeri",
      "effective_threshold": "Etkili eşik",
      "not_defined": "tanımlanmamış"
    },
    "remind_period": {
      "days": "Günler",
      "inherited_setting": "Kalıtsal tutum",
      "title": "Faturanın son ödeme tarihi Hatırlatma dönemi"
    },
    "sellDownloadPdf": "PDF satışını indir",
    "success_management": {
      "title": "Başarı yönetimi",
      "manager": "Başarı yöneticisi",
      "switch_title": "Son görüntülenen anıları etkinleştirin",
      "interval": "Son görüntülenen dönem, gün",
      "last_seen": "Son görülme",
      "error_no_self": "Kendini Başarı Yöneticisi olarak atayamıyor",
      "error_no_same_parent": "Başarı Yöneticisi başka bir şirkete bağlı olmalıdır"
    },
    "copy": "Anlaşıldı",
    "is_client_registration_allowed": "Kaydı etkinleştirin",
    "is_email_validation_enabled": "E-posta doğrulama",
    "email_validation_pattern": "E-posta doğrulaması için regex",
    "confirmation_email_cc": "Kayıt onayı için CC",
    "client_registration_title": "Kayıt ayarları",
    "registration_fields_show": "Ekran alanları",
    "registration_fields_require": "Zorunlu alanlar",
    "accept_terms_of_use_before_continuing": "Devam etmeden önce, kullanım şartlarımızı kabul etmelisiniz",
    "subscription_settings": "Abonelikler",
    "client_subscription_settings": "Müşteri ve ürün abonelikleri",
    "subscription_settings_description": "Burada temel bir abonelik oluşturabilir ve bir tür atayabilirsiniz",
    "client_subscription_settings_description": "Burada temel aboneliği türe bağlı olarak bir müşteriye veya bir ürüne atayabilir ve fiyatları ayarlayabilirsiniz",
    "subscriptions_": "Abonelikler",
    "subscription_name": "İsim",
    "subscription_title": "Başlık",
    "subscription_description": "Açıklama",
    "subscription_fee": "Ücret",
    "subscription_startingFee": "Giriş ücreti (isteğe bağlı)",
    "subscription_start": "Başlangıç",
    "subscription_end": "Bitiş",
    "subscription_status": "Durum",
    "subscription_action": "Eylemler",
    "subscription_items": "Ürünler",
    "subscription_clients": "Müşteriler",
    "subscription_companies": "Şirketler",
    "subscription_companies_detailed_role": "Şirket Kullanıcısı/Rolü",
    "subscription_basic": "Temel abonelik",
    "add_subscription": "Abonelik ekle",
    "create_subscription": "Abonelik oluştur",
    "edit_subscription": "Abonelik kaydet",
    "subscription_language": "Dil seçimi",
    "period_status": "Dönem sonunda devre dışı bırakın",
    "subscription_edit": "Düzenle",
    "omit_subscription_fee": "Giriş ücretini atlayın",
    "invoice_email": "Faturalar için e-posta adresi",
    "order_processing_contract": "Sipariş işleme sözleşmesi",
    "accept_order_processing_contract_before_continuing": "Devam etmeden önce, sipariş işleme sözleşmemizi kabul etmelisiniz",
    "no_image_title": "Resim başlığı bulunamadı. Düzenlemek için buraya tıklayın.",
    "no_image_description": "Resim açıklaması bulunamadı. Düzenlemek için buraya tıklayın",
    "no_image_copyright": "Telif hakkı bilgisi bulunamadı. Düzenlemek için buraya tıklayın",
    "no_text": "Metin bulunamadı. Düzenlemek için buraya tıklayın",
    "subscription_clients_and_companies": "Müşteriler ve şirketler",
    "save_subscription": "Kaydet",
    "go_to": "Gitmek",
    "no_invoices_to_display": "Görüntülenecek fatura yok",
    "url_settings": "URL ayarları",
    "update_notification": "Güncelleme yönetimi",
    "update_notification_description": "Açıklama",
    "update_notification_settings_description": "Güncellemeler için bildirimleri buradan yönetebilirsiniz",
    "update_name": "İsim",
    "update_notification_meta": {
      "title": "Güncelleme yönetimi",
      "description": "Güncelleme bildirimlerinizi buradan yönetin",
      "name": "Güncelleme yönetimi"
    },
    "new_update_notification": "Yeni güncelleme bildirimi oluşturun",
    "update_from": "itibaren",
    "update_to": "kadar",
    "edit_update_notification": "Güncelleme bildirimini düzenle",
    "individual_prices": "Özelleştirilmiş fiyatlar",
    "template_pdf_profile_x3": "X-3 formatında PDF oluşturma",
    "countries_for_prices": {
      "de": "Almanya",
      "ch": "İsviçre",
      "at": "Avusturya",
      "es": "İspanya"
    },
    "choose_country": "Lütfen bir ülke seçin",
    "product_groups": "Ürün grupları - Tedarikçiler",
    "product_groups_description": "Burada tedarikçilerin ürün gruplarını yönetebilirsiniz",
    "new_product_group": "Yeni ürün grubu ekleme",
    "edit_product_group": "Düzenle",
    "create_product_group": "Oluştur",
    "product_group_number": "Ürün grubu numarası",
    "save_product_group": "Kaydet",
    "product_group_actions": "Eylemler",
    "product_group_status": "Durum",
    "hide_preview": "Düzenleyicide önizlemeyi devre dışı bırakma",
    "editor_settings": "Ayarlar Editörü",
    "share_edition": "Paylaşım baskısı",
    "canceled_quote": "Teklif reddedildi",
    "active_quote": "Teklif beklemede",
    "manual_quote_status": "Tekliflerin durumu",
    "invalid_manual_quote_status": "Teklifin geçersiz durumu",
    "internal_status": "Dahili durum",
    "edit_quote": "Teklifi düzenle",
    "copy_quote": "Kopya teklifi",
    "open_lead": "Açık Kurşun",
    "upload_pdf": "PDF yükleyin ve bağlayın",
    "upload_pdf_title": "PDF Yükleme",
    "upload_pdf_status_done": "PDF dosyanız başarıyla yüklendi",
    "upload_pdf_status_error": "PDF yükleme başarısız oldu.",
    "placeholder_upload_error": "Yükleme sırasında bir sorun oluştu!",
    "linkedin_insight_tag": "LinkedIn Insight Etiketi",
    "linkedin_partner_id": "Ortak Kimliği",
    "tracking_pixel": "İzleme Pikseli, örneğin Meta Piksel",
    "tracking_pixel_data": "Uygulama kodu",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Görüntü yükleme ve önizleme",
    "upload_website_favicon_info": "Lütfen dikkat! Gerekli dosya formatı PNG'dir. Kullanılan cihaza bağlı olarak, optimum çözünürlük 32 x 32 piksel ile maksimum 64 x 64 piksel arasındadır. Kare oldukları ve maksimum çözünürlüğü aşmadıkları sürece diğer boyutları da kullanabilirsiniz.",
    "upload_favicon_error": "Görüntü gerekli çözünürlüğe sahip değil",
    "go_back_to_campaign": "Kampanyaya geri dönelim",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Google Etiket Yöneticisi",
    "google_tag_manager_id": "GTM KIMLIĞI",
    "website_analyse": "Web sitesi analizi",
    "legal_information": "Yasal bilgiler",
    "contact_info": "İletişim bilgileri",
    "import_users_via_csv": "Bir CSV dosyasını içe aktararak kullanıcı oluşturma",
    "remove_users_via_csv": "Bir CSV dosyasını içe aktararak kullanıcıları kaldırma",
    "import_users": "Kullanıcıları içe aktarma",
    "remove_users": "Kullanıcıyı kaldır",
    "fill_data_policy_before_autosave": "Otomatik kaydetme başarısız oldu. Lütfen veri koruma ve yasal bildirim bilgilerini tamamlayın. Bunlar ayarlarda bulunabilir",
    "invoice_customer_info": "ID - Şirket / İsim",
    "invoice_search_debtor": "Borçlu numarası",
    "invoice_search_vat": "KDV",
    "invoice_search_amount": "Miktar",
    "search_field_name": "Bir arama alanı seçin",
    "search_field_info_text": "Arama alanında sayısal bir değer aramak istiyorsanız, bir alan seçmelisiniz",
    "your_export": "İhracatınız",
    "export_download_info": "Dosyanız oluşturuldu ve artık kullanılabilir.",
    "export_download": "İndir",
    "export_file": "Dosya",
    "export_share": "Paylaş",
    "image_permission_title": "Dikkat!",
    "image_permission_warning": "Görüntü hakları mevcut mu / tüm kişilerden onay alındı mı?",
    "other_settings": "Diğer ayarlar",
    "image_rights_query": "Görüntü hakları sorgusu",
    "direct_ordering_label": "Doğrudan sipariş",
    "direct_ordering_info_text": "Doğrudan sipariş durumunda, oluşturulan baskı artık düzenlenemez. Doğrudan alışveriş sepetine yerleştirilir.",
    "subscription_automation": {
      "title": "Yeni kullanıcı abonelikleri için faturalandırma hedefi",
      "payer": "Ödeyen",
      "fee_for_role_user": "ROLE_USER için ücret",
      "fee_for_role_admin": "ROLE_ADMIN için ücret",
      "fee_for_role_wl_admin": "ROLE_WL_ADMIN için ücret",
      "hint_title": "Not",
      "hint_body": "Lütfen daha sonra oluşturulan tüm kullanıcıları kapsayacak şekilde bu şirket için bir şirket aboneliği oluşturun ve etkinleştirin.",
      "debtor_ancestor": "Her kullanıcı aboneliği %billingTargetName%'e faturalandırılır.",
      "debtor_ancestor_missing": "Hiçbir selef şirket borçlu olarak işaretlenmemiştir. Lütfen bunu düzeltin."
    },
    "need_content_authorization": "İçerik yetkilendirmesi gerekli",
    "publisher_info_text": "Yayıncı, yasal yönergelere uygun olarak gerekli her reklam ortamına otomatik olarak eklenir.",
    "mark_campaign": "Mark kampanyası",
    "template_exports": {
      "download_complete": "İndirme işlemi tamamlandı",
      "export": "İhracat",
      "download_files": "Dosyaları indirin",
      "synchronize": "Senkronize et"
    },
    "info_import_and_export": {
      "note": "İthalat ve ihracat hakkında genel bilgiler",
      "import": "İçe aktar: Şablon ayarları (CSS, içerik blokları, diğer ayarlar) ve dosyalar hakkındaki tüm bilgileri içe aktarır. Dışa aktarma için bir başlangıç zip dosyası oluşturur. Mevcut zip dosyalarının üzerine yazılır.",
      "upload": "Yükleme: S3 şablon dizinine başka dosyalar ekler. Bu dosyalar dışa aktarım için zip dosyasına eklenir.",
      "sync": "Senkronize Et: Şablon ayarlarını (CSS, içerik blokları, diğer ayarlar) S3 şablon dizinindeki dosyalarla (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) ve zip dosyasıyla (dışa aktar) senkronize eder.",
      "export": "Dışa Aktar: Kopyalamak ve yapıştırmak için bir index.html yapısı oluşturur",
      "download": "Dosyaları indirin: Mevcut zip dosyasını indirir. (En son verileri elde etmek için indirmeden önce bir senkronizasyon gerçekleştirin",
      "copy": "Şablonu Kopyala: 'Şablonu Kopyala' işlevi yalnızca şablon ayarlarını kopyalar, dizinleri ve dosyaları kopyalamaz"
    },
    "template_upload_title": "Dosyaları şablon dizinine yükleyin",
    "pls_choose_text": "Lütfen bir metin seçin",
    "discounts": {
      "discount_type_invalid": "İndirim türü geçerli değil",
      "discounts_tab_title": "İndirimler",
      "intro_text": "Lütfen FlyerAlarm ürün grubu numarasını ve ilgili indirim türünü ve değerini girin. Bunlar, ödeme sırasında mevcut kuruluştaki tüm kullanıcılara uygulanacaktır.",
      "none_yet_add_some": "Henüz hiçbir indirim tanımlanmadı. Lütfen biraz ekleyin.",
      "number_must_be_unique": "Numara benzersiz olmalıdır",
      "must_be_0_to_100": "0 ile 100 arasında olmalıdır"
    },
    "link_to_uploaded_pdf": "Yüklenen PDF dosyasının URL'si",
    "upload_qrcode_logo": "QR kodu için logo yükleyin",
    "choose_file": "Dosya seçiniz",
    "upload_successful": "Yükleme başarılı",
    "upload_failed": "Yükleme başarısız",
    "qr_logo": "Logo",
    "qr_example": "Örnek",
    "qr_settings": "QR kodu ayarları",
    "qr_margin": "Mesafe",
    "qr_upload": "QRCode logosunu yükleyin",
    "google_search_console": "Google Arama Konsolu",
    "gsc_html_tag": "HTML-TAG doğrulaması",
    "content_id": "İçerik Kimliği",
    "gsc_html_tag_example": "Lütfen HTML etiket doğrulamasını kullanın. Kimliği kopyalayın, örneğin Xu9c238409d8JDSF8... doğrulama için Google'dan aldığınız meta etiketin içerik niteliğinden kopyalayın ve kimliği buraya yapıştırın. Örnek: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Kampanyanızda iyi şanslar!",
      "order_complete": "Siparişiniz başarıyla tamamlandı",
      "pls_check_inbox": "Lütfen gelen kutunuzu kontrol edin.",
      "order_info": "Tüm sipariş detayları sizin için kayıtlı e-posta adresinize gönderilmiştir. Sipariş geçmişinizi istediğiniz zaman şu adresteki kullanıcı hesabınızdan görüntüleyebilirsiniz",
      "orders": "Siparişler",
      "view": "Bak.",
      "start_next_campaign": "Şimdi başka bir kampanya başlatın"
    },
    "no_product_selected": "Seçili ürün yok",
    "mail_messages": {
      "header": {
        "header_title": "Akıllı çevrimiçi tasarım portalı",
        "support": "Yardım & SSS",
        "login": "GİRİŞ YAP"
      },
      "footer": {
        "email_info": "Bu e-postayı %company% şirketine kayıt olduğunuz için alıyorsunuz.",
        "email_info_first_name_form": "Bu e-postayı %company% şirketine kayıt olduğunuz için alıyorsunuz.",
        "dont_reply": "Bu e-posta, gelen e-postaları alamayan bir adresten gönderilmiştir. </br>Lütfen bu mesajı yanıtlamayın. Herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle şu adresten iletişime geçin",
        "agb": "GTC",
        "privacy_policy": "Veri koruma",
        "imprint": "Künye"
      }
    },
    "content_check": "İçerik incelemesi",
    "terms_of_payment": "Ödeme koşulları",
    "terms_of_payment_edit": "Ödeme koşullarını düzenleyin",
    "cancel_impersonation": "Rol değişimine son verin",
    "impersonate": "Rolü kabul et",
    "template_deleted_warning": "Dikkat! Baskı şablonu artık mevcut değildir.",
    "click_to_enable_editing": "Geçerli sekmede düzenlemeyi etkinleştirmek için buraya tıklayın.",
    "click_to_send_a_request_to_enable_editing": "İşlemeyi etkinleştirmek üzere bir talep göndermek için buraya tıklayın",
    "waiting_for_form_release": "Serbest bırakılmayı bekleyin",
    "already_edited_by_another_user": "Bu baskı zaten başka bir kullanıcı tarafından düzenlenmiş olabilir.",
    "please_wait": "Lütfen bekleyin.",
    "user_already_requested_release": "onay için zaten başvuruda bulundu",
    "user_requested_that_you_give_up_the_editing": "düzenlemek için izin ister.",
    "emergency_release": "Serbest bırakmayı zorla. Kendi sorumluluğunuzda kullanın. Sürüm, üzerinde çalışmakta olan başka bir kullanıcı tarafından üzerine yazılabilir.",
    "alt_debtor_number": "Alternatif borçlu kimliği",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Bu seçeneği etkinleştirirseniz, bootstrap kütüphaneleri entegre edilir",
    "last_queried_attributes": "Son talep edilen fiyatlar",
    "transfer_campaign": "Transfer",
    "duplicate_campaign_description": "Kampanyayı çoğaltın ve tek tek kullanıcılara veya bir şirketin tüm kullanıcılarına atayın",
    "duplicate_campaign": "Yinelenen",
    "clone_to_single_user": "Bir veya daha fazla kullanıcı için kopya oluşturma",
    "clone_to_all_users_of_company": "Bir şirketin tüm kullanıcıları için kopya oluşturma",
    "enum_option_invalid": "Geçersiz seçenek",
    "pdf_download_option": {
      "enabled": "Etkinleştirilmiş",
      "disabled": "Devre dışı bırakıldı",
      "inherited": "Miras",
      "title": "PDF indirmeye izin verildi",
      "loading": "Devralınan ayarı yükle",
      "effective_setting": "Etkili ayar"
    },
    "choose_image_for_filter": "Lütfen bir resim seçin",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Yeni faturayı e-posta ile gönder BCC",
      "email_address_bcc": "E-posta adresi BCC",
      "info_text": "Yeni fatura e-postasının gizli bir kopyası da bu adrese gönderilecektir."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Bu ürün, bir Creacheck çalışanı tarafından manuel olarak kontrol edildikten sonra indirimli KDV oranı için uygundur"
    },
    "forgot_password_infotext": "Şifrenizi unuttuysanız, buradan sıfırlayabilirsiniz. Lütfen aşağıdaki forma kayıtlı e-posta adresinizi girin ve \"Devam\" butonuna tıklayın. Daha sonra size daha fazla bilgi içeren bir e-posta göndereceğiz.",
    "forgot_password_infotext_robethood": "Şifrenizi unuttuysanız, buradan sıfırlayabilirsiniz. Lütfen aşağıdaki forma kayıtlı e-posta adresinizi girin ve \"Devam\" butonuna tıklayın. Daha sonra size daha fazla bilgi içeren bir e-posta göndereceğiz.",
    "forgot_password_info_email_not_used": "Hesabınızla ilişkili e-posta adresini artık kullanmıyorsanız, lütfen iletişim formumuzu kullanarak bizimle iletişime geçin:",
    "forgot_password_info_email_not_used_robethood": "Hesabınızla ilişkili e-posta adresini artık kullanmıyorsanız, lütfen iletişim formumuz aracılığıyla bizimle iletişime geçin:",
    "invoice_greeting_male": "Sayın Bay",
    "invoice_greeting_female": "Sevgili Bayan",
    "invoice_greeting_text": "Şirket% üzerinden verdiğiniz sipariş için çok teşekkür ederiz. Aşağıdaki hizmetler için sizden ücret alacağız:",
    "bank_account": "Banka bilgileri",
    "mandatory_field": "Bunu zorunlu bir alan olarak beyan edin",
    "custom_checkbox": "Özelleştirilmiş onay kutusu",
    "set_label_checkbox": "Lütfen onay kutusu için bir etiket atayın",
    "set_checkbox_link": "Etiketi buraya bağlayabilirsiniz",
    "change_checkbox_link": "Bağlantıyı değiştir",
    "checkbox_infotext": "İlgili alanı $ karakterleriyle yerleştirerek etiketi bağlayabilirsiniz. ör. $Gizlilik Politikasını$ kabul ediyorum.",
    "perforation": {
      "cart_radiobox_title": "Dosyalama perforasyonu için seçenekler",
      "attribute_is_custom_fa_setting": "Özelleştirilmiş FA önceden seçilmiş perforasyon seçenekleri",
      "set_perforation_options": "Perforasyon seçeneklerini ayarlama",
      "preset": "Varsayılan ayar",
      "how_to": "Lütfen ürün grubu tanımlayıcısını arayın, bazı varyantları seçin, farklı miktarları ve gönderim yöntemlerini deneyin. Ardından bu özellik ile önceden seçilecek perforasyon seçeneğini seçin. Birkaç seçenek eklenebilir.",
      "select_variant": "Miktarları ve gönderim yöntemlerini görüntülemek için lütfen bir varyant seçin.",
      "select_shipping_type": "Mevcut seçenekleri görmek için lütfen bir gönderim yöntemine tıklayın.",
      "select_option_value": "Seçime eklemek için lütfen bir seçeneğin üzerine tıklayın.",
      "please_try_other": "Lütfen başka bir varyant, miktar veya gönderim yöntemi deneyin",
      "options": "Seçenekler",
      "other_options": "Diğer seçenekler",
      "selected_options": "Seçilen seçenekler",
      "perforation_options": "Perforasyon seçenekleri",
      "product_group": "Ürün grubu",
      "variants": "Varyantlar",
      "quantities_in_variant": "Varyanttaki miktarlar"
    },
    "textile_select_exactly_n_items": "Lütfen tam olarak {toplam} seçiniz",
    "textile_exact_number_selected": "Hepsi iyi: tam olarak {toplam} seçilmiş makaleler",
    "textile_order_quantity_mismatch": "Kumaş boyutlarının sayısı seçilen baskıya uygun olmalıdır.",
    "tracking_pixel_help_text": "Uygulamanın sağlayıcıya bağlı olarak farklılık gösterebileceğini lütfen unutmayın. Örneğimizde, Meta'nın izleme pikselini kullanıyoruz. Entegrasyon öncelikle alan adı doğrulaması gerektirir. Bunu yapmak için, örneğimizde Meta tarafından sağlanan bir meta etiketi entegre etmeniz gerekir. Aşağıdaki örnek koda bakın. Sağlayıcıya bağlı olarak doğrulamanın 72 saate kadar sürebileceğini lütfen unutmayın. İkinci adımda, izleme pikseli entegrasyonu için temel kodu ekleyin. Daha fazla bilgi için lütfen ilgili sağlayıcınız tarafından sağlanan belgeleri okuyun.",
    "reg_greeting_male": "Sayın Bay",
    "reg_greeting_female": "Sevgili Bayan",
    "reg_gender_male": "Bay",
    "reg_gender_female": "Bayan",
    "reg_name": "Selamlama ve isim",
    "reg_phone_number": "Telefon numarası",
    "reg_domain": "Etki Alanı",
    "reg_ancestors": "Yapı Kimlikleri",
    "reg_explanation": "<p>Kayıt işleminiz için teşekkür ederiz. Lütfen e-posta adresinizi 24 saat içinde onaylayın.</p><p>Sadece onaylanmış bir e-posta adresi ile yeni bir şifre veya değişiklikler hakkında bilgi alabilirsiniz.</p> <p>",
    "widget_order_stats": {
      "title": "Toplam siparişler",
      "orders_count": "Siparişleri sayın",
      "orders_gross_total": "Toplam brüt siparişler",
      "no_orders_in_date_range": "Seçilen dönem içinde sipariş yok",
      "loading": "Yükleniyor",
      "this_month": "Bu ay",
      "previous_month": "Geçen ay",
      "last_3_months": "Son 3 ay",
      "last_6_months": "Son 6 ay",
      "last_12_months": "Son 12 ay",
      "for_companies": "şirket(ler) için",
      "for_clients": "müşteri(ler) için",
      "title1": "Toplam siparişler",
      "title2": "En çok sipariş edilen 10 ürün",
      "items_count": "Ürün sayısı",
      "item_types_count": "Ürünler (tipler)"
    },
    "cart_domain": "Belirttiğiniz alan adı",
    "cart_domain_available": "hazır!",
    "cart_domain_not_available": "artık mevcut değil!",
    "cart_domain_not_available_title": "Alan adı mevcut değil",
    "pages_to_delete": "Aşağıdaki sayfalar kaldırılacaktır:",
    "page_to_delete": "Sayfa",
    "sales_doc_reverse_charge": "Fatura KDV'siz olarak düzenlenir, çünkü bu durumda ters ücret prosedürü uygulanır. KDV, hizmetin alıcısı tarafından beyan edilmeli ve ödenmelidir.",
    "invoice_for_service": "Hizmet faturası",
    "phone_invalid_country": "Telefon numarasının ülke kodu geçersiz",
    "mobile_phone_invalid_country": "Cep telefonu numarasının ülke kodu geçersiz",
    "fax_invalid_country": "Faks numarasının ülke kodu geçersiz",
    "search_campaign": "Kampanyaları arayın",
    "coupon_usage": "Kupon kullanımı",
    "coupon_balance_title": "Kupon bilançosu",
    "get_coupon_balance": "Kalan miktarı sorgula",
    "total_coupon_budget": "Toplam bütçe:",
    "remaining_coupon_budget": "Kalan bütçe:",
    "spent_coupon_budget": "Harcanan bütçe:",
    "coupon_balance_success": "Sorgu başarılı",
    "upload_print_data_previews": "Baskı verileri önizleme görüntülerini yükleme",
    "uploaded_print_data_previews": "Yüklenen baskı verileri Görüntüleri önizleme",
    "confirm_upload": "Onaylayın",
    "image_minimum_size": "Resim minimum boyuta karşılık gelmiyor!",
    "data_policy_info": "Veri koruma ve baskı",
    "fill_data_policy_before_save": "Lütfen kaydetmeden önce veri koruma ve yasal uyarı bilgilerini doldurun. Bunları ayarlarda bulabilirsiniz",
    "subscription_liu_subscribe": "Abone Olun",
    "subscription_liu_cancel": "İptal",
    "subscription_liu_extend": "Genişletme",
    "subscription_status_changed_to_active": "Durum aktif olarak değiştirildi!",
    "subscription_status_changed_to_inactive": "Durum aktif değil olarak değiştirildi!",
    "sub_start_end": "Başlangıç tarihi bitiş tarihinden büyük olamaz!",
    "subscription_liu_send": "Gönderme",
    "subscription_to_client": {
      "subscription_not_available": "Lütfen bir abonelik seçin!",
      "type_parameter_not_present": "type% parametresi eksik",
      "date_parameter_not_present": "type% parametresi eksik",
      "end_date_greater_then_start_date": "Bitiş tarihi başlangıç tarihinden büyük olamaz!",
      "end_date_less_then_first_day_of_next_month": "Bitiş tarihi bir sonraki ayın ilk tarihinden daha erken olamaz!"
    },
    "subscription_billing_period": "Muhasebe dönemi",
    "mail_greeting_female": "Merhaba Bayan",
    "mail_greeting_male": "Merhaba Bay",
    "mail_greeting_first_name_form": "Merhaba",
    "mail_preview": "E-posta önizlemesi",
    "mail_preview_update": "Önizlemeyi başlat/güncelle",
    "mail_preview_close": "Kapat",
    "mail_preview_hint": "Lütfen önizlemenin yalnızca düzeni ve mesajları kontrol etmek için olduğunu unutmayın. Tüm şablonlar sahte veriler içerir.",
    "tracking_details": "Daha fazla bilgi için takip numarasına tıklayın",
    "ups_tracking_title": "API üzerinden gönderi takibi (UPS)",
    "ups_tracking_button": "Sorgu durumu",
    "tracking_informations": "Konsinye bilgi",
    "ups_tracking_status": "Gönderinizin durumu (UPS)",
    "shipment_package": "Paket",
    "shipment": "Program",
    "shipment_package_current_status": "Mevcut durum",
    "shipment_package_current_status_description": "Açıklama",
    "shipment_package_delivery_date": "Teslim tarihi",
    "shipment_package_delivery_location": "Nereden alınacağı",
    "shipment_package_delivery_receivedBy": "Şuradan alınıyor",
    "tracking_error": "Sorgu sırasında bir hata oluştu!",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Web sitesi (arama motorları) ve PDF (erişilebilirlik) için başlık",
    "basic_billing_settings": "Temel faturalama ayarları",
    "currency_and_vat": "Para Birimi ve KDV",
    "basic_client_settings": "Temel ayarlar",
    "client_settings_info_text": {
      "info_text_phone": "Sabit hat numaranızı doğru girmek için lütfen ülke kodu da dahil olmak üzere tam numarayı girin. Boşluk veya özel karakter kullanmadığınızdan emin olun. Almanya için örnek: +49XXXXXXXXXXX.",
      "info_text_mobile": "Cep telefonu numaranızı doğru girmek için lütfen ülke kodu da dahil olmak üzere tam numarayı girin. Boşluk veya özel karakter kullanmadığınızdan emin olun. Almanya için örnek: +49XXXXXXXXXXX.",
      "info_text_demo": "Demo modunu etkinleştirin, örneğin test siparişlerini gerçekleştirmek için.",
      "info_text_image_rights_query": "Bu seçeneği etkinleştirirseniz, her resim yüklediğinizde ek bir bilgi istemi görüntülenecektir. Ardından yüklenen görselin kullanım haklarına sahip olduğunuzu onaylamanız istenecektir. Bu, yüklenen tüm görüntülerin yasal olarak sağlam olduğundan ve herhangi bir telif hakkını ihlal etmediğinden emin olmamıza yardımcı olur.",
      "info_text_pdf_download_option": "Düzenleyicide PDF indirme seçeneğini etkinleştirir.",
      "info_text_needs_content_authorization": "İçerik kontrolünü etkinleştirirseniz, şirket üyeleriniz tarafından verilen tüm siparişler manuel olarak kontrol edilmeli ve onaylanmalıdır.",
      "info_text_main_language": "Uygulamayı esas olarak hangi dilde kullanmak istediğinizi seçin. Bir dil seçerek, kullanıcı arayüzünü ihtiyaçlarınıza göre özelleştiriyoruz.",
      "info_text_password_renew": "Bu seçeneği etkinleştirirseniz, kullanıcıya bir sonraki oturum açışında bir şifre yenileme e-postası göndeririz",
      "info_text_editor_auto_save": "Bu seçeneği etkinleştirirseniz, düzenleyici değişikliklerinizi her 5 dakikada bir otomatik olarak kaydeder."
    },
    "content_check_status_rejected": "Reddedildi",
    "content_check_status_approved": "yetkili",
    "cart_remaining_user_budget": "Kalan kullanıcı bütçesi",
    "cart_remaining_user_budget_after_order": "Siparişten sonra kalan kullanıcı bütçesi",
    "cart_budget_user": "Kullanıcı bütçesi",
    "archived_product_templates": "Arşivlenmiş şablonlar",
    "archived_product_templates_description": "Arşivlenmiş tüm şablonları burada bulabilirsiniz",
    "archive_template": "Arşiv",
    "restore_template": "Geri Yükleme",
    "budget_value_used": "Kullanıldı",
    "budget_value_reserved": "Ayrılmış",
    "budget_value_residual": "Artık değer",
    "double_opt_in": "Çift taraflı onay prosedürü",
    "export_video": "Videoyu dışa aktar",
    "available_actions": "Mevcut eylemler",
    "mail_text_rhenus": {
      "support": "SSS & Yardım",
      "login": "Giriş",
      "thank_you": "Siparişiniz için teşekkür ederiz!",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "Siparişiniz üretildi ve teslimat için kuryeye teslim edildi.",
          "possible_actions_tracking_first_name_form": "Teslimatınızı şu adresten takip edin",
          "possible_actions": "<a href='%domain%'><span class='r_url bold'>%domain%</span></a> altında siparişinizi istediğiniz zaman görüntüleyebilirsiniz."
        }
      },
      "order_item_sales": {
        "explanation": "Siparişinizin detayları aşağıda listelenmiştir:",
        "possible_actions": "<a href='%domain%'><span class='r_url bold'>%domain%</span></a> altında siparişinizi istediğiniz zaman görüntüleyebilirsiniz.",
        "call_to_action": "Sipariş detayları"
      },
      "order_content_check_status": {
        "explanation": "Siparişiniz için teşekkür ederiz. Sipariş %status% içerik kontrolünden sonra gönderilmiştir.",
        "possible_actions": "<a href='%domain%'><span class='r_url bold'>%domain%</span></a> altında siparişinizi istediğiniz zaman görüntüleyebilirsiniz.",
        "call_to_action": "Sipariş detayları"
      },
      "possible_actions_default": "<a href='%domain%'><span class='r_url bold'>%domain%</span></a> altında siparişinizi istediğiniz zaman görüntüleyebilirsiniz.",
      "call_to_action_order_details_default": "Sipariş detayları",
      "contact_form_edition": {
        "subject": "Yeni irtibat",
        "info": "Bir iletişim formunun gönderildiğine dair bildirim. Lütfen bunu derhal işleme alın ve müşterinin mümkün olan en iyi desteği almasını sağlayın.",
        "form_fields": "Tamamlanmış form alanları"
      },
      "contact_form_cms": {
        "subject": "isim%'den yeni kişi",
        "info": "Bir iletişim formunun gönderildiğine dair bildirim. Lütfen bunu derhal işleme alın ve müşterinin mümkün olan en iyi desteği almasını sağlayın."
      },
      "password_renew": {
        "greeting": "Merhaba %email%,",
        "explanation_first_name_form": "PlatformAdı% 'na giriş yapmayı denediniz. Parolanızın süresi doldu. Gelecekte oturum açabilmek için yeni bir parola belirlemelisiniz."
      },
      "password_reset": {
        "greeting": "Merhaba %email%,",
        "explanation_first_name_form": "Platformumuz aracılığıyla %platformAdi% 'ndan yeni bir şifre talep edilmiştir. Eğer bu talepte bulunduysanız aşağıdaki linke tıklayınız."
      }
    },
    "authorized": "Yetkili",
    "open_in_new_tab": "Açık",
    "subscription_set_availability_period": "Aboneliğin kullanılabilirlik süresini ayarlama",
    "failed_video_generation": "Video oluşturulamadı! Lütfen yüklenen dosyaların geçerli olup olmadığını kontrol edin!",
    "video_uploads_missing": "Baskı bloklarında video yüklemesi bulunamadı!",
    "subscription_video_missing": "Video aboneliği bulunamadı!",
    "download_invoices": "Faturaları indirin",
    "download_user_list": "Kullanıcı listesini indir",
    "cookie_management": "Çerez onayı yöneticisi",
    "cookie_manager_id": "Çerez Yöneticisi URL'si (isteğe bağlı) - ccm19.de",
    "supported_cookie_manger_provider": "Şu anda yalnızca ccm19.de'den Cookie Constent Managers desteklenmektedir.",
    "cookie_management_custom": "Bu ayar isteğe bağlıdır. Varsayılan olarak web sitenize bir çerez izni yöneticisi entegre edilmiştir.",
    "print_accessibility_pdf": "PDF Yazdır (engelsiz PDF)",
    "accessibility_settings": {
      "accessibility": "Erişilebilirlik",
      "language_of_text": "Metnin dili (ülke kodları - ISO 639-1)",
      "language_example": "Belgenizde veya web sitenizde kullandığınız dilin ülke kodunu buraya girin. (Ülke kodları - ISO 639-1) örneğin Almanca için de (standart) veya İngilizce için en",
      "activate_accessibility_pdf": "Erişilebilir PDF - UA-1",
      "bookmarks": "Yer imi",
      "use_bookmark_classes": "Yer imi sınıfları",
      "template_settings_bookmarks_info": "Yer imi olarak ayarlanmış sınıfları (.bookmarks-l1 ila .bookmarks-l6) etkinleştirir. H1-H6 yer imi seçenekleri devre dışı bırakılır.",
      "edition_settings_bookmarks_info_classes": "PDF çıktısında yer imleri ayarlamak için mevcut başlık veya yer imi öğelerini kullanın.",
      "edition_settings_bookmarks_info_h": "Bu seçeneği etkinleştirirseniz, kullanılan başlıklar PDF çıktısında yer imi olarak ayarlanır. Örneğin, 'H1 Yer İşareti', 1 başlığı veya H1 etiketi içeren tüm öğeler için yer işaretleri ayarlar.",
      "accessible_pdf_infos": "Erişilebilir PDF belgeleri için gereklilikler",
      "accessible_pdf_infos_1": "1. meta veri: Bir başlık ve içeriğin kısa bir özetini belirleyin.",
      "accessible_pdf_infos_2": "2. Dil ayarları: Sürümünüzde kullandığınız ilgili dili ayarlayın.",
      "accessible_pdf_infos_3": "3. Etiketli (etiketli) PDF: Etiketler ve okuma sırası otomatik olarak ayarlanır.",
      "accessible_pdf_infos_4": "4. Resimler için alternatif metin: Medya merkezinde veya yükleme sırasında \"Açıklamalar\" tanımlama seçeneğiniz vardır. Bunlar resimler için alternatif metin olarak kullanılır.",
      "accessible_pdf_infos_5": "5 Erişilebilir tablolar: Tablo kullanıyorsanız, ilgili başlık satırlarını veya başlık sütunlarını ayarlayın.",
      "accessible_pdf_infos_6": "6. Görüntü olarak metin: Mümkünse metni görüntü olarak kullanmayın",
      "accessible_pdf_infos_7": "7. Okunaklı yazı tipleri: Ekran okuyucu programlar tarafından kolayca çözülebilen yazı tipleri kullanın. Ekran okuyucuların yorumlaması zor olan yazı tiplerinden kaçının.",
      "accessible_pdf_infos_8": "8. Gezinme yardımcıları: Belgede gezinmeyi kolaylaştırmak amacıyla yer imleri, bağlantılar ve içindekiler tablosu oluşturmak için uygun öğeleri (başlık ve yer imi öğeleri) ekleyin. Ayarlarda daha fazla seçenek mevcut olabilir. Ayarlar -> Yer İmleri bölümüne bakın.",
      "accessible_pdf_infos_9": "9. Renk kontrastı: Metinlerin ve arka planların görme engelli kişiler için yeterli kontrast sunduğundan emin olun. Ayırt edilmesi zor renk kombinasyonlarından kaçının."
    },
    "save_options": "Kaydet",
    "print_options": "PDF Dışa Aktar",
    "css_accessibility_pdf": "Erişilebilir PDF çıktısı için CSS",
    "css_accessibility_pdf_info_text": "Erişilebilir PDF'ler oluşturulurken ürün CSS'si tarafından yalnızca 'genel CSS' ve 'erişilebilir PDF'ler için CSS'nin kullanıldığını lütfen unutmayın. 'PDF çıktısı için CSS' ayrıca istemci, beyaz etiket veya şablon tarafından da kullanılır.",
    "payment_options": "Paypal / kredi kartı / otomatik ödeme",
    "default_logo_size": "E-posta logosu. Standart e-posta düzeni için ...px x 175px (Y x G) logo boyutunun gerekli olduğunu lütfen unutmayın. 'Posta logosu' seçeneği de etkinleştirilmelidir.",
    "lead_authorization": {
      "authorization": "Yetkilendirme",
      "status": "Durum",
      "approved": "yetkili",
      "rejected": "Reddedildi",
      "pending": "Olağanüstü",
      "type": "Tip"
    },
    "loading": "Yükleniyor",
    "no_data": "Veri yok",
    "whiteLabel": "WhiteLabel",
    "widget_login_stats": {
      "widget_title": "Giriş istatistikleri",
      "number_of_logins_label_title": "Kayıt sayısı"
    },
    "widget_edition_stats": {
      "widget_title": "Çıktı istatistikleri",
      "social_media_downloads_label_title": "Sosyal medya indirmeleri",
      "total_editions_label_title": "Toplam harcama",
      "amount_of_new_edition_per_date": "Tarih başına yeni ihraç sayısı",
      "amount_of_new_edition_per_template_per_date": "Şablon başına yeni ihraç sayısı"
    },
    "widget_item_stats": {
      "widget_title": "Makale istatistikleri",
      "used_coupons_title": "Kullanılan kuponlar",
      "turnovers_title": "Ciro",
      "for_items": "makaleler için",
      "total_used_coupons_count_label_title": "Kullanılan toplam kupon",
      "total_turnovers_count_label_title": "Toplam satışlar",
      "total_used_coupons_value_label_title": "Kullanılan kuponların toplam değeri",
      "total_turnovers_value_label_title": "Toplam satış değeri"
    },
    "pipeline_owner": "Sahibi",
    "set_to_null": "Sahibi sıfır olarak ayarla",
    "change_page_number_notify": {
      "same_page": "Bu sayfa zaten istenen konumdadır.",
      "fixed_page": "Bu pozisyonun hareket ettirilemeyen sabit bir tarafı vardır.",
      "out_of_range": "Sayfa numarası geçersiz. Lütfen geçerli aralıkta bir sayı girin."
    },
    "change_page_options": "Sayfaları taşımak için ayarları açar",
    "filtered_subscriptions": "Filtrelenmiş abonelikler",
    "filtered_subscriptions_description": "Seçtiğiniz her öğe bu şirketin alt kullanıcıları tarafından kullanılabilir; bir seçim yapmazsanız, hepsi kullanılabilir.",
    "video_export_warning": "Video kaydedildikten sonra başka değişiklik yapmak mümkün değildir. Sonraki değişiklikler için yeni bir reklam ortamı oluşturulmalıdır.",
    "cssPdfX3Compatible": "PDF/X-3 için CSS uyumlu",
    "template_css_convert": {
      "to_pdf_x3_compatible": "CSS'yi PDF/X-3 uyumlu hale dönüştürme",
      "template_has_no_css": "Şablonda CSS yok",
      "css_converted": "CSS dönüştürüldü",
      "css_convert_failed": "CSS dönüştürme başarısız oldu",
      "no_svg_files_to_convert": "Dönüştürme için SVG dosyası yok",
      "svg_files_content_read_problem": "SVG dosya içeriğini okuma sorunu:"
    },
    "save_and_render_video": "Video kaydetme ve işleme",
    "creatomate_video": "Video",
    "creatomate_image": "Resim",
    "choose_video_source": "Video kaynağı",
    "choose_image_source": "Resim kaynağı",
    "url_to_video_file": "Video dosyasının URL'si",
    "url_to_image_file": "Görüntü dosyasının URL'si",
    "upload_image": "Resim yükle",
    "choice_image_file": "Görüntü (jpg/png) dosyasını seçin",
    "duration_image": "Videodaki yer paylaşımının süresi. Standart 3 saniye",
    "image_video_height": "Yükseklik px cinsinden (isteğe bağlı)",
    "image_video_width": "px cinsinden genişlik (isteğe bağlı)",
    "creatomate_width": "px cinsinden genişlik (isteğe bağlı)",
    "creatomate_height": "Yükseklik px cinsinden (isteğe bağlı)",
    "image_resolution_info_text": "Daha sonra kırpmak ve videoda kullanmak için görüntünün yüksekliğini veya genişliğini burada tanımlayın. 0 değeri serbest ölçeklendirmeye izin verir ve video ayarlarında otomatik bir değer ayarlar. Diğer yapılandırma seçenekleri baskı ayarlarında bulunabilir veya 'Diğer ayarlar' düğmesine tıklanabilir.",
    "video_resolution_info_text": "Burada videonun yüksekliğini veya genişliğini tanımlayın. 0 değeri video ayarlarında otomatik bir değer belirler. Diğer yapılandırma seçenekleri baskı ayarlarında bulunabilir veya 'Daha fazla ayar' düğmesine tıklanabilir.",
    "video_settings": "Video ayarları",
    "creatomate_resolution_info_text": "Burada videonun genel yüksekliğini veya genişliğini tanımlayın. Belirtim yok veya 0 video ayarlarında otomatik bir çözünürlük ayarlar",
    "further_settings": "Diğer ayarlar"
  }
}