/**
 * This reducer is not a root entry standalone reducer,
 * but is used as extension inside the /list/* state branch.
 */
import {
  GET_SUBSCRIPTIONS_PENDING,
  GET_SUBSCRIPTIONS_FULFILLED,
  ADD_SUBSCRIPTION_PENDING,
  ADD_SUBSCRIPTION_FULFILLED,
  EDIT_SUBSCRIPTION_PENDING,
  EDIT_SUBSCRIPTION_FULFILLED,
  DELETE_SUBSCRIPTION_PENDING,
  DELETE_SUBSCRIPTION_FULFILLED,
  DEACTIVATE_SUBSCRIPTION_PENDING,
  DEACTIVATE_SUBSCRIPTION_FULFILLED,
  GET_SUBSCRIPTIONS_FOR_USER_PENDING,
  GET_SUBSCRIPTIONS_FOR_USER_FULFILLED,
} from 'actions/SubscriptionsActions';

import {
  CANCEL_SUBSCRIPTION_PRICE_FOR_USER_PENDING,
  CANCEL_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED,
  CANCEL_SUBSCRIPTION_PRICE_FOR_USER_REJECTED,
  ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_PENDING,
  ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED,
  ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_REJECTED,
  EDIT_SUBSCRIPTION_PRICE_FOR_USER_PENDING,
  EDIT_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED,
  EDIT_SUBSCRIPTION_PRICE_FOR_USER_REJECTED,
} from 'actions/SubscriptionPricesActions';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import React from 'react';

export default (state, { type, payload }) => {
  const { subscriptions } = state;
  const { subscriptionsForUser } = state;

  switch (type) {
    case GET_SUBSCRIPTIONS_PENDING:
    case ADD_SUBSCRIPTION_PENDING:
    case EDIT_SUBSCRIPTION_PENDING:
    case DELETE_SUBSCRIPTION_PENDING:
    case DEACTIVATE_SUBSCRIPTION_PENDING: {
      const { data } = subscriptions;
      return { ...state, subscriptions: { ...subscriptions, data: { ...data, pending: true } } };
    }
    case GET_SUBSCRIPTIONS_FULFILLED: {
      const { list } = payload;

      return { ...state, subscriptions: { ...subscriptions, data: { list, pending: false } } };
    }
    case ADD_SUBSCRIPTION_FULFILLED: {
      const { data } = subscriptions;
      const { list } = data;

      return { ...state, subscriptions: { ...subscriptions, data: { list: [payload, ...list], pending: false } } };
    }
    case DELETE_SUBSCRIPTION_FULFILLED: {
      const { data } = subscriptions;
      const { list } = data;
      const { id: deletedId } = payload;

      const newList = list.filter(({ id }) => id !== deletedId);

      return { ...state, subscriptions: { ...subscriptions, data: { list: newList, pending: false } } };
    }
    case EDIT_SUBSCRIPTION_FULFILLED: {
      const { data } = subscriptions;
      const { list } = data;
      const { id: updatedId } = payload;

      const index = list.findIndex(({ id }) => id === updatedId);
      const newList = [...list];
      newList.splice(index, 1, payload);

      return { ...state, subscriptions: { ...subscriptions, data: { list: newList, pending: false } } };
    }
    case DEACTIVATE_SUBSCRIPTION_FULFILLED: {
      const { data } = subscriptions;
      const { list } = data;
      const { id: updatedId } = payload?.data;
      const index = list.findIndex(({ id }) => id === updatedId);
      const newList = [...list];
      newList.splice(index, 1, payload?.data);
      const options = {
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body">
              {payload?.data?.is_active ? (
                <h1>{payload?.messages?.subscription_status_changed_to_active}</h1>
              ) : (
                <h1>{payload?.messages?.subscription_status_changed_to_inactive}</h1>
              )}
              <div className="react-confirm-alert-button-group">
                <>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </Button>
                </>
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };
      confirmAlert(options);
      return { ...state, subscriptions: { ...subscriptions, data: { list: newList, pending: false } } };
    }
    /* Subscriptions in Client Profile Section */
    case GET_SUBSCRIPTIONS_FOR_USER_PENDING: {
      const { data } = subscriptionsForUser;
      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, data: { ...data, pending: true } },
      };
    }
    case GET_SUBSCRIPTIONS_FOR_USER_FULFILLED: {
      const { list } = payload;

      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, data: { list, pending: false } },
      };
    }
    case CANCEL_SUBSCRIPTION_PRICE_FOR_USER_PENDING: {
      const { data } = subscriptionsForUser;
      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, pending: true, data: { ...data } },
      };
    }
    case CANCEL_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED: {
      const { subscriptionsForUser } = state;
      const index = _.findIndex(subscriptionsForUser?.data?.list, sub => sub.id === payload?.subscription?.id);

      const updatedList = [...subscriptionsForUser.data.list];
      if (index !== -1) {
        const indexActiveSub = _.findIndex(
          updatedList?.[index]?.activesubscriptions,
          activeSub => activeSub.id === payload?.id
        );
        if (indexActiveSub !== -1) {
          updatedList[index].activesubscriptions = [];
        }
      }

      return {
        ...state,
        subscriptionsForUser: {
          ...subscriptionsForUser,
          pending: false,
          data: {
            ...subscriptionsForUser.data,
            list: updatedList,
          },
        },
      };
    }

    case ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_PENDING: {
      const { data } = subscriptionsForUser;
      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, pending: true, data: { ...data } },
      };
    }
    case ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED: {
      const { subscriptionsForUser } = state;
      const index = _.findIndex(
        subscriptionsForUser?.data?.list,
        sub => sub.id === payload?.list?.[0]?.subscription?.id
      );

      const updatedList = [...subscriptionsForUser.data.list];
      if (index !== -1) {
        updatedList[index].activesubscriptions.push(payload?.list?.[0]);
      }

      return {
        ...state,
        subscriptionsForUser: {
          ...subscriptionsForUser,
          pending: false,
          data: {
            ...subscriptionsForUser.data,
            list: updatedList,
          },
        },
      };
    }

    case EDIT_SUBSCRIPTION_PRICE_FOR_USER_PENDING: {
      const { data } = subscriptionsForUser;
      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, pending: true, data: { ...data } },
      };
    }

    case EDIT_SUBSCRIPTION_PRICE_FOR_USER_FULFILLED: {
      const { subscriptionsForUser } = state;
      const index = _.findIndex(
        subscriptionsForUser?.data?.list,
        sub => sub.id === payload?.list?.[0]?.subscription?.id
      );

      const updatedList = [...subscriptionsForUser.data.list];
      if (index !== -1) {
        const indexActiveSub = _.findIndex(
          updatedList?.[index]?.activesubscriptions,
          activeSub => activeSub.id === payload?.list?.[0]?.id
        );
        if (indexActiveSub !== -1) {
          updatedList[index].activesubscriptions[indexActiveSub] = payload?.list?.[0];
        }
      }

      return {
        ...state,
        subscriptionsForUser: {
          ...subscriptionsForUser,
          pending: false,
          data: {
            ...subscriptionsForUser.data,
            list: updatedList,
          },
        },
      };
    }

    case CANCEL_SUBSCRIPTION_PRICE_FOR_USER_REJECTED:
    case ACTIVATE_SUBSCRIPTION_PRICE_FOR_USER_REJECTED:
    case EDIT_SUBSCRIPTION_PRICE_FOR_USER_REJECTED: {
      const { data } = subscriptionsForUser;
      return {
        ...state,
        subscriptionsForUser: { ...subscriptionsForUser, pending: false, data: { ...data } },
      };
    }
  }

  return state;
};
